import {
  Col,
  InputNumber,
  Row,
  Popover,
  DatePicker,
  Drawer,
  Table,
  Form,
  Button,
  Select,
  Input,
} from 'antd'

import moment from 'moment'

import React, { useState, useEffect } from 'react'
import styles from './balance.module.scss'
import {
  InfoCircleOutlined,
  PlusOutlined,
  DollarOutlined,
} from '@ant-design/icons'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 150,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: 150,
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    width: 150,
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transactionID',
    width: 150,
  },
  {
    title: 'Site',
    dataIndex: 'site',
    width: 150,
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
    width: 150,
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    width: 150,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    width: 150,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 150,
  },
  {
    title: 'Note',
    dataIndex: 'note',
    width: 150,
  },
]

const data = []
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    id: `A${i}`,
    amount: `${i}`,
    discount: `Giảm ${i}%`,
    transactionID: `090674811${i}`,
    site: `Website bán hàng ${i}`,
    createdBy: `2021/05/03 ${i}`,
    createdDate: `giá trị rỗng ${i}`,
    action: `chưa có ${i}`,
    status: `Active ${i}`,
    note: `chưa có ${i}`,
  })
}
export default function Balance() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal1Visible, setModal1Visible] = useState(false)
  const [modal2Visible, setModal2Visible] = useState(false)
  const [visible, setVisile] = useState(false)
  const [balance, setBalance] = useState({})
  const [visibleDeposit, setVisileDeposit] = useState(false)
  const setModal1Visible1 = (modal1Visible) => {
    setModal1Visible(modal1Visible)
  }

  const setModal1Visible2 = (modal2Visible) => {
    setModal2Visible(modal2Visible)
  }
  const { Option } = Select
  function handleChange(value) {

  }
  function handleChangeProductTypeDrawer(value) {

  }
  function handleChangeSitesDrawer(value) {

  }
  function handleChangeStatusProductDrawer(value) {

  }
  function handleChangeSites(value) {

  }
  function handleChangeActions(value) {

  }
  function handleChangeStatus(value) {

  }
  const showDrawerDeposit = () => {
    setVisileDeposit(true)
  }
  const onCloseDeposit = () => {
    setVisileDeposit(false)
  }
  useEffect(() => {
    var data = JSON.parse(localStorage.getItem('balance'))
    setBalance(data)
  }, [])
  const { Search } = Input
  const onSearch = (value) => console.log(value)
  const { RangePicker } = DatePicker
  function onChange(dates, dateStrings) {


  }
  function onChangeDrawer(dates, dateStrings) {


  }
  const showDrawer = () => {
    setVisile(true)
  }
  const onClose = () => {
    setVisile(false)
  }
  const start = () => {
    setLoading(true)
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 1000)
  }

  const onSelectChange = (selectedRowKeys) => {

    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0
  const contentAvailable = (
    <div className={styles['popover']}>
      <div>
        Available amount = Approved Deposit - Paid amount: The money amount that
        is available for charging orders
      </div>
    </div>
  )
  const contentUpcoming = (
    <div className={styles['popover']}>
      <div>
        Upcoming amount = Sum of your non-charge orders – Available amount: Here
        the money you need to make deposit so that all non-charged orders can be
        paid and processed.
      </div>
    </div>
  )
  const contentPending = (
    <div className={styles['popover']}>
      <div>
        Pending deposit = All Deposit that Admin not Approved nor Rejected yet
      </div>
    </div>
  )
  const contentPaid = (
    <div className={styles['popover']}>
      <div>Paid amount = Your total money that is charged on your orders.</div>
    </div>
  )
  const onFinishDeposit = (values) => {

  }

  const onFinishFailedDeposit = (errorInfo) => {

  }
  function onChangeFormatter(value) {

  }

  return (
    <div className={styles['orders_manager']}>
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }
                >
                  <DollarOutlined />
                </div>
                <div>BALANCE OVERVIEW</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div onClick={showDrawerDeposit}>
              <div className={styles['orders_manager_header_row_col_right']}>
                <div>
                  <Button type="primary" size="large">
                    Make a deposit
                  </Button>
                </div>
              </div>
            </div>
            <Drawer
              title="Make deposit"
              width={400}
              onClose={onCloseDeposit}
              visible={visibleDeposit}
              bodyStyle={{ paddingBottom: 80 }}
              footer={null}
            >
              <Form
                layout="vertical"
                hideRequiredMark
                onFinish={onFinishDeposit}
                onFinishFailed={onFinishFailedDeposit}
              >
                <Row gutter={16}></Row>
                <Row gutter={16}>
                  <Col className={styles['title_parent']} span={24}>
                    <div className={styles['title']}>
                      Please make a payment according to this guide then fill
                      the information below:
                    </div>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Transaction ID"
                      name="transactionID"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your transactionID!',
                        },
                      ]}
                    >
                      <Input placeholder="Input transaction ID" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Amount"
                      name="amount"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your amount!',
                        },
                      ]}
                    >
                      <InputNumber
                        className={styles['amount_drawer']}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={onChangeFormatter}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your note!',
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Input notes"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className={styles['deposit_button']} span={24}>
                    <Form.Item>
                      <Button
                        style={{ width: '5rem' }}
                        icon={<PlusOutlined />}
                        type="primary"
                        htmlType="submit"
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </Col>
        </Row>
        <Row className={styles['orders_manager_header_bottom']}>
          <Col
            className={styles['orders_manager_header_bottom_col']}
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
          >
            <div className={styles['orders_manager_header_bottom_col_parent']}>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_child']
                }
              >
                <div>AVAILABLE AMOUNT</div>
                <Popover placement="top" content={contentAvailable}>
                  <div
                    className={
                      styles[
                      'orders_manager_header_bottom_col_parent_child_icon'
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_bottom']
                }
              >
                <div>{`$${balance.available_amount}`}</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_bottom_col']}
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
          >
            <div className={styles['orders_manager_header_bottom_col_parent']}>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_child']
                }
              >
                <div>UPCOMING AMOUNT</div>
                <Popover placement="top" content={contentUpcoming}>
                  <div
                    className={
                      styles[
                      'orders_manager_header_bottom_col_parent_child_icon'
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles[
                  'orders_manager_header_bottom_col_parent_bottom_upcoming'
                  ]
                }
              >
                <div>{`$${balance.upcoming_amount}`}</div>
              </div>
            </div>
          </Col>

          <Col
            className={styles['orders_manager_header_bottom_col']}
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
          >
            <div className={styles['orders_manager_header_bottom_col_parent']}>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_child']
                }
              >
                <div>PENDING DEPOSIT</div>
                <Popover placement="top" content={contentPending}>
                  <div
                    className={
                      styles[
                      'orders_manager_header_bottom_col_parent_child_icon'
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>{' '}
                </Popover>
              </div>

              <div
                className={
                  styles[
                  'orders_manager_header_bottom_col_parent_bottom_pending'
                  ]
                }
              >
                <div>{`$${balance.pending_amount}`}</div>
              </div>
            </div>
          </Col>

          <Col
            className={styles['orders_manager_header_bottom_col']}
            xs={24}
            sm={24}
            md={6}
            lg={6}
            xl={6}
          >
            <div className={styles['orders_manager_header_bottom_col_parent']}>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_child']
                }
              >
                <div>PAID AMOUNT</div>
                <Popover placement="top" content={contentPaid}>
                  <div
                    className={
                      styles[
                      'orders_manager_header_bottom_col_parent_child_icon'
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles['orders_manager_header_bottom_col_parent_bottom_paid']
                }
              >
                <div>{`$${balance.paid_amount}`}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['balance_manager']}>
        <div className={styles['orders_manager_content']}>
          <Row className={styles['orders_manager_content_row']}>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div>
                <Search
                  className={styles['orders_manager_content_row_col_search']}
                  placeholder="Search transaction ID or created by"
                  onSearch={onSearch}
                  enterButton
                />
              </div>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div>
                <RangePicker
                  className={styles['orders_manager_content_row_col_date']}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [
                      moment().startOf('month'),
                      moment().endOf('month'),
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div>
                <Select
                  className={styles['import_orders_content_select_child']}
                  defaultValue="default"
                  onChange={handleChangeSites}
                >
                  <Option value="default">Choose sites</Option>
                  <Option value="viewsoftware">Website bán hàng Vie</Option>
                  <Option value="ecommerce">Website thương mại điện tử</Option>
                </Select>
              </div>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div>
                {' '}
                <Select
                  className={styles['import_orders_content_select_child']}
                  defaultValue="default"
                  onChange={handleChangeActions}
                >
                  <Option value="default">Choose action</Option>
                  <Option value="deposit">Deposit</Option>
                  <Option value="fulfilled">Fulfilled</Option>
                  <Option value="supplier">Paid supplier</Option>
                  <Option value="refund">Refund</Option>
                  <Option value="customerAdd">Customer add</Option>
                  <Option value="customerSubtract">Customer subtract</Option>
                </Select>
              </div>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={4}
              md={4}
              lg={4}
              xl={4}
            >
              <div>
                {' '}
                <Select
                  className={styles['import_orders_content_select_child']}
                  defaultValue="default"
                  onChange={handleChangeStatus}
                >
                  <Option value="default">Choose status</Option>
                  <Option value="approved">Approved</Option>
                  <Option value="upcomingAmount">Upcoming amount</Option>
                  <Option value="pending">Pending</Option>
                  <Option value="rejected">Rejected</Option>
                </Select>
              </div>
              <Drawer
                title="More filters"
                width={250}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                  <div
                    style={{
                      textAlign: 'right',
                    }}
                  >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                      Clear all filter
                    </Button>
                    <Button onClick={onClose} type="primary">
                      Done
                    </Button>
                  </div>
                }
              >
                <Form layout="vertical" hideRequiredMark>
                  <Row gutter={16}></Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="statusProduct"
                        label="Product status"
                        rules={[
                          {
                            required: true,
                            message: 'Please select product status!',
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeStatusProductDrawer}
                        >
                          <Option value="default">Choose product status</Option>
                          <Option value="statusProduct1">
                            Product status 1
                          </Option>
                          <Option value="statusProduct2">
                            Product status 2
                          </Option>
                          <Option value="statusProduct3">
                            Product status 3
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="site"
                        label="Site"
                        rules={[
                          {
                            required: true,
                            message: 'Please select site!',
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeSitesDrawer}
                        >
                          <Option value="default">Choose site</Option>
                          <Option value="site1">Site 1</Option>
                          <Option value="site2">Site 2</Option>
                          <Option value="site3">Site 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="typeProduct"
                        label="Product type"
                        rules={[
                          {
                            required: true,
                            message: 'Please select product type!',
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeProductTypeDrawer}
                        >
                          <Option value="default">Choose product tpye</Option>
                          <Option value="typeProduct1">Product type 1</Option>
                          <Option value="typeProduct2">Product type 2</Option>
                          <Option value="typeProduct3">Product type 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="dateTime"
                        label="DateTime"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose the dateTime',
                          },
                        ]}
                      >
                        <RangePicker
                          style={{ width: '100%' }}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          ranges={{
                            Today: [moment(), moment()],
                            'This Month': [
                              moment().startOf('month'),
                              moment().endOf('month'),
                            ],
                          }}
                          onChange={onChangeDrawer}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </Col>
          </Row>
        </div>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            // rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
          />
        </div>{' '}
      </div>
    </div>
  )
}
