import React, { useEffect } from 'react'

import { Row, Col, notification } from 'antd'

import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'

import { addSite } from 'apis/sites'

export default function AuthApp() {
  const location = useLocation()
  const history = useHistory()

  const _verifyApp = async () => {
    const params = queryString.parse(location.search)

    if (Object.keys(params).length) {
      const nameSite = localStorage.getItem('name_site_amazon')

      const body = {
        ...params,
        name: nameSite || 'Amazon',
        platform: 'Amazon',
        site_name: `sellercentral.amazon.com/${
          params.selling_partner_id || ''
        }`,
        status: true,
        time_collingoff: 0,
        url: '',
        user_admin: 'huynhtrongmandev',
      }
      console.log('body add site amazon: ', body)
      const res = await addSite(body)
      console.log(res)
      if (res.status === 200) {
        notification.success({
          message: 'Authorization success',
        })
      } else
        notification.error({
          message:
            res.data.mess ||
            'The authorization code was not found or was already used',
        })
    }

    history.push(ROUTES.SITES)
  }

  useEffect(() => {
    _verifyApp()
  }, [])

  return (
    <div>
      <Row justify="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            margin: 'auto',
            background: 'rgb(255, 255, 255)',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width="150px"
          height="150px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid">
          <circle cx="30" cy="50" fill="#e90c59" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"></animate>
          </circle>
          <circle cx="70" cy="50" fill="#46dff0" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"></animate>
          </circle>
          <circle cx="30" cy="50" fill="#e90c59" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"></animate>
          </circle>
        </svg>
      </Row>
      <Row justify="center">
        <Col>
          <div style={{ textAlign: 'center' }}>
            Completing connection, this shouldn't take much longer.
          </div>
          <div style={{ textAlign: 'center' }}>
            You will be automatically redirected once complete.
          </div>
        </Col>
      </Row>
    </div>
  )
}
