import React, { useState, useEffect } from 'react'
import styles from './profile.module.scss'

//icons antd
import {
  InstagramOutlined,
  HomeOutlined,
  GiftOutlined,
  PhoneOutlined,
  UserOutlined,
  KeyOutlined,
  MailOutlined,
} from '@ant-design/icons'

//antd
import {
  Input,
  Row,
  Col,
  DatePicker,
  Button,
  notification,
  Modal,
  Form,
} from 'antd'

//apis
import { changePassword, updateUser, getsAllUsers } from 'apis/users'

import { useDispatch } from 'react-redux'

import { decodeToken } from 'react-jwt'
import { ACTION } from 'consts'
import moment from 'moment'
import profile from '../../assets/img/profile.png'

export default function Profile() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formPassword] = Form.useForm()
  const dateFormat = 'DD/MM/YYYY'

  const [birthday, setBirthday] = useState(
    moment(new Date('1999-09-19')).format('DD/MM/YYYY')
  )
  const [username, setUsername] = useState('')

  const _updateUser = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const dataUpdateUser = form.getFieldsValue()
      const body = {
        ...dataUpdateUser,
        birthday,
        username,
      }
      console.log(JSON.stringify(body))
      const res = await updateUser(body)
      console.log(res)
      if (res.status === 200) {
        await getInfoUserById()
        notification.success({ message: 'Update success!' })
      } else notification.error({ message: 'Update failed!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinishChangePassword = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const data = formPassword.getFieldsValue()
      const object = {
        oldpassword: data.passwordOld,
        password: data.passwordNew,
      }
      const res = await changePassword(object)
      console.log(res)
      if (res.status === 200) {
        notification.success({
          message: 'Success',
          description: 'Change password successfully',
        })
      } else
        notification.error({
          message: 'Error',
          description: 'Current password is not correct',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const ModalChangePassword = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <Button onClick={toggle} style={{ width: '10rem' }} type="primary">
          Change password
        </Button>
        <Modal
          title="Change Password"
          centered
          visible={visible}
          onCancel={toggle}
          onOk={async () => {
            await onFinishChangePassword()
            toggle()
            formPassword.resetFields()
          }}
        >
          <Form form={formPassword} layout="vertical">
            <Form.Item
              label="Current password"
              name="passwordOld"
              rules={[{ required: true, message: 'Please input password old' }]}
            >
              <Input.Password
                onPressEnter={async () => {
                  await onFinishChangePassword()
                  toggle()
                  formPassword.resetFields()
                }}
                allowClear
                placeholder="input password old"
              />
            </Form.Item>
            <Form.Item
              label="New password"
              name="passwordNew"
              rules={[{ required: true, message: 'Please input password new' }]}
            >
              <Input.Password
                onPressEnter={async () => {
                  await onFinishChangePassword()
                  toggle()
                  formPassword.resetFields()
                }}
                allowClear
                placeholder="input password new"
              />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const getInfoUserById = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await getsAllUsers()
      if (res.data.success) {
        const dataAccessToken = decodeToken(localStorage.getItem('accessToken'))
        if (dataAccessToken) {
          const userInfo = res.data.data.find(
            (e) => e._id === dataAccessToken._id
          )
          if (userInfo) {
            form.setFieldsValue({ ...userInfo })
            setBirthday(userInfo.birthday)
            setUsername(userInfo.username)
          }
        }
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  useEffect(() => {
    getInfoUserById()
  }, [])

  return (
    <div className={styles['profile']}>
      <div
        style={{
          borderBottom: '1px solid rgb(236, 223, 223)',
          paddingBottom: '1rem',
        }}
      >
        <UserOutlined
          style={{
            fontSize: '1.25rem',
            fontWeight: '600',
          }}
        />
        <div
          style={{
            fontSize: '1rem',
            fontWeight: '600',
            marginLeft: '0.5rem',
          }}
        >
          Account overview
        </div>
      </div>
      <div style={{ width: '100%', marginTop: '1.5rem' }}>
        <Row justify="center" style={{ width: '100%' }}>
          <img
            src={profile}
            style={{ width: '7.5rem', height: '7.5rem', objectFit: 'contain' }}
            alt=""
          />
        </Row>
      </div>
      <Row justify="space-between" style={{ width: '100%' }}>
        <Col style={{ width: '100%', marginTop: '1rem' }}>
          <Form className={styles['profile__right']} form={form}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Col
                style={{ width: '100%', marginTop: '1rem' }}
                xs={24}
                sm={24}
                md={11}
                lg={11}
                xl={11}
              >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <UserOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      color: 'black',
                      fontWeight: '600',
                    }}
                  >
                    First name
                  </div>
                </div>

                <Form.Item name="first_name">
                  <Input placeholder="input first name" />
                </Form.Item>
              </Col>
              <Col
                style={{ width: '100%', marginTop: '1rem' }}
                xs={24}
                sm={24}
                md={11}
                lg={11}
                xl={11}
              >
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <UserOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      fontWeight: '600',
                    }}
                  >
                    Last name
                  </div>
                </div>
                <Form.Item name="last_name">
                  <Input placeholder="input last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <MailOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      color: 'black',
                      fontWeight: '600',
                    }}
                  >
                    Email
                  </div>
                </div>
                <Form.Item name="mail" style={{ marginBottom: 0 }}>
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <KeyOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      fontWeight: '600',
                    }}
                  >
                    Password
                  </div>
                </div>
                <ModalChangePassword />
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <PhoneOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      fontWeight: '600',
                    }}
                  >
                    Phone number
                  </div>
                </div>
                <Form.Item name="phone" style={{ marginBottom: 0 }}>
                  <Input placeholder="Input phone number" />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <GiftOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      fontWeight: '600',
                    }}
                  >
                    Birthday
                  </div>
                </div>
                <DatePicker
                  allowClear={false}
                  value={moment(birthday, dateFormat)}
                  format={dateFormat}
                  style={{ width: 200 }}
                  placeholder="Input birthday"
                  onChange={(date, dateString) => {
                    setBirthday(dateString)
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <HomeOutlined />
                  <div
                    style={{
                      marginLeft: '0.25rem',
                      fontWeight: '600',
                    }}
                  >
                    Address
                  </div>
                </div>
                <Form.Item name="address" style={{ marginBottom: 0 }}>
                  <Input placeholder="Input address" />
                </Form.Item>
              </Col>
            </Row>
            <Button
              style={{ width: '100%', marginTop: 16 }}
              icon={<InstagramOutlined />}
              type="primary"
              onClick={_updateUser}
            >
              Save
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  )
}
