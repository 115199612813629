import { get, post } from './httpClient'

export const getOrders = (limit) => get('/order', limit && { limit })
export const orders = (params) => get('/order', { ...params })
export const listCountOrder = () => get('/order/countorder')
export const getAllSite = (body) => get('/site/getallsite', body)
export const searchOrders = (values) =>
  get('/order', { index: '100', ...values })
export const updateStatusOrders = (object) =>
  post('/order/updatestatus', object)
export const updateOrders = (object) => post('/order/updateorder', object)
export const getSupplier = () =>
  get('/user/getuserbyrole', { role: 'Supplier' })
export const resendOrder = (object) => post('/order/resend', object)
export const refundOrder = (object) => post('/order/refund', object)
export const rebuildOrder = (object) => post('/order/rebuild', object)
export const getOrdersArchive = (params) => get('/order/orderarchived', params)
export const pushNocustom = (body) => post('/order/pushnocustom', body)
export const asyncOrders = (site_name) =>
  post('/order/syncorder', { site_name })
export const repairOrder = (body) => post('/order/repair', body)
export const exportOrders = (params) => get('/order/export', params)
export const trackingNeedUpload = () =>
  get('/trackingmore/gettrackingneedupload')
export const uploadTracking = () => post('/trackingmore/uploadtracking')
export const importOrders = (body) => post('/order/addorder', body)
