import * as types from "../../../constants/index";
// nhận data từ server
const initialState = false;


var register = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER: {

      var register = {
        email: action.data.mail,
        recoverycode: action.data.recoverycode
      }
      localStorage.setItem('register', JSON.stringify(register));
      //   var {status} = action.data;
      //   
      //   state = status;

      //   
      //   return state;
    }
    // case types.TEST: {
    //   
    //   state = action.data;
    //   return state;
    // }
    default: return state;
  }
};
export default register;
