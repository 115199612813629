import {
  Col,
  InputNumber,
  Row,
  Popover,
  Modal,
  DatePicker,
  Drawer,
  Table,
  notification,
  Form,
  Button,
  Select,
  Input,
} from "antd";

import moment from "moment";

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION, PERMISSIONS } from "consts";
import Permission from 'components/permission'
import {
  getSupplier,
  updateStatusTransaction,
  getAllSupplierSelect,
  selectSupplier,
  createTransaction,
  searchTransactionDate,
  searchTransaction,
} from "apis/transaction";
import styles from "./supplier-transaction.module.scss";
import {
  InfoCircleOutlined,

  EditOutlined,
  WalletOutlined,

  DownloadOutlined,

} from "@ant-design/icons";

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    supplier: `supplier ${i}`,
    id: `id ${i}`,
    amount: `${i}`,
    transactionID: `transaction ${i}`,
    createdBy: `2021/05/0${i}`,
    createdDate: `2021/06/0${i}`,
    status: `status ${i}`,
    note: `note ${i}`,
  });
}
export default function SupplierTransaction() {
  const dispatch = useDispatch();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [checkStrictly, setCheckStrictly] = useState(false)
  const [supplier, setSupplier] = useState([]);
  const [form] = Form.useForm();
  const [modal2Visible, setModal2Visible] = useState(false);
  const [visible, setVisile] = useState(false);
  const [gmailSelect, setGmailSelect] = useState("");
  const [supplierSelect, setSupplierSelect] = useState([]);
  const [balance, setBalance] = useState({});
  const [visibleDeposit, setVisileDeposit] = useState(false);
  const setModal1Visible1 = (modal1Visible) => {
    setModal1Visible(modal1Visible);
  };

  // const showDrawer = () => {
  //   setVisibleDrawer(true)

  // };

  // const onClose = () => {
  //   setVisibleDrawer(false)
  // };

  const setModal1Visible2 = (modal2Visible) => {
    setModal2Visible(modal2Visible);
  };
  const { Option } = Select;
  function handleChange(value) {

  }
  function handleChangeProductTypeDrawer(value) {

  }
  function handleChangeSitesDrawer(value) {

  }
  function handleChangeStatusProductDrawer(value) {

  }
  function handleChangeSites(value) {

  }
  function handleChangeActions(value) {

  }

  const searchTransactionStatusData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await searchTransaction({ status: value });

      if (res.status === 200) {
        setSupplier(res.data)
      }
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function handleChangeStatus(value) {

    if (value === 'default') {
      getSupplierData();
    }
    else {
      searchTransactionStatusData(value);
    }
  }
  const showDrawerDeposit = () => {
    setVisileDeposit(true);
  };
  const onCloseDeposit = () => {
    setVisileDeposit(false);
  };
  const setModal2VisibleModal = (modal2Visible) => {
    setModal2Visible(modal2Visible);
  };
  useEffect(() => {
    var data = JSON.parse(localStorage.getItem("balance"));
    setBalance(data);
  }, []);
  const { Search } = Input;
  const searchTransactionData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });

      const res = await searchTransaction({ search: value });

      if (res.status === 200) setSupplier(res.data)
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const typingTimeoutRef = useRef(null);
  const onSearch = (e) => {

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value;
      searchTransactionData(value);
    }, 300);
    // 
  };
  const { RangePicker } = DatePicker;
  const searchTransactionDateData = async (data1, data2) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await searchTransactionDate({ startDate: data1, endDate: data2 });

      if (res.status === 200) setSupplier(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function onChange(dates, dateStrings) {
    if (dateStrings[0] && dateStrings[0].length > 0 && dateStrings[1] && dateStrings[1].length > 0) {
      while (dateStrings[0].indexOf("-") !== -1) {
        dateStrings[0] = dateStrings[0].replace("-", "/");
      }
      while (dateStrings[1].indexOf("-") !== -1) {
        dateStrings[1] = dateStrings[1].replace("-", "/");
      }


      searchTransactionDateData(dateStrings[0], dateStrings[1]);
    }
    else {
      getSupplierData();
    }

  }
  function onChangeDrawer(dates, dateStrings) {


  }
  const showDrawer = () => {
    setVisile(true);
  };
  const onClose = () => {
    setVisile(false);
  };
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  const selectSupplierData = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await selectSupplier({ idSupplier: username });

      // if (res.status === 200) setSupplier(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function handleChangeSeller(value) {


    if (value === "default") {
      getSupplierData();
    } else {
      supplierSelect.forEach((values, index) => {
        if (values.supplier === value) {
          selectSupplierData(values.username);
        }
      })
    }
  }
  const [selectedRowKeysMain, setSelectedRowKeysMain] = useState([])
  const onSelectChange = (selectedRowKeys, checkStrictly) => {


    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeysMain(checkStrictly)
  };
  const rowSelection = {
    selectedRowKeys, checkStrictly,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const contentAvailable = (
    <div className={styles["popover"]}>
      <div>
        Available amount = Approved Deposit - Paid amount: The money amount that
        is available for charging orders
      </div>
    </div>
  );
  const contentUpcoming = (
    <div className={styles["popover"]}>
      <div>
        Upcoming amount = Sum of your non-charge orders – Available amount: Here
        the money you need to make deposit so that all non-charged orders can be
        paid and processed.
      </div>
    </div>
  );
  const contentPending = (
    <div className={styles["popover"]}>
      <div>
        Pending deposit = All Deposit that Admin not Approved nor Rejected yet
      </div>
    </div>
  );
  const contentPaid = (
    <div className={styles["popover"]}>
      <div>Paid amount = Your total money that is charged on your orders.</div>
    </div>
  );
  const openNotification = () => {
    notification.success({
      message: "Success",
      description: "Successful transaction creation!!!",
    });
  };
  const [actions, setActions] = useState(0);
  const [status, setStatus] = useState(false);
  const onFinishDeposit = (values) => {

    var role = JSON.parse(localStorage.getItem("role"));
    var username = JSON.parse(localStorage.getItem("username"));
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDay();
    var object = {
      idTransaction: values.transactionID,
      amount: values.amountMoney,
      discount: 0,
      // action: "SubtractCustom",
      createdBy: username,
      createdDate: `${year}/${month}/${day}`,
      idSite: "",
      note: values.note,
      status: role === "Admin" ? "approve" : "pending",
      idSeller: username,
      idSupplier: values.supplier,
    };
    if (actions < 0) {
      object.action = "SubtractCustom";
    } else if (actions === 0) {
      object.action = "Deposit";
    } else {
      object.action = "AddCustom";
    }

    // if (actions < 0) {
    //   object = {
    //     idTransaction: values.transactionID,
    //     amount: values.amountMoney,
    //     discount: 0,
    //     action: "SubtractCustom",
    //     createdBy: username,
    //     createdDate: `${year}/${month}/${day}`,
    //     idSite: "",
    //     note: values.note,
    //     status: role === "Admin" ? "approve" : "pending",
    //     idSeller: username,
    //     idSupplier: values.supplier,
    //   };
    // } else if (actions === 0) {
    //   object = {
    //     idTransaction: values.transactionID,
    //     amount: values.amountMoney,
    //     discount: 0,
    //     action: "Deposit",
    //     createdBy: username,
    //     createdDate: `${year}/${month}/${day}`,
    //     idSite: "",
    //     note: values.note,
    //     status: role === "Admin" ? "approve" : "pending",
    //     idSeller: username,
    //     idSupplier: values.supplier,
    //   };
    // } else {
    //   object = {
    //     idTransaction: values.transactionID,
    //     amount: values.amountMoney,
    //     discount: 0,
    //     action: "AddCustom",
    //     createdBy: username,
    //     createdDate: `${year}/${month}/${day}`,
    //     idSite: "",
    //     note: values.note,
    //     status: role === "Admin" ? "approve" : "pending",
    //     idSeller: username,
    //     idSupplier: values.supplier,
    //   };
    // }

    const createTransactionData = async () => {
      try {
        dispatch({ type: ACTION.LOADING, data: true });
        // 
        const res = await createTransaction(object);

        if (res.status === 200) {
          setStatus(res.data.status);
          await getSupplierData();
          openNotification();
          onCloseDeposit();
          // setVisileDeposit(false);
        }
        dispatch({ type: ACTION.LOADING, data: false });
        // openNotification();
        // history.push(ROUTES.NEWS);
      } catch (error) {

        dispatch({ type: ACTION.LOADING, data: false });
      }
    };
    createTransactionData();
  };

  const onFinishFailedDeposit = (errorInfo) => {

  };
  function onChangeFormatter(value) {

    setActions(value);
  }
  const [statusData, setStatusData] = useState([]);
  const getSupplierData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getSupplier();

      if (res.status === 200) {
        setSupplier(res.data);
        setStatusData(res.data)
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  useEffect(() => {
    getSupplierData();
  }, []);
  const getAllSupplierSelectData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getAllSupplierSelect();

      if (res.status === 200) {
        var array = [];
        res.data.data.forEach((values, index) => {
          array.push({ supplier: `${values.first_name} ${values.last_name}`, username: values.username })
        })

        setSupplierSelect(array);
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  useEffect(() => {
    getAllSupplierSelectData();
  }, []);
  const openNotificationUpdateStatus = () => {
    notification.success({
      message: "Success",
      description: "Status update successful!!!",
    });
  };
  const openNotificationUpdateStatusError = () => {
    notification.error({
      message: "Error",
      description: "Can only be changed from pending to approved or denied!!!",
    });
  };
  const Setting = (props) => (

    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      }}
      site={props.site}
    >
      <Permission permissions={[PERMISSIONS.update_balance]}>
        {props.site.status === "pending" ? (
          <div
            onClick={() => onClickTableEdit(props)}
            style={{ marginRight: "0.5rem", cursor: "pointer" }}
          >
            <EditOutlined style={{ color: "#36A0FB", fontSize: "1.25rem" }} />
          </div>
        ) : (
          <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
            <EditOutlined style={{ color: "grey", fontSize: "1.25rem" }} />
          </div>
        )}
      </Permission>
    </div>

  );
  const columns = [
    {
      title: "Supplier",
      dataIndex: "nameSupplier",
      width: 150,
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
    },
    {
      title: "ID",
      dataIndex: "idSupplier",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 150,
    },
    {
      title: "Transaction ID",
      dataIndex: "idTransaction",
      width: 150,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      width: 150,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 150,
      render: (text) => text && moment(text).format('YYYY-MM-DD')
    },
    {
      title: "Note",
      dataIndex: "note",
      width: 150,
    },
    {
      title: "Setting",
      dataIndex: "setting",
      width: 150,
      render: (text, record) => <Setting site={record} />,
    },
  ];
  const onClickTableEdit = (props) => {

    setModal2Visible(true);
    const updateStatus = form.getFieldValue();
    updateStatus.status = props.site.status;
    updateStatus.id = props.site._id;
    updateStatus.amount = props.site.amount;
    updateStatus.transactionID = props.site.idTransaction;
    updateStatus.createdBy = props.site.createdBy;
    updateStatus.createdDate = props.site.createdDate;
    updateStatus.note = props.site.note;
  };
  const onFinishUpdateTransaction = (values) => {

    if (values.status === "pending") {
      openNotificationUpdateStatusError();
      // setModal2VisibleModal(false);
    } else {
      var object = {
        idTransaction: values.transactionID,
        status: values.status,
      };

      updateStatusTransactionData(object);
    }
  };
  const updateStatusTransactionData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await updateStatusTransaction(object);

      if (res.status === 200) {
        openNotificationUpdateStatus();
        setSelectedRowKeys([])
        getSupplierData();
      }
      dispatch({ type: ACTION.LOADING, data: false });
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const onFinishFailedUpdateTransaction = (errorInfoy) => {

  };
  var arrayStatus = [];
  statusData.forEach((values, index) => {
    arrayStatus.push(values.status.toLowerCase());
  })

  const unique = [...new Set(arrayStatus)];

  const onClickAccept = () => {
    var temp = 0;
    var idTransaction;
    selectedRowKeysMain.forEach((values, index) => {
      if (values.status === 'pending') {
        temp++;
        var object = {
          idTransaction: values.idTransaction,
          status: 'approve',
        };

        updateStatusTransactionData(object);
      }
      else {
      }
    })
    if (temp > 0) {

    }
    else {
      setSelectedRowKeys([])
      openNotificationUpdateStatusError();
    }
  }
  const onClickDecline = () => {
    setSelectedRowKeys([])
  }
  return (
    <div className={styles["orders_manager"]}>
      <div className={styles["orders_manager_header"]}>
        <Row className={styles["orders_manager_header_row"]}>
          <Col
            className={styles["orders_manager_header_row_col"]}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div
              className={styles["orders_manager_header_row_col_parent_select"]}
            >
              <div className={styles["orders_manager_header_row_col_parent"]}>
                <div
                  className={
                    styles["orders_manager_header_row_col_parent_icon"]
                  }
                >
                  <WalletOutlined />
                </div>
                <div>BALANCE OVERVIEW</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Select
                  defaultValue="default"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onChange={handleChangeSeller}
                >
                  <Option value="default">All supplier</Option>
                  {supplierSelect.map((values, index) => {
                    return (
                      <Option value={values.supplier}>{values.supplier}</Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Col>
          <Col
            className={styles["orders_manager_header_row_col"]}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div
              className={styles["orders_manager_header_row_col_parent_fix"]}
              onClick={showDrawerDeposit}
            >
              <div className={styles["orders_manager_header_row_col_download"]}>
                <div style={{ marginRight: "0.5rem" }}>
                  <DownloadOutlined style={{ fontSize: "1rem" }} />
                </div>
                <div>Export</div>
              </div>
              <div className={styles["orders_manager_header_row_col_right"]}>
                <div>
                  <Button type="primary" size="large">
                    New transaction
                  </Button>
                </div>
              </div>
            </div>
            <Drawer
              title="New transaction"
              width={400}
              onClose={onCloseDeposit}
              visible={visibleDeposit}
              bodyStyle={{ paddingBottom: 80 }}
              footer={null}
            >
              <Form
                layout="vertical"
                hideRequiredMark
                style={{ width: "100%" }}
                onFinish={onFinishDeposit}
                onFinishFailed={onFinishFailedDeposit}
              >
                <Row gutter={16}>
                  {/* <Col className={styles["title_parent"]} span={24}>
                    <div className={styles["title"]}>
                      Please make a payment according to this guide then fill
                      the information below:
                    </div>
                  </Col> */}
                  <Col style={{ width: "100%" }} span={24}>
                    <Form.Item
                      style={{ width: "100%", color: "black" }}
                      name="supplier"
                      label="Select supplier"
                      rules={[
                        {
                          required: true,
                          message: "Please select an supplier",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select supplier"
                      >
                        {supplierSelect.map((values, index) => {
                          return (
                            <Option value={values.username}>
                              {values.username}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "100%" }} span={12}>
                    <Form.Item
                      style={{ width: "100%", color: "black" }}
                      name="amount"
                      label="Amount"
                      rules={[
                        { required: true, message: "Please select an amount" },
                      ]}
                    >
                      <Select style={{ width: "100%" }} placeholder="Actions">
                        {actions < 0 ? (
                          <Option value="SubtractCustom">SubtractCustom</Option>
                        ) : (
                          ""
                        )}
                        {actions === 0 ? (
                          <Option value="Deposit">Deposit</Option>
                        ) : (
                          ""
                        )}
                        {actions > 0 ? (
                          <Option value="AddCustom">AddCustom</Option>
                        ) : (
                          ""
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ paddingTop: "1.75rem" }} span={12}>
                    <Form.Item
                      style={{ width: "100%" }}
                      // label="Amount"
                      name="amountMoney"
                      rules={[
                        {
                          required: true,
                          message: "Please input your amount!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        defaultValue={0}
                        // className={styles["amount_drawer"]}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={onChangeFormatter}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Transaction ID"
                      name="transactionID"
                      rules={[
                        {
                          required: true,
                          message: "Please input your transaction ID!",
                        },
                      ]}
                    >
                      <Input placeholder="Input transaction ID" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: "Please input your note!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Input notes"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    // onClick={onCloseDeposit}
                    className={styles["deposit_button"]}
                    span={24}
                  >
                    <Form.Item>
                      <Button
                        style={{ width: "100%", height: "2.5rem" }}
                        // icon={<PlusOutlined />}
                        type="primary"
                        htmlType="submit"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </Col>
        </Row>
        <Row className={styles["orders_manager_header_bottom"]}>
          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>UPCOMING AMOUNT</div>
                <Popover placement="top" content={contentUpcoming}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles[
                  "orders_manager_header_bottom_col_parent_bottom_upcoming"
                  ]
                }
              >
                <div>{`$${balance.upcoming_amount}`}</div>
              </div>
            </div>
          </Col>

          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>PAID AMOUNT</div>
                <Popover placement="top" content={contentPaid}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_bottom_paid"]
                }
              >
                <div>{`$${balance.paid_amount}`}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles["balance_manager"]}>
        <div className={styles["orders_manager_content"]}>
          <Row className={styles["orders_manager_content_row"]}>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={11}
              xl={5}
            >
              <div>
                <Input onChange={onSearch} className={styles["orders_manager_content_row_col_search"]}
                  placeholder="Search by transaction ID" allowClear />

              </div>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={11}
              xl={5}
            >
              <div>
                <RangePicker
                  className={styles["orders_manager_content_row_col_date"]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
            </Col>

            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={11}
              xl={5}
            >
              <div>
                {" "}
                <Select
                  className={styles["import_orders_content_select_child"]}
                  defaultValue="default"
                  onChange={handleChangeStatus}
                >
                  <Option value="default">All status</Option>
                  {
                    unique.map((values, index) => {
                      return (
                        <Option value={values}>{values}</Option>
                      )
                    })
                  }
                  {/* <Option value="approved">Approved</Option>
                  <Option value="upcomingAmount">Upcoming amount</Option>
                  <Option value="pending">Pending</Option>
                  <Option value="rejected">Rejected</Option> */}
                </Select>
              </div>
              <Drawer
                title="More filters"
                width={250}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                      Clear all filter
                    </Button>
                    <Button onClick={onClose} type="primary">
                      Done
                    </Button>
                  </div>
                }
              >
                <Form layout="vertical" hideRequiredMark>
                  <Row gutter={16}></Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="statusProduct"
                        label="Product status"
                        rules={[
                          {
                            required: true,
                            message: "Please select product status!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeStatusProductDrawer}
                        >
                          <Option value="default">Choose product status</Option>
                          <Option value="statusProduct1">
                            Product status 1
                          </Option>
                          <Option value="statusProduct2">
                            Product status 2
                          </Option>
                          <Option value="statusProduct3">
                            Product status 3
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="site"
                        label="Site"
                        rules={[
                          {
                            required: true,
                            message: "Please select site!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeSitesDrawer}
                        >
                          <Option value="default">Choose site</Option>
                          <Option value="site1">Site 1</Option>
                          <Option value="site2">Site 2</Option>
                          <Option value="site3">Site 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="typeProduct"
                        label="Product type"
                        rules={[
                          {
                            required: true,
                            message: "Please select product type!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeProductTypeDrawer}
                        >
                          <Option value="default">Choose product tpye</Option>
                          <Option value="typeProduct1">Product type 1</Option>
                          <Option value="typeProduct2">Product type 2</Option>
                          <Option value="typeProduct3">Product type 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="dateTime"
                        label="DateTime"
                        rules={[
                          {
                            required: true,
                            message: "Please choose the dateTime",
                          },
                        ]}
                      >
                        <RangePicker
                          style={{ width: "100%" }}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          ranges={{
                            Today: [moment(), moment()],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                          }}
                          onChange={onChangeDrawer}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </Col>

            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={11}
              xl={5}
            ></Col>
          </Row>
        </div>
        <div className={styles["orders_manager_tabs_table"]}>
          <Table
            className={styles["orders_manager_tabs_table_child"]}
            rowSelection={rowSelection}
            columns={columns}
            rowKey="_id"
            dataSource={supplier}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
          />

          <Modal
            title="Update Transaction"
            centered
            footer={null}
            width={600}
            visible={modal2Visible}
            onOk={() => setModal2VisibleModal(false)}
            onCancel={() => setModal2VisibleModal(false)}
          >
            <Form
              form={form}
              onFinish={onFinishUpdateTransaction}
              onFinishFailed={onFinishFailedUpdateTransaction}
            >
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Status
                  </div>
                }
                name="status"
                rules={[{ required: true, message: "Please input status!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    ID
                  </div>
                }
                name="id"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Amount
                  </div>
                }
                name="amount"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Transaction ID
                  </div>
                }
                name="transactionID"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Created by
                  </div>
                }
                name="createdBy"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Created Date
                  </div>
                }
                name="createdDate"
              >
                <Input disabled />
              </Form.Item>

              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Note
                  </div>
                }
                name="note"
              >
                <Input disabled />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Form.Item onClick={() => setModal2VisibleModal(false)}>
                  <Button style={{ marginRight: "1rem" }} type="primary" danger>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>

        </div>
        {
          selectedRowKeys && selectedRowKeys.length > 0 ? (<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
            <div onClick={onClickDecline} style={{ marginRight: '1rem' }}><Button style={{ width: '7.5rem', height: '2.5rem' }}>Decline</Button></div>
            <div onClick={onClickAccept}><Button type="primary" style={{ width: '7.5rem', height: '2.5rem' }}>Accept</Button></div>
          </div>) : (<div></div>)
        }
      </div>
    </div>
  );
}
