import React, { useState, useEffect, useRef } from 'react'
import styles from './setting-app.module.scss'
import timezones from 'timezones-list'
import { useDispatch, useSelector } from 'react-redux'

//apis
import { updateAppSetting, getAppSetting } from 'apis/app-setting'
import { uploadFile } from 'apis/upload'

//antd
import {
  Col,
  Row,
  Upload,
  Input,
  Space,
  Select,
  Button,
  Form,
  notification,
} from 'antd'

//icons
import {
  SettingOutlined,
  PlusOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons'
import { ACTION } from 'consts'

export default function SettingApp() {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const setting = useSelector((state) => state.setting)

  const _updateSettingApp = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateAppSetting(body)
      console.log(res)
      if (res.status === 200) {
        await _getSettingApp()
        notification.success({ message: 'Update settings app success' })
      } else
        notification.error({
          message:
            res.data.mess || res.data.message || 'Update settings app failed',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getSettingApp = async () => {
    try {
      const res = await getAppSetting()
      if (res.status === 200) {
        if (res.data.data) {
          form.setFieldsValue({ ...res.data.data })
          dispatch({ type: 'GET_SETTING_APP', data: res.data.data })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (setting) form.setFieldsValue({ ...setting })
  }, [setting])

  return (
    <div className={styles['orders_manager']}>
      <div className={styles['orders_manager_header']}>
        <Row
          justify="space-between"
          className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div className={styles['orders_manager_header_row_col_parent']}>
              <div
                className={styles['orders_manager_header_row_col_parent_icon']}>
                <SettingOutlined />
              </div>
              <div>SETTING APP</div>
            </div>
          </Col>
          <Space>
            <a href="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/10/02/5cb8164e-b733-45ea-89a3-b4c268daf007/hướng dẫn Tracking More.docx">
              <Button type="primary" icon={<VerticalAlignBottomOutlined />}>
                Tutorial Tracking More
              </Button>
            </a>
            <a href="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/10/02/5cb8164e-b733-45ea-89a3-b4c268daf007/Hướng dẫn Wasabi (2).docx">
              <Button type="primary" icon={<VerticalAlignBottomOutlined />}>
                Tutorial Wasabi
              </Button>
            </a>
          </Space>
        </Row>
      </div>
      <div style={{ width: '100%', marginTop: 30 }}>
        <span style={{ fontWeight: 500, fontSize: 15 }}>Logo:</span>
        <Upload
          style={{ marginTop: 10 }}
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          onChange={(info) => {
            if (info.file.status === 'uploading') info.file.status = 'done'
          }}
          data={async (file) => {
            dispatch({ type: ACTION.LOADING, data: true })
            const link_logo = await uploadFile(file)
            if (link_logo) {
              document.querySelector("link[rel*='icon']").href = link_logo || ''
              _updateSettingApp({ link_logo })
            }
          }}>
          {setting ? (
            <img
              src={setting.link_logo || ''}
              alt="avatar"
              style={{ width: '100%' }}
            />
          ) : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </div>

      <Form form={form} style={{ width: '100%' }} layout="vertical">
        <div
          style={{
            width: '100%',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ fontWeight: 500, fontSize: 15 }}>Email primary:</span>
          <Form.Item style={{ marginBottom: 0 }} name="emailPrimary">
            <Input style={{ width: 350 }} placeholder="example@gmail.com" />
          </Form.Item>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ fontWeight: 500, fontSize: 15 }}>
            Key tracking more:
          </span>
          <Form.Item style={{ marginBottom: 0 }} name="keyTrackingMore">
            <Input
              style={{ width: 350 }}
              placeholder="Input key tracking more"
            />
          </Form.Item>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ fontWeight: 500, fontSize: 15 }}>Mail Bot:</span>
          <Space size="large">
            <Form.Item
              style={{ marginBottom: 0 }}
              name="host_mailbot"
              label="Host mail bot">
              <Input style={{ width: 350 }} placeholder="Input host mail bot" />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="port_mailbot"
              label="Port mail bot">
              <Input style={{ width: 350 }} placeholder="Input port mail bot" />
            </Form.Item>
          </Space>
          <Space size="large" style={{ marginTop: 10 }}>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="usernameMailBot"
              label="Username mail bot">
              <Input
                style={{ width: 350 }}
                placeholder="Input username mail bot"
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 0 }}
              name="passwordMailBot"
              label="Password mail bot">
              <Input.Password
                style={{ width: 350 }}
                placeholder="Input password mail bot"
              />
            </Form.Item>
          </Space>
        </div>
        <div
          style={{
            width: '100%',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ fontWeight: 500, fontSize: 15 }}>Wasabi Storage:</span>
          <Space size="large">
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>Access Key Id</span>
                <Form.Item style={{ marginBottom: 0 }} name="accessKeyIdWasabi">
                  <Input
                    style={{ width: 350 }}
                    placeholder="Input access key id"
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 8,
                }}>
                <span style={{ fontSize: 14 }}>Secret Access Key</span>
                <Form.Item style={{ marginBottom: 0 }} name="secretKeyIdWasabi">
                  <Input.Password
                    style={{ width: 350 }}
                    placeholder="Input secret access key"
                  />
                </Form.Item>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 14 }}>Bucket Name</span>
                <Form.Item style={{ marginBottom: 0 }} name="bucketNameWasabi">
                  <Input
                    style={{ width: 350 }}
                    placeholder="Input bucket name"
                  />
                </Form.Item>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 8,
                }}>
                <span style={{ fontSize: 14 }}>Endpoint</span>
                <Form.Item style={{ marginBottom: 0 }} name="endpointWasabi">
                  <Input style={{ width: 350 }} placeholder="Input endpoint" />
                </Form.Item>
              </div>
            </div>
          </Space>
        </div>

        <div
          style={{
            width: '100%',
            marginTop: 30,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span style={{ fontWeight: 500, fontSize: 15 }}>Timezone:</span>
          <Form.Item style={{ marginBottom: 0 }} name="timezone">
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              placeholder="Choose timezone"
              style={{ width: 350 }}>
              {timezones.map((timezone, index) => (
                <Select.Option key={index} value={timezone.tzCode}>
                  {timezone.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>

      <Row style={{ width: '100%' }} justify="end">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            const dataForm = form.getFieldsValue()
            _updateSettingApp(dataForm)
          }}>
          Save Settings
        </Button>
      </Row>
    </div>
  )
}
