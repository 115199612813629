import { NEWS_SOCKET, NEWS_API } from 'consts'

var initialState = []

const news = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_SOCKET: {

      state.unshift(action.data);


      return [...state];
    }
    case NEWS_API: {

      state = action.data;

      var stateReverse = state.reverse();
      return [...stateReverse];
    }
    default:
      return state
  }
}

export default news
