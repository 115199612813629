import { post, get } from './httpClient'

export const addSite = (body) => post('/site/createsite', { ...body })
export const updateSite = (body) => post('/site/updatesite', { ...body })
export const getSites = (params) => get('/site/getallsite', { ...params })
export const searchSites = (object) => get('/site/getallsite', object)
export const removeSite = (_id) => post('/site/removesite', { _id })
export const verifyApp = (path, params, endpoint) =>
  get(path, params, undefined, endpoint)
export const assignDesigner = (body) => post('/site/assignuser', body)
export const removeDesigner = (body) => post('/site/removeassign', body)
