import React, { useState, useEffect } from 'react'
import styles from './modalUpdateProduct.module.scss'

import { useDispatch } from 'react-redux'
import { ACTION } from 'consts'

import {
  Drawer,
  Button,
  Badge,
  Row,
  Space,
  Collapse,
  Upload,
  notification,
} from 'antd'

import { SaveOutlined, PlusOutlined } from '@ant-design/icons'

//apis
import { updateProduct } from 'apis/product'
import { uploadFiles } from 'apis/upload'

export default function ModalPrintFilesProduct({
  product,
  printFiles,
  reload,
}) {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [printFilesProduct, setPrintFilesProduct] = useState([])
  const [images, setImages] = useState([])

  const toggle = () => setVisible(!visible)

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const upload = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      let imagesUpdate = images

      const listImgUpload = imagesUpdate.filter((e) => e.fileUpload)
      const resUpload = await uploadFiles(listImgUpload)

      resUpload.map((url) => {
        for (let i = 0; i < imagesUpdate.length; ++i) {
          if (imagesUpdate[i].fileUpload) {
            imagesUpdate[i].image_file = url
            delete imagesUpdate[i].fileUpload
            break
          }
        }
      })

      const body = {
        ...product,
        printFiles: imagesUpdate,
      }

      delete body.dateCreated
      delete body.platform
      delete body.id_fulfill
      delete body.product_type
      delete body.product_type_id
      delete body.category

      console.log(body)

      const res = await updateProduct(body)

      if (res.status === 200) {
        await reload()
        toggle() //hidden drawer
        notification.success({ message: 'Update success!' })
      } else notification.error({ message: 'Update failed!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      notification.error({ message: 'Update failed!' })
    }
  }

  //reset when open drawer
  useEffect(() => {
    if (visible) {
      setPrintFilesProduct([...printFiles])
      setImages([...printFiles])
    }
  }, [visible])

  return (
    <>
      <Badge count={printFiles && printFiles.length} showZero>
        <Button onClick={toggle}>VIEW</Button>
      </Badge>

      <Drawer
        width="400"
        title="View print file designs"
        onClose={toggle}
        visible={visible}
        footer={
          <Row justify="end">
            <Space>
              <Button type="primary" icon={<SaveOutlined />} onClick={upload}>
                Save
              </Button>
              <Button onClick={toggle}>Cancel</Button>
            </Space>
          </Row>
        }>
        <Collapse ghost expandIconPosition="right" defaultActiveKey={['1']}>
          <Collapse.Panel
            header={
              <span style={{ fontWeight: 700 }}>AOP Fleece Winter Jacket</span>
            }
            key="1">
            <div>
              {printFilesProduct.map((e, index) => (
                <div style={{ display: 'flex', marginBottom: 20 }}>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className={styles['upload-print-file']}
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    data={(file) => {
                      if (file)
                        getBase64(file, (imageUrl) => {
                          //images Views
                          const printFilesProductNew = printFilesProduct
                          printFilesProductNew[index] = {
                            ...printFilesProductNew[index],
                            image_file: imageUrl,
                          }
                          setPrintFilesProduct([...printFilesProductNew])

                          //images update
                          const imagesNew = images
                          imagesNew[index] = {
                            ...imagesNew[index],
                            fileUpload: file,
                          }
                          setImages([...imagesNew])
                        })
                    }}>
                    {e.image_file ? (
                      <img
                        src={e.image_file}
                        alt="avatar"
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    )}
                  </Upload>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <span
                      style={{
                        marginBottom: 0,
                        fontSize: 17,
                        fontWeight: 500,
                        color: 'gray',
                      }}>
                      {e.name_file}
                    </span>
                    <span
                      style={{ marginBottom: 0, fontSize: 14, color: 'gray' }}>
                      {e.size}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      </Drawer>
    </>
  )
}
