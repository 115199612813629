import { ACTION } from 'consts'

var initialState = {
  dataProducts: [],
}

const products = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.GET_PRODUCTS: {
      return state
    }

    default:
      return state
  }
}

export default products
