import { NEWS_API, NEWS_SOCKET, NEWS_WATCHED, NEWS_API_IS_SEEN, NEWS_API_IS_SEEN_ACTION, SEARCH_NEWS } from "consts";

export const newsWatched = (data) => {
  return {
    type: NEWS_WATCHED,
    data: data,
  };
};
export const newsSocket = (data) => {

  return {
    type: NEWS_SOCKET,
    data: data,
  };
};
export const newsAPI = (data) => {

  return {
    type: NEWS_API,
    data: data,
  };
};
export const newsAPIIsSeen = (data) => {

  return {
    type: NEWS_API_IS_SEEN,
    data: data,
  };
};
export const newsAPIIsSeenAction = (data) => {

  return {
    type: NEWS_API_IS_SEEN_ACTION,
    data: data,
  };
};
export const searchNewsAPI = (data) => {

  return {
    type: SEARCH_NEWS,
    data: data,
  };
};