import { get, post } from './httpClient'

export const getSeller = (body) => get('/transaction', {typeUser:'seller',...body})
export const getSupplier = (body) => get('/transaction', {typeUser:'supplier',...body})
export const selectSupplier = (value) => get('/transaction', value)
export const selectSeller = (value) => get('/transaction', value)
export const searchTransaction = (value) => get('/transaction', value)
export const searchTransactionDate = (value) => get('/transaction', value)
export const searchTransactionAction = (value) => get('/transaction', value)
export const createTransaction = (body) => post('/transaction/createtransaction', body)
export const getAllSellerSelect = (body) => get('/user/getuserbyrole', {role:'Seller',...body})
export const getAllSupplierSelect = (body) => get('/user/getuserbyrole', {role:'Supplier',...body})
export const updateStatusTransaction = (object) => post('/transaction/updatetransaction', object)