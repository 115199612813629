import React from 'react'

import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { ROUTES, PERMISSIONS } from 'consts'

//base layout
import UI from 'components/Layout/UI'
import Authentication from 'components/authentication'

//views
import Login from './login'
import NotFound from './not-found/404'
import Mail from './mail'
import Dashboard from './dashboard'
import Orders from './orders'
import Products from './products'
import AddProduct from './products/add'
import Sites from './sites'
import Balance from './balance'
import News from './news'
import NewsLetter from './news/newsletter'
import Users from './users'
import Profile from './profile'
import Roles from './roles'
import ForgotPassword from './forgot-password'
import SellerTransaction from './seller-transaction'
import SupplierTransaction from './supplier-transaction'
import NewPassword from './new-password'
import SettingCategories from './setting-categories'
import SettingCarries from './setting-carries'
import SettingSellerLevels from './setting-seller-levels'
import SettingNews from './news'
import SettingApp from './setting-app'
import SettingContent from './setting-content'
import ProductTypes from './product-types'
import OrdersTracking from './orders-tracking'
import OrdersTrackingDetail from './orders-tracking/orders-tracking-detail'
import InstallAppEtsy from './install-app-etsy'
import InstallAppAmazon from './install-app-amazon'
import VerifyAppAmazon from './verify-app-amazon'
import LogsApp from './log'
import AuthApp from './auth-app'
import RedirectApp from './redirect'
import OrderArchive from './order-archive'
import TrackingLog from './tracking-log'
import Privacy from './privacy'

const DEFINE_ROUTER = [
  {
    path: ROUTES.DASHBOARD,
    Component: () => <Dashboard />,
    title: 'Dashboard',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDERS,
    Component: () => <Orders />,
    title: 'Orders',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDERS_TRACKING,
    Component: () => <OrdersTracking />,
    title: 'Orders tracking',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCTS,
    Component: () => <Products />,
    title: 'Products',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ADD_PRODUCT,
    Component: () => <AddProduct />,
    title: 'Add Product',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.BALANCE,
    Component: () => <Balance />,
    title: 'Balance',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SITES,
    Component: () => <Sites />,
    title: 'Sites',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SELLER_TRANSACTION,
    Component: () => <SellerTransaction />,
    title: 'Seller Transaction',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SUPPLIER_TRANSACTION,
    Component: () => <SupplierTransaction />,
    title: 'Supplier Transaction',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.NEWS,
    Component: () => <News />,
    title: 'News',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.NEWS_LETTER,
    Component: () => <NewsLetter />,
    title: 'NewsLetter',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.USERS,
    Component: () => <Users />,
    title: 'Users',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ROLES,
    Component: () => <Roles />,
    title: 'Roles',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_CATEGORIES,
    Component: () => <SettingCategories />,
    title: 'Setting Categories',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_APP,
    Component: () => <SettingApp />,
    title: 'Setting App',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_CARRIES,
    Component: () => <SettingCarries />,
    title: 'Setting Carriers',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_SELLER_LEVELS,
    Component: () => <SettingSellerLevels />,
    title: 'Setting Seller Levels',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_NEWS,
    Component: () => <SettingNews />,
    title: 'Setting News',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.SETTING_CONTENT,
    Component: () => <SettingContent />,
    title: 'Setting Content',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.ORDERS_TRACKING_DETAIL,
    Component: () => <OrdersTrackingDetail />,
    title: 'Orders Tracking Detail',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PRODUCT_TYPES,
    Component: () => <ProductTypes />,
    title: 'Product types',
    permissions: [],
    exact: true,
  },
  {
    path: ROUTES.PROFILE,
    Component: () => <Profile />,
    exact: true,
    title: 'Profile',
    permissions: [],
  },
  {
    path: ROUTES.LOGS,
    Component: () => <LogsApp />,
    exact: true,
    title: 'Logs App',
    permissions: [],
  },
  {
    path: ROUTES.ORDER_ARCHIVE,
    Component: () => <OrderArchive />,
    exact: true,
    title: 'Orders Archive',
    permissions: [],
  },
  {
    path: ROUTES.TRACKING_LOG,
    Component: () => <TrackingLog />,
    exact: true,
    title: 'History import tracking number',
    permissions: [],
  },
]

const AUTH_ROUTER = [
  {
    path: ROUTES.LOGIN,
    Component: () => <Login />,
    exact: true,
    title: 'Login to fullfillment',
    permissions: [],
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    Component: () => <ForgotPassword />,
    exact: true,
    title: 'Forgot password',
    permissions: [],
  },
  {
    path: ROUTES.NEW_PASSWORD,
    Component: () => <NewPassword />,
    exact: true,
    title: 'New password',
    permissions: [],
  },
  {
    path: ROUTES.MAIL,
    Component: () => <Mail />,
    exact: true,
    title: 'Authentication otp',
    permissions: [],
  },
]

export default function Views() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact={true}>
          <Redirect to={ROUTES.DASHBOARD} />
        </Route>

        {/* <Route path="/" exact={true}>
          <RedirectApp />
        </Route> */}

        <Route path={ROUTES.INSTALL} exact={true}>
          <InstallAppEtsy />
        </Route>

        <Route path={ROUTES.AUTH} exact={true}>
          <AuthApp />
        </Route>

        <Route path={ROUTES.PRIVACY} exact={true}>
          <Privacy />
        </Route>

        <Route path={ROUTES.INSTALL_APP_AMAZON} exact={true}>
          <InstallAppAmazon />
        </Route>

        <Route path={ROUTES.VERIFY_APP_AMAZON} exact={true}>
          <VerifyAppAmazon />
        </Route>

        {AUTH_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Component />
          </Route>
        ))}

        {DEFINE_ROUTER.map(({ Component, ...rest }, index) => (
          <Route {...rest} key={index}>
            <Authentication {...rest}>
              <UI>
                <Component />
              </UI>
            </Authentication>
          </Route>
        ))}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
