import React, { useState, useEffect, useRef } from 'react'
import styles from './users.module.scss'

import {
  Col,
  Row,
  Modal,
  DatePicker,
  Radio,
  Drawer,
  Table,
  Form,
  Button,
  Select,
  Input,
  Collapse,
  Checkbox,
  notification,
  Space,
  Popconfirm,
  Tag,
  Badge,
  BackTop,
} from 'antd'

//icons
import {
  PlusOutlined,
  UserOutlined,
  EditOutlined,
  SaveOutlined,
  ReloadOutlined,
  DeleteOutlined,
  LockOutlined,
} from '@ant-design/icons'

import moment from 'moment'
import { ACTION, PERMISSIONS } from 'consts'
import { useDispatch } from 'react-redux'

//components
import ExportUsers from 'components/ExportCSV/ExportUsers'
import Permission from 'components/permission'

//apis
import { getAppSetting, updateAppSetting } from 'apis/app-setting'
import { register } from 'apis/auth'
import {
  getsAllUsers,
  updateUser,
  addRole,
  deleteRole,
  getRoles,
} from 'apis/users'
import { getLevel } from 'apis/level'

export default function SellerTransaction() {
  const dispatch = useDispatch()
  const dateFormat = 'DD/MM/YYYY'

  const [birthDay, setBirthday] = useState(null)
  const [form] = Form.useForm()
  const [users, setUsers] = useState([])
  const [countUsers, setCountUsers] = useState(0)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [paramsFilter, setParamsFilter] = useState({})
  const [valueSearch, setValueSearch] = useState('')
  const [valueDate, setValueDate] = useState(null)
  const [valueRole, setValueRole] = useState()

  const [visibleDeposit, setVisileDeposit] = useState(false)
  const [visibleEditPermission, setVisibleEditPermission] = useState(false)
  const [allLevel, setAllLevel] = useState([])
  const [isUpdateUser, setIsUpdateUser] = useState(false) //check co dang update user ko ?
  const [objectUpdateUser, setUpdateUser] = useState(null) //dung de reset form update user

  const [allRole, setAllRole] = useState([])
  const [roles, setRoles] = useState({})

  const toggleVisibleEditPermission = () =>
    setVisibleEditPermission(!visibleEditPermission)

  const { Option } = Select

  function handleChangeActions(value) {
    if (value === 'default') {
      delete paramsFilter.role
      getAllUsers({ page: 1, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    } else {
      paramsFilter.role = value
      getAllUsers({ page, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    }
    setValueRole(value)
  }

  const showDrawerDeposit = () => {
    setVisileDeposit(true)
  }
  const onCloseDeposit = () => {
    setVisileDeposit(false)
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      getAllUsers({ page, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    }, 750)
  }
  const { RangePicker } = DatePicker
  function onChange(dates, dateStrings) {
    if (dates) {
      while (dateStrings[0].indexOf('-') !== -1) {
        dateStrings[0] = dateStrings[0].replace('-', '/')
      }
      while (dateStrings[1].indexOf('-') !== -1) {
        dateStrings[1] = dateStrings[1].replace('-', '/')
      }
      getAllUsers({
        page: 1,
        pageSize,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        ...paramsFilter,
      })
      setParamsFilter({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        ...paramsFilter,
      })
      setValueDate(dates)
    } else {
      delete paramsFilter.startDate
      delete paramsFilter.endDate
      getAllUsers({ page, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
      setValueDate(null)
    }
  }

  const _updateActiveIsUser = async (is_active, username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const object = {
        is_active,
        username,
      }

      console.log(JSON.stringify(object))
      const res = await updateUser(object)
      console.log(res)
      if (res.status === 200) {
        await getAllUsers({ page, pageSize, ...paramsFilter })
        notification.success({
          message: 'Update user successfully!!!',
        })
        onCloseDeposit()
      } else
        notification.error({
          message: res.data.message || 'Update user failed!!!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _updateUser = async (is_active, username) => {
    let isValidated = true
    try {
      await form.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) return

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const values = form.getFieldsValue()
      delete values.level

      const object = {
        ...values,
        is_active,
        username,
        birthday: birthDay || '',
      }

      console.log(JSON.stringify(object))
      const res = await updateUser(object)
      console.log(res)
      if (res.status === 200) {
        await getAllUsers({ page, pageSize, ...paramsFilter })
        notification.success({
          message: 'Update user successfully!!!',
        })
        onCloseDeposit()
      } else
        notification.error({
          message: res.data.message || 'Update user failed!!!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const registerData = async () => {
    let isValidated = true
    try {
      await form.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) return

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const values = form.getFieldsValue()
      const object = {
        ...values,
        phone: values.phone || '',
        birthday: birthDay || '',
        address: values.address || '',
        displayName: '',
        permissions: [],
        level: allLevel.find((e) => e.name === values.level) || '',
        is_active: true,
      }
      console.log(object)
      const res = await register(object)
      console.log(res)
      if (res.status === 200) {
        await getAllUsers({ page, pageSize, ...paramsFilter })
        notification.success({
          message: 'Create user successfully!!!',
        })

        onCloseDeposit()
      } else
        notification.error({
          message: res.data.message || 'Create user failed!!!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _getAppSetting = async () => {
    try {
      const res = await getAppSetting()
      if (res.status === 200) {
        Object.keys(res.data.data).map((key) => {
          if (!key.includes('permission')) delete res.data.data[key]
        })

        setRoles(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updatePermission = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateAppSetting({ ...roles })
      console.log(res)
      if (res.status === 200) {
        await _getAppSetting()
        notification.success({ message: 'Update permission success!' })
        toggleVisibleEditPermission()
      } else notification.error({ message: 'Update permission failed!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _deleteRole = async (nameRoleDelete) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteRole({ namerole: nameRoleDelete })
      console.log(res)
      if (res.status === 200) {
        await _getAppSetting()
        notification.success({ message: 'Delete role success!' })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Delete role failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _addRole = async (nameRoleAdd) => {
    try {
      if (!nameRoleAdd) {
        notification.error({ message: 'Please input name role' })
        return
      }
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await addRole({ namerole: 'permission' + nameRoleAdd })
      console.log(res)
      if (res.status === 200) {
        await _getAppSetting()
        notification.success({ message: 'Add role success!' })
      } else
        notification.error({
          message: res.data.mess || res.data.message || 'Add role failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const ModalEditPermission = () => {
    const [nameRoleAdd, setNameRoleAdd] = useState('')

    return (
      <>
        <Button
          style={{
            display:
              JSON.parse(localStorage.getItem('role')) === 'Admin'
                ? ''
                : 'none',
          }}
          size="large"
          type="primary"
          icon={<EditOutlined />}
          onClick={toggleVisibleEditPermission}>
          Edit Permission
        </Button>

        <Modal
          onOk={updatePermission}
          title="Edit Permission"
          visible={visibleEditPermission}
          onCancel={() => {
            toggleVisibleEditPermission()
            _getAppSetting() //reload list permission
            getAllRole()
          }}>
          <Row justify="end" style={{ marginBottom: 15 }} wrap={false}>
            <Input
              value={nameRoleAdd}
              onChange={(e) => setNameRoleAdd(e.target.value)}
              onPressEnter={() => _addRole(nameRoleAdd)}
              placeholder="Name role"
              style={{ width: '100%' }}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => _addRole(nameRoleAdd)}>
              Add Role
            </Button>
          </Row>
          <Collapse accordion>
            {Object.keys(roles).map((key) => {
              if (key !== 'permissionAdmin')
                return (
                  <Collapse.Panel
                    key={key}
                    header={key.slice(10)}
                    extra={
                      <Popconfirm
                        onConfirm={(e) => {
                          _deleteRole(key)
                          e.stopPropagation()
                        }}
                        okText="Ok"
                        cancelText="No"
                        title="Are you sure delete this role!">
                        <DeleteOutlined
                          style={{ color: 'red' }}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Popconfirm>
                    }>
                    <Row justify="space-between">
                      {roles.permissionAdmin.map((p) => (
                        <>
                          <Checkbox
                            style={{ marginTop: 5, width: '50%' }}
                            onChange={(e) => {
                              if (e.target.checked) roles[key].push(p)
                              else
                                roles[key].map((value, index) => {
                                  if (value === p) roles[key].splice(index, 1)
                                })
                            }}
                            defaultChecked={roles[key].includes(p)}>
                            {p}
                          </Checkbox>
                          <br />
                        </>
                      ))}
                    </Row>
                  </Collapse.Panel>
                )
            })}
          </Collapse>
        </Modal>
      </>
    )
  }

  const Setting = (props) => (
    <Space size="middle">
      <Permission permissions={[PERMISSIONS.edit_user]}>
        <EditOutlined
          style={{ color: '#2499FF', fontSize: '1rem' }}
          onClick={() => {
            setUpdateUser(props.user)
            setBirthday(props.user.birthday || null)
            setIsUpdateUser(true)
            showDrawerDeposit()

            delete props.user.password
            form.setFieldsValue({
              ...props.user,
              level: (props.user.level && props.user.level.name) || '',
            })
          }}
        />
      </Permission>
      <Permission permissions={[PERMISSIONS.remove_user]}>
        <Popconfirm
          title="Are you sure delete this user?"
          onConfirm={() => _updateActiveIsUser(false, props.user.username)}
          okText="Yes"
          cancelText="No">
          <DeleteOutlined
            style={{
              color: 'orange',
              fontSize: '1rem',
              cursor: 'pointer',
              display: !props.user.is_active && 'none',
            }}
          />
        </Popconfirm>
      </Permission>
      <Permission permissions={[PERMISSIONS.edit_user]}>
        <Popconfirm
          title="Are you sure active this user?"
          onConfirm={() => _updateActiveIsUser(true, props.user.username)}
          okText="Yes"
          cancelText="No">
          <LockOutlined
            style={{
              marginLeft: '-15px',
              color: 'green',
              fontSize: '1rem',
              cursor: 'pointer',
              display: props.user.is_active && 'none',
            }}
          />
        </Popconfirm>
      </Permission>
    </Space>
  )

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'Name',
      render: (text, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Email',
      dataIndex: 'mail',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      render: (text) => text && moment(text).format('YYYY-MM-DD'),
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend'],
    },
    {
      title: 'Level',
      dataIndex: 'level',
      render: (text, record) =>
        record.level && record.level.name && record.level.name,
    },
    {
      title: 'Roles',
      dataIndex: 'role',
      render: (text) =>
        text && (
          <Tag
            color={
              (text === 'Admin' && 'red') ||
              (text === 'Fullfiller' && 'volcano') ||
              (text === 'Supplier' && 'purple') ||
              (text === 'Seller' && 'cyan') ||
              (text === 'Listing' && 'orange')
            }>
            {text}
          </Tag>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 90,
      render: (isActive) => (
        <Badge
          status={isActive ? 'success' : 'warning'}
          text={isActive ? 'Active' : 'Deactive'}
        />
      ),
    },
    {
      title: 'Actions',
      render: (text, record) => <Setting user={record} />,
    },
  ]

  const getAllUsersExport = async () => {
    let data = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getsAllUsers()
      if (res.status === 200) data = res.data.data
      dispatch({ type: ACTION.LOADING, data: false })
      return data
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      return data
    }
  }

  const getAllLevel = async () => {
    try {
      const res = await getLevel()
      if (res.status === 200) {
        setAllLevel(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllUsers = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      console.log(params)
      const res = await getsAllUsers({ ...params })
      console.log(res)
      if (res.status === 200) {
        setUsers(res.data.data.reverse())
        setCountUsers(res.data.count)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const getAllRole = async () => {
    try {
      const res = await getRoles()
      if (res.status === 200) setAllRole([...res.data.map((e) => e.namerole)])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllRole()
    getAllUsers({ page, pageSize })
    _getAppSetting()
    getAllLevel()
  }, [])

  //reset drawer
  useEffect(() => {
    if (!visibleDeposit) {
      form.resetFields()
      setBirthday(null)
      setIsUpdateUser(false)
      setUpdateUser(null)
    }
  }, [visibleDeposit])

  return (
    <div className={styles['orders_manager']}>
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <div className={styles['balance_manager']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div
              className={styles['orders_manager_header_row_col_parent_select']}>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }>
                  <UserOutlined />
                </div>
                <div>USERS MANAGEMENT</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <Row
              className={styles['orders_manager_header_row_col_parent_fix']}
              style={{ marginBottom: 25 }}>
              <Space size="middle">
                <ExportUsers
                  fileName="Export Users"
                  name="Export Users"
                  getUsersByExportOrder={getAllUsersExport}
                />
                <ModalEditPermission />
                <Permission permissions={[PERMISSIONS.create_new_user]}>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    size="large"
                    onClick={showDrawerDeposit}>
                    Create new user
                  </Button>
                </Permission>
              </Space>
            </Row>
            <Drawer
              title={isUpdateUser ? 'Edit User' : 'Add New User'}
              width={800}
              onClose={onCloseDeposit}
              visible={visibleDeposit}
              footer={
                <Row justify="end">
                  <Space>
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      onClick={() => {
                        const { username } = form.getFieldsValue()
                        if (isUpdateUser) _updateUser(true, username)
                        else registerData()
                      }}>
                      {isUpdateUser ? 'Save' : 'Create'}
                    </Button>
                    <Button
                      icon={<ReloadOutlined />}
                      onClick={() => {
                        form.setFieldsValue({
                          ...objectUpdateUser,
                          level:
                            (objectUpdateUser.level &&
                              objectUpdateUser.level.name) ||
                            '',
                        })
                        setBirthday(objectUpdateUser.birthday || null)
                      }}
                      style={{ display: !isUpdateUser && 'none' }}>
                      Reset
                    </Button>
                    <Button onClick={onCloseDeposit}>Cancel</Button>
                  </Space>
                </Row>
              }>
              <Form layout="vertical" form={form}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your first name!',
                        },
                      ]}>
                      <Input placeholder="Enter first name" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your last name!',
                        },
                      ]}>
                      <Input placeholder="Enter last name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}>
                      <Input
                        placeholder="Enter username"
                        disabled={isUpdateUser}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: isUpdateUser ? false : true,
                          message: 'Please input your password!',
                        },
                      ]}>
                      <Input.Password
                        placeholder="Enter password"
                        iconRender={() => ''}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="mail"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                      ]}>
                      <Input placeholder="Enter email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Phone Number" name="phone">
                      <Input placeholder="Enter phone number" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="BirthDay" style={{ width: '100%' }}>
                      <DatePicker
                        format={dateFormat}
                        value={birthDay ? moment(birthDay, dateFormat) : null}
                        style={{ width: '100%' }}
                        onChange={(date, dateString) => setBirthday(dateString)}
                        placeholder="Enter your birthday"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Address" name="address">
                      <Input placeholder="Enter address" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      style={{ width: '100%', color: 'black' }}
                      name="level"
                      label="Level">
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Select level">
                        {allLevel.map((e) => (
                          <Option value={e.name}>{e.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="role"
                      label="Role"
                      rules={[
                        { required: true, message: 'Please choose role' },
                      ]}>
                      <Radio.Group>
                        {allRole.map((role) => (
                          <Radio
                            style={{ marginBottom: '5px', width: '6.5rem' }}
                            value={role}>
                            {role}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="note" label="Note">
                      <Input.TextArea rows={4} placeholder="Enter note" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </Col>
        </Row>
        <div className={styles['orders_manager_content']}>
          <Row justify="space-between" style={{ width: '100%' }} wrap={false}>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}>
              <Input.Search
                value={valueSearch}
                onChange={onSearch}
                className={styles['orders_manager_content_row_col_search']}
                placeholder="Search by name"
                allowClear
              />
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}>
              <RangePicker
                value={valueDate}
                className={styles['orders_manager_content_row_col_date']}
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
                onChange={onChange}
              />
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}>
              <Select
                value={valueRole}
                className={styles['import_orders_content_select_child']}
                defaultValue="default"
                onChange={handleChangeActions}>
                <Option value="default">All roles</Option>
                {allRole.map((role, index) => {
                  return (
                    <Option value={role} key={index}>
                      {role}
                    </Option>
                  )
                })}
              </Select>
            </Col>
            <Button
              style={{ display: !Object.keys(paramsFilter).length && 'none' }}
              type="link"
              onClick={() => {
                getAllUsers({ page, pageSize })
                setParamsFilter({})
                setValueDate(null)
                setValueSearch('')
                setValueRole()
              }}>
              Clear all filter
            </Button>
          </Row>
        </div>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            size="small"
            columns={columns}
            dataSource={users}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              defaultPageSize: 20,
              pageSizeOptions: [20, 30, 50, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getAllUsers({ page, pageSize, ...paramsFilter })
              },
              total: countUsers,
            }}
          />
        </div>
      </div>
    </div>
  )
}
