import { ACTION, NEWS_WATCHED } from "consts";
var data;
if (process.browser) {
  data = JSON.parse(localStorage.getItem('newsWatched'));
}
const initialState = data ? data : [];

const newsWatched = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_WATCHED: {

      state = action.data;
      localStorage.setItem('newsWatched', JSON.stringify(state));

      return [...state];
    }

    default:
      return state;
  }
};

export default newsWatched;
