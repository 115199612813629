import React, { useState, useEffect, useRef } from 'react'
import styles from './categories.module.scss'

//component
import Permission from 'components/permission'

//apis
import {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from 'apis/category'

//antd
import {
  Col,
  Row,
  notification,
  Drawer,
  Form,
  Table,
  Button,
  Input,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd'

import moment from 'moment'
import { ACTION, PERMISSIONS } from 'consts'
import { useDispatch } from 'react-redux'

//icons antd
import {
  ReloadOutlined,
  PicCenterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons'

export default function Categories() {
  const [form] = Form.useForm()
  const [category, setCategory] = useState([])
  const [visible, setVisile] = useState(false)
  const [editCategory, setEditCategory] = useState(null)

  const dispatch = useDispatch()

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const values = e.target.value
      try {
        dispatch({ type: ACTION.LOADING, data: true })
        const res = await getCategories({ search: values })
        console.log(res)
        if (res.status === 200) setCategory(res.data)
        dispatch({ type: ACTION.LOADING, data: false })
      } catch (error) {
        console.log(error)
        dispatch({ type: ACTION.LOADING, data: false })
      }
    }, 300)
  }

  const getCategoryData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCategories()
      console.log(res)
      if (res.status === 200) setCategory(res.data)
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    getCategoryData()
  }, [])

  const showDrawer = () => {
    setVisile(true)
  }
  const onClose = () => {
    setVisile(false)
  }

  //reset drawer create category
  useEffect(() => {
    if (!visible) form.resetFields()
  }, [visible])

  const createCategoryData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await createCategory(object)
      console.log(res)
      if (res.status === 200) {
        await getCategoryData()
        notification.success({ message: 'Successful category creation!' })
        onClose()
        form.resetFields()
      } else
        notification.error({
          message: 'Create category failed, please try again!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Create category failed, please try again!',
      })
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const updateCategoryData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateCategory(object)
      console.log(res)
      if (res.status === 200) {
        await getCategoryData()
        notification.success({ message: 'Update category creation!' })
        onClose()
        form.resetFields()
      } else
        notification.error({
          message: 'Update category failed, please try again!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Update category failed, please try again!',
      })
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const deleteCategoryData = async (id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await deleteCategory(id)
      console.log(res)
      if (res.status === 200) {
        await getCategoryData()
        notification.success({ message: 'Delete category creation!' })
        onClose()
        form.resetFields()
      } else
        notification.error({
          message: 'Delete category failed, please try again!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({
        message: 'Delete category failed, please try again!',
      })
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinish = async () => {
    let isValidated = false
    try {
      await form.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) return
    const data = form.getFieldsValue()
    var object = {
      name: data.name,
      userCreated:
        localStorage.getItem('username') &&
        JSON.parse(localStorage.getItem('username')),
      note: data.note || '',
    }

    if (editCategory) {
      delete object.userCreated
      object._id = editCategory._id

      console.log(object)
      updateCategoryData(object)
    } else {
      console.log(object)
      createCategoryData(object)
    }
  }

  const columns = [
    {
      title: 'Category',
      dataIndex: 'name',
    },
    {
      title: 'Create date',
      dataIndex: 'dateCreated',
      render: (text) => moment(text).format('DD-MM-YYYY HH:mm:ss'),
      width: 180,
    },
    {
      title: 'Note',
      dataIndex: 'note',
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space size="middle">
          <Permission permissions={[PERMISSIONS.edit_category]}>
            <Tooltip title="Edit">
              <EditOutlined
                style={{ color: '#1890ff', fontSize: 15, cursor: 'pointer' }}
                onClick={() => {
                  form.setFieldsValue({ ...record })
                  showDrawer()
                  setEditCategory({ ...record })
                }}
              />
            </Tooltip>
          </Permission>
          <Permission permissions={[PERMISSIONS.remove_category]}>
            <Popconfirm
              title="Are you sure to delete?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                deleteCategoryData(record._id)
              }}
            >
              <DeleteOutlined
                style={{ color: 'orange', fontSize: 15, cursor: 'pointer' }}
              />
            </Popconfirm>
          </Permission>
        </Space>
      ),
      width: 90,
    },
  ]

  return (
    <div className={styles['orders_manager']}>
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }
                >
                  <PicCenterOutlined />
                </div>
                <div>CATEGORIES</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_right']}>
                <div>
                  <Permission permissions={[PERMISSIONS.create_category]}>
                    <Button
                      onClick={showDrawer}
                      type="primary"
                      style={{ width: '7.5rem', height: '2.5rem' }}
                      // icon={<DownloadOutlined />}
                      size="large"
                    >
                      Add New
                    </Button>
                  </Permission>
                  <Drawer
                    title={editCategory ? 'Edit Category' : 'Add New Category'}
                    width={500}
                    onClose={() => {
                      onClose()
                      setEditCategory(null)
                    }}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={onFinish}
                          icon={<SaveOutlined />}
                        >
                          {editCategory ? 'Save' : 'Add'}
                        </Button>
                        <Space>
                          <Button
                            icon={<ReloadOutlined />}
                            onClick={() => {
                              if (editCategory)
                                form.setFieldsValue({ ...editCategory })
                              else form.resetFields()
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            onClick={() => {
                              onClose()
                              setEditCategory(null)
                            }}
                          >
                            Cancel
                          </Button>
                        </Space>
                      </div>
                    }
                  >
                    <Form form={form} layout="vertical">
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="name"
                            label="Category"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter category',
                              },
                            ]}
                          >
                            <Input placeholder="Enter category" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item name="note" label="Note">
                            <Input.TextArea placeholder="Enter note" rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Drawer>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_content']}>
        <Row className={styles['orders_manager_content_row']}>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}
          >
            <Input
              onChange={onSearch}
              className={styles['orders_manager_content_row_col_search']}
              placeholder="Search by category"
              allowClear
            />
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_tabs']}>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            columns={columns}
            dataSource={category}
            scroll={{ y: 550 }}
            size="small"
          />
        </div>
      </div>
    </div>
  )
}
