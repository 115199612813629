import React, { useState, useEffect, useRef } from 'react'
import styles from './sites.module.scss'
import querystring from 'querystring'
import {
  Col,
  Row,
  notification,
  Dropdown,
  Drawer,
  Table,
  Checkbox,
  Form,
  Button,
  Modal,
  Select,
  Input,
  Menu,
  Badge,
  Space,
} from 'antd'

import { ACTION, PERMISSIONS } from 'consts'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import pkceChallenge from 'pkce-challenge'

//components
import Permission from 'components/permission'

//apis
import {
  addSite,
  getSites,
  updateSite,
  removeSite,
  assignDesigner,
  removeDesigner,
} from 'apis/sites'
import { filterRoleUsers, getsAllUsers } from 'apis/users'

//icons
import {
  SwapOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  ChromeOutlined,
  SettingOutlined,
  IeOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  UnlockOutlined,
  CheckOutlined,
  SaveOutlined,
} from '@ant-design/icons'

const { Option } = Select
const Sites = () => {
  const dataUser = useSelector((state) => state.login.dataUser)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [formKey] = Form.useForm()
  const [formAmazon] = Form.useForm()

  //install etsy
  const _pkceChallenge = pkceChallenge()
  const CLIENT_ID = 'vci7uwjikacwrh1u0zobtynp'
  const url_etsy = `https://www.etsy.com/oauth/connect?response_type=code&redirect_uri=https://ecom-fulfill.com/install&scope=listings_d%20listings_r%20listings_w%20shops_w%20shops_r%20transactions_r%20transactions_w%20cart_r%20email_r%20billing_r%20address_r%20profile_r&client_id=${CLIENT_ID}&state=bamboo&code_challenge=${_pkceChallenge.code_challenge}&code_challenge_method=S256`

  const [visible, setVisible] = useState(false)
  const [platform, setPlatform] = useState('')
  const [sites, setSites] = useState([])
  const [titleDrawer, setTitleDrawer] = useState('Add new site')
  const [isAddSite, setIsAddSite] = useState(true)
  const [url, setUrl] = useState('')
  const [isAsyncOldOrders, setIsAsyncOldOrders] = useState(false)
  const [siteName, setSiteName] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [countSite, setCountSite] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({})
  const [listUserBySeller, setListUserBySeller] = useState([])
  const [visibleModalAssign, setVisibleModalAssign] = useState(false)
  const [listUser, setListUser] = useState([])
  const [visibleModalDesigner, setVisibleModalDesigner] = useState(false)
  const [objectSite, setObjectSite] = useState({})

  const toggleDrawer = () => setVisible(!visible)

  const MenuSetting = (props) => (
    <Menu>
      <Permission permissions={[PERMISSIONS.edit_site]}>
        <Menu.Item
          icon={<EditOutlined style={{ color: '#1890FF' }} />}
          onClick={() => {
            form.setFieldsValue({ ...props.site })
            setPlatform(props.site.platform)
            setTitleDrawer('Edit Site')
            setIsAsyncOldOrders(props.site.is_async)
            setIsAddSite(false)
            toggleDrawer()
            setObjectSite(props.site)
          }}
          style={{ display: props.site.platform === 'Etsy' && 'none' }}>
          Edit
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.assign_seller_site]}>
        <Menu.Item
          icon={<SwapOutlined />}
          onClick={() => {
            setObjectSite(props.site)
            setVisibleModalAssign(true)
          }}>
          Assign Seller
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.assign_view_order]}>
        <Menu.Item
          icon={<SwapOutlined style={{ color: 'green' }} />}
          onClick={() => {
            setObjectSite(props.site)
            setVisibleModalDesigner(true)
          }}>
          Assign View Order
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.update_active_site]}>
        <Menu.Item
          icon={
            props.site.status === 'active' ? (
              <LockOutlined style={{ color: 'red' }} />
            ) : (
              <UnlockOutlined style={{ color: 'green' }} />
            )
          }
          onClick={() =>
            updateStatusSite(
              props.site.status === 'active' ? 'pending' : 'active',
              props.site
            )
          }>
          {props.site.status === 'active' ? 'Deactive' : 'Active'}
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.remove_site]}>
        <Menu.Item
          icon={<DeleteOutlined style={{ color: 'orange' }} />}
          onClick={() => {
            Modal.confirm({
              title: 'Do you want delete these site?',
              icon: <ExclamationCircleOutlined />,
              onOk: () => {
                updateStatusSite('remove', props.site)
                // _removeSite(props.site._id)
              },
            })
          }}>
          Delete
        </Menu.Item>
      </Permission>
      <Permission permissions={[PERMISSIONS.edit_access_file_design]}>
        <Menu.Item
          icon={<Checkbox checked={props.site.access_file_design}></Checkbox>}
          onClick={() => accessFileDesign(props.site)}>
          Access file design
        </Menu.Item>
      </Permission>
    </Menu>
  )

  const accessFileDesign = async (site) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        site_name: site.site_name,
        access_file_design: !site.access_file_design,
        name: site.name,
      }
      console.log(body)
      const res = await updateSite(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success == false) {
          notification.error({
            message: 'Error',
            description: res.data.mess || `Access file design failed!`,
          })
        } else {
          await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
          notification.success({
            message: 'Success',
            description: `Access file design success!`,
          })
        }
      } else notification.error({ message: `Access file design failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const assignSeller = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      delete objectSite._id
      const res = await updateSite({ ...objectSite, user_admin: username })
      console.log(res)
      if (res.status === 200) {
        if (res.data.success == false) {
          notification.error({
            message: 'Error',
            description: res.data.mess || `Assign seller failed!`,
          })
        } else {
          await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
          notification.success({
            message: 'Success',
            description: `Assign seller success!`,
          })
          setObjectSite({})
          setVisibleModalAssign(false)
        }
      } else notification.error({ message: `Assign seller failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const ModalAssignSeller = () => {
    return (
      <Modal
        visible={visibleModalAssign}
        title="Assign seller"
        onCancel={() => {
          setObjectSite({})
          setVisibleModalAssign(false)
        }}
        footer={null}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          Assign seller
          <Select
            defaultValue={objectSite.user_admin}
            style={{
              width: 300,
            }}
            placeholder="Choose seller"
            onChange={(value) => assignSeller(value)}>
            {listUserBySeller.map((e, index) => (
              <Select.Option value={e.username} key={index}>
                {e.username}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    )
  }

  const _assignDesigner = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        site_name: objectSite.site_name,
        username,
      }
      const res = await assignDesigner(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success == false) {
          notification.error({
            message: 'Error',
            description: res.data.mess || `Assign failed!`,
          })
        } else {
          setVisibleModalDesigner(false)
          await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
          notification.success({
            message: 'Success',
            description: `Assign success!`,
          })
          setObjectSite({})
        }
      } else notification.error({ message: `Assign failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _removeDesigner = async (username) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        site_name: objectSite.site_name,
        username,
      }
      const res = await removeDesigner(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success == false) {
          notification.error({
            message: 'Error',
            description: res.data.mess || `Remove assign failed!`,
          })
        } else {
          setVisibleModalDesigner(false)
          await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
          notification.success({
            message: 'Success',
            description: `Remove assign success!`,
          })
          setObjectSite({})
        }
      } else notification.error({ message: `Remove assign failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const ModalAssignDesigner = () => {
    return (
      <Modal
        visible={visibleModalDesigner}
        title="Assign View Order"
        onCancel={() => {
          setObjectSite({})
          setVisibleModalDesigner(false)
        }}
        footer={null}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          Assign View Order
          <Select
            style={{
              width: 300,
            }}
            placeholder="Choose user"
            onChange={(value) => {
              if (
                objectSite.users_assign &&
                objectSite.users_assign.find((f) => f.username === value)
              )
                _removeDesigner(value)
              else _assignDesigner(value)
            }}>
            {listUser.map((e, index) => (
              <Select.Option value={e.username} key={index}>
                <Row justify="space-between" align="middle">
                  {e.username}
                  <CheckOutlined
                    style={{
                      color: 'green',
                      fontSize: 14,
                      display:
                        objectSite.users_assign &&
                        objectSite.users_assign.find(
                          (f) => f.username === e.username
                        )
                          ? ''
                          : 'none',
                    }}
                  />
                </Row>
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    )
  }

  const updateStatusSite = async (status, site) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        site_name: site.site_name,
        status,
        name: site.name,
      }
      console.log(body)
      const res = await updateSite(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success == false) {
          notification.error({
            message: 'Error',
            description: res.data.mess || `Update site failed!`,
          })
        } else {
          await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
          notification.success({
            message: 'Success',
            description: `Update site success!`,
          })
        }
      } else notification.error({ message: `Update site failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const asyncOldOrderSite = async (site) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        site_name: site.site_name,
        is_async: true,
      }
      const res = await updateSite(body)
      console.log(res)
      if (res.status === 200) {
        await _getSites({ page, pageSize, ...paramsFilter }) //reload sites khi cập nhật thành công
        notification.success({
          message: 'Success',
          description: `Async site success!`,
        })
      } else notification.error({ message: `Async site failed` })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const Setting = (props) => (
    <>
      <Dropdown overlay={<MenuSetting site={props.site} />} trigger={['click']}>
        <SettingOutlined
          style={{
            color: '#1890FF',
            cursor: 'pointer',
          }}
        />
      </Dropdown>
    </>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: (text) => (
        <a href={text} target="_blank">
          {text}
        </a>
      ),
    },
    {
      title: 'Site id',
      dataIndex: 'id',
      key: 'id',
      render: (text) => text && `Ecom ${text}`,
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'Connected date',
      dataIndex: 'connected_date',
      key: 'connected_date',
    },
    {
      title: 'Seller',
      dataIndex: 'user_admin',
      key: 'user_admin',
    },
    {
      title: 'Async Old Order',
      dataIndex: 'is_async',
      key: 'asyncOldOrder',
      render: (text, record) => (
        <Button
          type="primary"
          disabled={!text}
          onClick={() => asyncOldOrderSite(record)}>
          Async
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      render: (text, record) =>
        text && (
          <Badge
            status={
              (text == 'pending' && 'warning') ||
              (text == 'active' && 'success') ||
              (text == 'remove' && 'error')
            }
            text={text}
          />
        ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, record) => (
        <Space>
          <Setting site={record} />
          <Button
            type="dashed"
            style={{ display: record.platform !== 'Etsy' && 'none' }}
            onClick={() => {
              localStorage.setItem('site_name', record.site_name)
              localStorage.setItem('key_etsy', CLIENT_ID)
              localStorage.setItem(
                'code_verifier',
                _pkceChallenge.code_verifier
              )

              window.location.href = url_etsy
            }}>
            Reconnect
          </Button>
        </Space>
      ),
    },
  ]

  const _removeSite = async (id) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await removeSite(id)
      console.log(res)

      if (res.status === 200) {
        await _getSites({ page, pageSize, ...paramsFilter })
        notification.success({ message: 'Remove site success!' })
      } else notification.error({ message: 'Remove site failed!' })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Error',
        description: 'Remove site failed',
      })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _addSite = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await addSite(body)
      console.log(res)
      if (res.status === 200) {
        toggleDrawer()
        notification.success({
          message: 'Success',
          description: 'Add site success!',
        })
        await _getSites({ page, pageSize, ...paramsFilter })
      } else {
        if (res.data && res.data.message) {
          notification.error({
            message: 'Error',
            description: res.data.message,
          })
        } else
          notification.error({
            message: 'Error',
            description: 'Add site failed',
          })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Error', description: 'Add site failed' })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinishEditSite = async () => {
    try {
      const data = form.getFieldsValue()

      var body = {
        site_name: objectSite.site_name,
        name: data.name,
        time_collingoff: data.time_collingoff,
      }

      dispatch({ type: ACTION.LOADING, data: true })
      const res = await updateSite(body)
      console.log(res)
      if (res.status === 200) {
        if (res.data.success === undefined) {
          notification.success({
            message: 'Update site success!',
          })
          dispatch({ type: ACTION.LOADING, data: false })
          _getSites({ page, pageSize, ...paramsFilter })
          toggleDrawer()
        } else
          notification.error({
            message: res.data.message || 'Update site failed',
          })
      } else
        notification.error({
          message: res.data.message || 'Update site failed',
        })

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      notification.error({ message: 'Update site failed' })
    }
  }

  function handlePlatform(value) {
    setPage(1)
    if (!value) {
      delete paramsFilter.platform
    } else {
      paramsFilter.platform = value
    }

    _getSites({ page: 1, pageSize, ...paramsFilter })
    setParamsFilter({ ...paramsFilter })
  }

  function handleChangeSites(value) {
    setPage(1)
    if (value === 'default') {
      delete paramsFilter.status
    } else {
      paramsFilter.status = value
    }

    _getSites({ page: 1, pageSize, ...paramsFilter })
    setParamsFilter({ ...paramsFilter })
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) {
        _getSites({ page: 1, pageSize, search: value, ...paramsFilter })
        setParamsFilter({ ...paramsFilter, search: value })
      } else {
        delete paramsFilter.search
        _getSites({ page: 1, pageSize, ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      }
    }, 300)
  }

  const _connectSiteByAmazon = async () => {
    let isValidated = true
    try {
      await formAmazon.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) return

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const dataForm = formAmazon.getFieldsValue()
      const formSite = form.getFieldsValue()

      const body = {
        platform,
        ...dataForm,
        time_collingoff: formSite.time_collingoff,
      }
      const res = await addSite(body)
      console.log(res)
      toggleDrawer()
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          formAmazon.resetFields()
          notification.success({
            message: (
              <div>
                <span>List site add success: </span>
                {res.data.data.map(
                  (e) => e.site_name && <p>- {e.site_name}</p>
                )}
              </div>
            ),
            duration: 6,
          })
        } else
          notification.warning({ message: 'Not have site add!', duration: 6 })
      } else
        notification.warning({ message: 'Not have site add!', duration: 6 })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getSites = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      setSites([])
      console.log(params)
      const res = await getSites({ ...params, view_order: true })
      console.log(res)
      if (res.status === 200) {
        setSites(res.data.data)
        setCountSite(res.data.count)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinish = () => {
    const dataKey = formKey.getFieldsValue()
    const data = form.getFieldsValue()

    if (!data.url.includes('http') || !data.url.includes('https')) {
      notification.error({ message: 'Please input http or https in url' })
      return
    }

    if (data.platform === 'Shopify' && !data.url.includes('myshopify.com')) {
      notification.error({ message: 'Url must have myshopify.com' })
      return
    }

    if (data.platform === 'Shopbase' && !data.url.includes('onshopbase.com')) {
      notification.error({ message: 'Url must have onshopbase.com' })
      return
    }

    //check kí tự cuối của url có kí tự / ko
    //nếu có thì bỏ
    let urlNew = data.url
    if (data.url[data.url.length - 1] === '/')
      urlNew = data.url.slice(0, data.url.length - 1)

    var body = {
      ...data,
      is_async: isAsyncOldOrders,
      url: urlNew,
      connected_date: moment(new Date()).format('DD-MM-YYYY HH:mm:ss'),
      deleted_date: '',
      status: '',
      user_admin: dataUser.username && dataUser.username,
      name: data.name,
      site_name: siteName,
      key: dataKey.key,
      keySecret: dataKey.keySecret,
    }

    console.log(JSON.stringify(body))
    _addSite(body)
  }
  const ConnectWoo = (props) => {
    const endpoint = '/wc-auth/v1/authorize'
    const params = {
      app_name: process.env.REACT_APP_APP_NAME,
      scope: 'read_write',
      user_id: `${
        localStorage.getItem('username') &&
        JSON.parse(localStorage.getItem('username'))
      }@${siteName}`,
      return_url: `${process.env.REACT_APP_DOMAIN}/sites`,
      callback_url: `https://api.bamboolife.info/api/key/woo`,
    }
    const query_string = querystring.stringify(params).replace(/%20/g, '+')

    //check kí tự cuối của url có kí tự / ko
    //nếu có thì bỏ
    if (url[url.length - 1] === '/') {
      const urlNew = url.slice(0, url.length - 1)
      setUrl(urlNew)
    }

    //nếu ko có htpp hoặc https thì tự động thêm
    if (!url.includes('http') || !url.includes('https')) {
      setUrl('https://' + url)
    }

    return !isAddSite ? (
      <a onClick={onFinishEditSite}>{props.children}</a>
    ) : (
      <a
        href={url + endpoint + '?' + query_string}
        target="_blank"
        onClick={onFinish}>
        {props.children}
      </a>
    )
  }

  const ConnectShopify = (props) => {
    const endpoint = '/admin/oauth/authorize'
    const params = {
      client_id: process.env.REACT_APP_API_KEY_SHOPIFY,
      scope: process.env.REACT_APP_API_SCOPES_SHOPIFY.split(','),
      redirect_uri: process.env.REACT_APP_DOMAIN,
      state: 123,
    }
    const query_string = querystring.stringify(params).replace(/%20/g, '+')

    return <a href={url + endpoint + '?' + query_string}>{props.children}</a>
  }

  const ConnectEtsy = (props) => {
    return (
      <a
        href={url_etsy}
        target="_blank"
        onClick={async (e) => {
          let isValidated = true
          try {
            await form.validateFields()
            isValidated = true
          } catch (error) {
            isValidated = false
          }

          if (!isValidated) {
            e.preventDefault()
            return
          }

          const formData = form.getFieldsValue()

          localStorage.setItem('name_site_etsy', formData.name)
          localStorage.setItem('code_verifier', _pkceChallenge.code_verifier)
          localStorage.setItem('key_etsy', CLIENT_ID)
          localStorage.setItem('url_etsy', formData.url)
          localStorage.setItem('collingoff_etsy', formData.time_collingoff)
        }}>
        {props.children}
      </a>
    )
  }

  const ConnectAmazon = (props) => {
    const url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.b0286acd-fc24-48ae-9d98-62191b034a03&version=beta`

    return (
      <a
        href={url}
        target="_blank"
        onClick={(e) => {
          const formData = form.getFieldsValue()
          if (!formData.name) {
            notification.error({ message: 'Please input name' })
            e.preventDefault()
            return
          }

          localStorage.setItem('name_site_amazon', formData.name)
        }}>
        {props.children}
      </a>
    )
  }

  const ConnectShopbase = (props) => {
    return <a href="">{props.children}</a>
  }

  const getAllUserBySeller = async () => {
    try {
      const res = await filterRoleUsers({ role: 'Seller', status: 1 })
      if (res.status === 200) setListUserBySeller(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getAllUser = async () => {
    try {
      const res = await getsAllUsers({ status: 1 })
      console.log(res)
      if (res.status === 200) setListUser(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (visible && isAddSite) {
      form.resetFields()
    }
    if (!visible) {
      setPlatform('')
      form.resetFields()
      formKey.resetFields()
    }
  }, [visible])

  useEffect(() => {
    _getSites({ page, pageSize })
    getAllUserBySeller()
    getAllUser()
  }, [])

  return (
    <div>
      <ModalAssignDesigner />
      <ModalAssignSeller />
      <div className={styles['orders_manager']}>
        <div className={styles['orders_manager_header']}>
          <Row className={styles['orders_manager_header_row']}>
            <Col
              className={styles['orders_manager_header_row_col']}
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }>
                  <ChromeOutlined />
                </div>
                <div>SITES MANAGER</div>
              </div>
            </Col>
            <Col
              className={styles['orders_manager_header_row_col']}
              xs={24}
              sm={24}
              md={11}
              lg={11}
              xl={11}>
              <div>
                <div className={styles['orders_manager_header_row_col_right']}>
                  <Permission permissions={[PERMISSIONS.add_site]}>
                    <Button
                      onClick={() => {
                        toggleDrawer()
                        setTitleDrawer('Add new site')
                        setIsAddSite(true)
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                      size="large">
                      Add new site
                    </Button>
                  </Permission>
                  <Drawer
                    title={titleDrawer}
                    width={350}
                    onClose={toggleDrawer}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                      <div
                        style={{
                          textAlign: 'right',
                        }}>
                        <Button
                          onClick={toggleDrawer}
                          style={{ marginRight: 8 }}>
                          Cancel
                        </Button>
                      </div>
                    }>
                    <Form form={form} layout="vertical" hideRequiredMark>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="name"
                            label="Name"
                            required
                            rules={[
                              {
                                required: true,
                                message: 'Please enter name',
                              },
                            ]}>
                            <Input placeholder="Enter name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="time_collingoff"
                            label="Initial Cooling-off time"
                            rules={[
                              {
                                required: true,
                                message: 'Please select time!',
                              },
                            ]}>
                            <Select defaultValue="default">
                              <Option value="default">Choose time</Option>
                              <Option value={0}>0h</Option>
                              <Option value={2}>2h</Option>
                              <Option value={12}>12h</Option>
                              <Option value={24}>24h</Option>
                              <Option value={48}>48h</Option>
                              <Option value={72}>72h</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="url"
                            label="Site Url"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter url',
                              },
                            ]}>
                            <Input
                              onChange={(e) => {
                                const value = e.target.value

                                //check kí tự cuối của url có kí tự / ko
                                //nếu có thì bỏ
                                let urlNew = value
                                if (value[value.length - 1] === '/') {
                                  urlNew = value.slice(0, value.length - 1)
                                }

                                //lấy site name từ url
                                let site_name = ''
                                if (urlNew.includes('http'))
                                  site_name = urlNew.replace('http://', '')

                                if (urlNew.includes('https'))
                                  site_name = urlNew.replace('https://', '')

                                setSiteName(site_name)

                                setUrl(urlNew)
                              }}
                              disabled={!isAddSite && true}
                              style={{ width: '100%' }}
                              placeholder="https://example.com"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="platform"
                            label="Platform"
                            rules={[
                              {
                                required: true,
                                message: 'Please select platform!',
                              },
                            ]}>
                            <Select
                              disabled={!isAddSite && true}
                              placeholder="Choose platform"
                              onChange={(value) => setPlatform(value)}>
                              <Option value="Woo">WOOCOMMERCE</Option>
                              <Option value="Etsy">ETSY</Option>
                              <Option value="Shopify">SHOPIFY</Option>
                              <Option value="Shopbase">SHOPBASE</Option>
                              <Option value="Amazon">AMAZON</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display:
                            platform == 'Shopify' || platform == 'Shopbase'
                              ? ''
                              : 'none',
                        }}>
                        <Form
                          layout="vertical"
                          form={formKey}
                          style={{ width: '100%' }}>
                          <Form.Item
                            name="key"
                            label="Key"
                            rules={[
                              { required: true, message: 'Please input key' },
                            ]}>
                            <Input.Password placeholder="Input key" />
                          </Form.Item>
                          <Form.Item
                            name="keySecret"
                            label="Key Secret"
                            rules={[
                              {
                                required: true,
                                message: 'Please input key secret',
                              },
                            ]}>
                            <Input.Password placeholder="Input key secret" />
                          </Form.Item>
                        </Form>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Checkbox
                            style={{ marginBottom: 20 }}
                            checked={isAsyncOldOrders}
                            disabled={!isAddSite && true}
                            onChange={(e) =>
                              setIsAsyncOldOrders(e.target.checked)
                            }>
                            Sync Old Order
                          </Checkbox>
                        </Col>
                      </Row>
                      <Form form={formAmazon}>
                        <Space
                          direction="vertical"
                          style={{
                            width: '100%',
                            marginBottom: 15,
                            display: platform !== 'Amazon' && 'none',
                          }}>
                          <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                              {
                                message: 'Please input username',
                                required: true,
                              },
                            ]}>
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Username Seller Central"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                              {
                                message: 'Please input password',
                                required: true,
                              },
                            ]}>
                            <Input.Password
                              style={{ width: '100%' }}
                              placeholder="Password Seller Central"
                            />
                          </Form.Item>
                          <div>
                            <Row justify="space-between">
                              <span>Barcode</span>
                              <a href="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/10/10/b69ef974-27b0-4621-82d4-ec71e75affbc/Hướng dẫn lấy barcode OTP.docx">
                                Tutorial get barcode OTP
                              </a>
                            </Row>
                            <Form.Item
                              name="barcode"
                              rules={[
                                {
                                  message: 'Please input barcode',
                                  required: true,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                placeholder="Barcode OTP Login"
                              />
                            </Form.Item>
                          </div>
                        </Space>
                      </Form>
                      <Row gutter={16}>
                        <Col span={24}>
                          {platform === 'Woo' && (
                            <ConnectWoo>
                              <Button
                                className={styles['connect_button']}
                                type="primary"
                                icon={
                                  !isAddSite ? <SaveOutlined /> : <IeOutlined />
                                }>
                                {!isAddSite ? 'Save' : 'Connect'}
                              </Button>
                            </ConnectWoo>
                          )}
                          {/* {platform === 'Shopify' && (
                            <ConnectShopify>
                              <Button
                                className={styles['connect_button']}
                                type="primary"
                                icon={<IeOutlined />}
                              >
                                Connect
                              </Button>
                            </ConnectShopify>
                          )} */}
                          {platform === 'Etsy' && (
                            <ConnectEtsy>
                              <Button
                                className={styles['connect_button']}
                                type="primary"
                                icon={<IeOutlined />}>
                                Connect
                              </Button>
                            </ConnectEtsy>
                          )}
                          {platform === 'Amazon' && (
                            <Button
                              onClick={
                                !isAddSite
                                  ? onFinishEditSite
                                  : _connectSiteByAmazon
                              }
                              className={styles['connect_button']}
                              type="primary"
                              icon={
                                !isAddSite ? <SaveOutlined /> : <IeOutlined />
                              }>
                              {!isAddSite ? 'Save' : 'Connect'}
                            </Button>
                          )}

                          {/* {platform === 'Shopbase' &&
                            <ConnectShopbase>
                              <Button
                                className={styles['connect_button']}
                                type="primary"
                                icon={<IeOutlined />}
                              >
                                Connect
                              </Button>
                            </ConnectShopbase>
                          } */}
                          {(platform === 'Shopbase' ||
                            platform === 'Shopify') && (
                            <Button
                              className={styles['connect_button']}
                              type="primary"
                              icon={
                                !isAddSite ? <SaveOutlined /> : <IeOutlined />
                              }
                              onClick={
                                !isAddSite ? onFinishEditSite : onFinish
                              }>
                              {!isAddSite ? 'Save' : 'Connect'}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Drawer>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles['orders_manager_content']}>
          <Row className={styles['orders_manager_content_row']}>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={24}
              md={15}
              lg={15}
              xl={15}>
              <div>
                <Input
                  onChange={onSearch}
                  className={styles['orders_manager_content_row_col_search']}
                  placeholder="Search by name"
                  allowClear
                />
              </div>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={24}
              md={4}
              lg={4}
              xl={4}>
              <Select
                onChange={handlePlatform}
                allowClear
                placeholder="Filter by platform"
                className={styles['import_orders_content_select_child']}>
                <Option value="Shopbase">Shopbase</Option>
                <Option value="Etsy">Etsy</Option>
                <Option value="Woo">Woo</Option>
                <Option value="Shopify">Shopify</Option>
                <Option value="Amazon">Amazon</Option>
              </Select>
            </Col>
            <Col
              className={styles['orders_manager_content_row_col']}
              xs={24}
              sm={24}
              md={4}
              lg={4}
              xl={4}>
              <Select
                className={styles['import_orders_content_select_child']}
                defaultValue="default"
                onChange={handleChangeSites}>
                <Option value="default">All status</Option>
                <Option value="active">active</Option>
                <Option value="pending">pending</Option>
                <Option value="deactive">deactive</Option>
              </Select>
            </Col>
          </Row>
        </div>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            size="small"
            columns={columns}
            dataSource={sites}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              defaultPageSize: 20,
              pageSizeOptions: [20, 30, 50, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                _getSites({ page, pageSize, ...paramsFilter })
              },
              total: countSite,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Sites
