import React, { useState, useEffect, useRef } from 'react'
import styles from './setting-seller-levels.module.scss'

//apis
import { getLevel, updateLevel, createLevel, searchLevel } from 'apis/level'

//antd
import {
  Col,
  Row,
  InputNumber,
  Drawer,
  Form,
  Table,
  Button,
  Modal,
  Input,
  notification,
} from 'antd'

import { ACTION, PERMISSIONS } from 'consts'
import { useDispatch } from 'react-redux'

//components
import Permission from 'components/permission'

//icons antd
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  ApartmentOutlined,
  InstagramOutlined,
} from '@ant-design/icons'

export default function Categories() {
  const [visible, setVisile] = useState(false)
  const [levels, setLevels] = useState([])
  const [modal2Visible, setModal2Visible] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const setModal2VisibleModal = (modal2Visible) => {
    setModal2Visible(modal2Visible)
  }
  const onClickEditLevel = (props) => {
    setModal2VisibleModal(true)
    const updateLevel = form.getFieldValue()
    updateLevel.nameUpdate = props.site.name
    updateLevel.idLevelUpdate = props.site.idLevel
    updateLevel.conditionUpdate = props.site.condition
    updateLevel.conditionNotifyUpdate = props.site.conditionNotify
    updateLevel.discountUpdate = props.site.discount
  }
  const onFinish = (values) => {
    var object = {
      idLevel: values.idLevelUpdate,
      name: values.nameUpdate,
      discount: values.discountUpdate,
      condition: values.conditionUpdate,
      conditionNotify: values.conditionNotifyUpdate,
    }
    const updateLevelData = async () => {
      try {
        dispatch({ type: ACTION.LOADING, data: true })
        const res = await updateLevel(object)

        if (res.status === 200) {
          await _getLevel()
          setModal2VisibleModal(false)
          notification.success({ message: 'Update success!' })
        } else notification.error({ message: 'Update failed!' })
        dispatch({ type: ACTION.LOADING, data: false })
      } catch (error) {
        notification.error({ message: 'Update failed!' })
        dispatch({ type: ACTION.LOADING, data: false })
      }
    }
    updateLevelData()
  }

  const onFinishFailed = (errorInfo) => {}

  const Setting = (props) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        onClick={() => onClickEditLevel(props)}
        style={{ marginRight: '0.5rem' }}
      >
        <EditOutlined
          style={{ cursor: 'pointer', fontSize: '1.25rem', color: '#65B5FF' }}
        />
      </div>
      <div>
        <DeleteOutlined
          style={{ fontSize: '1.25rem', color: '#FFAA4E', cursor: 'pointer' }}
        />
      </div>
    </div>
  )

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },

    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (text) => text && `${text}%`,
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
    },
    {
      title: 'ConditionNotify',
      dataIndex: 'conditionNotify',
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => <Setting site={record} />,
    // },
  ]
  const _getLevel = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getLevel()

      if (res.status === 200) {
        setLevels(res.data.data.reverse())
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const showDrawer = () => {
    setVisile(true)
  }
  const onClose = () => {
    setVisile(false)
  }

  const _createLevel = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const data = form.getFieldsValue()
      console.log(data)
      const res = await createLevel({ ...data })

      if (res.status === 200) {
        await _getLevel()
        notification.success({ message: 'Success!' })
        onClose()
        form.resetFields()
      } else notification.error({ message: 'Create level failed!' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      notification.error({ message: 'Create level failed!' })
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    _getLevel()
  }, [])
  const searchLevelData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await searchLevel({ search: value })

      if (res.status === 200) {
        setLevels(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      searchLevelData(value)
    }, 300)
  }

  //reset form data
  useEffect(() => {
    if (!visible) form.resetFields()
  }, [visible])

  return (
    <div className={styles['orders_manager']}>
      {/* <ModalUpdateLevel /> */}
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }
                >
                  <ApartmentOutlined />
                </div>
                <div>SELLER LEVELS</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_right']}>
                <div>
                  <Permission permissions={[PERMISSIONS.create_level]}>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={showDrawer}
                      type="primary"
                      style={{
                        width: '7.5rem',
                        height: '2.5rem',
                      }}
                      size="large"
                      type="primary"
                    >
                      Add New
                    </Button>
                  </Permission>
                  <Drawer
                    title="Add New Seller Level"
                    width={400}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                      <div
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                          Cancel
                        </Button>
                        {/* <Button onClick={onClose} type="primary">
                          Submit
                        </Button> */}
                      </div>
                    }
                  >
                    <Form
                      layout="vertical"
                      hideRequiredMark
                      onFinish={_createLevel}
                      form={form}
                    >
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter name',
                              },
                            ]}
                          >
                            <Input placeholder="Enter name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="condition"
                            label="Condition"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter condition',
                              },
                            ]}
                          >
                            <Input placeholder="enter condition" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="conditionNotify"
                            label="Condition Notify"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter condition notify',
                              },
                            ]}
                          >
                            <Input placeholder="enter condition notify" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="discount"
                            label="Discount"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter discount',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="enter discount"
                              min={0}
                              max={100}
                              style={{ width: '100%' }}
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                              }
                              parser={(value) =>
                                value.replace(/\$\s?|(,*)/g, '')
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              icon={
                                <InstagramOutlined
                                  style={{ fontSize: '1rem' }}
                                />
                              }
                              type="primary"
                              style={{ width: '100%', height: '2rem' }}
                              htmlType="submit"
                            >
                              Add
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Drawer>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_content']}>
        <Row className={styles['orders_manager_content_row']}>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}
          >
            <div>
              <Input
                onChange={onSearch}
                className={styles['orders_manager_content_row_col_search']}
                placeholder="Search by name"
                allowClear
              />
            </div>
          </Col>

          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}
          ></Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}
          ></Col>
        </Row>
      </div>
      <div className={styles['orders_manager_tabs']}>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            columns={columns}
            dataSource={levels}
            scroll={{ y: 550 }}
            size="small"
          />

          <Modal
            title="Update Level"
            centered
            footer={null}
            visible={modal2Visible}
            onOk={() => setModal2VisibleModal(false)}
            onCancel={() => setModal2VisibleModal(false)}
          >
            <Form
              style={{
                width: '100%',
                display: 'flex',
                justifyItems: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // form={formUpdateLevel}
              // onFinish={_updateLevel}
            >
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  Name
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    name="nameUpdate"
                    rules={[{ required: true, message: 'Please input name' }]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  Id level
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    name="idLevelUpdate"
                    rules={[
                      { required: true, message: 'Please input id level' },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    marginBottom: '0.5rem',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  Condition
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    name="conditionUpdate"
                    rules={[
                      { required: true, message: 'Please input condition' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  Condition notify
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    name="conditionNotifyUpdate"
                    rules={[
                      {
                        required: true,
                        message: 'Please input condition notify',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginBottom: '0.5rem',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  Discount
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                  }}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    name="discountUpdate"
                    rules={[
                      { required: true, message: 'Please input discount' },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Form.Item
                  onClick={() => setModal2VisibleModal(false)}
                  style={{ marginRight: '1rem' }}
                >
                  <div className={styles['button_update']}>
                    <Button
                      style={{ width: '7.5rem', height: '2.5rem' }}
                      // type="primary"
                      // htmlType="submit"
                    >
                      Cancel
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div className={styles['button_update']}>
                    <Button
                      style={{ width: '7.5rem', height: '2.5rem' }}
                      type="primary"
                      htmlType="submit"
                    >
                      Update
                    </Button>
                  </div>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  )
}
