import * as types from "../../../constants/index";
// nhận data từ server
const initialState = false;


var newPassword = (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_PASSWORD: {

      var statusNewpassword = {
        statusNewpassword: action.data.status
      }
      localStorage.setItem('statusNewpassword', JSON.stringify(statusNewpassword));




      //   var {status} = action.data;
      //   
      //   state = status;

      //   
      //   return state;
    }
    // case types.TEST: {
    //   
    //   state = action.data;
    //   return state;
    // }
    default: return state;
  }
};
export default newPassword;
