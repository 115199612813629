import * as types from "../../../constants/index";
// nhận data từ server
const initialState = false;


var passwordForget = (state = initialState, action) => {
  switch (action.type) {
    case types.PASSWORD_FORGET: {

      var { status, email } = action.data;
      var object = {
        status: status,
        email: action.email.username
      }

      state = object;
      localStorage.setItem('passwordForget', JSON.stringify(object));

      return state;
    }
    // case types.TEST: {
    //   
    //   state = action.data;
    //   return state;
    // }
    default: return state;
  }
};
export default passwordForget;
