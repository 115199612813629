import styles from './news.module.scss'
import { Link } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Input, Row, Table, Col, Button, Popconfirm, notification } from 'antd'
import { getNewMySelf, deleteNew } from 'apis/news'
import { ROUTES, PERMISSIONS, ACTION } from 'consts'
import Permission from 'components/permission'
import moment from 'moment'
import parse from 'html-react-parser'
import { useDispatch } from 'react-redux'

export default function News() {
  const dispatch = useDispatch()

  const [news, setNews] = useState([])
  const [countNews, setCountNews] = useState(0)
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 20 })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const columns = [
    {
      title: 'Title',
      render: (text, record) => (
        <Link to={{ pathname: ROUTES.NEWS_LETTER, state: record }}>
          {record.titleNew}
        </Link>
      ),
    },
    {
      title: 'Description',
      render: (text, record) =>
        record.descriptionNew && parse(record.descriptionNew),
    },
    {
      title: 'Create date',
      dataIndex: 'createdDate',
      render: (text) => text && moment(text).format('YYYY-MM-DD'),
    },
  ]

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      paramsFilter.page = 1

      getAllNew(paramsFilter)
      setParamsFilter(paramsFilter)
    }, 750)
  }

  const _deleteNews = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const listPromise = selectedRowKeys.map(async (_id) => {
        return await deleteNew({ _id })
      })

      await Promise.all(listPromise)
      notification.success({ message: 'Delete news success!' })
      getAllNew(paramsFilter)
      setSelectedRowKeys([])
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const getAllNew = async (params) => {
    try {
      setLoading(true)
      const res = await getNewMySelf(params)
      console.log(res)

      if (res.status === 200) {
        setNews(res.data.data)
        setCountNews(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAllNew(paramsFilter)
  }, [])

  return (
    <div className={styles['news_manager']}>
      <div className={styles['news_manager_title']}>
        <Row className={styles['news_manager_title_row']}>
          <Col
            className={styles['news_manager_title_row_col']}
            xs={24}
            sm={11}
            md={11}
            lg={11}
            xl={11}>
            <div className={styles['news_manager_title_row_col_left']}>
              <div>NEWS</div>
            </div>
          </Col>
          <Permission permissions={[PERMISSIONS.create_news]}>
            <Col
              className={styles['news_manager_title_row_col']}
              xs={24}
              sm={11}
              md={11}
              lg={11}
              xl={11}>
              <Link to={ROUTES.NEWS_LETTER}>
                <div className={styles['news_manager_title_row_col_right']}>
                  <Button type="primary">Create new</Button>
                </div>
              </Link>
            </Col>
          </Permission>
        </Row>
      </div>
      <div className={styles['news_manager_select']}>
        <Input.Search
          onChange={onSearch}
          className={styles['orders_manager_content_row_col_search']}
          placeholder="Search by title"
          allowClear
        />
      </div>
      <Row
        style={{
          width: '100%',
          marginBottom: 10,
          display: !selectedRowKeys.length && 'none',
        }}>
        <Popconfirm
          onConfirm={_deleteNews}
          title="Are you sure delete this news ?"
          okText="Ok"
          cancelText="No">
          <Button icon={<DeleteOutlined />} type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      </Row>
      <div className={styles['news_manager_table']}>
        <Table
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: (keys) => setSelectedRowKeys(keys),
          }}
          size="small"
          style={{ width: '100%' }}
          loading={loading}
          rowKey="_id"
          columns={columns}
          dataSource={news}
          pagination={{
            position: ['bottomRight'],
            current: paramsFilter.page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 40, 50, 70, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.pageSize = pageSize
              getAllNew(paramsFilter)
              setParamsFilter(paramsFilter)
            },
            total: countNews,
          }}
        />
      </div>
    </div>
  )
}
