import React from 'react'
import styles from './exportcsv.module.scss'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

import { Button } from 'antd'
import { ToTopOutlined } from '@ant-design/icons'

export default function ExportTracking({ fileName, dataExport, name }) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData, fileName, name) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div
      style={{ minWidth: 130 }}
      onClick={async () => {
        const data = dataExport.resultEcom.map((e, index) => {
          if (e)
            return {
              'Result Ecom':
                (dataExport.resultEcom[index] &&
                dataExport.resultEcom[index].success
                  ? 'SUCCESS'
                  : 'FAILED') || '',
              'Result Fulfill':
                (dataExport.resultFulfill[index] &&
                dataExport.resultFulfill[index].success
                  ? 'SUCCESS'
                  : 'FAILED') || '',
              'Site ID': e.key_design || '',
              'Order ID': e.id_order || '',
              'Order Item ID': e.id || '',
              'Customer email': e.customer_email || '',
              'Customer phone': e.customer_phone || '',
              Carrier: e.carrier || '',
              'Tracking Number': e.tracking_number || '',
            }
        })

        exportToCSV(data, fileName)
      }}
      className={styles['import_orders_title_right']}>
      <Button type="link" icon={<ToTopOutlined />}>
        {name}
      </Button>
    </div>
  )
}
