import React, { useState, useEffect, useRef } from 'react'
import styles from './carries.module.scss'

//apis
import { searchCarrier, updateCarrier } from 'apis/carrier'

//antd
import {
  Col,
  Row,
  notification,
  Upload,
  Drawer,
  Form,
  Table,
  Button,
  Input,
  Popconfirm,
  BackTop,
  Badge,
} from 'antd'

import { ACTION, PERMISSIONS } from 'consts'
import { useDispatch } from 'react-redux'

//components
import Permission from 'components/permission'

//icons antd
import {
  EditOutlined,
  LockOutlined,
  CarOutlined,
  InstagramOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { getCarrier, getCarrierCreate } from 'apis/carrier'
import { uploadFile } from 'apis/upload'

export default function Carries() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [countCarriers, setCountCarriers] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({})
  const [carrier, setCarrier] = useState([])
  const [visible, setVisile] = useState(false)
  const [picture, setPicture] = useState('')
  const [pictureView, setPictureView] = useState('')
  const [isUpdateCarrier, setIsUpdateCarrier] = useState(false) //check co dang update carrier ko ?

  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      if (value) {
        getCarrierData({ page: 1, pageSize, search: value, ...paramsFilter })
        setParamsFilter({ ...paramsFilter, search: value })
      } else {
        delete paramsFilter.search
        getCarrierData({ page, pageSize, ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      }
    }, 750)
  }

  const showDrawer = () => {
    setVisile(true)
  }
  const onClose = () => {
    setVisile(false)
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const getCarrierData = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      console.log(params)
      const res = await getCarrier({ active: true, ...params })
      console.log(res)
      if (res.status === 200) {
        setCountCarriers(res.data.count)
        setCarrier(res.data.data)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  useEffect(() => {
    getCarrierData({ page, pageSize })
  }, [])
  const openNotification = () => {
    notification.success({
      message: 'Success',
      description: 'Successful carrier creation!!!',
    })
  }

  const openNotificationUpdate = () => {
    notification.success({
      message: 'Success',
      description: 'Successful carrier update!!!',
    })
  }
  const createCarrierData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await getCarrierCreate(object)

      if (res.status === 200) {
        openNotification()
        await getCarrierData({ page, pageSize, ...paramsFilter })
        onClose()
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }
  const onFinish = async () => {
    let isValidated = true
    try {
      await form.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) return

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const data = form.getFieldsValue()
      let urlPicture = ''

      if (picture) {
        if (typeof picture == 'string') urlPicture = picture
        else {
          const resUpload = await uploadFile(picture)
          console.log(resUpload)
          if (resUpload.data.success) urlPicture = resUpload
        }
      }

      var body = {
        ...data,
        picture: urlPicture,
        is_active: true,
      }
      console.log(body)
      dispatch({ type: ACTION.LOADING, data: false })

      if (isUpdateCarrier) updateCarrierData(body)
      else createCarrierData(body)
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const updateCarrierData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      delete object._id
      const res = await updateCarrier(object)
      console.log(res)
      if (res.status === 200) {
        await getCarrierData({ page, pageSize, ...paramsFilter })
        openNotificationUpdate()
        onClose()
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log()
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const Setting = (props) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
      site={props.site}>
      <Permission permissions={[PERMISSIONS.edit_carrier]}>
        <div
          onClick={() => {
            form.setFieldsValue({ ...props.site })
            setPictureView(props.site.picture)
            setPicture(props.site.picture)
            setIsUpdateCarrier(true)
            setTimeout(() => setVisile(true), 200)
          }}
          style={{ marginRight: '0.5rem', cursor: 'pointer' }}>
          <EditOutlined style={{ color: '#36A0FB', fontSize: '1rem' }} />
        </div>
      </Permission>

      <Permission permissions={[PERMISSIONS.update_active_carrier]}>
        <Popconfirm
          title={`Are you sure to ${
            props.site.is_active ? 'deactivate' : 'activate'
          } this carrier`}
          okText="Yes"
          cancelText="No"
          onConfirm={() =>
            updateCarrierData({
              ...props.site,
              is_active: !props.site.is_active,
            })
          }>
          <div style={{ marginRight: '0.5rem', cursor: 'pointer' }}>
            {props.site.is_active ? (
              <LockOutlined style={{ color: '#FC2310', fontSize: '1rem' }} />
            ) : (
              <UnlockOutlined style={{ color: 'green', fontSize: '1rem' }} />
            )}
          </div>
        </Popconfirm>
      </Permission>
    </div>
  )

  const columns = [
    {
      title: 'Picture',
      dataIndex: 'picture',
      render: (text) => (
        <div>
          <img style={{ width: '5rem', height: '5rem' }} src={text} alt="" />
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Home page',
      dataIndex: 'homepage',
      render: (text) => (
        <a
          href={text}
          target="_blank"
          style={{ cursor: 'pointer', color: '#40A9FF' }}>
          {text}
        </a>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Country code',
      dataIndex: 'country_code',
    },
    {
      title: 'Name cn',
      dataIndex: 'name_cn',
    },
    {
      title: 'Active',
      dataIndex: 'is_active',
      width: 100,
      render: (text) => (
        <Badge
          status={text ? 'success' : 'warning'}
          text={text ? 'Active' : 'Deactive'}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 80,
      render: (text, record) => <Setting site={record} />,
    },
  ]

  //reset drawer
  useEffect(() => {
    if (!visible) {
      form.resetFields()
      setIsUpdateCarrier(false)
      setPictureView('')
      setPicture('')
    }
  }, [visible])

  return (
    <div className={styles['orders_manager']}>
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }>
                  <CarOutlined />
                </div>
                <div>CARRIERS</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div>
              <div className={styles['orders_manager_header_row_col_right']}>
                <div>
                  <Permission permissions={[PERMISSIONS.create_carrier]}>
                    <Button
                      onClick={showDrawer}
                      type="primary"
                      style={{ width: '7.5rem', height: '2.5rem' }}
                      size="large">
                      Add New
                    </Button>
                  </Permission>
                  <Drawer
                    title={
                      isUpdateCarrier ? 'Update Carrier' : 'Add New Carrier'
                    }
                    width={500}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    footer={
                      <div
                        style={{
                          textAlign: 'right',
                        }}>
                        <Button onClick={onClose} style={{ marginRight: 8 }}>
                          Cancel
                        </Button>
                      </div>
                    }>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter name',
                              },
                            ]}>
                            <Input placeholder="Enter name" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="code"
                            label="Code"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter code',
                              },
                            ]}>
                            <Input placeholder="Enter code" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter phone',
                              },
                            ]}>
                            <Input placeholder="Enter phone" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="homepage"
                            label="Home page"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter home page',
                              },
                            ]}>
                            <Input placeholder="Enter home page" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="type"
                            label="Type"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter type',
                              },
                            ]}>
                            <Input placeholder="Enter type" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item label="Picture" required>
                            <Upload
                              showUploadList={false}
                              listType="picture-card"
                              name="files"
                              action="/upload.do"
                              data={(file) => {
                                getBase64(file, (imageUrl) =>
                                  setPictureView(imageUrl)
                                )
                                setPicture(file)
                              }}>
                              {pictureView ? (
                                <img
                                  src={pictureView}
                                  alt="avatar"
                                  style={{ width: '100%' }}
                                />
                              ) : (
                                <PlusOutlined />
                              )}
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="country_code"
                            label="Country code"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter country code',
                              },
                            ]}>
                            <Input placeholder="Enter country code" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="name_cn"
                            label="Name cn"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter name cn',
                              },
                            ]}>
                            <Input placeholder="Enter name cn" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="track_url"
                            label="Track url"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter track url',
                              },
                            ]}>
                            <Input placeholder="Enter track url (https or http)" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item>
                            <Button
                              icon={<InstagramOutlined />}
                              style={{ width: '100%', height: '2.5rem' }}
                              type="primary"
                              htmlType="submit">
                              {isUpdateCarrier ? 'Upadte' : 'Add'}
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Drawer>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_content']}>
        <Row className={styles['orders_manager_content_row']}>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}>
            <div>
              <Input
                onChange={onSearch}
                className={styles['orders_manager_content_row_col_search']}
                placeholder="Search by name, code"
                allowClear
              />
            </div>
          </Col>

          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}></Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={7}
            md={7}
            lg={7}
            xl={7}></Col>
        </Row>
      </div>
      <div className={styles['orders_manager_tabs']}>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            className={styles['orders_manager_tabs_table_child']}
            columns={columns}
            dataSource={carrier}
            size="small"
            pagination={{
              position: ['bottomLeft'],
              current: page,
              defaultPageSize: 20,
              pageSizeOptions: [20, 30, 50, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getCarrierData({ page, pageSize, ...paramsFilter })
              },
              total: countCarriers,
            }}
          />
        </div>
      </div>
    </div>
  )
}
