import { ACTION, DATA_PRINT_FILE, DATA_VARIANT, ROUTES } from 'consts'
import { decodeJWT } from 'utils'


var initialState = [];
var dataPrintFile = (state = initialState, action) => {
  switch (action.type) {
    case DATA_PRINT_FILE: {

      state.push(action.data);
      return [...state];
    }


    default:
      return state
  }
}
export default dataPrintFile
