import {
  NEWS_SOCKET,
  NEWS_API,
  NEWS_API_IS_SEEN,
  NEWS_API_IS_SEEN_ACTION,
  SEARCH_NEWS,
} from "consts";

var initialState = [];

const newsIsSeen = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_API_IS_SEEN: {

      state = action.data;
      // state.push(action.data);
      // 
      // 
      var result = state.reverse();
      return [...result];
    }
    case NEWS_API_IS_SEEN_ACTION: {


      action.data.forEach((values, index) => {
        state.unshift(values);
      });
      // state.push(action.data);
      // state = action.data;
      return [...state];
    }
    case SEARCH_NEWS: {

      state = action.data;

      return [...state];
    }
    default:
      return state;
  }
};

export default newsIsSeen;
