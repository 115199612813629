import {
  Col,
  Row,
  Checkbox,
  Table,
  Form,
  Button,
  Modal,
  notification,
  Input,
} from 'antd'
import { Link } from 'react-router-dom'
import styles from './add.module.scss'
import { ACTION, ROUTES } from 'consts'
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons'
import { CKEditor } from 'ckeditor4-react'
import parse from 'html-react-parser'

//apis
import { getsAllUsers } from 'apis/users'
import { createNews, updateNew } from 'apis/news'

const columns = [
  {
    title: 'Username',
    dataIndex: 'username',
  },
  {
    title: 'Full name',
    dataIndex: 'name',
    render: (text, record) =>
      (record.first_name || '') + ' ' + (record.last_name || ''),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'Mail',
    dataIndex: 'mail',
  },
  {
    title: 'Birthday',
    dataIndex: 'birthday',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Role',
    dataIndex: 'role',
  },
  {
    title: 'Date created',
    dataIndex: 'createDate',
  },
]

export default function NewsLetter() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const typingTimeoutRef = useRef(null)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [users, setUsers] = useState([])
  const [modal2Visible, setModal2Visible] = useState(false)
  const [checkboxAll, setCheckboxAll] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 20 })
  const [countUsers, setCountUsers] = useState(0)
  const [loading, setLoading] = useState(false)

  const setModal2VisibleModal = (modal2Visible) => {
    setModal2Visible(modal2Visible)
  }

  const openNotification = () => {
    notification.success({
      message: 'Success',
      description: 'Successfully added News!!!',
    })
  }
  const openNotificationCheckbox = () => {
    notification.error({
      message: 'Error',
      description: 'You have not selected All or Choose recipient!!!',
    })
  }

  const createNewsData = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      const res = await createNews(body)
      console.log(res)
      if (res.status === 200) {
        openNotification()
        history.push(ROUTES.NEWS)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const _updateNew = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        _id: location.state._id,
        titleNew: title,
        descriptionNew: description,
      }
      const res = await updateNew(body)
      console.log(res)
      if (res.status === 200) {
        notification.success({ message: 'Update new success' })
        history.push(ROUTES.NEWS)
      } else
        notification.error({ message: res.data.mess || 'Update new failed' })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinish = () => {
    if (!description) {
      notification.error({ message: 'Please input description' })
      return
    }

    let sendListUser = []
    users.map((user) => {
      if (selectedRowKeys.includes(user._id))
        sendListUser.push({ idUser: user.username, isSeen: false })
    })

    if (!sendListUser.length) {
      openNotificationCheckbox()
      return
    }

    const body = {
      isAll: checkboxAll,
      listUser: sendListUser,
      titleNews: title,
      descriptionNews: description,
    }

    createNewsData(body)
  }

  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      paramsFilter.page = 1
      getsAllUsersData(paramsFilter)
      setParamsFilter({ ...paramsFilter })
    }, 750)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys),
  }
  const getsAllUsersData = async (params) => {
    try {
      setLoading(true)
      const res = await getsAllUsers(params)
      console.log(res)
      if (res.status === 200) {
        setUsers(res.data.data)
        setCountUsers(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getsAllUsersData(paramsFilter)
  }, [])

  useEffect(() => {
    if (location.state) {
      setTitle(location.state.titleNew)
      setDescription(location.state.descriptionNew)
    }
  }, [])

  return (
    <div className={styles['newsletter_manager']}>
      <Modal
        title="Recipient list"
        centered
        width={1100}
        footer={null}
        visible={modal2Visible}
        onOk={() => setModal2VisibleModal(false)}
        onCancel={() => setModal2VisibleModal(false)}>
        <Input.Search
          className={styles['modal_recipient_parent_top_input']}
          placeholder="Search user by username"
          onChange={onSearch}
          enterButton
        />
        <Table
          loading={loading}
          rowKey="_id"
          style={{ width: '100%', marginBottom: 30, marginTop: 25 }}
          size="small"
          rowSelection={rowSelection}
          columns={columns}
          dataSource={users}
          scroll={{ y: 350 }}
          pagination={{
            position: ['bottomRight'],
            current: paramsFilter.page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 40, 50, 70, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              paramsFilter.page = page
              paramsFilter.pageSize = pageSize
              getsAllUsersData(paramsFilter)
              setParamsFilter(paramsFilter)
            },
            total: countUsers,
          }}
        />

        <Row justify="end">
          <Button
            onClick={() => setModal2VisibleModal(false)}
            style={{ width: '5rem', color: 'white' }}
            type="primary">
            Ok
          </Button>
        </Row>
      </Modal>

      <Link style={{ width: '100%' }} to={ROUTES.NEWS}>
        <div className={styles['newsletter_manager_title']}>
          <div>
            <ArrowLeftOutlined />
          </div>
          <div>{location.state ? 'Edit new' : 'Add new'}</div>
        </div>
      </Link>
      <Form className={styles['newsletter_manager_bottom']} onFinish={onFinish}>
        <div className={styles['newsletter_manager_bottom']}>
          <Row className={styles['newsletter_manager_bottom_row']}>
            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
              Title
            </Col>
            <Col xs={24} sm={24} md={21} lg={21} xl={21}>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Input title"
              />
            </Col>
          </Row>

          <Row
            className={styles['newsletter_manager_bottom_row']}
            style={{ marginTop: 15, marginBottom: 15 }}>
            <Col xs={24} sm={24} md={2} lg={2} xl={2}>
              Description
            </Col>
            <Col xs={24} sm={24} md={21} lg={21} xl={21}>
              <CKEditor
                initData={
                  location.state && parse(location.state.descriptionNew)
                }
                onChange={(e) => setDescription(e.editor.getData())}
              />
            </Col>
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ width: '100%', marginBottom: 15 }}>
            <Col
              xs={24}
              sm={24}
              md={2}
              lg={2}
              xl={2}
              style={{ visibility: location.state && 'hidden' }}>
              <Checkbox
                checked={checkboxAll}
                onChange={(e) => setCheckboxAll(e.target.checked)}>
                All user
              </Checkbox>
            </Col>
            <Col xs={24} sm={24} md={21} lg={21} xl={21}>
              <Row justify="space-between">
                <a
                  style={{ visibility: location.state && 'hidden' }}
                  onClick={() => setModal2VisibleModal(true)}>
                  Choose recipient
                </a>
                <Button
                  onClick={location.state ? _updateNew : onFinish}
                  icon={<SaveOutlined />}
                  type="primary">
                  Save
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
}
