import React, { useState, useEffect, useRef } from 'react'
import styles from './orders-tracking.module.scss'

//apis
import { getCarrier } from 'apis/carrier'
import { getTrackingMore } from 'apis/logs'
import { getSites } from 'apis/sites'
import { filterRoleUsers } from 'apis/users'
import { trackingNeedUpload, uploadTracking } from 'apis/order'

//antd
import {
  Col,
  Row,
  Table,
  Select,
  Input,
  Space,
  BackTop,
  Spin,
  Popover,
  DatePicker,
  notification,
} from 'antd'

import { ACTION } from 'consts'
import { useDispatch } from 'react-redux'
import moment from 'moment'

//icons antd
import { ShoppingCartOutlined } from '@ant-design/icons'

const { Option } = Select
export default function OrdersTracking() {
  const dispatch = useDispatch()

  const [countTracking, setCountTracking] = useState()

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [ordersTracking, setOrdersTracking] = useState([])

  const [ordersTrackingNeedUpload, setOrdersTrackingNeedUpload] = useState(0)
  const [carriers, setCarriers] = useState([])
  const [countData, setCountData] = useState(0)
  const [status, setStatus] = useState('all')
  const [optionsSearch, setOptionsSearch] = useState('search')
  const [valueSearch, setValueSearch] = useState('') //dùng để hiện thị value search trong input search
  const [paramsFilter, setParamsFilter] = useState({ this_month: true }) //params search by site, date, order name
  const [page, setPage] = useState(1) //pagination, current page
  const [pageSize, setPageSize] = useState(20) //pagination, current page size
  const [allSite, setAllSite] = useState([])
  const [usersSeller, setUsersSeller] = useState([])

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const [valueTime, setValueTime] = useState('this_month') //dùng để hiện thị value trong filter by time
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({
    this_month: true,
  })
  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date

  const STATUS_TRACKING = {
    ALL: 'all',
    PENDING: 'pending',
    NOT_FOUND: 'notfound',
    TRANSIT: 'transit',
    PICKUP: 'pickup',
    DELIVERED: 'delivered',
    EXPIRED: 'expired',
    UNDELIVERED: 'undelivered',
    EXCEPTION: 'exception',
    WAIT_UPDATE: 'waitUpdate',
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const values = e.target.value
      //khi search hoac filter thi reset page ve 1
      setPage(1)

      if (values) {
        const paramsOptionsSearch = Object.fromEntries([
          [optionsSearch, values],
        ]) //convert array[[key, value]] to object

        getOrdersTrackingData({
          status,
          page: 1,
          pageSize,
          ...paramsFilter,
          ...paramsOptionsSearch,
        })
        setParamsFilter({ ...paramsFilter, ...paramsOptionsSearch })
      } else {
        delete paramsFilter[optionsSearch]
        getOrdersTrackingData({
          status,
          page: 1,
          pageSize,
          ...paramsFilter,
        })
        setParamsFilter({ ...paramsFilter })
      }
    }, 450)
  }

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const _uploadTracking = async () => {
    const key = 'upload'
    try {
      notification.open({
        key: key,
        message: 'Upload Tracking...',
        description: <Spin />,
        duration: 0,
        closeIcon: null,
      })
      const res = await uploadTracking()
      notification.close(key)
      console.log(res)
      if (res.status === 200) {
        await _getTrackingNeedUpload()
        notification.success({ message: res.data.mess || 'Upload success' })
      } else notification.error({ message: res.data.mess || 'Upload failed!' })
    } catch (error) {
      notification.error({ message: 'Upload failed!' })
      notification.close(key)
      console.log(error)
    }
  }

  const _getCarrier = async () => {
    try {
      const res = await getCarrier()
      if (res.status === 200) setCarriers(res.data.data)
    } catch (error) {}
  }

  const _getAllSite = async () => {
    try {
      const res = await getSites()
      if (res.status === 200) setAllSite(res.data.data)
    } catch (error) {}
  }

  const _getUsersBySeller = async () => {
    try {
      const res = await filterRoleUsers({ role: 'Seller' })
      if (res.status === 200) setUsersSeller(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const _getTrackingNeedUpload = async () => {
    try {
      const res = await trackingNeedUpload()
      if (res.status === 200) setOrdersTrackingNeedUpload(res.data.count)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getUsersBySeller()
    _getTrackingNeedUpload()
    _getCarrier()
    _getAllSite()
    getOrdersTrackingData({ page, pageSize, ...paramsFilter })
  }, [])

  const getOrdersTrackingData = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })

      //nếu status là all thì ko cần truyền query status
      if (params.status === 'all') delete params.status

      console.log(params)
      const res = await getTrackingMore(params)
      console.log(res)
      if (res.status === 200) {
        setOrdersTracking(res.data.data)
        setCountTracking(res.data.status)
        setCountData(res.data.count)
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const columns = [
    {
      title: 'Tracking number',
      dataIndex: 'tracking_number',
    },
    {
      title: 'Name Order',
      dataIndex: 'name_order',
    },
    {
      title: 'Seller',
      dataIndex: 'id_user',
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
    },
    {
      title: 'Date Create',
      dataIndex: 'time_created',
      render: (text, record) =>
        text && moment(text).format('DD-MM-YYYY HH:mm:ss'),
    },
    {
      title: 'Customer',
      render: (text, record) =>
        record.customer ? (
          <Popover
            content={
              <div style={{ maxWidth: 350 }}>
                <div>
                  Fullname: {record.customer.first_name || ''}{' '}
                  {record.customer.last_name || ''}
                </div>
                <div>Province: {record.customer.province || ''}</div>
                <div>Phone: {record.customer.phone || ''}</div>
                <div>Note: {record.customer.note || ''}</div>
                <div>Postcode: {record.customer.postcode || ''}</div>
                <div>Email: {record.customer.email || ''}</div>
                <div>Country Code: {record.customer.country_code || ''}</div>
                <div>Country: {record.customer.country || ''}</div>
                <div>Company: {record.customer.company || ''}</div>
                <div>City: {record.customer.city || ''}</div>
                <div>Address 1: {record.customer.address_1 || ''}</div>
                <div>Address 2: {record.customer.address_2 || ''}</div>
              </div>
            }>
            <div style={{ color: '#1890FF' }}>
              {record.customer.first_name || ''}{' '}
              {record.customer.last_name || ''}
            </div>
          </Popover>
        ) : (
          ''
        ),
    },
    {
      title: 'Shipping',
      render: (text, record) =>
        record.shipping ? (
          <Popover
            content={
              <div style={{ maxWidth: 350 }}>
                <div>
                  Fullname: {record.shipping.first_name || ''}{' '}
                  {record.shipping.last_name || ''}
                </div>
                <div>Province: {record.shipping.province || ''}</div>
                <div>Phone: {record.shipping.phone || ''}</div>
                <div>Note: {record.shipping.note || ''}</div>
                <div>Postcode: {record.shipping.postcode || ''}</div>
                <div>Email: {record.shipping.email || ''}</div>
                <div>Country Code: {record.shipping.country_code || ''}</div>
                <div>Country: {record.shipping.country || ''}</div>
                <div>Company: {record.shipping.company || ''}</div>
                <div>City: {record.shipping.city || ''}</div>
                <div>Address 1: {record.shipping.address_1 || ''}</div>
                <div>Address 2: {record.shipping.address_2 || ''}</div>
              </div>
            }>
            <div style={{ color: '#1890FF' }}>
              {record.shipping.first_name || ''}{' '}
              {record.shipping.last_name || ''}
            </div>
          </Popover>
        ) : (
          ''
        ),
    },
  ]

  return (
    <div className={styles['orders_manager']}>
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <Row
        justify="space-between"
        style={{
          width: '100%',
          borderBottom: '1px solid #ddcfcf',
          paddingBottom: 8,
          fontSize: 17,
        }}>
        <div style={{ fontWeight: 500 }}>
          <ShoppingCartOutlined style={{ marginRight: 8 }} />
          ORDERS MANAGER
        </div>
        <div
          className={styles['item_carrier']}
          style={{ width: 241, backgroundColor: 'green', color: 'white' }}
          onClick={_uploadTracking}>
          <div className={styles['items_carrier_title']}>Upload Tracking More</div>
          <div className={styles['item_carrier_child']}>
            {ordersTrackingNeedUpload}
          </div>
        </div>
      </Row>

      <div className={styles['filter_carrier']}>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}>
          {Object.keys(STATUS_TRACKING).map((e) => (
            <Col
              style={{ width: '100%', marginBottom: '1rem' }}
              xs={24}
              sm={11}
              md={5}
              lg={5}
              xl={5}
              onClick={() => {
                setStatus(STATUS_TRACKING[e])
                getOrdersTrackingData({
                  status: STATUS_TRACKING[e],
                  page: 1,
                  pageSize,
                  ...paramsFilter,
                })
              }}>
              <div
                className={
                  styles[
                    status === STATUS_TRACKING[e]
                      ? 'item_carrier_active'
                      : 'item_carrier'
                  ]
                }>
                <div
                  className={
                    styles[
                      status === STATUS_TRACKING[e]
                        ? 'items_carrier_title_active'
                        : 'items_carrier_title'
                    ]
                  }>
                  {e}
                </div>
                <div
                  className={
                    styles[
                      status === STATUS_TRACKING[e]
                        ? 'item_carrier_child_active'
                        : 'item_carrier_child'
                    ]
                  }>
                  {countTracking && countTracking[STATUS_TRACKING[e]]}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Row style={{ marginTop: 20, marginBottom: 30, width: '100%' }}>
        <Row style={{ width: '100%' }}>
          <Space size="middle" wrap>
            <Col>
              <div>Search by options</div>
              <Input
                style={{ width: 430 }}
                value={valueSearch}
                onChange={onSearch}
                placeholder="Search by"
                allowClear
                addonAfter={
                  <Select
                    placeholder="options"
                    value={optionsSearch}
                    style={{ width: 150 }}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      delete paramsFilter[optionsSearch]
                      setParamsFilter({
                        ...paramsFilter,
                        ...Object.fromEntries([[value, valueSearch]]),
                      })
                      setOptionsSearch(value)
                    }}>
                    <Option value="search">tracking number</Option>
                    <Option value="name_order">name order</Option>
                    <Option value="name_customer">name customer</Option>
                    <Option value="sku">sku</Option>
                    <Option value="email">email</Option>
                  </Select>
                }
              />
            </Col>
            <Col>
              <div>Filter by time</div>
              <Select
                open={isOpenSelect}
                onBlur={() => {
                  if (isOpenSelect) toggleOpenSelect()
                }}
                onClick={() => {
                  if (!isOpenSelect) toggleOpenSelect()
                }}
                style={{ width: 380 }}
                placeholder="Choose time"
                allowClear
                value={valueTime}
                onChange={async (value) => {
                  setValueTime(value)

                  //khi search hoac filter thi reset page ve 1
                  setPage(1)

                  //xoa params search date hien tai
                  const p = Object.keys(valueDateTimeSearch)
                  if (p.length) delete paramsFilter[p[0]]

                  setValueDateSearch(null)
                  delete paramsFilter.startDate
                  delete paramsFilter.endDate

                  if (isOpenSelect) toggleOpenSelect()

                  if (value) {
                    const searchDate = Object.fromEntries([[value, true]]) // them params search date moi
                    getOrdersTrackingData({
                      status,
                      page: 1,
                      pageSize,
                      ...paramsFilter,
                      ...searchDate,
                    })
                    setParamsFilter({ ...paramsFilter, ...searchDate })
                    setValueDateTimeSearch({ ...searchDate })
                  } else {
                    getOrdersTrackingData({
                      status,
                      page: 1,
                      pageSize,
                      ...paramsFilter,
                    })
                    setParamsFilter({ ...paramsFilter })
                    setValueDateTimeSearch({})
                  }
                }}
                dropdownRender={(menu) => (
                  <div>
                    <DatePicker.RangePicker
                      onFocus={() => {
                        if (!isOpenSelect) toggleOpenSelect()
                      }}
                      onBlur={() => {
                        if (isOpenSelect) toggleOpenSelect()
                      }}
                      value={valueDateSearch}
                      onChange={(dates, dateStrings) => {
                        //khi search hoac filter thi reset page ve 1
                        setPage(1)

                        if (isOpenSelect) toggleOpenSelect()

                        //nếu search date thì xoá các params date
                        delete paramsFilter.to_day
                        delete paramsFilter.yesterday
                        delete paramsFilter.this_week
                        delete paramsFilter.last_week
                        delete paramsFilter.last_month
                        delete paramsFilter.this_month
                        delete paramsFilter.this_year
                        delete paramsFilter.last_year

                        //Kiểm tra xem date có được chọn ko
                        //Nếu ko thì thoát khỏi hàm, tránh cash app
                        //và get danh sách order
                        if (!dateStrings[0] && !dateStrings[1]) {
                          delete paramsFilter.startDate
                          delete paramsFilter.endDate
                          getOrdersTrackingData({
                            status,
                            page: 1,
                            pageSize,
                            ...paramsFilter,
                          })
                          setParamsFilter({ ...paramsFilter })
                          setValueDateSearch(null)
                          setValueTime()
                        } else {
                          const dateFirst = dateStrings[0]
                          const dateLast = dateStrings[1]
                          setValueDateSearch(dates)
                          setValueTime(`${dateFirst} -> ${dateLast}`)

                          dateFirst.replace(/-/g, '/')
                          dateLast.replace(/-/g, '/')
                          getOrdersTrackingData({
                            status,
                            page: 1,
                            pageSize,
                            ...paramsFilter,
                            startDate: dateFirst,
                            endDate: dateLast,
                          })
                          setParamsFilter({
                            ...paramsFilter,
                            startDate: dateFirst,
                            endDate: dateLast,
                          })
                        }
                      }}
                      style={{ width: '100%' }}
                    />
                    {menu}
                  </div>
                )}>
                <Option value="to_day">Today</Option>
                <Option value="yesterday">Yesterday</Option>
                <Option value="this_week">This week</Option>
                <Option value="last_week">Last week</Option>
                <Option value="last_month">Last month</Option>
                <Option value="this_month">This month</Option>
                <Option value="this_year">This year</Option>
                <Option value="last_year">Last year</Option>
              </Select>
            </Col>
            <Col>
              <div>Filter by carrier</div>
              <Select
                style={{ width: 240 }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={carriers.length ? null : <Spin size="small" />}
                allowClear
                placeholder="Choose carrier"
                onChange={(value) => {
                  //khi search hoac filter thi reset page ve 1
                  setPage(1)

                  if (value) paramsFilter.carrier = value
                  else delete paramsFilter.carrier

                  getOrdersTrackingData({
                    status,
                    page: 1,
                    pageSize,
                    ...paramsFilter,
                  })
                  setParamsFilter({ ...paramsFilter })
                }}>
                {carriers.map((e) => (
                  <Option value={e.code}>{e.name}</Option>
                ))}
              </Select>
            </Col>
            <Col>
              <div>Filter by site</div>
              <Select
                style={{ width: 240 }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                notFoundContent={allSite.length ? null : <Spin size="small" />}
                allowClear
                placeholder="Choose site"
                onChange={(value) => {
                  //khi search hoac filter thi reset page ve 1
                  setPage(1)

                  if (value) paramsFilter.id_site = value
                  else delete paramsFilter.id_site

                  getOrdersTrackingData({
                    status,
                    page: 1,
                    pageSize,
                    ...paramsFilter,
                  })
                  setParamsFilter({ ...paramsFilter })
                }}>
                {allSite.map((e, index) => (
                  <Option value={e.site_name} key={index}>
                    {e.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <div>Filter by seller</div>
              <Select
                style={{ width: 240 }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                placeholder="Choose seller"
                onChange={(value) => {
                  //khi search hoac filter thi reset page ve 1
                  setPage(1)

                  if (value) paramsFilter.username = value
                  else delete paramsFilter.username

                  getOrdersTrackingData({
                    status,
                    page: 1,
                    pageSize,
                    ...paramsFilter,
                  })
                  setParamsFilter({ ...paramsFilter })
                }}>
                {usersSeller.map((e, index) => (
                  <Option value={e.username} key={index}>
                    {e.username}
                  </Option>
                ))}
              </Select>
            </Col>
          </Space>
        </Row>
      </Row>
      <div className={styles['orders_manager_tabs']}>
        <div className={styles['orders_manager_tabs_table']}>
          <Table
            style={{ width: '100%' }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={ordersTracking}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ padding: '5px 25px' }}>
                  <div>Goods Title:signpify-440247</div>
                  <div>Customer Name:Michael Ball</div>
                  <div>Customer Email or phone:docball@me.com+1 5023300997</div>
                  <div>Destination Country - Not Found</div>
                  <div>
                    2021-08-02 15:30,Delivered, In/At Mailbox, Your item was
                    delivered in or at the mailbox at 3:30 pm on August 2, 2021
                    in FRANKFORT, KY 40601., FRANKFORT,KY,40601
                  </div>
                  <div>
                    2021-08-02 06:10,Out for Delivery, FRANKFORT,KY,40601
                  </div>
                  <div>2021-08-01 10:01,Arrived at Hub, FRANKFORT,KY,40601</div>
                  <div>
                    2021-08-01 08:46,Accepted at USPS Destination Facility,
                    FRANKFORT,KY,40601
                  </div>
                  <div>
                    2021-07-30 12:01,Arrived Shipping Partner Facility, USPS
                    Awaiting Item, HEBRON,KY,41048
                  </div>
                  <div>
                    2021-07-29 19:22,Departed Shipping Partner Facility, USPS
                    Awaiting Item, MELROSE PARK,IL,60160
                  </div>
                  <div>
                    2021-07-29 13:48,Arrived Shipping Partner Facility, USPS
                    Awaiting Item, MELROSE PARK,IL,60160
                  </div>
                  <div>Notifications Record</div>
                  <div>
                    2021-08-04 11:08, "Delivered"email was sent to Michael
                    Ball(docball@me.com)
                  </div>
                </div>
              ),
              expandedRowKeys: selectedRowKeys,
              expandIconColumnIndex: -1,
            }}
            size="small"
            pagination={{
              position: ['bottomLeft'],
              current: page,
              defaultPageSize: 20,
              pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setPage(page)
                setPageSize(pageSize)
                getOrdersTrackingData({
                  status,
                  page,
                  pageSize,
                  ...paramsFilter,
                })
              },
              total: countData,
            }}
          />
        </div>
      </div>
    </div>
  )
}
