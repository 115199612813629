import * as types from "../../../constants/index";
// nhận data từ server
const initialState = [];
var site = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_SITE: {

      state = action.data;

      return state;
    }
    case types.CREATE_SITE: {

      state.push(action.data);

      state = state.reverse();
      return [...state];
    }
    case types.EDIT_SITE: {

      state.forEach((values, index) => {
        if (values.site_name === action.data.site_name) {
          state[index] = action.data;
        }
      })

      return [...state];
    }
    default: return state;
  }
};
export default site;
