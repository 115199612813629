import React, { useState, useEffect, useRef } from 'react'
import styles from './orders.module.scss'

//apis
import {
  getAllSite,
  updateOrders,
  updateStatusOrders,
  orders,
  resendOrder,
  rebuildOrder,
  pushNocustom,
  asyncOrders,
  exportOrders,
  importOrders,
  repairOrder,
} from 'apis/order'
import { updateOrdersTracking } from 'apis/ordersTracking'
import { getAllProductType } from 'apis/product'
import { getCarrier } from 'apis/carrier'
import { filterRoleUsers } from 'apis/users'
import { uploadFile, uploadFiles } from 'apis/upload'

//antd
import {
  Col,
  Row,
  Tabs,
  notification,
  Popconfirm,
  Form,
  Upload,
  DatePicker,
  Table,
  Popover,
  Button,
  Modal,
  Select,
  Input,
  Badge,
  Space,
  Timeline,
  Drawer,
  BackTop,
  Tag,
  Checkbox,
  Spin,
  Slider,
  InputNumber,
} from 'antd'

import moment from 'moment'
import { ACTION, PERMISSIONS, ROUTES, STATUS_ORDER } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { ExcelRenderer } from 'react-excel-renderer'

//component
import ExportTrackingInOrders from 'components/ExportCSV/ExportTrackingInOrders'
import ExportOrders from 'components/ExportCSV/ExportOrders'
import Permission from 'components/permission'

//icons antd
import {
  DownloadOutlined,
  FormOutlined,
  ShoppingCartOutlined,
  PlusOutlined,
  FilterOutlined,
  CloseCircleOutlined,
  ExportOutlined,
  CheckCircleOutlined,
  LinkOutlined,
  EditOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'

//columns table
import SettingColumns from 'components/setting-column'
import orderColumns from './orderColumns'

const { TabPane } = Tabs
const { Option } = Select
export default function Orders() {
  const [formFilter] = Form.useForm()
  const dispatch = useDispatch()

  const ordersReducers = useSelector((state) => state.orders)

  //list state select row keys
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const [visibleImportTracking, setVisibleImportTracking] = useState(false)
  const [dataImportTracking, setDataImportTracking] = useState([]) // dùng để hiện thị lên table import tracking
  const [dataImportCsv, setDataImportCsv] = useState(null) //dùng để import tracking
  const [dataImportOrdersView, setDataImportOrdersView] = useState([]) // dùng để hiện thị lên table import order
  const [dataImportOrders, setDataImportOrders] = useState([]) // dùng để hiện thị lên table import order
  const [modal2Visible, setModal2Visible] = useState(false)
  const [key, setKey] = useState(
    localStorage.getItem('statusOrder')
      ? localStorage.getItem('statusOrder')
      : 'all'
  )
  const [allProductType, setAllProductType] = useState([])
  const [loadingTable, setLoadingTable] = useState(false) //loading table antd
  const [countOrders, setCountOrders] = useState([]) //hiện thị số lượng orders theo status
  const [ordersByStatus, setOrdersByStatus] = useState([]) // data table
  const [carriers, setCarriers] = useState([])
  const [paramsFilter, setParamsFilter] = useState({}) //params search by site, date, order name
  const [page, setPage] = useState(
    localStorage.getItem('pageOrder') ? +localStorage.getItem('pageOrder') : 1
  )
  const [pageSize, setPageSize] = useState(20) //pagination, current page size
  const [valueSearch, setValueSearch] = useState('') //dùng để hiện thị value search trong input search
  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [optionsSearch, setOptionsSearch] = useState('search')
  const [usersSeller, setUsersSeller] = useState([]) //dung de filter, o more filter
  const [usersSupplier, setUsersSupplier] = useState([]) //dung de filter, o more filter
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [tagsFilterRender, setTagsFilterRender] = useState({})
  const [valueFileImport, setValueFileImport] = useState('')
  const [isFileImportError, setIsFileImportError] = useState(false)
  const [visibleImages, setVisibleImages] = useState(false)
  const [fileImportTracking, setFileImportTracking] = useState(null) //file import tracking, dung de luu tracking log
  const [totalRowFileImportTracking, setTotalRowFileImportTracking] =
    useState(0) // tổng row của file import tracking

  const onClickCancel = async (recordMini, recordMain) => {
    const keyNotify = `cancel ${recordMini.id}`
    try {
      if (recordMini.status === 'cancel') {
        notification.error({ message: 'Status current is cancel!' })
        return
      }

      const indexProductUnsign = recordMain.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductUnsign !== -1) {
        recordMain.list_product_unsign[indexProductUnsign].status = 'cancel'
        recordMain.action = `cancel item ${recordMain.list_product_unsign[indexProductUnsign].id}`
      }

      const indexProductFulfill = recordMain.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductFulfill !== -1) {
        recordMain.list_product_fulfill[indexProductFulfill].status = 'cancel'
        recordMain.action = `cancel item ${recordMain.list_product_fulfill[indexProductFulfill].id}`
      }

      notification.open({
        key: keyNotify,
        message: `Updating status cancel by item ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })
      const res = await updateOrders(recordMain)
      if (res.status === 200) {
        let body
        if (key === 'all')
          body = {
            page,
            pageSize,
            ...paramsFilter,
          }
        else
          body = {
            page,
            pageSize,
            ...paramsFilter,
            status: key,
          }
        const resOrder = await orders(body)
        if (resOrder.status === 200) {
          setOrdersByStatus(resOrder.data.data)
          setCountOrders([...resOrder.data.status_count])

          //nếu order là pending design thì cho expand table
          if (key === STATUS_ORDER.PENDING_DESIGN)
            setSelectedRowKeys(resOrder.data.data.map((e) => e.id_order))
        }
        notification.open({
          key: keyNotify,
          message: 'Updating status cancel success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration: 2,
        })
      } else
        notification.open({
          key: keyNotify,
          message: 'Updating status cancel failed, please try again',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          duration: 2,
        })
    } catch (error) {
      console.log(error)
      notification.open({
        key: keyNotify,
        message: 'Updating status cancel failed, please try again',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        duration: 2,
      })
    }
  }

  const onClickApprove = async (recordMini, recordMain) => {
    let keyNotify = `approve ${recordMini.id}`

    try {
      if (recordMini.status === 'approve') {
        notification.error({ message: 'Status current is approve!' })
        return
      }

      const indexProductUnsign = recordMain.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductUnsign !== -1) {
        recordMain.list_product_unsign[indexProductUnsign].status = 'approve'
        recordMain.action = `approve item ${recordMain.list_product_unsign[indexProductUnsign].id}`
      }

      const indexProductFulfill = recordMain.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductFulfill !== -1) {
        recordMain.list_product_fulfill[indexProductFulfill].status = 'approve'
        recordMain.action = `approve item ${recordMain.list_product_fulfill[indexProductFulfill].id}`
      }

      notification.open({
        key: keyNotify,
        message: `Updating status approve by item ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })
      console.log(JSON.stringify(recordMain))
      const res = await updateOrders(recordMain)
      if (res.status === 200) {
        setTimeout(() => {
          notification.open({
            key: keyNotify,
            message: 'Updating status approve success',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            duration: 2,
          })
        }, 10000)
      } else
        setTimeout(() => {
          notification.open({
            key: keyNotify,
            message: 'Updating status approve failed, please try again',
            icon: <CloseCircleOutlined style={{ color: 'red' }} />,
            duration: 2,
          })
        }, 10000)

      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => {
        if (res.status === 200) {
          let body
          if (key === 'all')
            body = {
              page,
              pageSize,
              ...paramsFilter,
            }
          else
            body = {
              page,
              pageSize,
              ...paramsFilter,
              status: key,
            }
          setLoadingTable(true)
          const resOrder = await orders(body)
          setLoadingTable(false)

          if (resOrder.status === 200) {
            setOrdersByStatus(resOrder.data.data)
            setCountOrders([...resOrder.data.status_count])

            //nếu order là pending design thì cho expand table
            if (key === STATUS_ORDER.PENDING_DESIGN)
              setSelectedRowKeys(resOrder.data.data.map((e) => e.id_order))
          }
        }
      }, 10000)
    } catch (error) {
      console.log(error)
      notification.open({
        key: keyNotify,
        message: 'Updating status approve failed, please try again',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        duration: 2,
      })
      setLoadingTable(false)
    }
  }

  const onClickRebuild = async (recordMini, recordMain) => {
    const keyNotify = `pending ${recordMini.id}`
    try {
      if (recordMini.status == 'pending') {
        notification.error({ message: 'Status current is pending!' })
        return
      }
      notification.open({
        key: keyNotify,
        message: `Updating status pending by item ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })
      const body = {
        key: recordMain.key,
        product_id: recordMini.id,
      }

      const res = await rebuildOrder(body)
      if (res.status === 200) {
        setTimeout(() => {
          notification.open({
            key: keyNotify,
            message: 'Updating status pending success',
            icon: <CheckCircleOutlined style={{ color: 'green' }} />,
            duration: 2,
          })
        }, 10000)
      } else
        setTimeout(() => {
          notification.open({
            key: keyNotify,
            message: 'Updating status pending failed, please try again',
            icon: <CloseCircleOutlined style={{ color: 'red' }} />,
            duration: 2,
          })
        }, 10000)

      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current)
      }
      typingTimeoutRef.current = setTimeout(async () => {
        if (res.status === 200) {
          let body
          if (key === 'all')
            body = {
              page,
              pageSize,
              ...paramsFilter,
            }
          else
            body = {
              page,
              pageSize,
              ...paramsFilter,
              status: key,
            }
          setLoadingTable(true)
          const resOrder = await orders(body)
          setLoadingTable(false)

          if (resOrder.status === 200) {
            setOrdersByStatus(resOrder.data.data)
            setCountOrders([...resOrder.data.status_count])

            //nếu order là pending design thì cho expand table
            if (key === STATUS_ORDER.PENDING_DESIGN)
              setSelectedRowKeys(resOrder.data.data.map((e) => e.id_order))
          }
        }
      }, 10000)
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
      notification.open({
        key: keyNotify,
        message: 'Updating status pending failed, please try again',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        duration: 2,
      })
    }
  }

  const ModalSyncOrderById = () => {
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)

    const toggle = () => setVisible(!visible)

    const _repairOrder = async () => {
      try {
        if (!value) {
          notification.warning({ message: 'Please input id order' })
          return
        }
        setLoading(true)
        const site = sites.find((site) => site.site_name === paramsFilter.site)
        const body = {
          id_order: `${value}-${site.platform}`,
          id_site: paramsFilter.site,
        }
        const res = await repairOrder(body)
        console.log(res)
        if (res.status === 200) {
          if (res.data.success) {
            notification.success({ message: 'Sync order by id success' })
            getOrdersByStatus(key, page, pageSize, paramsFilter)
            toggle()
            setValue('')
          } else
            notification.error({
              message:
                res.data.mess || res.data.message || 'Sync order by id failed',
            })
        } else
          notification.error({
            message: 'Sync order by id failed, please try again',
          })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }

    return (
      <>
        <Button type="primary" onClick={toggle}>
          Sync Order By ID
        </Button>
        <Modal
          confirmLoading={loading}
          onOk={_repairOrder}
          okText="Sync"
          visible={visible}
          onCancel={toggle}
          title="Sync Order By ID">
          <Input
            placeholder="Input order id"
            onPressEnter={_repairOrder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Modal>
      </>
    )
  }

  const ModalPushNocustom = ({ recordMini }) => {
    const [visible, setVisible] = useState(false)
    const [value, setValue] = useState(recordMini.sku || '')
    const [checkUpdate, setCheckUpdate] = useState(false)

    const toggle = () => setVisible(!visible)

    useEffect(() => {
      if (!visible) {
        setValue(recordMini.sku || '')
        setCheckUpdate(false)
      }
    }, [visible])

    return (
      <>
        <Button
          style={{
            width: '7.5rem',
            height: '2.5rem',
            paddingLeft: 10,
            paddingRight: 10,
          }}
          type="primary"
          onClick={toggle}>
          Push Nocustom
        </Button>
        <Modal
          visible={visible}
          onOk={() => {
            if (checkUpdate) recordMini = { ...recordMini, sku: value }
            onClickPushNocustom(recordMini)
            toggle()
          }}
          onCancel={toggle}
          title="Push nocustom">
          <Input
            onPressEnter={() => {
              if (checkUpdate) recordMini = { ...recordMini, sku: value }
              onClickPushNocustom(recordMini)
              toggle()
            }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              setCheckUpdate(true)
            }}
          />
        </Modal>
      </>
    )
  }

  const onClickPushNocustom = async (recordMini) => {
    try {
      //check format sku product có dạng xxxx-xxxx ko
      const isSkuValid = recordMini.sku.split('-')

      if (!isSkuValid || isSkuValid.length !== 2) {
        notification.error({ message: 'Sku is invalid' })
        return
      }

      dispatch({ type: ACTION.LOADING, data: true })

      const body = {
        sku: recordMini.sku || '',
        mockupFile: recordMini.mockup || [],
        printFile: recordMini.printFiles || [],
        productType:
          (recordMini.product_type && recordMini.product_type.title) || '',
      }

      const res = await pushNocustom(body)
      console.log(res)
      dispatch({ type: ACTION.LOADING, data: false })
      if (res.status === 200) {
        await getOrdersByStatus(key, page, pageSize, paramsFilter)
        notification.success({ message: 'Push nocustom success!' })
      }
      if (res.status === 500 || res.status === 406)
        notification.success({
          message: res.data.mess || 'Push nocustom failed!',
        })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const values = e.target.value
      //khi search hoac filter thi reset page ve 1
      setPage(1)

      if (values) {
        const paramsOptionsSearch = Object.fromEntries([
          [optionsSearch, values],
        ]) //convert array[[key, value]] to object

        getOrdersByStatus(key, 1, pageSize, {
          ...paramsFilter,
          ...paramsOptionsSearch,
        })
        setParamsFilter({ ...paramsFilter, ...paramsOptionsSearch })
      } else {
        delete paramsFilter[optionsSearch]
        getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      }
    }, 750)
  }

  const { RangePicker } = DatePicker
  async function onChange(dates, dateStrings) {
    //khi search hoac filter thi reset page ve 1
    setPage(1)

    if (isOpenSelect) toggleOpenSelect()

    //nếu search date thì xoá các params date
    delete paramsFilter.to_day
    delete paramsFilter.yesterday
    delete paramsFilter.this_week
    delete paramsFilter.last_week
    delete paramsFilter.last_month
    delete paramsFilter.this_month
    delete paramsFilter.this_year
    delete paramsFilter.last_year

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dateStrings[0] && !dateStrings[1]) {
      delete paramsFilter.startDate
      delete paramsFilter.endDate
      getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]
      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)

      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')
      getOrdersByStatus(key, 1, pageSize, {
        ...paramsFilter,
        startDate: dateFirst,
        endDate: dateLast,
      })
      setParamsFilter({
        ...paramsFilter,
        startDate: dateFirst,
        endDate: dateLast,
      })
    }
  }

  const scan250LastOrder = async () => {
    try {
      const key = 'scanLastOrder'
      notification.open({
        key,
        duration: 0,
        message: 'Scan 250 last order...',
        icon: <Spin size="small" />,
      })
      const res = await asyncOrders(paramsFilter.site)
      if (res.status === 200)
        notification.open({
          key,
          duration: 2,
          message: 'Scan 250 last order success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        })
      else
        notification.open({
          key,
          duration: 2,
          message: 'Scan 250 last order failed',
          icon: <InfoCircleOutlined style={{ color: 'red' }} />,
        })
    } catch (error) {
      console.log(error)
      notification.open({
        key,
        duration: 2,
        message: 'Scan 250 last order failed',
        icon: <InfoCircleOutlined style={{ color: 'red' }} />,
      })
    }
  }

  const TagsFilter = () => {
    return (
      <Row align="center" style={{ marginTop: 25 }}>
        {Object.keys(tagsFilterRender).map(
          (e) =>
            tagsFilterRender[e] && (
              <Tag
                style={{ height: 'max-content', borderStyle: 'dashed' }}
                closable
                onClose={async () => {
                  delete paramsFilter[e]
                  delete tagsFilterRender[e]

                  if (e === 'no_custom' || e === 'have_tracking')
                    formFilter.setFieldsValue({
                      product_status: undefined,
                    })

                  await getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
                  setParamsFilter({ ...paramsFilter })
                  setTagsFilterRender({ ...tagsFilterRender })
                  formFilter.setFieldsValue(
                    Object.fromEntries([[e, undefined]])
                  )
                }}>
                {e}: {tagsFilterRender[e]}
              </Tag>
            )
        )}
      </Row>
    )
  }
  const DrawerMoreFilter = () => {
    const [visible, setVisible] = useState(false)
    const [customily, setCustomily] = useState(
      tagsFilterRender.customily || false
    )

    const toggle = () => setVisible(!visible)

    return (
      <>
        <Space style={{ marginTop: 20 }}>
          <Button
            type="dashed"
            onClick={async () => {
              //khi search hoac filter thi reset page ve 1
              setPage(1)
              await getOrdersByStatus(key, 1, pageSize)
              setParamsFilter({})
              setValueSearch('')
              setValueDateSearch(null)
              formFilter.resetFields()
              setTagsFilterRender({})
              setValueTime()
              setCustomily(false)
              toggle()
            }}
            style={{ display: !Object.keys(paramsFilter).length && 'none' }}>
            Clear all filter
          </Button>
          <Button type="primary" icon={<FilterOutlined />} onClick={toggle}>
            More Filter
          </Button>
        </Space>
        <Drawer
          title="More fitlers"
          onClose={toggle}
          visible={visible}
          width={430}
          footer={
            <Row justify="end">
              <Button
                type="primary"
                onClick={async () => {
                  //khi search hoac filter thi reset page ve 1
                  setPage(1)
                  let data = formFilter.getFieldsValue()
                  if (customily) data.customily = true
                  else data.customily = false

                  if (
                    data.product_status &&
                    data.product_status === 'no_custom'
                  ) {
                    delete data.product_status
                    delete paramsFilter.product_status
                    delete paramsFilter.have_tracking
                    data.no_custom = true
                  }

                  if (
                    data.product_status &&
                    data.product_status === 'have_tracking'
                  ) {
                    delete data.product_status
                    delete paramsFilter.product_status
                    delete paramsFilter.no_custom
                    data.have_tracking = true
                  }

                  if (
                    data.product_status &&
                    (data.product_status !== 'have_tracking' ||
                      data.product_status !== 'have_tracking')
                  ) {
                    delete paramsFilter.no_custom
                    delete paramsFilter.have_tracking
                  }

                  if (!data.product_status) {
                    delete paramsFilter.no_custom
                    delete paramsFilter.have_tracking
                  }

                  Object.keys(data).filter(
                    (e) => !data[e] && delete data[e] && delete paramsFilter[e]
                  )
                  setTagsFilterRender({ ...data })

                  await getOrdersByStatus(key, 1, pageSize, {
                    ...paramsFilter,
                    ...data,
                  })
                  setParamsFilter({ ...paramsFilter, ...data })
                  toggle()
                }}>
                Done
              </Button>
            </Row>
          }>
          <Form layout="vertical" form={formFilter}>
            <Row>
              <Space
                direction="vertical"
                size="large"
                style={{ width: '100%' }}>
                <Col>
                  <div>Filter by status product</div>
                  <Form.Item name="product_status">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose status"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div style={{ padding: '5px 12px' }}>
                            <Checkbox
                              checked={customily}
                              onChange={(e) => setCustomily(e.target.checked)}>
                              Customily
                            </Checkbox>
                          </div>
                        </>
                      )}>
                      <Option value="approve">Approve</Option>
                      <Option value="uncheck">Uncheck</Option>
                      <Option value="pending">Pending</Option>
                      <Option value="have_tracking">Have Tracking</Option>
                      <Option value="no_custom">Nocustom</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by carrier</div>
                  <Form.Item name="carrier">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose carrier">
                      {carriers.map((e, index) => (
                        <Option value={e.name} key={index}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by seller</div>
                  <Form.Item name="seller">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose seller">
                      {usersSeller.map((e, index) => (
                        <Option value={e.username} key={index}>
                          {e.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by supplier</div>
                  <Form.Item name="supplier">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose supplier">
                      {usersSupplier.map((e, index) => (
                        <Option value={e.username} key={index}>
                          {e.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by product type</div>
                  <Form.Item name="product_type">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose product type">
                      {allProductType.map((e, index) => (
                        <Option value={e.sku} key={index}>
                          {e.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>
          </Form>
        </Drawer>
      </>
    )
  }

  const _importOrders = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const res = await importOrders({
        data: dataImportOrders,
      })
      console.log(res)
      if (res.status === 200) {
        if (res.data.success) {
          notification.success({
            message: 'Import orders success!',
          })
          setModal2Visible(false)
          setDataImportOrdersView([])
        } else
          notification.error({
            message: res.data.mess || 'Import orders failed!',
          })
      } else
        notification.error({
          message: 'Import orders failed!',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
  }

  //list column table in tab
  const columnsImport = [
    {
      title: 'Site ID',
      dataIndex: 'key_design',
    },
    {
      title: 'Order ID',
      dataIndex: 'id_order',
    },
    {
      title: 'Order Item ID',
      dataIndex: 'id',
    },
    {
      title: 'Shipping Name',
      dataIndex: 'first_name',
    },
    {
      title: 'Shipping address1',
      dataIndex: 'address_1',
    },
    {
      title: 'Shipping address2',
      dataIndex: 'address_2',
    },
    {
      title: 'Shipping city',
      dataIndex: 'city',
    },
    {
      title: 'Shipping Province code',
      dataIndex: 'province_code',
    },
    {
      title: 'Shipping country code',
      dataIndex: 'country_code',
    },
    {
      title: 'Postcode',
      dataIndex: 'postcode',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Product name',
      dataIndex: 'name',
      width: 400,
    },
    {
      title: 'Item Sku',
      dataIndex: 'sku',
    },
    {
      title: 'Item Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Print File',
      dataIndex: 'printFiles',
      width: 400,
    },
    {
      title: 'Mockup File',
      dataIndex: 'mockup',
      width: 400,
    },
  ]
  const columnsImportTracking = [
    {
      title: 'Site ID',
      dataIndex: 'key_design',
    },
    {
      title: 'Order ID',
      dataIndex: 'id_order',
    },
    {
      title: 'Order Item ID',
      dataIndex: 'id',
    },
    {
      title: 'Customer email',
      dataIndex: 'customer_email',
    },
    {
      title: 'Customer phone',
      dataIndex: 'customer_phone',
    },
    {
      title: 'Tracking Carrier',
      dataIndex: 'carrier_code',
    },
    {
      title: 'Tracking Number',
      dataIndex: 'tracking_number',
    },
  ]

  const [columnsAll, setColumnsAll] = useState(
    localStorage.getItem('columnsAll')
      ? JSON.parse(localStorage.getItem('columnsAll'))
      : [...orderColumns]
  )
  const [columnsCollingOff, setColumnsCollingOff] = useState(
    localStorage.getItem('columnsCollingOff')
      ? JSON.parse(localStorage.getItem('columnsCollingOff'))
      : [...orderColumns]
  )
  const [columnsPendingDesign, setColumnsPendingDesign] = useState(
    localStorage.getItem('columnsPendingDesign')
      ? JSON.parse(localStorage.getItem('columnsPendingDesign'))
      : [...orderColumns]
  )
  const [columnsNeedPay, setColumnsNeedPay] = useState(
    localStorage.getItem('columnsNeedPay')
      ? JSON.parse(localStorage.getItem('columnsNeedPay'))
      : [...orderColumns]
  )
  const [columnsPending, setColumnsPending] = useState(
    localStorage.getItem('columnsPending')
      ? JSON.parse(localStorage.getItem('columnsPending'))
      : [...orderColumns]
  )
  const [columnsInProduction, setColumnsInProduction] = useState(
    localStorage.getItem('columnsInProduction')
      ? JSON.parse(localStorage.getItem('columnsInProduction'))
      : [...orderColumns]
  )
  const [columnsShipped, setColumnsShipped] = useState(
    localStorage.getItem('columnsShipped')
      ? JSON.parse(localStorage.getItem('columnsShipped'))
      : [...orderColumns]
  )
  const [columnsCancelled, setColumnsCancelled] = useState(
    localStorage.getItem('columnsCancelled')
      ? JSON.parse(localStorage.getItem('columnsCancelled'))
      : [...orderColumns]
  )
  const [columnsOnHold, setColumnsOnHold] = useState(
    localStorage.getItem('columnsOnHold')
      ? JSON.parse(localStorage.getItem('columnsOnHold'))
      : [...orderColumns]
  )
  const [columnsActionRequired, setColumnsActionRequired] = useState(
    localStorage.getItem('columnsActionRequired')
      ? JSON.parse(localStorage.getItem('columnsActionRequired'))
      : [...orderColumns]
  )

  const columnsPendingDesignItemMain = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'upload',
      width: 100,
    },
    {
      title: 'Information',
      dataIndex: 'information',
      key: 'information',
    },
    {
      title: 'Additional file',
      dataIndex: 'printFiles',
      width: 275,
      key: 'additionalFile',
    },
    {
      title: 'Mockup',
      dataIndex: 'mockup',
      width: 275,
      key: 'mockup',
    },
  ]

  const UploadPrintFileProductOrder = ({ record, recordMini }) => {
    const [listPrintFileRemove, setListPrintFileRemove] = useState([]) // list checkbox xoa anh hang loat
    const [
      classUploadPrintFileProductOrder,
      setClassUploadPrintFileProductOrder,
    ] = useState('')

    const [data, setData] = useState([])

    useEffect(() => {
      if (recordMini.printFiles_thumb && recordMini.printFiles_thumb.length) {
        setData([
          ...recordMini.printFiles_thumb.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
        return
      }

      if (recordMini.printFiles)
        setData([
          ...recordMini.printFiles.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
    }, [])

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={
            visibleImages
              ? []
              : recordMini.printFiles_thumb &&
                recordMini.printFiles_thumb.length
              ? recordMini.printFiles_thumb.map((url, index) => {
                  return {
                    uid: index,
                    name: 'image',
                    status: 'done',
                    url: url,
                  }
                })
              : (recordMini.printFiles &&
                  recordMini.printFiles.map((url, index) => {
                    return {
                      uid: index,
                      name: 'image',
                      status: 'done',
                      url: url,
                    }
                  })) ||
                []
          }
          onChange={({ fileList }) => {
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current)
            }
            typingTimeoutRef.current = setTimeout(async () => {
              uploadAdditionalFileOrderPendingDesign(
                record,
                recordMini,
                fileList
              )
            }, 350)
          }}
          className={classUploadPrintFileProductOrder}
          showUploadList={{
            removeIcon: (file) => (
              <div
                style={{
                  position: 'absolute',
                  left: '-1px',
                  right: '-1px',
                  top: '-1px',
                  bottom: '-1px',
                }}
                onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  defaultChecked={listPrintFileRemove.includes(file.url)}
                  onClick={(e) => {
                    const listPrintFileRemoveNew = [...listPrintFileRemove]
                    const indexLink = listPrintFileRemoveNew.findIndex(
                      (e) => e === file.url
                    )

                    if (indexLink !== -1)
                      listPrintFileRemoveNew.splice(indexLink, 1)
                    else listPrintFileRemoveNew.push(file.url)

                    if (listPrintFileRemoveNew.length)
                      setClassUploadPrintFileProductOrder(
                        'print-file-product-order'
                      )
                    else setClassUploadPrintFileProductOrder('')

                    setListPrintFileRemove([...listPrintFileRemoveNew])

                    e.stopPropagation()
                  }}></Checkbox>
              </div>
            ),
            showDownloadIcon: true,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}></div>
                </Popover>
              )
            },
          }}>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>

        <Popconfirm
          title="Are you sure to delete list image?"
          onConfirm={() => {
            const listPrintFileRemoveNew = data.filter(
              (e) => !listPrintFileRemove.includes(e.url)
            )
            uploadAdditionalFileOrderPendingDesign(
              record,
              recordMini,
              listPrintFileRemoveNew
            )
          }}
          okText="Yes"
          cancelText="No">
          <Button
            type="dashed"
            danger
            style={{ display: !listPrintFileRemove.length && 'none' }}>
            Remove list image
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure to delete all image?"
          onConfirm={() => {
            uploadAdditionalFileOrderPendingDesign(record, recordMini, [])
          }}
          okText="Yes"
          cancelText="No">
          <Button type="dashed" danger style={{ marginTop: 10 }}>
            Remove all image
          </Button>
        </Popconfirm>
        <ModalListImageDownload record={{ links: recordMini.printFiles }} />
      </>
    )
  }

  const UploadMockupProductOrder = ({ record, recordMini }) => {
    const [listPrintFileRemove, setListPrintFileRemove] = useState([]) // list checkbox xoa anh hang loat
    const [
      classUploadPrintFileProductOrder,
      setClassUploadPrintFileProductOrder,
    ] = useState('')

    const [data, setData] = useState([])

    useEffect(() => {
      if (recordMini.mockup_thumb && recordMini.mockup_thumb.length) {
        setData([
          ...recordMini.mockup_thumb.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
        return
      }

      if (recordMini.mockup)
        setData([
          ...recordMini.mockup.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
    }, [])

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={
            visibleImages
              ? []
              : recordMini.mockup_thumb && recordMini.mockup_thumb.length
              ? recordMini.mockup_thumb.map((url, index) => {
                  return {
                    uid: index,
                    name: 'image',
                    status: 'done',
                    url: url,
                  }
                })
              : (recordMini.mockup &&
                  recordMini.mockup.map((url, index) => {
                    return {
                      uid: index,
                      name: 'image',
                      status: 'done',
                      url: url,
                    }
                  })) ||
                []
          }
          onChange={({ fileList }) => {
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current)
            }
            typingTimeoutRef.current = setTimeout(async () => {
              uploadMockupOrderPendingDesign(record, recordMini, fileList)
            }, 350)
          }}
          className={classUploadPrintFileProductOrder}
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: (file) => (
              <div
                style={{
                  position: 'absolute',
                  left: '-1px',
                  right: '-1px',
                  top: '-1px',
                  bottom: '-1px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  defaultChecked={listPrintFileRemove.includes(file.url)}
                  onClick={(e) => {
                    const listPrintFileRemoveNew = [...listPrintFileRemove]
                    const indexLink = listPrintFileRemoveNew.findIndex(
                      (e) => e === file.url
                    )

                    if (indexLink !== -1)
                      listPrintFileRemoveNew.splice(indexLink, 1)
                    else listPrintFileRemoveNew.push(file.url)

                    if (listPrintFileRemoveNew.length)
                      setClassUploadPrintFileProductOrder(
                        'print-file-product-order'
                      )
                    else setClassUploadPrintFileProductOrder('')

                    setListPrintFileRemove([...listPrintFileRemoveNew])

                    e.stopPropagation()
                  }}></Checkbox>
              </div>
            ),
            showDownloadIcon: true,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}></div>
                </Popover>
              )
            },
          }}>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
        <Popconfirm
          title="Are you sure to delete list image?"
          onConfirm={() => {
            const listPrintFileRemoveNew = data.filter(
              (e) => !listPrintFileRemove.includes(e.url)
            )
            uploadMockupOrderPendingDesign(
              record,
              recordMini,
              listPrintFileRemoveNew
            )
          }}
          okText="Yes"
          cancelText="No">
          <Button
            type="dashed"
            danger
            style={{ display: !listPrintFileRemove.length && 'none' }}>
            Remove list image selected
          </Button>
        </Popconfirm>
        <Popconfirm
          title="Are you sure to delete all image?"
          onConfirm={() => {
            uploadMockupOrderPendingDesign(record, recordMini, [])
          }}
          okText="Yes"
          cancelText="No">
          <Button type="dashed" danger style={{ marginTop: 10 }}>
            Remove all image
          </Button>
        </Popconfirm>
        <ModalListImageDownload record={{ links: recordMini.mockup }} />
        {/* <Button
          type="success"
          onClick={(e) => {
            if (recordMini.mockup) {
              for (var i = 0; i < recordMini.mockup?.length; i++) {
                window.open(recordMini.mockup[i], '_sefl')
              }
            }
          }}
          danger
          style={{ marginTop: 10 }}>
          Download all image
        </Button> */}
      </>
    )
  }

  const [sites, setSites] = useState([])
  const getAllSiteData = async () => {
    try {
      const res = await getAllSite()
      if (res.status === 200) setSites(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function confirm(action = '', reasonActionRequired = '') {
    try {
      //bắt buộc phải có printfile mới cho chuyển trạng thái
      //doi voi cac quyen khac admin, fullfiller
      if (
        localStorage.getItem('role') &&
        !['Admin', 'Fullfiller'].includes(
          JSON.parse(localStorage.getItem('role'))
        )
      ) {
        //nếu chuyển từ pending design -> pending thì ko cần ràng buộc
        if (
          key !== STATUS_ORDER.PENDING_DESIGN &&
          action !== STATUS_ORDER.PENDING
        ) {
          const listOrderCheckImage = ordersByStatus.filter((e) =>
            selectedRowKeys.includes(e.id_order)
          )
          for (let i = 0; i < listOrderCheckImage.length; ++i) {
            for (
              let j = 0;
              j < listOrderCheckImage[i].list_product_unsign.length;
              ++j
            ) {
              if (
                !listOrderCheckImage[i].list_product_unsign[j].printFiles.length
              ) {
                notification.error({
                  message: `Please choose additional file in order ${listOrderCheckImage[i].name}`,
                })
                return
              }
            }

            for (
              let j = 0;
              j < listOrderCheckImage[i].list_product_fulfill.length;
              ++j
            ) {
              if (
                !listOrderCheckImage[i].list_product_fulfill[j].printFiles
                  .length
              ) {
                notification.error({
                  message: `Please choose additional file in order ${listOrderCheckImage[i].name}`,
                })
                return
              }
            }
          }
        }
      }

      dispatch({
        type: ACTION.LOADING,
        data: true,
      })

      const listPromise = selectedRowKeys.map(async (key) => {
        const order = ordersByStatus.find((e) => e.id_order === key)
        let resUpdate = null

        //Button More action
        //update status action-required
        if (action === STATUS_ORDER.ACTION_REQUIRED) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.ACTION_REQUIRED,
            reasonCancel: reasonActionRequired,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.ON_HOLD) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.ON_HOLD,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.CANCELLED_REFUNDED) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.CANCELLED_REFUNDED,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.IN_PRODUCTION) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.IN_PRODUCTION,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.NEED_PAY) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.NEED_PAY,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.PENDING_DESIGN) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.PENDING_DESIGN,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
        if (action === STATUS_ORDER.PENDING) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.PENDING,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }

        //key la order status tab hien tai
        //colling -> pending design
        if (key === STATUS_ORDER.COLLING_OFF) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.PENDING_DESIGN,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }

        //pending design -> need pay
        if (key === STATUS_ORDER.PENDING_DESIGN) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.NEED_PAY,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }

        //need pay -> pending
        if (key === STATUS_ORDER.NEED_PAY) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.PENDING,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }

        //pending -> in production
        if (key === STATUS_ORDER.PENDING) {
          const object = {
            id_order: order.id_order,
            key: order.key,
            key_design: order.key_design,
            status: STATUS_ORDER.IN_PRODUCTION,
          }
          resUpdate = await updateStatusOrders(object)
          return resUpdate
        }
      })

      const res = await Promise.all(listPromise)
      console.log(res)
      let isCheckUpdateSuccess = true
      for (let i = 0; i < res.length; ++i) {
        if (res[i].status === 200) isCheckUpdateSuccess = true
        else {
          isCheckUpdateSuccess = false
          break
        }
      }

      if (isCheckUpdateSuccess) {
        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        notification.success({ message: 'Update success!' })
        setSelectedRowKeys([]) //reset select checkbox table

        await getOrdersByStatus(key, page, pageSize, { ...paramsFilter })
      } else {
        notification.error({ message: 'Update error, please try again' })
        dispatch({
          type: ACTION.LOADING,
          data: false,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  const updateProductInOrderPendingDesign = async (object) => {
    try {
      dispatch({
        type: ACTION.LOADING,
        data: true,
      })
      console.log(JSON.stringify(object))
      const res = await updateOrders(object)
      console.log(res)
      if (res.status === 200)
        notification.success({ message: 'Update success!' })
      else
        notification.error({
          message: res.data.message || 'Update failed, please try again!',
        })
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      if (res.status === 200) {
        await getOrdersByStatus(key, page, pageSize, { ...paramsFilter })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  function callback(key) {
    if (key === 'all') {
      setOrdersByStatus([]) //reset data order
      getOrdersByStatus('all', 1, pageSize, paramsFilter)
    } else {
      setOrdersByStatus([]) //reset data order
      getOrdersByStatus(key, 1, pageSize, paramsFilter)
    }

    //reset phan trang
    setPage(1)

    setKey(key)
    setSelectedRowKeys([]) //reset select checkbox
  }

  /*list modal update infomation table child in order pending design*/
  const ModalUpdateProductTypePendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`Product type: ${
            productUnsign &&
            productUnsign.product_type &&
            productUnsign.product_type.title
          }`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Product type</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={
                productUnsign &&
                productUnsign.product_type &&
                productUnsign.product_type._id
              }
              onChange={async (value) => {
                const productType = allProductType.find((e) => e._id === value)
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (productType && indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].product_type =
                    productType
                  record.action = `edit product type by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (productType && indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].product_type =
                    productType
                  record.action = `edit product type by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {allProductType.map((e) => (
                <Option value={e._id}>{e.title}</Option>
              ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateCarrierPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={() => {
            //check product type empty
            if (!productUnsign.product_type) {
              notification.error({ message: 'Please update product type' })
              return
            }
            toggle()
          }}>
          {`Carrier: ${productUnsign.carrier}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Carriers</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={productUnsign.carrier}
              onChange={async (value) => {
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].carrier = value
                  record.action = `edit carriers by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].carrier =
                    value
                  record.action = `edit carriers by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {/* show list carrier từ product type của product unsign */}
              {productUnsign.product_type &&
                productUnsign.product_type.listcarrier.map((e) => (
                  <Option value={e.name}>{e.name}</Option>
                ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateSupplierPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={() => {
            //check product type empty
            if (!productUnsign.product_type) {
              notification.error({ message: 'Please update product type' })
              return
            }
            toggle()
          }}>
          {`Supplier: ${productUnsign.supplier}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Suppliers</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={productUnsign.supplier}
              onChange={async (value) => {
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].supplier = value
                  record.action = `edit suppliers by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].supplier =
                    value
                  record.action = `edit suppliers by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {/* show list supplier từ product type của product unsign */}
              {productUnsign.product_type &&
                productUnsign.product_type.supplyBy.map((e) => (
                  <Option value={e}>{e}</Option>
                ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalListImageDownload = ({ record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div> </div>
        <Button
          onClick={(e) => {
            toggle()
          }}
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          type="success"
          danger
          style={{ marginTop: 10 }}>
          Download all image
        </Button>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Press Image To Download</h3>
            {record?.links.map((_link) => {
              return (
                <a
                  target="_blank"
                  href={_link}
                  style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                  {<img src={_link} height={100} width={100} />}
                </a>
              )
            })}
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateCustomInfoPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const [listCustomInfo, setListCustomInfo] = useState(
      productUnsign.custom_info
    )
    const [isUpdate, setIsUpdate] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <Row onClick={toggle} style={{ cursor: 'pointer' }}>
          Custom info:
        </Row>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            maxHeight: 400,
            overflowY: 'auto',
          }}
          onClick={toggle}>
          {listCustomInfo.map((e) => {
            const RowName = () => (
              <Row style={{ marginLeft: 80 }}>
                Name - {typeof e.name === 'string' ? e.name : ''}
              </Row>
            )
            const RowValue = () => (
              <Row style={{ marginLeft: 80 }}>Value - {e.value || ''}</Row>
            )
            return (
              <Col style={{ marginTop: 7 }}>
                <RowName />
                <RowValue />
              </Col>
            )
          })}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={async () => {
            if (isUpdate)
              await getOrdersByStatus(key, page, pageSize, paramsFilter)
            toggle()
          }}
          onOk={async () => {
            const indexUpdate = record.list_product_unsign.findIndex(
              (v) => v.id === productUnsign.id
            )
            if (indexUpdate !== -1) {
              record.list_product_unsign[indexUpdate].custom_info =
                listCustomInfo
              record.action = `edit custom info name by item ${record.list_product_unsign[indexUpdate].id}`
            }

            const indexUpdateFulfill = record.list_product_fulfill.findIndex(
              (v) => v.id === productUnsign.id
            )
            if (indexUpdateFulfill !== -1) {
              record.list_product_fulfill[indexUpdateFulfill].custom_info =
                listCustomInfo
              record.action = `edit custom info name by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
            }
            await updateProductInOrderPendingDesign(record)
            toggle()
          }}>
          {listCustomInfo.map((e, index) => {
            const InputName = () => (
              <Col>
                <h4 style={{ marginBottom: 0 }}>Name</h4>
                <Input
                  placeholder="Input name"
                  defaultValue={e.name || ''}
                  onBlur={(e) => {
                    const listCustomInfoNew = [...listCustomInfo]
                    listCustomInfoNew[index].name = e.target.value

                    setListCustomInfo([...listCustomInfoNew])
                    setIsUpdate(true)
                  }}
                />
              </Col>
            )

            const InputValue = () => (
              <Col>
                <h4 style={{ marginBottom: 0 }}>Value</h4>
                <Input
                  placeholder="Input value"
                  defaultValue={e.value || ''}
                  onBlur={(e) => {
                    const listCustomInfoNew = [...listCustomInfo]
                    listCustomInfoNew[index].value = e.target.value

                    setListCustomInfo([...listCustomInfoNew])
                    setIsUpdate(true)
                  }}
                />
              </Col>
            )
            return (
              <Row justify="space-between" style={{ marginTop: 10 }}>
                <InputName />
                <Space>
                  <InputValue />
                  <CloseOutlined
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      fontSize: 13,
                    }}
                    onClick={() => {
                      const listCustomInfoNew = [...listCustomInfo]
                      listCustomInfoNew.splice(index, 1)
                      setListCustomInfo([...listCustomInfoNew])
                    }}
                  />
                </Space>
              </Row>
            )
          })}
          <Row justify="end" style={{ marginTop: 20 }}>
            <Button
              type="dashed"
              onClick={() => {
                const listCustomInfoNew = [...listCustomInfo]
                listCustomInfoNew.push({ name: '', value: '' })
                setListCustomInfo([...listCustomInfoNew])
                setIsUpdate(true)
              }}>
              Add
            </Button>
          </Row>
        </Modal>
      </>
    )
  }

  const ModalUpdateCustomNotePendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(
      productUnsign.custom_note || record.note || ''
    )

    const updateCustomNote = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].custom_note = value
        record.action = `edit customer note by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].custom_note = value
        record.action = `edit customer note by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`Customer Note: ${productUnsign.custom_note || record.note || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateCustomNote}>
          <Col>
            <h4>Customer Note</h4>
            <Input
              autoFocus
              onPressEnter={updateCustomNote}
              style={{ width: '100%' }}
              placeholder="Input customer note"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateQuantityPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.quantity || '')

    const updateQuantity = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].quantity = value
        record.action = `edit quantity by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].quantity = value
        record.action = `edit quantity by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`Quantity: ${productUnsign.quantity || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateQuantity}>
          <Col>
            <h4>Quantity</h4>
            <InputNumber
              autoFocus
              onPressEnter={updateQuantity}
              style={{ width: '100%' }}
              placeholder="input quantity"
              onChange={(value) => setValue(value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateRenameForSupPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.rename_for_Sup || '')

    const updateRename = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].rename_for_Sup = value
        record.action = `edit rename for sup by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].rename_for_Sup = value
        record.action = `edit rename for sup by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`SKU Custom: ${productUnsign.rename_for_Sup || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateRename}>
          <Col>
            <h4>SKU Custom</h4>
            <Input
              autoFocus
              onPressEnter={updateRename}
              style={{ width: '100%' }}
              placeholder="input sku custom"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateSkuPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.sku || '')

    const updateSku = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].sku = value
        record.action = `edit sku by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].sku = value
        record.action = `edit rename for sup by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`SKU: ${productUnsign.sku || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateSku}>
          <Col>
            <h3>Sku</h3>
            <Input
              autoFocus
              onPressEnter={updateSku}
              defaultValue={productUnsign.custom_note || ''}
              style={{ width: '100%' }}
              placeholder="input sku"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }
  /*list modal update infomation table child in order pending design*/

  //chức năng này dành cho admin, supplier và fullfiller
  //với order status là in production
  const AcceptOrderStatusInProduction = () => {
    const listOrder = ordersByStatus.filter((e) =>
      selectedRowKeys.includes(e.id_order)
    )

    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <Button
          className={styles['status_button_ok_fix']}
          style={{
            width: '100%',
            display:
              key === STATUS_ORDER.IN_PRODUCTION &&
              localStorage.getItem('role') &&
              ['Admin', 'Supplier', 'Fullfiller'].includes(
                JSON.parse(localStorage.getItem('role'))
              )
                ? ''
                : 'none',
          }}
          onClick={toggle}>
          Confirm Order
        </Button>

        <Modal
          width={660}
          title="Info Tracking More"
          visible={visible}
          onCancel={toggle}
          onOk={async () => {
            try {
              let orders = []
              listOrder.map((e) => {
                let objFulfill = e.list_product_fulfill.map((v) => {
                  return {
                    code: v.code || v.carrier,
                    name: v.name,
                    tracking_number: v.tracking_number,
                    product_id: v.product_id,
                    id: v.id,
                  }
                })

                let objUnsign = e.list_product_unsign.map((v) => {
                  return {
                    code: v.code || v.carrier,
                    name: v.name,
                    tracking_number: v.tracking_number,
                    product_id: v.product_id,
                    id: v.id,
                  }
                })

                let objOrder = {
                  id_order: e.id_ecommerce,
                  key_design: e.key_design,
                  order_items: [
                    ...objFulfill.filter((v) => v.code && v.name),
                    ...objUnsign.filter((v) => v.code && v.name),
                  ],
                }

                orders.push(objOrder)
              })

              //loại bỏ các tracking nào ko đc điền hoặc ko điền đầy đủ
              const bodyOrders = orders.filter((v) => v.order_items.length)

              dispatch({ type: ACTION.LOADING, data: true })
              console.log(JSON.stringify({ orders: bodyOrders }))
              const res = await updateOrdersTracking({ orders: bodyOrders })
              console.log(res)
              if (res.status === 200) {
                if (res.data.success) {
                  setTimeout(() => {
                    notification.success({
                      message:
                        res.data.mess || 'Update tracking number success!',
                    })
                    dispatch({ type: ACTION.LOADING, data: false })
                    toggle()
                  }, 2000)
                  setTimeout(() => {
                    getOrdersByStatus(key, page, pageSize, { ...paramsFilter })
                  }, 2000)
                } else {
                  dispatch({ type: ACTION.LOADING, data: false })
                  notification.error({
                    message: res.data.mess || 'Update tracking number failed!',
                  })
                }
              } else {
                dispatch({ type: ACTION.LOADING, data: false })
                notification.error({
                  message: 'Update tracking number failed!',
                })
              }
            } catch (error) {
              console.log(error)
              dispatch({ type: ACTION.LOADING, data: false })
            }
          }}>
          {listOrder.map((e, index) => (
            <>
              {e.list_product_fulfill.map((v, indexProduct) => (
                <>
                  <div style={{ fontWeight: 500 }}>Order item id: {v.id}</div>
                  <Row align="middle" style={{ marginBottom: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      Name Carrier
                      <Select
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        placeholder="Input name carrier"
                        defaultValue={
                          listOrder[index].list_product_fulfill[indexProduct]
                            .carrier || undefined
                        }
                        onChange={(value) => {
                          listOrder[index].list_product_fulfill[
                            indexProduct
                          ].code = value || ''
                          const carrier = carriers.find((e) => e.code === value)
                          if (carrier)
                            listOrder[index].list_product_fulfill[
                              indexProduct
                            ].name = carrier.name
                        }}
                        style={{ width: 300, marginRight: 10 }}>
                        {carriers.map((e, index) => (
                          <Option value={e.code} key={index}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <Col>
                      Tracking Number
                      <Input
                        defaultValue={
                          listOrder[index].list_product_fulfill[indexProduct]
                            .tracking_number || undefined
                        }
                        placeholder="Input tracking number"
                        onChange={(e) => {
                          listOrder[index].list_product_fulfill[
                            indexProduct
                          ].tracking_number = e.target.value || ''
                        }}
                      />
                    </Col>
                  </Row>
                </>
              ))}

              {e.list_product_unsign.map((v, indexProduct) => (
                <>
                  <div style={{ fontWeight: 500 }}>Order item id: {v.id}</div>
                  <Row align="middle" style={{ marginBottom: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      Name Carrier
                      <Select
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        placeholder="Input name carrier"
                        defaultValue={
                          listOrder[index].list_product_unsign[indexProduct]
                            .carrier || undefined
                        }
                        onChange={(value) => {
                          listOrder[index].list_product_unsign[
                            indexProduct
                          ].code = value || ''
                          const carrier = carriers.find((e) => e.code === value)
                          if (carrier)
                            listOrder[index].list_product_unsign[
                              indexProduct
                            ].name = carrier.name
                        }}
                        style={{ width: 300, marginRight: 10 }}>
                        {carriers.map((e, index) => (
                          <Option value={e.code} key={index}>
                            {e.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <Col>
                      Tracking Number
                      <Input
                        defaultValue={
                          listOrder[index].list_product_unsign[indexProduct]
                            .tracking_number || undefined
                        }
                        placeholder="Input tracking number"
                        onChange={(e) => {
                          listOrder[index].list_product_unsign[
                            indexProduct
                          ].tracking_number = e.target.value || ''
                        }}
                      />
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ))}
        </Modal>
      </>
    )
  }

  const ModalUpdateInformationPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [product_type, setProduct_type] = useState(
      productUnsign.product_type || null
    )
    return (
      <>
        <Permission permissions={[PERMISSIONS.update_product_in_order]}>
          <Button
            style={{
              width: '7.5rem',
              height: '2.5rem',
            }}
            type="primary"
            onClick={toggle}>
            Update
          </Button>
        </Permission>
        <Modal
          title="Update order information"
          centered
          visible={visible}
          onCancel={toggle}
          onOk={async () => {
            record.action = 'edit infomation'
            await updateProductInOrderPendingDesign(record)
            toggle()
          }}>
          <Col>
            <Col style={{ marginBottom: 10 }}>
              <div>SKU</div>
              <Input
                defaultValue={productUnsign.sku || ''}
                style={{ width: '100%' }}
                placeholder="Input sku"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].sku = value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[indexUpdateFulfill].sku = value
                  }
                }}
              />
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>Product type</div>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Choose product type"
                style={{ width: 250 }}
                defaultValue={
                  productUnsign &&
                  productUnsign.product_type &&
                  productUnsign.product_type._id
                }
                onChange={(value) => {
                  const productType = allProductType.find(
                    (e) => e._id === value
                  )
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (productType && indexUpdate !== -1) {
                    setProduct_type({ ...productType })
                    record.list_product_unsign[indexUpdate].product_type =
                      productType
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (productType && indexUpdateFulfill !== -1) {
                    setProduct_type({ ...productType })
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].product_type = productType
                  }
                }}>
                {allProductType.map((e) => (
                  <Option value={e._id}>{e.title}</Option>
                ))}
              </Select>
            </Col>

            <Col
              style={{
                margin: '15px 0px',
                display: !productUnsign.custom_info.length && 'none',
              }}>
              Custom info
              {productUnsign.custom_info.map((e, index) => {
                const InputName = () => (
                  <Col>
                    <h4 style={{ marginBottom: 0 }}>Name</h4>
                    <Input
                      placeholder="Input name"
                      defaultValue={typeof e.name === 'string' ? e.name : ''}
                      onBlur={async (e) => {
                        const value = e.target.value
                        const indexUpdate =
                          record.list_product_unsign.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdate !== -1) {
                          record.list_product_unsign[indexUpdate].custom_info[
                            index
                          ].name = value
                        }

                        const indexUpdateFulfill =
                          record.list_product_fulfill.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdateFulfill !== -1) {
                          record.list_product_fulfill[
                            indexUpdateFulfill
                          ].custom_info[index].name = value
                        }
                      }}
                    />
                  </Col>
                )

                const InputValue = () => (
                  <Col>
                    <h4 style={{ marginBottom: 0 }}>Value</h4>
                    <Input
                      placeholder="Input value"
                      defaultValue={e.value || ''}
                      onBlur={async (e) => {
                        const value = e.target.value
                        const indexUpdate =
                          record.list_product_unsign.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdate !== -1) {
                          record.list_product_unsign[indexUpdate].custom_info[
                            index
                          ].value = value
                        }

                        const indexUpdateFulfill =
                          record.list_product_fulfill.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdateFulfill !== -1) {
                          record.list_product_fulfill[
                            indexUpdateFulfill
                          ].custom_info[index].value = value
                        }
                      }}
                    />
                  </Col>
                )
                return (
                  <Row
                    justify="space-between"
                    style={{ marginBottom: 10, padding: '0 10px' }}>
                    <InputName />
                    <InputValue />
                  </Row>
                )
              })}
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>Custom note</div>
              <Input
                defaultValue={productUnsign.custom_note || ''}
                style={{ width: '100%' }}
                placeholder="Input custom note"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].custom_note = value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].custom_note = value
                  }
                }}
              />
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>SKU custom</div>
              <Input
                defaultValue={productUnsign.rename_for_Sup || ''}
                style={{ width: '100%' }}
                placeholder="Input sku custom"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].rename_for_Sup =
                      value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].rename_for_Sup = value
                  }
                }}
              />
            </Col>

            {product_type && (
              <>
                <Col style={{ marginBottom: 10 }}>
                  <div>Suppliers</div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Choose supplier"
                    defaultValue={productUnsign.supplier || undefined}
                    onChange={(value) => {
                      const indexUpdate = record.list_product_unsign.findIndex(
                        (v) => v.id === productUnsign.id
                      )
                      if (indexUpdate !== -1) {
                        record.list_product_unsign[indexUpdate].supplier = value
                      }

                      const indexUpdateFulfill =
                        record.list_product_fulfill.findIndex(
                          (v) => v.id === productUnsign.id
                        )
                      if (indexUpdateFulfill !== -1) {
                        record.list_product_fulfill[
                          indexUpdateFulfill
                        ].supplier = value
                      }
                    }}
                    style={{ width: '100%' }}>
                    {product_type.supplyBy.map((e) => (
                      <Option value={e}>{e}</Option>
                    ))}
                  </Select>
                </Col>

                <Col style={{ marginBottom: 10 }}>
                  <div>Carriers</div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Choose carriers"
                    defaultValue={productUnsign.carrier || undefined}
                    onChange={(value) => {
                      const indexUpdate = record.list_product_unsign.findIndex(
                        (v) => v.id === productUnsign.id
                      )
                      if (indexUpdate !== -1) {
                        record.list_product_unsign[indexUpdate].carrier = value
                      }

                      const indexUpdateFulfill =
                        record.list_product_fulfill.findIndex(
                          (v) => v.id === productUnsign.id
                        )
                      if (indexUpdateFulfill !== -1) {
                        record.list_product_fulfill[
                          indexUpdateFulfill
                        ].carrier = value
                      }
                    }}
                    style={{ width: '100%' }}>
                    {product_type.listcarrier.map((e) => (
                      <Option value={e.name}>{e.name}</Option>
                    ))}
                  </Select>
                </Col>
              </>
            )}
          </Col>
        </Modal>
      </>
    )
  }
  /*list modal update infomation table child in order pending design*/

  const ModalTrackingOrders = ({ listTracking }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <a onClick={toggle}>{listTracking.length} Logs</a>
        <Modal
          style={{ minWidth: 600, maxWidth: '90vw' }}
          onCancel={toggle}
          visible={visible}
          title="Order logs"
          footer={null}>
          <Timeline style={{ width: '100%' }}>
            {listTracking.map((e) => (
              <Timeline.Item style={{ width: '100%' }}>
                <Row justify="space-between" style={{ width: '100%' }}>
                  <div>{e.name} </div>
                  <Col>
                    <div>{e.time}</div>
                    <div style={{ fontWeight: 700 }}>{e.user}</div>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Modal>
      </>
    )
  }

  const getOrdersByExportOrder = async () => {
    let result = []
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      let body
      if (key === 'all')
        body = {
          ...paramsFilter,
        }
      else
        body = {
          status: key,
          ...paramsFilter,
        }

      const res = await exportOrders({ ...body, isDown: true })
      console.log(res)
      if (res.status === 200) result = res.data.data

      dispatch({ type: ACTION.LOADING, data: false })

      return result
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
      return result
    }
  }

  const _resendOrder = async () => {
    try {
      dispatch({
        type: ACTION.LOADING,
        data: true,
      })

      const listPromise = selectedRowKeys.map(async (e) => {
        let resUpdate = null
        const findOrder = ordersByStatus.find((v) => v.id_order === e)
        if (findOrder) {
          const body = {
            id_user:
              localStorage.getItem('username') &&
              JSON.parse(localStorage.getItem('username')),
            id_site: findOrder.id_site,
            id_order: findOrder.id_order,
          }
          resUpdate = await resendOrder(body)
        }
        return resUpdate
      })

      const res = await Promise.all(listPromise)
      console.log(res)
      let isCheckUpdateSuccess = true
      for (let i = 0; i < res.length; ++i) {
        if (res[i].status === 200) isCheckUpdateSuccess = true
        else {
          isCheckUpdateSuccess = false
          break
        }
      }

      if (isCheckUpdateSuccess) {
        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        notification.success({ message: 'Resend success!' })
        setSelectedRowKeys([]) //reset select checkbox table

        await getOrdersByStatus(key, page, pageSize, { ...paramsFilter })
      } else {
        notification.error({
          message: res[0] ? res[0].data.mess : 'Resend error, please try again',
        })
        dispatch({
          type: ACTION.LOADING,
          data: false,
        })
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  const UpdateActionRequired = () => {
    const [visible, setVisible] = useState(false)
    const [reason, setReason] = useState('')

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <ExportOutlined
            rotate={180}
            style={{ color: '#F60DC6', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Action required</div>
        </div>
        <Modal
          zIndex={1200}
          title="Update status action required"
          visible={visible}
          onCancel={toggle}
          onOk={() => confirm(STATUS_ORDER.ACTION_REQUIRED, reason)}>
          <Col>
            Reason
            <Input.TextArea
              value={reason}
              rows={4}
              onChange={(e) => setReason(e.target.value)}
              autoFocus
              onPressEnter={() => confirm(STATUS_ORDER.ACTION_REQUIRED, reason)}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const updateOrdersTrackingData = async () => {
    const KEY_NOTIFYCATION = 'IMPORT_TRACKING'
    const config = {
      key: KEY_NOTIFYCATION,
      duration: 0,
      message: 'Import tracking number...',
      icon: <Spin />,
      placement: 'bottomLeft',
    }
    try {
      notification.open(config)
      setVisibleImportTracking(false)

      //upload file import tracking number
      const file = await uploadFile(fileImportTracking)
      const fileName = file.split('/') //cắt url lấy tên file
      const body = {
        orders: dataImportCsv,
        link: file,
        totalRow: totalRowFileImportTracking,
        filename: fileName[fileName.length - 1],
      }
      console.log('body', JSON.stringify(body))
      const res = await updateOrdersTracking(body)
      console.log(res)

      if (res.status === 200) {
        await getOrdersByStatus(key, page, pageSize, paramsFilter)

        notification.success({
          message: res.data.mess || 'Import tracking done',
        })
      } else notification.error({ message: 'Import tracking failed!' })

      setFileImportTracking(null)

      notification.close(KEY_NOTIFYCATION)
    } catch (error) {
      console.log(error)
      notification.close(KEY_NOTIFYCATION)
    }
  }

  const ModalAssignProductType = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <FormOutlined
            style={{ color: '#ff5f02', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Assign Product Type</div>
        </div>
        <Modal
          zIndex={2999}
          footer={null}
          title="Assign product type"
          visible={visible}
          onCancel={toggle}>
          <Select
            dropdownStyle={{ zIndex: 3000 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Choose product type"
            showSearch
            style={{ width: '100%' }}
            onChange={async (value) => {
              const listOrder = ordersByStatus.filter((e) =>
                selectedRowKeys.includes(e.id_order)
              )
              const productType = allProductType.find((e) => e._id === value)
              let listPromise

              if (productType) {
                listOrder.map((ordersDetail) => {
                  const listProductAssignProductType = [
                    ...ordersDetail.list_product_unsign,
                    ...ordersDetail.list_product_fulfill,
                  ]
                  listPromise = listProductAssignProductType.map(async (e) => {
                    let resUpdate = null

                    const indexUpdateUnsign =
                      ordersDetail.list_product_unsign.findIndex(
                        (v) => v.id === e.id
                      )
                    const indexUpdateFulfill =
                      ordersDetail.list_product_fulfill.findIndex(
                        (v) => v.id === e.id
                      )

                    if (indexUpdateUnsign !== -1) {
                      ordersDetail.list_product_unsign[
                        indexUpdateUnsign
                      ].product_type = productType
                      ordersDetail.action = `assign product type by item ${ordersDetail.list_product_unsign[indexUpdateUnsign].id}`
                      resUpdate = await updateOrders(ordersDetail)
                    }

                    if (indexUpdateFulfill !== -1) {
                      ordersDetail.list_product_fulfill[
                        indexUpdateFulfill
                      ].product_type = productType
                      ordersDetail.action = `assign product type by item ${ordersDetail.list_product_fulfill[indexUpdateFulfill].id}`
                      resUpdate = await updateOrders(ordersDetail)
                    }

                    return resUpdate
                  })
                })

                dispatch({
                  type: ACTION.LOADING,
                  data: true,
                })
                const res = await Promise.all(listPromise)
                dispatch({
                  type: ACTION.LOADING,
                  data: false,
                })

                let isCheckUpdateSuccess = true
                for (let i = 0; i < res.length; ++i) {
                  if (res[i].status === 200) isCheckUpdateSuccess = true
                  else {
                    isCheckUpdateSuccess = false
                    break
                  }
                }

                if (isCheckUpdateSuccess) {
                  await getOrdersByStatus(key, page, pageSize, {
                    ...paramsFilter,
                  })
                  toggle()
                  notification.success({
                    message: 'Assign product type success!',
                  })
                } else {
                  toggle()
                  notification.error({
                    message: 'Assign product type error, please try again',
                  })
                }
              } else {
                notification.error({ message: 'Not find product type!' })
                toggle()
              }
            }}>
            {allProductType.map((e, index) => (
              <Option value={e._id} key={index}>
                {e.title}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const ModalAssignSupplier = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <EditOutlined
            style={{ color: '#00c732d9', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Assign Supplier</div>
        </div>
        <Modal
          zIndex={2999}
          footer={null}
          title="Assign supplier"
          visible={visible}
          onCancel={toggle}>
          <Select
            dropdownStyle={{ zIndex: 3000 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Choose supplier"
            showSearch
            style={{ width: '100%' }}
            onChange={async (value) => {
              const listOrder = ordersByStatus.filter((e) =>
                selectedRowKeys.includes(e.id_order)
              )
              let listPromise

              listOrder.map((ordersDetail) => {
                const listProductAssignProductType = [
                  ...ordersDetail.list_product_unsign,
                  ...ordersDetail.list_product_fulfill,
                ]

                listPromise = listProductAssignProductType.map(async (e) => {
                  let resUpdate = null

                  const indexUpdateUnsign =
                    ordersDetail.list_product_unsign.findIndex(
                      (v) => v.id === e.id
                    )
                  const indexUpdateFulfill =
                    ordersDetail.list_product_fulfill.findIndex(
                      (v) => v.id === e.id
                    )

                  if (indexUpdateUnsign !== -1) {
                    ordersDetail.list_product_unsign[
                      indexUpdateUnsign
                    ].supplier = value
                    ordersDetail.action = `assign supplier by item ${ordersDetail.list_product_unsign[indexUpdateUnsign].id}`
                    resUpdate = await updateOrders(ordersDetail)
                  }

                  if (indexUpdateFulfill !== -1) {
                    ordersDetail.list_product_fulfill[
                      indexUpdateFulfill
                    ].supplier = value
                    ordersDetail.action = `assign supplier by item ${ordersDetail.list_product_fulfill[indexUpdateFulfill].id}`
                    resUpdate = await updateOrders(ordersDetail)
                  }

                  return resUpdate
                })
              })
              dispatch({
                type: ACTION.LOADING,
                data: true,
              })
              const res = await Promise.all(listPromise)
              dispatch({
                type: ACTION.LOADING,
                data: false,
              })
              let isCheckUpdateSuccess = true
              for (let i = 0; i < res.length; ++i) {
                if (res[i].status === 200) isCheckUpdateSuccess = true
                else {
                  isCheckUpdateSuccess = false
                  break
                }
              }

              if (isCheckUpdateSuccess) {
                await getOrdersByStatus(key, page, pageSize, {
                  ...paramsFilter,
                })
                toggle()
                notification.success({ message: 'Assign supplier success!' })
              } else {
                toggle()
                notification.error({
                  message: 'Assign supplier error, please try again',
                })
              }
            }}>
            {usersSupplier.map((e, index) => (
              <Option value={e.username} key={index}>
                {e.username}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const contentMoreAction = (
    <div className={styles['assign_popover']}>
      <Permission permissions={[PERMISSIONS.assign_producttype_order]}>
        <ModalAssignProductType />
      </Permission>
      <Permission permissions={[PERMISSIONS.assign_supplier_order]}>
        <ModalAssignSupplier />
      </Permission>

      <Permission permissions={[PERMISSIONS.update_status_order]}>
        <>
          <Popconfirm
            title="Are you sure resend orders!"
            onConfirm={_resendOrder}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                style={{ color: '#00FF00', fontSize: '1rem', marginRight: 8 }}
              />
              <div>Resend</div>
            </div>
          </Popconfirm>
          <Popconfirm
            title="Are you sure update status need pay this order!"
            onConfirm={() => confirm(STATUS_ORDER.NEED_PAY)}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                rotate={180}
                style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
              />
              <div>Need Pay</div>
            </div>
          </Popconfirm>
          <Popconfirm
            title="Are you sure update status pending design this order!"
            onConfirm={() => confirm(STATUS_ORDER.PENDING_DESIGN)}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                rotate={180}
                style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
              />
              <div>Pending Design</div>
            </div>
          </Popconfirm>
          <Popconfirm
            title="Are you sure update status pending this order!"
            onConfirm={() => confirm(STATUS_ORDER.PENDING)}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                rotate={180}
                style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
              />
              <div>Pending</div>
            </div>
          </Popconfirm>
          <Popconfirm
            title="Are you sure update status in production this order!"
            onConfirm={() => confirm(STATUS_ORDER.IN_PRODUCTION)}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                rotate={180}
                style={{ color: '#0033FF', fontSize: '1rem', marginRight: 8 }}
              />
              <div>In Production</div>
            </div>
          </Popconfirm>
          <Popconfirm
            title="Are you sure update status on hold this order!"
            onConfirm={() => confirm(STATUS_ORDER.ON_HOLD)}
            okText="Yes"
            cancelText="No">
            <div>
              <ExportOutlined
                rotate={180}
                style={{ color: '#F60DC6', fontSize: '1rem', marginRight: 8 }}
              />
              <div>On hold</div>
            </div>
          </Popconfirm>
          <UpdateActionRequired />
          <Popconfirm
            title="Are you sure update status cancelled/refuned this order!"
            onConfirm={() => confirm(STATUS_ORDER.CANCELLED_REFUNDED)}
            okText="Yes"
            cancelText="No">
            <div>
              <CloseCircleOutlined
                style={{ color: 'red', fontSize: '1rem', marginRight: 8 }}
              />
              <div>Refunded/cancel</div>
            </div>
          </Popconfirm>
        </>
      </Permission>
    </div>
  )

  const _getAllProductType = async () => {
    try {
      const res = await getAllProductType()
      if (res.status === 200) {
        setAllProductType(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getCarriers = async () => {
    try {
      const res = await getCarrier()

      if (res.status === 200) {
        setCarriers(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersBySeller = async () => {
    try {
      const res = await filterRoleUsers({ role: 'Seller' })
      if (res.status === 200) {
        setUsersSeller(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersBySupplier = async () => {
    try {
      const res = await filterRoleUsers({ role: 'Supplier' })
      if (res.status === 200) {
        setUsersSupplier(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getOrdersByStatus = async (status, page = 1, pageSize = 20, filter) => {
    try {
      setLoadingTable(true)
      let params

      //nếu get orders status là all thì ko cần truyền status vao params
      //filter là các pamrams search như filter site, search ordername
      if (!status || status === 'all') {
        if (filter)
          params = {
            page,
            pageSize,
            ...filter,
          }
        else
          params = {
            page,
            pageSize,
          }
      } else {
        if (filter)
          params = {
            status,
            page,
            pageSize,
            ...filter,
          }
        else
          params = {
            status,
            page,
            pageSize,
          }
      }

      const res = await orders(params)
      console.log(res)
      if (res.status === 200) {
        setOrdersByStatus(res.data.data)
        setCountOrders([...res.data.status_count])
        //nếu order là pending design thì cho expand table
        if (status === STATUS_ORDER.PENDING_DESIGN) {
          const keys = res.data.data.map((e) => e.id_order)
          setSelectedRowKeys(keys)
        }
      }
      setLoadingTable(false)
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const getOrdersBySocket = async () => {
    try {
      let params

      //nếu get orders status là all thì ko cần truyền status vao params
      //filter là các pamrams search như filter site, search ordername
      if (!key || key == 'all') {
        params = {
          page,
          pageSize,
        }
      } else {
        params = {
          status: key,
          page,
          pageSize,
        }
      }
      const res = await orders(params)
      if (res.status === 200) setOrdersByStatus(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const listParamsFilter =
      localStorage.getItem('filters') &&
      JSON.parse(localStorage.getItem('filters'))
    const pageDetault = localStorage.getItem('pageOrder')
    const statusDetault = localStorage.getItem('statusOrder')

    if (
      (listParamsFilter &&
        Object.keys(listParamsFilter).length &&
        Object.keys(listParamsFilter).length !== 1) ||
      pageDetault ||
      statusDetault
    ) {
      setOptionsSearch(listParamsFilter.optionsSearch)
      setValueSearch(listParamsFilter[listParamsFilter.optionsSearch] || '')
      setTagsFilterRender(
        listParamsFilter.product_status
          ? { product_status: listParamsFilter.product_status }
          : {}
      )
      formFilter.setFieldsValue({
        product_status: listParamsFilter.product_status || undefined,
      })
      const DATE_PARAMS = [
        'to_day',
        'yesterday',
        'this_week',
        'last_week',
        'last_month',
        'this_month',
        'this_year',
        'last_year',
      ]

      let p = {}

      Object.keys(listParamsFilter).map((key) => {
        if (DATE_PARAMS.includes(key)) {
          p[key] = listParamsFilter[key]
          setValueTime(key)
          let valueDateTime = {}
          valueDateTime[key] = listParamsFilter[key]
          setValueDateTimeSearch(valueDateTime)
        }

        if (key === 'startDate') {
          p['startDate'] = listParamsFilter.startDate
          p['endDate'] = listParamsFilter.endDate
          setValueTime(
            `${listParamsFilter['startDate']} -> ${listParamsFilter['endDate']}`
          )
        }

        if (key === 'site') p.site = listParamsFilter.site
        if (key === 'platform') p.platform = listParamsFilter.platform
        if (key === 'product_status')
          p.product_status = listParamsFilter.product_status
        if (key === listParamsFilter.optionsSearch)
          p[listParamsFilter.optionsSearch] =
            listParamsFilter[listParamsFilter.optionsSearch]
      })

      setParamsFilter({ ...p })
      getOrdersByStatus(key, page, pageSize, { ...p })
    } else {
      setValueTime('to_day')
      setParamsFilter({ to_day: true })
      setValueDateTimeSearch({ to_day: true })
      getOrdersByStatus(key, page, pageSize, { to_day: true })
    }

    getAllSiteData()
    _getAllProductType()
    _getCarriers()
    getUsersBySupplier()
    getUsersBySeller()
  }, [])

  //lưu các params filter dùng cho lần sau
  useEffect(() => {
    try {
      localStorage.setItem(
        'filters',
        JSON.stringify({ ...paramsFilter, optionsSearch })
      )
    } catch (error) {
      console.log(error)
    }
  }, [paramsFilter])

  //check có order nào nhận đc từ socket
  //nếu có thì call lại api và set lại data
  // useEffect(() => {
  //   if (!Object.keys(paramsFilter).length) {
  //     getOrdersBySocket()
  //   }
  // }, [ordersReducers])

  //dùng localStorage để xử lí setting columns 1 lần
  //áp dụng có các lần sử dụng sau
  useEffect(() => {
    if (!localStorage.getItem('columnsAll'))
      localStorage.setItem('columnsAll', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsCollingOff'))
      localStorage.setItem('columnsCollingOff', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsPendingDesign'))
      localStorage.setItem('columnsPendingDesign', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsNeedPay'))
      localStorage.setItem('columnsNeedPay', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsPending'))
      localStorage.setItem('columnsPending', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsInProduction'))
      localStorage.setItem('columnsInProduction', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsShipped'))
      localStorage.setItem('columnsShipped', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsCancelled'))
      localStorage.setItem('columnsCancelled', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsOnHold'))
      localStorage.setItem('columnsOnHold', JSON.stringify(orderColumns))
    if (!localStorage.getItem('columnsActionRequired'))
      localStorage.setItem(
        'columnsActionRequired',
        JSON.stringify(orderColumns)
      )
  }, [])

  const ContentZoomImage = (data) => {
    const [valueBox, setValueBox] = useState(300)
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <img
          src={data}
          style={{ width: valueBox, height: valueBox, objectFit: 'contain' }}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        <Slider
          defaultValue={300}
          min={100}
          max={1000}
          onChange={(value) => setValueBox(value)}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    )
  }

  const uploadAdditionalFileOrderPendingDesign = async (
    record,
    recordMini,
    files
  ) => {
    try {
      //tham số files có thể chứa link ảnh hoặc chứa các file cần uploadd
      let arrayLinkImg = [] //lọc các link ảnh
      let isCheckUpload = false //check user upload hay remove
      let filesUpload = [] //loc item là file để upload
      files.map((e) => {
        if (e.originFileObj) {
          filesUpload.push(e.originFileObj)
          isCheckUpload = true
        } else arrayLinkImg.push(e.url)
      })

      const indexUploadUnsign = record.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      const indexUploadFulfill = record.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )

      //case upload
      if (isCheckUpload) {
        dispatch({
          type: ACTION.LOADING,
          data: true,
        })

        let listUrl = await uploadFiles(filesUpload)
        console.log(listUrl)
        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb &&
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb
              .length
          )
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb = [
              ...record.list_product_unsign[indexUploadUnsign].printFiles_thumb,
              ...listUrl,
            ]

          record.list_product_unsign[indexUploadUnsign].printFiles = [
            ...record.list_product_unsign[indexUploadUnsign].printFiles,
            ...listUrl,
          ]

          record.action = `upload print file by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrderPendingDesign(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb &&
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb
              .length
          )
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb = [
              ...record.list_product_fulfill[indexUploadFulfill]
                .printFiles_thumb,
              ...listUrl,
            ]

          record.list_product_fulfill[indexUploadFulfill].printFiles = [
            ...record.list_product_fulfill[indexUploadFulfill].printFiles,
            ...listUrl,
          ]

          record.action = `upload print file by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrderPendingDesign(record)
        }
      } else {
        //case remove
        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb &&
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb
              .length
          ) {
            let indexRemoveList = []
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_unsign[
              indexUploadUnsign
            ].printFiles.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_unsign[indexUploadUnsign].printFiles = [
              ...newPrintFiles,
            ]

            record.list_product_unsign[indexUploadUnsign].printFiles_thumb =
              arrayLinkImg
          } else
            record.list_product_unsign[indexUploadUnsign].printFiles =
              arrayLinkImg
          console.log(arrayLinkImg)
          record.action = `delete img print file by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrderPendingDesign(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb &&
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb
              .length
          ) {
            let indexRemoveList = []
            record.list_product_fulfill[
              indexUploadFulfill
            ].printFiles_thumb.map((url, index) => {
              if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
            })

            const newPrintFiles = record.list_product_fulfill[
              indexUploadFulfill
            ].printFiles.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_fulfill[indexUploadFulfill].printFiles = [
              ...newPrintFiles,
            ]

            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb =
              arrayLinkImg
          } else
            record.list_product_fulfill[indexUploadFulfill].printFiles =
              arrayLinkImg

          record.action = `delete img print file by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrderPendingDesign(record)
        }
      }
    } catch (error) {
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      console.log(error)
      notification.error({ message: 'Upload failed, please try again!' })
    }
  }

  const uploadMockupOrderPendingDesign = async (record, recordMini, files) => {
    try {
      //tham số files có thể chứa link ảnh hoặc chứa các file cần uploadd
      let arrayLinkImg = [] //lọc các link ảnh
      let isCheckUpload = false //check user upload hay remove
      let filesUpload = [] //loc item là file để upload
      files.map((e) => {
        if (e.originFileObj) {
          filesUpload.push(e.originFileObj)
          isCheckUpload = true
        } else arrayLinkImg.push(e.url)
      })

      const indexUploadUnsign = record.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      const indexUploadFulfill = record.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )

      //case upload
      if (isCheckUpload) {
        dispatch({
          type: ACTION.LOADING,
          data: true,
        })

        let listUrl = await uploadFiles(filesUpload)

        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].mockup_thumb &&
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.length
          )
            record.list_product_unsign[indexUploadUnsign].mockup_thumb = [
              ...record.list_product_unsign[indexUploadUnsign].mockup_thumb,
              ...listUrl,
            ]

          record.list_product_unsign[indexUploadUnsign].mockup = [
            ...record.list_product_unsign[indexUploadUnsign].mockup,
            ...listUrl,
          ]

          record.action = `upload mockup by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrderPendingDesign(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb &&
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.length
          )
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb = [
              ...record.list_product_fulfill[indexUploadFulfill].mockup_thumb,
              ...listUrl,
            ]

          record.list_product_fulfill[indexUploadFulfill].mockup = [
            ...record.list_product_fulfill[indexUploadFulfill].mockup,
            ...listUrl,
          ]

          record.action = `upload mockup by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrderPendingDesign(record)
        }
      } else {
        //case remove
        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].mockup_thumb &&
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.length
          ) {
            let indexRemoveList = []
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_unsign[
              indexUploadUnsign
            ].mockup.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_unsign[indexUploadUnsign].mockup = [
              ...newPrintFiles,
            ]

            record.list_product_unsign[indexUploadUnsign].mockup_thumb =
              arrayLinkImg
          } else
            record.list_product_unsign[indexUploadUnsign].mockup = arrayLinkImg

          record.action = `delete mockup by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrderPendingDesign(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb &&
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.length
          ) {
            let indexRemoveList = []
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_fulfill[
              indexUploadFulfill
            ].mockup.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_fulfill[indexUploadFulfill].mockup = [
              ...newPrintFiles,
            ]

            record.list_product_fulfill[indexUploadFulfill].mockup_thumb =
              arrayLinkImg
          } else
            record.list_product_fulfill[indexUploadFulfill].mockup =
              arrayLinkImg

          record.action = `delete mockup by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrderPendingDesign(record)
        }
      }
    } catch (error) {
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      console.log(error)
      notification.error({ message: 'Upload failed, please try again!' })
    }
  }

  return (
    <div className={styles['orders_manager']}>
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }>
                  <ShoppingCartOutlined />
                </div>
                <div> ORDERS MANAGER </div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div>
              <div className={styles['orders_manager_header_row_col_right']}>
                <div
                  style={{
                    width: 200,
                    marginRight: '1rem',
                  }}>
                  <ExportTrackingInOrders
                    fileName="Tracking number"
                    name="Export Tracking"
                    getOrdersByExportOrder={getOrdersByExportOrder}
                  />
                </div>
                <div
                  style={{
                    width: 120,
                    marginRight: '1rem',
                  }}>
                  <ExportOrders
                    fileName={`order-${moment(new Date())
                      .format('DDMMYYYY HHmm')
                      .replace(' ', '-')}`}
                    name="Export orders"
                    getOrdersByExportOrder={getOrdersByExportOrder}
                  />
                </div>
                <div>
                  <Permission
                    permissions={[PERMISSIONS.import_tracking_number]}>
                    <Button
                      onClick={() => setVisibleImportTracking(true)}
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="large"
                      style={{
                        height: 40,
                        margin: '0 15px',
                      }}>
                      Import Tracking CSV
                    </Button>
                  </Permission>

                  <Modal
                    title={
                      <a
                        download
                        href="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/09/10/6533a2b3-8866-41f9-908c-a7b5b21691a6_templated import tn new.xlsx">
                        <Button type="link" icon={<LinkOutlined />}>
                          Download template
                        </Button>
                      </a>
                    }
                    centered
                    visible={visibleImportTracking}
                    footer={
                      <Space>
                        <Button
                          onClick={() => {
                            setVisibleImportTracking(false)
                            setDataImportTracking([])
                          }}
                          type="primary"
                          danger>
                          Close
                        </Button>
                        <Button
                          onClick={updateOrdersTrackingData}
                          type="primary"
                          disabled={
                            dataImportTracking.length && !isFileImportError
                              ? false
                              : true
                          }>
                          Import
                        </Button>
                      </Space>
                    }
                    width={1000}
                    onCancel={() => {
                      setVisibleImportTracking(false)
                      setDataImportTracking([])
                    }}>
                    <div
                      className={styles['import_orders_content_bottom_input']}>
                      <Row
                        align="middle"
                        justify="space-between"
                        style={{ width: '100%' }}>
                        <Button icon={<DownloadOutlined />}>
                          Import Tracking
                          <label
                            for="import-tracking"
                            style={{
                              position: 'absolute',
                              top: 0,
                              bottom: 0,
                              right: 0,
                              left: 0,
                              cursor: 'pointer',
                            }}
                            onClick={() => setValueFileImport('')}></label>
                        </Button>
                        <span
                          style={{
                            color: 'red',
                            display: !isFileImportError && 'none',
                          }}>
                          Required attributes are missing, please check file!
                        </span>
                      </Row>
                      <input
                        id="import-tracking"
                        type="file"
                        value={valueFileImport}
                        onChange={(e) => {
                          let fileObj = e.target.files[0]
                          setFileImportTracking(fileObj)
                          if (fileObj)
                            ExcelRenderer(fileObj, (err, resp) => {
                              if (err) {
                                console.log(err)
                              } else {
                                setIsFileImportError(false)
                                let result = []
                                let ordersImport = []
                                setTotalRowFileImportTracking(
                                  resp.rows ? resp.rows.length - 1 : 0
                                )
                                for (let i = 1; i < resp.rows.length; ++i) {
                                  if (resp.rows[i].length) {
                                    let itemsImport = []
                                    let obj = {}
                                    let orderItem = {}
                                    for (
                                      let j = 0;
                                      j < resp.rows[0].length;
                                      ++j
                                    ) {
                                      if (resp.rows[0][j] === 'Site ID') {
                                        if (!resp.rows[i][j])
                                          setIsFileImportError(true)
                                        obj.key_design = resp.rows[i][j]
                                      }
                                      if (resp.rows[0][j] === 'Order ID') {
                                        if (!resp.rows[i][j])
                                          setIsFileImportError(true)
                                        obj.id_order = resp.rows[i][j]
                                        orderItem.product_id = ''
                                      }
                                      if (resp.rows[0][j] === 'Order Item ID') {
                                        if (!resp.rows[i][j])
                                          setIsFileImportError(true)
                                        obj.id = resp.rows[i][j]
                                        orderItem.id = resp.rows[i][j]
                                      }
                                      if (
                                        resp.rows[0][j] === 'Customer email'
                                      ) {
                                        if (!resp.rows[i][j])
                                          obj.customer_email = resp.rows[i][j]
                                        orderItem.customer_email =
                                          resp.rows[i][j]
                                      }
                                      if (
                                        resp.rows[0][j] === 'Customer phone'
                                      ) {
                                        if (!resp.rows[i][j])
                                          obj.customer_phone = resp.rows[i][j]
                                        orderItem.customer_phone =
                                          resp.rows[i][j]
                                      }
                                      if (
                                        resp.rows[0][j] === 'Tracking Carrier'
                                      ) {
                                        if (!resp.rows[i][j])
                                          setIsFileImportError(true)
                                        obj.carrier_code = resp.rows[i][j]
                                        orderItem.code = resp.rows[i][j]
                                      }
                                      if (
                                        resp.rows[0][j] === 'Tracking Number'
                                      ) {
                                        if (!resp.rows[i][j])
                                          setIsFileImportError(true)
                                        obj.tracking_number = resp.rows[i][j]
                                        orderItem.tracking_number =
                                          resp.rows[i][j]
                                      }
                                    }
                                    result.push(obj)
                                    itemsImport.push(orderItem)

                                    if (ordersImport.length === 0) {
                                      ordersImport.push({
                                        id_order: resp.rows[1][1],
                                        key_design: resp.rows[1][0],
                                        order_items: itemsImport,
                                      })
                                    } else {
                                      const index = ordersImport.findIndex(
                                        (e) => e.id_order === obj.id_order
                                      )
                                      if (index !== -1) {
                                        ordersImport[index].order_items.push(
                                          orderItem
                                        )
                                      } else {
                                        ordersImport.push({
                                          id_order: obj.id_order,
                                          key_design: obj.key_design,
                                          order_items: itemsImport,
                                        })
                                      }
                                    }
                                  }
                                }

                                setDataImportCsv(ordersImport)
                                console.log(JSON.stringify(ordersImport))
                                setDataImportTracking(result)
                              }
                            })
                          setValueFileImport(e.target.value)
                        }}
                        hidden
                      />
                    </div>
                    <div className={styles['orders_manager_tabs_table_excel']}>
                      <Table
                        style={{ width: '100%' }}
                        columns={columnsImportTracking}
                        dataSource={dataImportTracking}
                        size="small"
                        pagination={false}
                      />
                    </div>
                  </Modal>
                </div>
                <div>
                  <Permission permissions={[PERMISSIONS.import_orders]}>
                    <Button
                      onClick={() => setModal2Visible(true)}
                      type="primary"
                      icon={<DownloadOutlined />}
                      size="large">
                      Import Orders
                    </Button>
                  </Permission>
                  <Modal
                    title={
                      <a
                        download
                        href="https://s3.ap-northeast-1.wasabisys.com/ecom-fulfill/2021/11/02/43121d4f-100a-40c5-a50e-4aa366e77bb1/template import order.xlsx">
                        <Button type="link" icon={<LinkOutlined />}>
                          Download template
                        </Button>
                      </a>
                    }
                    centered
                    visible={modal2Visible}
                    footer={null}
                    style={{ maxWidth: '90vw', minWidth: '85vw' }}
                    onCancel={() => {
                      setModal2Visible(false)
                      setDataImportOrdersView([])
                    }}>
                    <div
                      className={styles['import_orders_content_bottom_input']}>
                      <Button type="dashed" icon={<DownloadOutlined />}>
                        Import orders
                        <label
                          for="import-order"
                          style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            cursor: 'pointer',
                          }}
                          onClick={() => setValueFileImport('')}></label>
                      </Button>
                      <input
                        value={valueFileImport}
                        id="import-order"
                        type="file"
                        onChange={(e) => {
                          let fileObj = e.target.files[0]
                          if (fileObj)
                            ExcelRenderer(fileObj, (err, resp) => {
                              if (err) {
                                console.log(err)
                              } else {
                                let result = []
                                let listOrderImport = []
                                for (let i = 1; i < resp.rows.length; ++i) {
                                  let obj = {}
                                  for (
                                    let j = 0;
                                    j < resp.rows[0].length;
                                    ++j
                                  ) {
                                    if (resp.rows[0][j] === 'Site ID') {
                                      obj.key_design = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Order ID') {
                                      obj.id_order = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Order item Id') {
                                      obj.id = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Shipping Name') {
                                      obj.first_name = resp.rows[i][j] || ''
                                    }
                                    if (
                                      resp.rows[0][j] === 'Shipping address1'
                                    ) {
                                      obj.address_1 = resp.rows[i][j] || ''
                                    }
                                    if (
                                      resp.rows[0][j] === 'Shipping address2'
                                    ) {
                                      obj.address_2 = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Shipping city') {
                                      obj.city = resp.rows[i][j] || ''
                                    }
                                    if (
                                      resp.rows[0][j] ===
                                      'Shipping Province code'
                                    ) {
                                      obj.province_code = resp.rows[i][j] || ''
                                    }
                                    if (
                                      resp.rows[0][j] ===
                                      'Shipping country code'
                                    ) {
                                      obj.country_code = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Postcode') {
                                      obj.postcode = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Phone') {
                                      obj.phone = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Product name') {
                                      obj.name = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Item Sku') {
                                      obj.sku = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Item Quantity') {
                                      obj.quantity = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Print File') {
                                      obj.printFiles = resp.rows[i][j] || ''
                                    }
                                    if (resp.rows[0][j] === 'Mockup File') {
                                      obj.mockup = resp.rows[i][j] || ''
                                    }
                                  }
                                  result.push(obj)
                                  listOrderImport.push({
                                    key_design: obj.key_design,
                                    id_order: obj.id_order,
                                    shipping: {
                                      first_name: obj.first_name,
                                      last_name: '',
                                      company: '',
                                      address_1: obj.address_1,
                                      address_2: obj.address_2,
                                      city: '',
                                      country: '',
                                      country_code: obj.country_code,
                                      postcode: obj.postcode,
                                      province: '',
                                      province_code: obj.province_code,
                                      phone: obj.phone,
                                      email: '',
                                    },
                                    list_product: [
                                      {
                                        name: obj.name,
                                        quantity: obj.quantity,
                                        sku: obj.sku,
                                        printFiles: [obj.printFiles],
                                        mockup: [obj.mockup],
                                        printFiles_thumb: [],
                                        mockup_thumb: [],
                                      },
                                    ],
                                  })
                                }
                                console.log(result)
                                setDataImportOrders(listOrderImport)
                                setDataImportOrdersView(result)
                              }
                            })

                          setValueFileImport(e.target.value)
                        }}
                        hidden
                      />
                    </div>
                    <Table
                      size="small"
                      style={{ width: '100%', marginTop: 15, marginBottom: 15 }}
                      columns={columnsImport}
                      dataSource={dataImportOrdersView}
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                    />
                    <Row justify="end">
                      <Space>
                        <Button
                          onClick={() => {
                            setModal2Visible(false)
                            setDataImportOrdersView([])
                          }}
                          type="primary"
                          danger>
                          Close
                        </Button>

                        <Button
                          disabled={dataImportOrdersView.length ? false : true}
                          onClick={_importOrders}
                          type="primary">
                          Import
                        </Button>
                      </Space>
                    </Row>
                  </Modal>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row style={{ marginTop: 20, marginBottom: 30, width: '100%' }}>
        <Row
          justify="end"
          style={{
            width: '100%',
            marginBottom: 10,
            visibility:
              paramsFilter.site && paramsFilter.site.split('00000').length === 1
                ? ''
                : 'hidden',
          }}>
          <Space>
            <Button type="primary" onClick={scan250LastOrder}>
              Scan 1000 Last Order
            </Button>
            <ModalSyncOrderById />
          </Space>
        </Row>
        <Row justify="space-between" style={{ width: '100%' }} wrap={false}>
          <Col>
            <div>Search orders by options</div>
            <Input
              style={{ width: 450 }}
              value={valueSearch}
              onChange={onSearch}
              placeholder="Search by"
              allowClear
              addonBefore={
                <Select
                  placeholder="options"
                  value={optionsSearch}
                  style={{ width: 180 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    delete paramsFilter[optionsSearch]
                    setParamsFilter({
                      ...paramsFilter,
                      ...Object.fromEntries([[value, valueSearch]]),
                    })
                    setOptionsSearch(value)
                  }}>
                  <Option value="search">name order</Option>
                  <Option value="name_customer">name customer</Option>
                  <Option value="address_customer">address customer</Option>
                  <Option value="email_customer">email customer</Option>
                  <Option value="phone_customer">phone customer</Option>
                  <Option value="country_customer">country customer</Option>
                  <Option value="product_name">name product</Option>
                  <Option value="sku">sku product</Option>
                  <Option value="rename_for_Sup">SKU custom</Option>
                </Select>
              }
            />
          </Col>
          <Col>
            <div>Filter by time</div>
            <Select
              open={isOpenSelect}
              onBlur={() => {
                if (isOpenSelect) toggleOpenSelect()
              }}
              onClick={() => {
                if (!isOpenSelect) toggleOpenSelect()
              }}
              style={{ width: 260 }}
              placeholder="Choose time"
              allowClear
              value={valueTime}
              onChange={async (value) => {
                setValueTime(value)

                //khi search hoac filter thi reset page ve 1
                setPage(1)

                //xoa params search date hien tai
                const p = Object.keys(valueDateTimeSearch)
                if (p.length) delete paramsFilter[p[0]]

                setValueDateSearch(null)
                delete paramsFilter.startDate
                delete paramsFilter.endDate

                if (isOpenSelect) toggleOpenSelect()

                if (value) {
                  const searchDate = Object.fromEntries([[value, true]]) // them params search date moi
                  getOrdersByStatus(key, 1, pageSize, {
                    ...paramsFilter,
                    ...searchDate,
                  })
                  setParamsFilter({ ...paramsFilter, ...searchDate })
                  setValueDateTimeSearch({ ...searchDate })
                } else {
                  getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
                  setParamsFilter({ ...paramsFilter })
                  setValueDateTimeSearch({})
                }
              }}
              dropdownRender={(menu) => (
                <div>
                  <RangePicker
                    onFocus={() => {
                      if (!isOpenSelect) toggleOpenSelect()
                    }}
                    onBlur={() => {
                      if (isOpenSelect) toggleOpenSelect()
                    }}
                    value={valueDateSearch}
                    onChange={onChange}
                    style={{ width: '100%' }}
                  />
                  {menu}
                </div>
              )}>
              <Option value="to_day">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This week</Option>
              <Option value="last_week">Last week</Option>
              <Option value="last_month">Last month</Option>
              <Option value="this_month">This month</Option>
              <Option value="this_year">This year</Option>
              <Option value="last_year">Last year</Option>
            </Select>
          </Col>
          <Col>
            <div>Filter by site</div>
            <Select
              mode="multiple"
              notFoundContent={!sites.length ? <Spin size="small" /> : null}
              style={{ width: 240 }}
              placeholder="Choose site"
              value={
                paramsFilter && paramsFilter.site
                  ? paramsFilter.site.split('00000')
                  : undefined
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                const sites = value.join('00000')
                //khi search hoac filter thi reset page ve 1
                setPage(1)
                if (sites) paramsFilter.site = sites
                else delete paramsFilter.site

                getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
                setParamsFilter({ ...paramsFilter })
              }}
              allowClear>
              {sites.map((values) => {
                return <Option value={values.site_name}>{values.name}</Option>
              })}
            </Select>
          </Col>
          <Col>
            <div>Filter by platform</div>
            <Select
              style={{ width: 170 }}
              allowClear
              placeholder="Filter by platform"
              value={paramsFilter.platform}
              onChange={async (value) => {
                //khi search hoac filter thi reset page ve 1
                setPage(1)
                if (value) paramsFilter.platform = value
                else delete paramsFilter.platform

                getOrdersByStatus(key, 1, pageSize, { ...paramsFilter })
                setParamsFilter({ ...paramsFilter })
              }}>
              <Option value="Shopbase">Shopbase</Option>
              <Option value="Etsy">Etsy</Option>
              <Option value="Woo">Woo</Option>
              <Option value="Shopify">Shopify</Option>
              <Option value="Amazon">Amazon</Option>
            </Select>
          </Col>
        </Row>
        <Row justify="end" style={{ width: '100%' }}>
          <TagsFilter />
          <DrawerMoreFilter />
        </Row>
        <Row justify="end" style={{ width: '100%', marginTop: 15 }}>
          <Checkbox
            checked={visibleImages}
            onChange={(e) => setVisibleImages(e.target.checked)}>
            {visibleImages ? 'Show images' : 'Hidden images'}
          </Checkbox>
        </Row>
      </Row>

      {[
        STATUS_ORDER.NEED_PAY,
        STATUS_ORDER.PENDING,
        STATUS_ORDER.COLLING_OFF,
        STATUS_ORDER.IN_PRODUCTION,
        STATUS_ORDER.PENDING_DESIGN,
        STATUS_ORDER.SHIPPED,
      ].includes(key) && selectedRowKeys.length ? (
        <Row style={{ width: '100%' }}>
          <Col
            style={{ width: '100%' }}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}>
            <Row className={styles['status_button']}>
              {/* <Col
                style={{ marginRight: '1rem', marginBottom: '2rem' }}
                className={styles["status_button_col"]}
                xs={24}
                sm={24}
                md={11}
                lg={11}
                xl={5}
              >
                <Popconfirm
                  title="Are you sure you cancel this order?"
                  onConfirm={() => confirm(STATUS_ORDER.CANCELLED_REFUNDED)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    className={styles["status_button_no"]}
                    style={{ width: "100%" }}
                  >
                    
                    Cancel Order
                  </Button>
                </Popconfirm>
              </Col> */}
              <Permission permissions={[PERMISSIONS.update_status_order]}>
                <Col
                  style={{
                    marginRight: '1rem',
                    marginBottom: '2rem',
                  }}
                  className={styles['status_button_col']}
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={5}>
                  <Popconfirm
                    title="Are you sure you accept this order?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      className={styles['status_button_ok_fix']}
                      style={{
                        width: '100%',
                        display: key === STATUS_ORDER.IN_PRODUCTION && 'none',
                      }}
                      onClick={(e) => e.stopPropagation()}>
                      Confirm Order
                    </Button>
                  </Popconfirm>
                  <AcceptOrderStatusInProduction />
                </Col>
              </Permission>
            </Row>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {selectedRowKeys.length ? (
        <Row
          style={{
            width: '100%',
            backgroundColor: '#FAFAFA',
            marginBottom: '1rem',
            padding: '1rem 1rem 0 1rem',
          }}>
          <Col
            style={{ width: '100%' }}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            <Row className={styles['more_action']}>
              <Col
                onClick={() => setSelectedRowKeys([])}
                className={styles['more_action_col']}
                style={{ width: '100%', marginBottom: '1rem' }}
                xs={24}
                sm={24}
                md={11}
                lg={7}
                xl={7}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                  <div
                    style={{
                      border: '1px solid rgb(218, 190, 190)',
                      marginRight: '0.5rem',
                      width: '1rem',
                      height: '1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        color: 'orange',
                        backgroundColor: '#1890FF',
                        width: '0.5rem',
                        height: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}></div>
                  </div>
                  <div>{selectedRowKeys.length} selected</div>
                </div>
              </Col>
              <Permission permissions={[PERMISSIONS.update_status_order]}>
                <Popover placement="bottom" content={contentMoreAction}>
                  <Col
                    className={styles['more_action_col']}
                    style={{ marginBottom: '1rem' }}
                    xs={24}
                    sm={24}
                    md={11}
                    lg={7}
                    xl={7}>
                    More Action
                  </Col>
                </Popover>
              </Permission>
            </Row>
          </Col>
        </Row>
      ) : (
        <div></div>
      )}

      <div className={styles['orders_manager_tabs']}>
        <Tabs
          tabPosition="top"
          className={styles['orders_manager_tabs_child']}
          activeKey={key}
          onChange={callback}>
          <TabPane
            className={styles['orders_manager_tabs_child']}
            tab={
              <Badge
                offset={[-5, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == 'all').count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#818b97' }}>
                All
              </Badge>
            }
            key="all">
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsAll}
                  setColumns={setColumnsAll}
                  nameColumn="columnsAll"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsAll.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                loading={loadingTable}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(null, page, pageSize, paramsFilter)
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == 'all').count,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            className={styles['orders_manager_tabs_child']}
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == 'cooling-off').count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: 'red' }}>
                Colling off
              </Badge>
            }
            key={STATUS_ORDER.COLLING_OFF}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsCollingOff}
                  setColumns={setColumnsCollingOff}
                  nameColumn="columnsCollingOff"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                columns={columnsCollingOff.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.COLLING_OFF,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == 'cooling-off').count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            className={styles['orders_manager_tabs_child']}
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.PENDING_DESIGN)
                    .count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#ffcc01' }}>
                Pending design
              </Badge>
            }
            key={STATUS_ORDER.PENDING_DESIGN}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsPendingDesign}
                  setColumns={setColumnsPendingDesign}
                  nameColumn="columnsPendingDesign"
                />
              </Row>
              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsPendingDesign.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          {`Base cost: ${recordMini.base_price}`}{' '}
                                        </div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.PENDING_DESIGN,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find(
                      (e) => e.name == STATUS_ORDER.PENDING_DESIGN
                    ).count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            className={styles['orders_manager_tabs_child']}
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.NEED_PAY).count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#ffcc01' }}>
                Need pay
              </Badge>
            }
            key={STATUS_ORDER.NEED_PAY}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsNeedPay}
                  setColumns={setColumnsNeedPay}
                  nameColumn="columnsNeedPay"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsNeedPay.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.NEED_PAY,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == STATUS_ORDER.NEED_PAY)
                      .count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.PENDING).count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#ffcc01' }}>
                Pending
              </Badge>
            }
            key={STATUS_ORDER.PENDING}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsPending}
                  setColumns={setColumnsPending}
                  nameColumn="columnsPending"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsPending.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.PENDING,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == STATUS_ORDER.PENDING)
                      .count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.IN_PRODUCTION)
                    .count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#0033ff' }}>
                In production
              </Badge>
            }
            key={STATUS_ORDER.IN_PRODUCTION}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsInProduction}
                  setColumns={setColumnsInProduction}
                  nameColumn="columnsInProduction"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsInProduction.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.IN_PRODUCTION,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find(
                      (e) => e.name == STATUS_ORDER.IN_PRODUCTION
                    ).count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.SHIPPED).count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#00ff02' }}>
                Shipped
              </Badge>
            }
            key={STATUS_ORDER.SHIPPED}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsShipped}
                  setColumns={setColumnsShipped}
                  nameColumn="columnsShipped"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsShipped.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.SHIPPED,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == STATUS_ORDER.SHIPPED)
                      .count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find(
                    (e) => e.name == STATUS_ORDER.CANCELLED_REFUNDED
                  ).count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: 'red' }}>
                Cancelled/Refunded
              </Badge>
            }
            key={STATUS_ORDER.CANCELLED_REFUNDED}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsCancelled}
                  setColumns={setColumnsCancelled}
                  nameColumn="columnsCancelled"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsCancelled.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.CANCELLED_REFUNDED,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find(
                      (e) => e.name == STATUS_ORDER.CANCELLED_REFUNDED
                    ).count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                showZero
                count={
                  countOrders.length &&
                  countOrders.find((e) => e.name == STATUS_ORDER.ON_HOLD).count
                }
                overflowCount={10000}
                style={{ backgroundColor: '#f60dc6' }}>
                On hold
              </Badge>
            }
            key={STATUS_ORDER.ON_HOLD}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsOnHold}
                  setColumns={setColumnsOnHold}
                  nameColumn="columnsOnHold"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsOnHold.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.ON_HOLD,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find((e) => e.name == STATUS_ORDER.ON_HOLD)
                      .count,
                }}
                size="small"
              />
            </div>
          </TabPane>
          <TabPane
            tab={
              <Badge
                offset={[0, -10]}
                count={
                  countOrders.length &&
                  countOrders.find(
                    (e) => e.name == STATUS_ORDER.ACTION_REQUIRED
                  ).count
                }
                showZero
                overflowCount={10000}
                style={{ backgroundColor: '#f60dc6' }}>
                Action required
              </Badge>
            }
            key={STATUS_ORDER.ACTION_REQUIRED}>
            <div className={styles['orders_manager_tabs_table']}>
              <Row
                justify="space-between"
                style={{ width: '100%', marginBottom: 16 }}>
                <Space size="large">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Uncheck
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <SettingColumns
                  columnsRender={orderColumns}
                  columns={columnsActionRequired}
                  setColumns={setColumnsActionRequired}
                  nameColumn="columnsActionRequired"
                />
              </Row>

              <Table
                className={styles['orders_manager_tabs_table_child']}
                loading={loadingTable}
                rowSelection={rowSelection}
                rowKey="id_order"
                columns={columnsActionRequired.map((e) => {
                  if (e.key === 'name') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          onClick={() => {
                            localStorage.setItem('pageOrder', page)
                            localStorage.setItem('statusOrder', key)
                          }}
                          href={
                            ROUTES.ORDERS_TRACKING_DETAIL +
                            '?order=' +
                            `${record.key_design}_${record.id_order}`
                          }>
                          {text}
                        </a>
                      ),
                      sorter: (a, b) =>
                        a.name.replace(/#/g, '').toLowerCase().length -
                        b.name.replace(/#/g, '').toLowerCase().length,
                    }
                  }
                  if (e.key === 'siteUrl') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <a
                          href={
                            localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) !==
                              'Supplier' &&
                            record.siteUrl
                          }
                          target="_blank">
                          {(localStorage.getItem('role') &&
                            JSON.parse(localStorage.getItem('role')) ===
                              'Admin' &&
                            `${record.key_design}: ${record.id_site}`) ||
                            (localStorage.getItem('role') &&
                              JSON.parse(localStorage.getItem('role')) ===
                                'Supplier' &&
                              `${record.key_design}`) ||
                            (localStorage.getItem('role') &&
                              !['Admin', 'Supplier'].includes(
                                JSON.parse(localStorage.getItem('role'))
                              ) &&
                              `${record.id_site}`)}
                        </a>
                      ),
                      sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                    }
                  }
                  if (e.key === 'customer') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.customer ? (
                              `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.customer &&
                              record.customer.first_name +
                                ' ' +
                                record.customer.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.customer.first_name.length -
                        b.customer.first_name.length,
                    }
                  }
                  if (e.key === 'shipping') {
                    return {
                      ...e,
                      render: (text, record) => (
                        <Popover
                          placement="top"
                          content={
                            record.shipping ? (
                              `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                            ) : (
                              <div> No information </div>
                            )
                          }>
                          <div
                            style={{
                              cursor: 'pointer',
                              color: '#2A53CD',
                            }}>
                            {record.shipping &&
                              record.shipping.first_name +
                                ' ' +
                                record.shipping.last_name}
                          </div>
                        </Popover>
                      ),
                      sorter: (a, b) =>
                        a.shipping.first_name.length -
                        b.shipping.first_name.length,
                    }
                  }
                  if (e.key === 'time_created') {
                    return {
                      ...e,
                      render: (text, record) => text || record.date_created,
                      defaultSortOrder: 'descend',
                      sorter: (a, b) =>
                        new Date(a.time_created || a.date_created).getTime() -
                        new Date(b.time_created || b.date_created).getTime(),
                    }
                  }
                  if (e.key === 'tracking_order') {
                    return {
                      ...e,
                      render: (text, record) =>
                        text && <ModalTrackingOrders listTracking={text} />,
                      sorter: (a, b) =>
                        a.tracking_order.length - b.tracking_order.length,
                    }
                  }
                  if (e.key === 'quantity') {
                    return {
                      ...e,
                      sorter: (a, b) => a.quantity - b.quantity,
                    }
                  }
                  if (e.key === 'total_base') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_base - b.total_base,
                    }
                  }
                  if (e.key === 'total_price') {
                    return {
                      ...e,
                      render: (text) =>
                        (text + '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                      sorter: (a, b) => a.total_price - b.total_price,
                    }
                  }
                  return e
                })}
                expandable={{
                  expandedRowRender: (record) => {
                    return (
                      <div style={{ margin: '13px 0px' }}>
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_unsign}
                          size="small"
                          style={{
                            display:
                              !record.list_product_unsign.length && 'none',
                          }}
                        />
                        <br />
                        <Table
                          pagination={false}
                          rowClassName={(productUnsign) => {
                            if (productUnsign.status === 'approve')
                              return 'product-unsign-approve'
                            if (productUnsign.status === 'pending')
                              return 'product-unsign-pending'
                            if (productUnsign.status === 'uncheck')
                              return 'product-unsign-uncheck'
                            if (productUnsign.status === 'cancel')
                              return 'product-unsign-cancel'
                          }}
                          columns={columnsPendingDesignItemMain.map(
                            (values, index) => {
                              if (values.key === 'additionalFile') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadPrintFileProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'mockup') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => (
                                    <UploadMockupProductOrder
                                      record={record}
                                      recordMini={recordMini}
                                    />
                                  ),
                                }
                              }
                              if (values.key === 'information') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Col>
                                        <a
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}
                                          target="_blank"
                                          href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                          recordMini &&
                                          recordMini.tracking_number
                                            ? recordMini.tracking_number
                                            : ''
                                        }`}</a>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Status: ${
                                          recordMini && recordMini.status
                                            ? recordMini.status
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateSkuPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Product name: ${
                                          recordMini && recordMini.name
                                            ? recordMini.name
                                            : ''
                                        }.`}</div>
                                        <div
                                          style={{
                                            marginBottom: '0.5rem',
                                          }}>
                                          Product Variantion:{' '}
                                          {recordMini.variants
                                            .map((e, index) => {
                                              if (
                                                index ===
                                                recordMini.variants.length - 1
                                              )
                                                return `${
                                                  e.name || e.option
                                                }: ${e.value}`
                                              return `${e.name || e.option}: ${
                                                e.value
                                              }|`
                                            })
                                            .join('')}
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>{`Order item id: ${
                                          recordMini && recordMini.id
                                            ? recordMini.id
                                            : ''
                                        }.`}</div>
                                        <ModalUpdateQuantityPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateProductTypePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginBottom: '0.5rem',
                                          }}>
                                          Order created:{' '}
                                          {(recordMini.dateCreated &&
                                            recordMini.dateCreated) ||
                                            (recordMini.date_created &&
                                              recordMini.date_created)}
                                        </div>
                                        <ModalUpdateCustomInfoPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateSupplierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCarrierPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateCustomNotePendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <ModalUpdateRenameForSupPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                      </Col>
                                    )
                                  },
                                }
                              }

                              if (values.key === 'upload') {
                                return {
                                  ...values,
                                  render: (text, recordMini) => {
                                    return (
                                      <Space wrap>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.approve_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickApprove(recordMini, record)
                                            }>
                                            Approve
                                          </Button>
                                        </Permission>

                                        <Permission
                                          permissions={[
                                            PERMISSIONS.rebuild_product_in_order,
                                          ]}>
                                          <Button
                                            style={{
                                              width: '7.5rem',
                                              height: '2.5rem',
                                            }}
                                            type="primary"
                                            onClick={() =>
                                              onClickRebuild(recordMini, record)
                                            }>
                                            Rebuild
                                          </Button>
                                        </Permission>
                                        <ModalUpdateInformationPendingDesign
                                          productUnsign={recordMini}
                                          record={record}
                                        />
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.cancel_product_in_order,
                                          ]}>
                                          <Popconfirm
                                            title="Are you sure cancel this item!"
                                            cancelText="No"
                                            okText="Ok"
                                            onConfirm={() =>
                                              onClickCancel(recordMini, record)
                                            }>
                                            <Button
                                              style={{
                                                width: '7.5rem',
                                                height: '2.5rem',
                                                marginTop: 35,
                                              }}
                                              type="primary"
                                              danger>
                                              Cancel
                                            </Button>
                                          </Popconfirm>
                                        </Permission>
                                        <Permission
                                          permissions={[
                                            PERMISSIONS.add_file_design,
                                          ]}>
                                          <ModalPushNocustom
                                            recordMini={recordMini}
                                          />
                                        </Permission>
                                      </Space>
                                    )
                                  },
                                }
                              }
                              return { ...values }
                            }
                          )}
                          dataSource={record.list_product_fulfill}
                          size="small"
                          style={{
                            display:
                              !record.list_product_fulfill.length && 'none',
                          }}
                        />
                      </div>
                    )
                  },
                  expandedRowKeys: selectedRowKeys,
                  expandIconColumnIndex: -1,
                }}
                dataSource={ordersByStatus.length ? ordersByStatus : []}
                pagination={{
                  position: ['bottomLeft'],
                  current: page,
                  pageSize: pageSize,
                  pageSizeOptions: [20, 30, 50, 100],
                  showQuickJumper: true,
                  onChange: (page, pageSize) => {
                    setSelectedRowKeys([])
                    setPage(page)
                    setPageSize(pageSize)
                    getOrdersByStatus(
                      STATUS_ORDER.ACTION_REQUIRED,
                      page,
                      pageSize,
                      paramsFilter
                    )
                  },
                  total:
                    countOrders.length &&
                    countOrders.find(
                      (e) => e.name == STATUS_ORDER.ACTION_REQUIRED
                    ).count,
                }}
                size="small"
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  )
}
