import { NEWS_WATCHED, ORDERS_API, ORDERS_SOCKET } from "consts";

export const ordersSocket = (data) => {

  return {
    type: ORDERS_SOCKET,
    data: data,
  };
};
export const ordersAPI = (data) => {

  return {
    type: ORDERS_API,
    data: data,
  };
};