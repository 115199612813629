import React, { useState, useEffect, useRef } from 'react'
import styles from './product-types.module.scss'

//components antd
import {
  Col,
  Row,
  Popover,
  Table,
  Button,
  Select,
  Input,
  Space,
  Popconfirm,
  Badge,
  notification,
} from 'antd'
import { Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { ACTION, PERMISSIONS } from 'consts'
import Permission from 'components/permission'

//icons antd
import {
  PlusOutlined,
  ShoppingCartOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//apis
import { getAllProductType } from 'apis/product'
import { searchProductType, updateProductType } from 'apis/productType'
import { deleteProductType, duplicateProductType } from 'apis/productType'

const { Option } = Select
export default function ProductTypes() {
  const dispatch = useDispatch()

  const [productType, setProductType] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [paramsFilter, setParamsFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [countProducts, setCountProducts] = useState(0)

  const [valueSearch, setValueSearch] = useState('')
  const [valueCategory, setValueCategory] = useState('default')
  const [valueSupplier, setValueSupplier] = useState('default')

  function handleChangeCategory(value) {
    setValueCategory(value)
    setPage(1)
    if (value === 'default') {
      delete paramsFilter.category
      getAllProductTypeData({ page: 1, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    } else {
      getAllProductTypeData({
        page: 1,
        pageSize,
        category: value,
        ...paramsFilter,
      })
      setParamsFilter({ ...paramsFilter, category: value })
    }
  }

  function handleChangeSupplier(value) {
    setValueSupplier(value)
    setPage(1)
    if (value === 'default') {
      delete paramsFilter.supplier
      getAllProductTypeData({ page: 1, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    } else {
      getAllProductTypeData({
        page: 1,
        pageSize,
        supplier: value,
        ...paramsFilter,
      })
      setParamsFilter({ ...paramsFilter, supplier: value })
    }
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      setPage(1)
      if (!value) {
        delete paramsFilter.search
        getAllProductTypeData({ page, pageSize, ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      } else {
        paramsFilter.search = value
        getAllProductTypeData({
          page: 1,
          pageSize,
          ...paramsFilter,
        })
        setParamsFilter({ ...paramsFilter })
      }
    }, 750)
  }

  const SettingSupplier = (props) => (
    <div>
      {props.site.supplyBy.map((values, index) => {
        return <div>{values},</div>
      })}
    </div>
  )

  const [selectCategory, setSelectCategory] = useState([])
  const [selectSupplier, setSelectSupplier] = useState([])
  const getAllProductTypeData = async (params) => {
    try {
      setLoading(true)
      console.log(params)
      const res = await getAllProductType(params)
      console.log(res)
      if (res.status === 200) {
        setProductType(res.data.result)
        setCountProducts(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const getCategoryAndSupplier = async () => {
    try {
      const res = await getAllProductType()
      if (res.status === 200) {
        setSelectCategory(res.data.result)
        let suppliers = res.data.result.reduce(
          (value, currentValue) => [...currentValue.supplyBy, ...value],
          []
        )
        setSelectSupplier([...new Set(suppliers)])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllProductTypeData({ page, pageSize })
    getCategoryAndSupplier()
  }, [])

  const _duplicateProductType = async (_id) => {
    try {
      const res = await duplicateProductType(_id)
      console.log(res)
      if (res.status === 200) {
        await getAllProductTypeData({ page, pageSize, ...paramsFilter })
        notification.success({ message: 'x2 product type success!' })
      } else {
        notification.error({ message: 'x2 product type failed!' })
      }
    } catch (error) {}
  }

  const _deleteProductType = async (_id) => {
    try {
      setLoading(true)
      const res = await deleteProductType(_id)
      console.log(res)
      if (res.status === 200) {
        await getAllProductTypeData({ page, pageSize, ...paramsFilter })
        notification.success({ message: 'Delete product type success!' })
      } else {
        notification.error({ message: 'Delete product type failed!' })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _updateProductType = async (body) => {
    try {
      setLoading(true)
      const res = await updateProductType(body)

      if (res.status === 200) {
        await getAllProductTypeData({ page, pageSize, ...paramsFilter })
        notification.success({ message: 'Update product type success!' })
      } else {
        notification.error({ message: 'Update product type failed!' })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const arrayCategory = []
  selectCategory.forEach((values, index) => {
    arrayCategory.push(values.category)
  })
  const uniqueCategory = [...new Set(arrayCategory)]
  const columns = [
    {
      title: 'Mockup',
      dataIndex: 'mockup',
      key: 'mockup',
      width: 300,
      render: (text, record) =>
        text &&
        text.map((e) => (
          <Popover
            trigger="hover"
            placement="topLeft"
            content={
              <div style={{ width: 820, height: 820 }}>
                <img
                  src={e}
                  alt=""
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  loading="lazy"
                />
              </div>
            }
          >
            <img
              src={e}
              alt=""
              style={{
                width: 45,
                height: 45,
                margin: 8,
                marginTop: 8,
                objectFit: 'cover',
              }}
            />
          </Popover>
        )),
    },
    {
      title: 'Title',
      dataIndex: 'nameProductType',
      key: 'nameProductType',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Sku',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplyBy',
      key: 'supplyBy',
      render: (text, record) => <SettingSupplier site={record} />,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },

    {
      title: 'Created date',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 100,
      key: 'is_active',
      render: (text, record) => (
        <Badge
          status={text ? 'success' : 'warning'}
          text={text ? 'Active' : 'Deactivate'}
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 120,
      render: (text, record) => (
        <Space>
          <Permission permissions={[PERMISSIONS.edit_product_type]}>
            <Link
              to={{
                pathname: '/add-product',
                state: record,
                search: '?type=productType',
              }}
            >
              <EditOutlined style={{ fontSize: '1rem' }} />
            </Link>
          </Permission>
          <Permission permissions={[PERMISSIONS.duplicate_product_type]}>
            <Popconfirm
              okText="Ok"
              cancelText="No"
              title="Are you sure x2 this product type!"
              onConfirm={() => _duplicateProductType(record._id)}
            >
              <Button type="link">X2</Button>
            </Popconfirm>
          </Permission>
          <Permission permissions={[PERMISSIONS.update_active_product_type]}>
            <Popconfirm
              title={`Are you sure to ${
                record.is_active ? 'deactivate' : 'active'
              } this productType?`}
              onConfirm={() => {
                _updateProductType({ ...record, is_active: !record.is_active })
              }}
              okText="Yes"
              cancelText="No"
            >
              {record.is_active ? (
                <LockOutlined
                  style={{
                    fontSize: '1rem',
                    cursor: 'pointer',
                    color: '#FF2929',
                  }}
                />
              ) : (
                <UnlockOutlined
                  style={{
                    fontSize: '1rem',
                    cursor: 'pointer',
                    color: 'rgba(0,199,50,.85)',
                  }}
                />
              )}
            </Popconfirm>
          </Permission>
          <Permission permissions={[PERMISSIONS.delete_product_type]}>
            <Popconfirm
              title="Are you sure delete this product type?"
              onConfirm={() => _deleteProductType(record._id)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined
                style={{ fontSize: '1rem', color: '#ff8500d9' }}
              />
            </Popconfirm>
          </Permission>
        </Space>
      ),
    },
  ]

  return (
    <div className={styles['orders_manager']}>
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }
                >
                  <ShoppingCartOutlined />
                </div>
                <div>PRODUCT TYPES</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div>
              <Permission permissions={[PERMISSIONS.create_product_type]}>
                <div className={styles['orders_manager_header_row_col_right']}>
                  <Link
                    to={{
                      pathname: '/add-product',
                      search: '?type=productType',
                    }}
                  >
                    <Button type="primary" icon={<PlusOutlined />} size="large">
                      Add new
                    </Button>
                  </Link>
                </div>
              </Permission>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_content']}>
        <Row className={styles['orders_manager_content_row']}>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}
          >
            <div>
              <Input.Search
                value={valueSearch}
                onChange={onSearch}
                className={styles['orders_manager_content_row_col_search']}
                placeholder="Search by title"
                allowClear
              />
            </div>
          </Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}
          >
            <div>
              <Select
                className={styles['import_orders_content_select_child']}
                defaultValue="default"
                onChange={handleChangeCategory}
                value={valueCategory}
              >
                <Option value="default">All category</Option>
                {uniqueCategory.map((values, index) => {
                  return <Option value={values}>{values}</Option>
                })}
              </Select>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}
          >
            <div>
              <Select
                className={styles['import_orders_content_select_child']}
                onChange={handleChangeSupplier}
                value={valueSupplier}
              >
                <Option value="default">All supplier</Option>
                {selectSupplier.map((values, index) => {
                  return <Option value={values}>{values}</Option>
                })}
              </Select>
            </div>
          </Col>
          <Row justify="end" style={{ width: '100%' }}>
            <Button
              type="link"
              onClick={() => {
                setParamsFilter({})
                setPage(1)
                setValueSearch('')
                setValueSupplier('default')
                setValueCategory('default')
                getAllProductTypeData({ page: 1, pageSize })
              }}
              style={{
                visibility: !Object.keys(paramsFilter).length && 'hidden',
              }}
            >
              Clear all filter
            </Button>
          </Row>
        </Row>
      </div>
      <div className={styles['orders_manager_tabs_table']}>
        <Table
          className={styles['orders_manager_tabs_table_child']}
          columns={columns}
          dataSource={productType}
          loading={loading}
          size="small"
          pagination={{
            current: page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 50, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
              getAllProductTypeData({ page, pageSize, ...paramsFilter })
            },
            total: countProducts,
          }}
        />
      </div>
    </div>
  )
}
