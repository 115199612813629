import React, { useEffect, useState } from 'react'
// import { Bar } from "@reactchartjs/react-chart.js";
import { Bar } from 'react-chartjs-2'
const ChartPage = React.memo(function ChartPage({ chartOrders }) {
  const [data, setData] = useState({})
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }
  useEffect(() => {
    var labelsData = []
    var labelsOrders = []
    var labelsOrderItems = []
    chartOrders.forEach((values, index) => {
      labelsData.push(values.date)
      labelsOrders.push(values.orders)
      labelsOrderItems.push(values.order_items)
    })

    setData({
      labels: labelsData,
      datasets: [
        {
          label: 'Order',
          data: labelsOrders,
          backgroundColor: '#007BFF',
        },
        {
          label: 'Order Items',
          data: labelsOrderItems,
          backgroundColor: '#D3E8FF',
        },
      ],
    })
  }, [chartOrders])

  return <Bar data={data} options={options} />
})
export default ChartPage
