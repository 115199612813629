import React from 'react'

export default function PrivacyPolicy() {
  return (
    <div style={{ backgroundColor: '#ECF2F3' }}>
      <div
        style={{
          padding: '50px 170px',
        }}>
        <h1 style={{ textAlign: 'center', fontWeight: 700, fontSize: 38 }}>
          Privacy Policy: Website Browsing
        </h1>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Protecting your personal data is our priority. This Privacy Policy
          describes and applies to the processing of your personal data by
          ShipBob, Inc. (together with any affiliated entities, EcomHolicGroup,”
          “we,” or “us”). This Privacy Policy explains your rights and our
          obligations concerning the processing of your personal data (we define
          both “processing” and “personal data” below), so you should read it
          carefully and contact us if you have questions or want to make any
          requests.
        </p>
        <h2 style={{ fontWeight: 700 }}>When this Privacy Policy Applies</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          This Privacy Policy applies to our processing of your personal data in
          connection with your browsing of our websites. If we have received
          your personal data in other situations, that processing is explained
          in a different privacy policy.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          This Privacy Policy describes and applies to EcomHolicGroup processing
          of your personal data. We are not responsible for, nor do we make any
          promises with respect to, processing undertaken by third parties
          (e.g., other websites). If you are curious as to how other websites or
          services process your personal data, we encourage you to check out
          their privacy policies.
        </p>
        <h2 style={{ fontWeight: 700 }}>Important Terms</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          “personal data” means any information that can be used to identify
          you, which may consist of information within the following categories:
          identifiers (e.g., name, email address, username), commercial
          information (e.g., records of product or service purchases,
          information concerning purchase histories), internet or other
          electronic network activity information (e.g., browsing and search
          history and information regarding interaction with a website),
          geolocation data, and professional or employment-related information;
          and
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          “processing” means anything we might do with respect to personal data,
          including collecting it, using it, disclosing it, and deleting it.
        </p>
        <h2 style={{ fontWeight: 700 }}>Collection of Your Personal Data</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup collects the following personal data about you:
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Internet / network activity information: IP address.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          We collect your personal data primarily through technological measures
          like cookies or third-party technology providers. You should consult
          your browser settings regarding cookie settings, but note that
          disabling certain cookies may affect your browsing experience.
        </p>
        <h2 style={{ fontWeight: 700 }}>Use of Your Personal Data</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Internet / network activity information: We monitor internet and
          network activity to operate our websites, understand user profiles of
          website visitors, and analyze traffic to our websites for purposes of
          improving them. We may also use this information to deliver customized
          content and advertising within EcomHolicGroup to customers whose
          behavior indicates that they are interested in a particular subject
          area.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup does not process your personal data without a lawful
          basis for doing so. In this case, our processing advances our
          legitimate interest in expanding our business by marketing our
          products and services effectively through our website. Given the
          limited nature of data processing and limited effects on our data
          subjects (e.g., you), we do not view this processing as harmful to
          data subject rights or freedoms.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Note specifically that (1) the provision of your personal data is not
          a statutory or contractual requirement, and (2) we do not conduct
          automated decision-making, including profiling, with respect to your
          personal data.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup will store your personal data for so long as it is
          necessary for us to process it to meet the purposes described above.
          While we may retain data for a longer period, we will only do so if an
          appropriate lawful basis applies.
        </p>
        <h2 style={{ fontWeight: 700 }}>Disclosures to Third Parties</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          We do not sell, rent, or lease your personal data to third parties.
          However, we may disclose personal data to third parties for commercial
          purposes, as described in this “Third-Party Disclosures” section.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Some of our third-party service providers may see your personal data
          (from all categories) from time to time. These include companies
          Google (our website analytics provider), Proof (a website
          personalization tool), Bizible (a tracking and attribution reporting
          tool), VWO (a testing and personalization service), and targeted
          advertising partners (e.g., Google, Facebook, and LinkedIn). Each of
          these companies—and others that we may engage from time to time for
          similar purposes—may have access to your personal data solely as
          required to provide their services to us. They are required to
          maintain the confidentiality of your personal data and keep it secure.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Note that we may use some of these service providers to help us
          perform statistical analyses regarding our products or services
          (including operation of our websites), to send you email or postal
          mail with relevant information, provide customer support, or arrange
          for deliveries.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          We may share your personal data (in all categories) if EcomHolicGroup,
          in its entirety or with respect to a portion of its business, is sold
          to or merges with a third party (regardless of how that transaction is
          structured). This may include sharing personal data with the relevant
          third party prior to consummating the transaction. Unless you are told
          otherwise, that third party will be required to continue processing
          your personal data in line with this Privacy Policy.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup will disclose your personal data (in any relevant
          category), without notice, only if required to do so by law or in the
          good faith belief that such action is necessary to: (a) conform to the
          edicts of the law or comply with legal process served on ShipBob or
          the site; (b) protect and defend the rights or property of ShipBob;
          and/or (c) act under exigent circumstances to protect the personal
          safety of users of ShipBob, or the public.
        </p>
        <h2 style={{ fontWeight: 700 }}>Where Your Data Goes</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup is based in the United States and processes personal
          data in the United States. Where your personal data was obtained
          through a third party, any cross-border transfers were carried out
          pursuant to appropriate safeguards that third party has applied. Where
          you provide information directly to us, you are knowingly consenting
          to transferring your data to the United States, where it will remain
          (well-protected) until you ask for it back.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          According to the European Commission, the United States does not, as a
          country, apply adequate levels of protection to personal data, meaning
          they are dissatisfied with U.S. laws and regulations governing data
          privacy and security and the U.S. government’s actions concerning and
          potential unauthorized access to personal data. This means that
          processing of your personal data in the United States is subject to
          fewer protections than in Europe, which could increase the risk that
          your personal data is accessed, used, or disclosed by unauthorized
          parties. In any event, EcomHolicGroup remains committed to data
          privacy and security and intends to protect your personal data
          strongly regardless of where you or your data reside.
        </p>
        <h2 style={{ fontWeight: 700 }}>Data Security</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup secures your personal information from unauthorized
          access, use, or disclosure using appropriate technical and
          organizational measures ensuring a level of security appropriate to
          the risk. To the extent possible, we work to ensure that our service
          providers (the third parties described above under “Disclosures to
          Third Parties”) apply similarly stringent security measures.
        </p>
        <h2 style={{ fontWeight: 700 }}>Children and Minors</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          EcomHolicGroup does not knowingly process or sell personal data from
          children under the age of sixteen, nor are our products or services
          directed at minors.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          If you are under the age of thirteen, you must ask your parent or
          guardian for permission to use this website.
        </p>
        <h2 style={{ fontWeight: 700 }}>Your Privacy Rights </h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          We respect your privacy and will do our best to honor your privacy
          rights under applicable laws (to the extent practicable).
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Depending on where you live, you may have specific rights to control
          our processing of your personal data. These rights include:
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Know: You may have the right to request information about the
          processing of your personal data if you think it is missing from this
          Privacy Policy. You may also have the right to know what specific
          pieces of personal data of yours we process.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Access: You may have the right to access your personal data
          that we process.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Rectification: You may have the right to request that we fix
          errors or omissions in your personal data that we process.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Deletion: Also known as the “right to erasure” or the “right
          to be forgotten,” you may have the right to have us delete your
          personal data.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Object: You may have the right to object to our processing of
          your personal data or to stop it altogether.
        </p>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Restrict Processing: You may have the right to limit the ways
          in which we process your personal data.
        </p>
        <h2 style={{ fontWeight: 700 }}>CONSENT</h2>
        <p style={{ fontSize: 13, fontWeight: 500 }}>
          Right to Data Portability: You may have the right to request a
          portable version of your personal data, either to be given to you or
          an alternative provider.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          Right to Non-Discrimination: We will not treat you unfairly as a
          result of your choice to exercise any of the above rights.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          Some of these rights are subject to prerequisites for their exercise.
          We will fill you in on those prerequisites should you seek to exercise
          one of these rights.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          You may exercise these rights by contacting us through one of the
          methods set forth below under “Contact Information.” Upon receiving
          your request, we may take certain actions to verify your identity
          before acting on your request. While those steps depend on the nature
          of your request, we will usually use your personal data in our
          possession to verify your identity.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          If you are a California resident, you may be able to appoint an
          authorized agent to exercise your privacy rights on your behalf. Where
          we receive a request from an authorized agent, we may take actions to
          verify that the agent is appropriately authorized to act on your
          behalf, including by asking for written proof of authorization or
          confirming directly with you.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          You should reach out to us right away if you have concerns with our
          processing of your personal data or believe we have violated this
          Privacy Policy or applicable law or regulation. This does not prevent
          you from filing complaints concerning alleged violations with
          appropriate government agencies, including, if you live in Europe,
          your local supervisory authority.
        </p>
        <h2 style={{ fontWeight: 700 }}>Changes to the Privacy Policy</h2>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          This Privacy Policy is current as of November 18, 2020.
        </p>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          EcomHolicGroup may update this Privacy Policy from time to time to
          reflect company and customer feedback. Because we cannot always notify
          you of these changes, we encourage you to review this Privacy Policy
          periodically to be informed of how EcomHolicGroup is processing your
          personal data.
        </p>
        <h2 style={{ fontWeight: 700 }}>Contacting EcomHolicGroup</h2>
        <p style={{ fontSize: 14, fontWeight: 700 }}>
          EcomHolicGroup welcomes your questions or comments regarding this
          Privacy Policy. If you have questions concerning this Privacy Policy,
          if you believe that EcomHolicGroup has not adhered to this Privacy
          Policy, or if you wish to exercise one of your privacy rights, please
          contact EcomHolicGroup at:
        </p>
        <h2 style={{ fontWeight: 700 }}>EcomHolicGroup, Inc</h2>
        <p style={{ fontSize: 14, fontWeight: 700 }}>Việt Nam, Hà Nội</p>
      </div>
    </div>
  )
}
