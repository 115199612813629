import React, { useState, useEffect } from 'react'
import styles from './dashboard.module.scss'

import ChartBar from 'react-google-charts'
import Chart from 'components/chart-page/ChartPage'
import { useDispatch } from 'react-redux'
import { BarChart } from 'react-chartkick'
import 'chartkick/chart.js'
import moment from 'moment'
import parse from 'html-react-parser'

import {
  getChartRevenue,
  getChartStatus,
  getChartTopSelling,
  getSummary,
  getLastNews,
  getLastProductTypes,
} from 'apis/dashboard'
import { getSites } from 'apis/sites'

//components antd
import {
  Select,
  DatePicker,
  Row,
  Col,
  Skeleton,
  Spin,
  Space,
  Modal,
  Button,
} from 'antd'

//icons antd
import { InfoCircleOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker
const { Option } = Select
const Dashboard = () => {
  const dispatch = useDispatch()

  const [loadingChart, setLoadingChart] = useState(false)
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [loadingTopSelling, setLoadingTopSelling] = useState(false)
  const [loadingStatusOrder, setLoadingStatusOrder] = useState(false)
  const [loadingProductType, setLoadingProductType] = useState(false)
  const [loadingNew, setLoadingNew] = useState(false)

  const [summary, setSummary] = useState({})
  const [paramsFilter, setParamsFilter] = useState({
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
  })
  const [productTypes, setProductTypes] = useState([])
  const [news, setNews] = useState([])

  const dateFormat = 'YYYY-MM-DD'

  const ModalNew = ({ objectNew }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          onClick={toggle}
          style={{
            maxWidth: 'max-content',
            fontSize: '1rem',
            fontWeight: 500,
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': '1',
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box',
          }}>
          {objectNew.titleNew}
        </div>
        <Modal
          width="100%"
          footer={
            <Row justify="end">
              <Button onClick={toggle}>Cancel</Button>
            </Row>
          }
          visible={visible}
          onCancel={toggle}
          title={
            <div
              style={{
                maxWidth: 'max-content',
                fontSize: '1rem',
                fontWeight: 500,
                cursor: 'pointer',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-line-clamp': '1',
                '-webkit-box-orient': 'vertical',
                display: '-webkit-box',
              }}>
              {objectNew.titleNew}
            </div>
          }>
          {objectNew.descriptionNew && parse(objectNew.descriptionNew)}
        </Modal>
      </>
    )
  }

  const getSummaryData = async (params) => {
    try {
      setLoadingSummary(true)
      const res = await getSummary(params)
      if (res.status === 200) setSummary(res.data.data)
      setLoadingSummary(false)
    } catch (error) {
      setLoadingSummary(false)
    }
  }

  const getNews = async (params) => {
    try {
      setLoadingNew(true)
      const res = await getLastNews(params)
      console.log(res)
      if (res.status === 200) setNews(res.data.data)
      setLoadingNew(false)
    } catch (error) {
      setLoadingNew(false)
    }
  }

  const getLatestProductType = async (params) => {
    try {
      setLoadingProductType(true)
      const res = await getLastProductTypes(params)
      if (res.status === 200) setProductTypes(res.data.data)
      setLoadingProductType(false)
    } catch (error) {
      setLoadingProductType(false)
    }
  }

  const [chartRevenue, setChartRevenue] = useState([])
  const getChartRevenueData = async () => {
    try {
      setLoadingChart(true)
      const res = await getChartRevenue()
      if (res.status === 200) setChartRevenue(res.data.data)
      setLoadingChart(false)
    } catch (error) {
      setLoadingChart(false)
      console.log(error)
    }
  }

  const [chartTopSelling, setChartTopSelling] = useState([])
  const getChartTopSellingData = async () => {
    try {
      setLoadingTopSelling(true)
      const res = await getChartTopSelling()
      if (res.status === 200) setChartTopSelling(res.data.data)
      setLoadingTopSelling(false)
    } catch (error) {
      setLoadingTopSelling(false)
      console.log(error)
    }
  }

  const [sites, setSites] = useState([])
  const getSitesData = async () => {
    try {
      const res = await getSites()
      if (res.status === 200) setSites(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  var result = chartTopSelling.map((values, index) => {
    return [values.name, values.amount]
  })

  const [chartStatus, setChartStatus] = useState([])
  const filterChartStatus = async (params) => {
    try {
      setLoadingStatusOrder(true)
      const res = await getChartStatus(params)
      if (res.status === 200) {
        const dataStatus = res.data.data.map((status) => [
          `${status[0]}: ${status[1]}`,
          +status[1],
        ])
        dataStatus.unshift(['Task', 'Hours per Day'])
        setChartStatus(dataStatus)
      }
      setLoadingStatusOrder(false)
    } catch (error) {
      setLoadingStatusOrder(false)
      console.log(error)
    }
  }

  useEffect(() => {
    filterChartStatus({ ...paramsFilter })
    getSummaryData({ ...paramsFilter })
    getLatestProductType({ ...paramsFilter })
    getNews({ ...paramsFilter })
    getChartTopSellingData()
    getSitesData()
    getChartRevenueData()
  }, [])

  return (
    <div className={styles['dashboard_manager']}>
      <Row style={{ width: '100%', marginBottom: 30, marginTop: 7 }}>
        <Space size="middle">
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
            }}
            defaultValue={[
              moment(new Date(), 'YYYY-MM-DD'),
              moment(new Date(), 'YYYY-MM-DD'),
            ]}
            format={dateFormat}
            style={{ width: 300 }}
            onChange={(date, dateString) => {
              if (date) {
                paramsFilter.startDate = dateString[0]
                paramsFilter.endDate = dateString[1]
              } else {
                delete paramsFilter.startDate
                delete paramsFilter.endDate
              }
              getSummaryData({ ...paramsFilter })
              getNews({ ...paramsFilter })
              getLatestProductType({ ...paramsFilter })
              filterChartStatus({ ...paramsFilter })
              setParamsFilter({ ...paramsFilter })
            }}
          />
          <Select
            notFoundContent={!sites.length ? <Spin size="small" /> : null}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: 300 }}
            placeholder="Choose site"
            onChange={(value) => {
              if (!value) delete paramsFilter.id_site
              else paramsFilter.id_site = value

              getSummaryData({ ...paramsFilter })
              getNews({ ...paramsFilter })
              getLatestProductType({ ...paramsFilter })
              filterChartStatus({ ...paramsFilter })
              setParamsFilter({ ...paramsFilter })
            }}>
            {sites.map((values, index) => {
              return <Option value={values.site_name}>{values.name}</Option>
            })}
          </Select>
          <Select
            allowClear
            style={{ width: 300 }}
            placeholder="Choose platform"
            onChange={(value) => {
              if (!value) delete paramsFilter.platform
              else paramsFilter.platform = value

              getSummaryData({ ...paramsFilter })
              getNews({ ...paramsFilter })
              getLatestProductType({ ...paramsFilter })
              filterChartStatus({ ...paramsFilter })
              setParamsFilter({ ...paramsFilter })
            }}>
            <Option value="Shopbase">Shopbase</Option>
            <Option value="Shopify">Shopify</Option>
            <Option value="Woo">Woo</Option>
            <Option value="Etsy">Etsy</Option>
            <Option value="Amazon">Amazon</Option>
          </Select>
        </Space>
      </Row>
      <div className={styles['dashboard_manager_balance']}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              width: '50%',
              padding: '0 10px 10px 10px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                borderBottom: '1px solid rgb(204, 189, 189)',
                paddingBottom: 5,
                alignItems: 'center',
                fontSize: '1rem',
                fontWeight: 600,
              }}>
              <div>5 latest product type</div>
            </div>
            {loadingProductType ? (
              <Skeleton active paragraph={{ rows: 5 }} />
            ) : (
              productTypes.map((e) => (
                <div style={{ fontWeight: 500, fontSize: 15, marginTop: 7 }}>
                  {e.title} created{' '}
                  {moment(e.dateCreated).format('DD-MM-YYYY HH:mm:ss')}
                </div>
              ))
            )}
          </div>
          <div style={{ width: '50%', padding: '0 10px 10px 10px' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                borderBottom: '1px solid rgb(204, 189, 189)',
                paddingBottom: 5,
                alignItems: 'center',
                fontSize: '1rem',
                fontWeight: 600,
              }}>
              <div>News</div>
            </div>
            {loadingNew ? (
              <Skeleton active paragraph={{ rows: 5 }} />
            ) : (
              <div>
                {news.map((e) => (
                  <ModalNew objectNew={e} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles['dashboard_manager_revenue']}>
        {loadingSummary ? (
          <Skeleton active paragraph={{ rows: 4 }} />
        ) : (
          <>
            <Row style={{ width: '100%' }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <h2>Total Order/Item</h2>
                <h3>
                  {summary.sumOrder || 0}/{summary.sumoderItems || 0}
                </h3>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                  borderRight: '1px solid rgb(204, 189, 189)',
                  borderLeft: '1px solid rgb(204, 189, 189)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <h2>Balance</h2>
                <h3>{summary.sumBalance || 0}</h3>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={8}
                xl={8}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <h2>Base Cost</h2>
                <h3>
                  ${(summary.sumRevenue && summary.sumRevenue.toFixed(2)) || 0}
                </h3>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className={styles['dashboard_manager_revenue']}>
        {loadingChart ? (
          <Skeleton active paragraph={{ rows: 12 }} />
        ) : (
          <>
            <div className={styles['dashboard_manager_revenue_title']}>
              <div>Total Order/Item Weekly</div>
              <InfoCircleOutlined />
            </div>
            <Chart chartOrders={chartRevenue} />
          </>
        )}
      </div>

      <div className={styles['dashboard_manager_bottom']}>
        <Row className={styles['dashboard_manager_bottom_row']}>
          <Col
            className={styles['dashboard_manager_bottom_row_col']}
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}>
            {loadingTopSelling ? (
              <Skeleton active paragraph={{ rows: 12 }} />
            ) : (
              <div
                className={styles['dashboard_manager_bottom_row_col_parent']}>
                <div
                  className={
                    styles['dashboard_manager_bottom_row_col_parent_top']
                  }>
                  <div>Top 10 Best Selling Products</div>
                  <div>
                    <InfoCircleOutlined />
                  </div>
                </div>
                <div
                  className={
                    styles['dashboard_manager_bottom_row_col_parent_bottom']
                  }>
                  <div
                    className={
                      styles[
                        'dashboard_manager_bottom_row_col_parent_bottom_chartbar'
                      ]
                    }>
                    <BarChart width="100%" height="25rem" data={result} />
                  </div>
                </div>
              </div>
            )}
          </Col>

          <Col
            className={styles['dashboard_manager_bottom_row_col']}
            xs={24}
            sm={24}
            md={24}
            lg={11}
            xl={11}>
            {loadingStatusOrder ? (
              <Skeleton active paragraph={{ rows: 12 }} />
            ) : (
              <div
                className={styles['dashboard_manager_bottom_row_col_parent']}>
                <div
                  className={
                    styles['dashboard_manager_bottom_row_col_parent_top']
                  }>
                  <div>Order Status Allocation</div>
                  <div>
                    <InfoCircleOutlined />
                  </div>
                </div>
                <div
                  className={
                    styles[
                      'dashboard_manager_bottom_row_col_parent_chart_bottom'
                    ]
                  }
                  style={{ overflow: 'hidden' }}>
                  <ChartBar
                    style={{ width: '100%', height: 300, overflow: 'hidden' }}
                    chartType="PieChart"
                    data={chartStatus}
                    options={{
                      is3D: true,
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Dashboard
