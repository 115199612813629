import React, { useState, useEffect } from 'react'
import styles from './ui.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ACTION, ROUTES, PERMISSIONS, VERSION_APP } from 'consts'
import Permission from 'components/permission'
import parse from 'html-react-parser'

//components antd
import { Layout, Menu, Popover, notification } from 'antd'

//icons antd
import {
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  DashboardOutlined,
  BellOutlined,
  EyeOutlined,
  ChromeOutlined,
  AppstoreOutlined,
  UsergroupAddOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  PaperClipOutlined,
} from '@ant-design/icons'

import { Link, useRouteMatch, useLocation } from 'react-router-dom'

import { newsAPI, newsAPIIsSeenAction } from 'actions/news/news'

//apis
import { getAllNew, isSeenNews } from 'apis/news'

const { Sider, Content } = Layout
export default function UI(props) {
  const newsReducers = useSelector((state) => state.news)
  const location = useLocation()
  const [balance, setBalance] = useState({})
  const [username, setUsername] = useState('')
  const dataUser = useSelector((state) => state.login.dataUser)
  const settingApp = useSelector((state) => state.setting)

  const routeMatch = useRouteMatch()
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)

  const rootSubmenuKeys = ['order', 'resolution', 'setting', 'feed']
  const [openKeys, setOpenKeys] = useState([])

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      localStorage.setItem('openKey', latestOpenKey)
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const toggle = () => setCollapsed(!collapsed)

  const onClickLogout = () => {
    let tmp = localStorage.DisputeColumns
    localStorage.clear()
    if (tmp) localStorage.setItem('DisputeColumns', tmp)
  }

  const openNotification = () => {
    notification.success({
      message: 'Success',
      description: 'Update news successfully!!!',
    })
  }
  const openNotificationError = () => {
    notification.error({
      message: 'Error',
      description: 'Update news failed!!!',
    })
  }
  const onClickIsSeen = async () => {
    var _id = []
    newsReducers.forEach((values, index) => {
      _id.push(values._id)
    })

    try {
      dispatch({ type: ACTION.LOADING, data: true })
      var username = JSON.parse(localStorage.getItem('username'))
      var object = {
        listNews: _id,
        idUser: username,
      }
      var actions = newsAPIIsSeenAction(newsReducers)
      dispatch(actions)
      const res = await isSeenNews(object)

      if (res.status === 200) {
        await getNewsData()
        openNotification()
        // setSelectedRowKeys([]);
      } else {
        openNotificationError()
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      openNotificationError()
    }
  }
  const contentBell = (
    <div className={styles['bell']}>
      {newsReducers.length > 0 ? (
        <div className={styles['news_show']}>
          {newsReducers.map((values, index) => {
            return (
              <div className={styles['bell_item']}>
                <div className={styles['bell_date']}>
                  <div className={styles['bell_date_username']}>
                    {values.titleNew}
                  </div>
                  <div style={{ width: '7rem' }}>{values.createdDate}</div>
                </div>
                <div className={styles['bell_content']}>
                  {values.descriptionNew && parse(values.descriptionNew)}
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '13.5rem',
          }}>
          <div>
            <BellOutlined style={{ fontSize: '3.5rem', color: 'grey' }} />
          </div>
          <div style={{ margin: '0.5rem 0', color: 'grey' }}>No news yet</div>
          <div style={{ color: 'grey' }}>
            The news you receive will be displayed here
          </div>
        </div>
      )}

      {newsReducers.length > 0 ? (
        <div onClick={onClickIsSeen} className={styles['bell_bottom']}>
          <div>
            <EyeOutlined />
          </div>
          <div>Watched</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
  const content = (
    <div className={styles['popoper']}>
      <Link to="/profile" className={styles['popoper_parent']}>
        <div>
          <UserOutlined />
        </div>
        <div>Account</div>
      </Link>
      <Link to="/login" onClick={onClickLogout}>
        <div className={styles['popoper_parent_bottom']}>
          <div>
            <LogoutOutlined />
          </div>
          <div>Logout</div>
        </div>
      </Link>
    </div>
  )

  const MENUS = [
    {
      path: ROUTES.DASHBOARD,
      title: 'Dashboard',
      permissions: [PERMISSIONS.view_dashboard],
      icon: <DashboardOutlined />,
    },
    {
      path: 'order',
      title: 'Order',
      permissions: [
        PERMISSIONS.view_order,
        PERMISSIONS.view_tracking_order,
        PERMISSIONS.view_order_archive,
      ],
      icon: <ShoppingCartOutlined />,
      menuItems: [
        {
          path: ROUTES.ORDERS,
          title: 'Orders',
          permissions: [PERMISSIONS.view_order],
        },
        {
          path: ROUTES.ORDERS_TRACKING,
          title: 'Orders Tracking',
          permissions: [PERMISSIONS.view_tracking_order],
        },
        {
          path: ROUTES.ORDER_ARCHIVE,
          title: 'Orders Archive',
          permissions: [PERMISSIONS.view_order_archive],
        },
      ],
    },
    {
      path: ROUTES.PRODUCTS,
      title: 'Add new product',
      permissions: [PERMISSIONS.view_product],
      icon: <AppstoreOutlined />,
    },
    {
      path: ROUTES.SITES,
      title: 'Sites',
      permissions: [PERMISSIONS.view_sites],
      icon: <ChromeOutlined />,
    },
    {
      path: ROUTES.TRACKING_LOG,
      title: 'Tracking log',
      permissions: [PERMISSIONS.view_tracking_log],
      icon: <PaperClipOutlined />,
    },
    {
      path: ROUTES.USERS,
      title: 'User management',
      permissions: [PERMISSIONS.view_user_management],
      icon: <UsergroupAddOutlined />,
    },
    // {
    //   path: ROUTES.LOGS,
    //   title: 'Logs App',
    //   permissions: [PERMISSIONS.view_logs_app],
    //   icon: <SlidersOutlined />,
    // },
    {
      path: 'setting',
      title: 'Setting',
      permissions: [PERMISSIONS.view_app_setting],
      icon: <SettingOutlined />,
      menuItems: [
        {
          path: ROUTES.SETTING_CATEGORIES,
          title: 'Categories',
          permissions: [PERMISSIONS.view_category],
        },
        {
          path: ROUTES.SETTING_CARRIES,
          title: 'Carriers',
          permissions: [PERMISSIONS.view_carrier],
        },
        {
          path: ROUTES.SETTING_SELLER_LEVELS,
          title: 'Seller levels',
          permissions: [PERMISSIONS.view_seller_level],
        },
        {
          path: ROUTES.SETTING_NEWS,
          title: 'News',
          permissions: [PERMISSIONS.view_news],
        },
        {
          path: ROUTES.SETTING_CONTENT,
          title: 'Content Setting',
          permissions: [PERMISSIONS.view_content_setting],
        },
        {
          path: ROUTES.PRODUCT_TYPES,
          title: 'Products Type',
          permissions: [PERMISSIONS.view_product_type],
        },
        {
          path: ROUTES.SETTING_APP,
          title: 'Setting app',
          permissions: [],
        },
      ],
    },
  ]

  const renderMenuItem = (_menu) => (
    <Permission permissions={_menu.permissions} key={_menu.path}>
      {_menu.menuItems ? (
        <Menu.SubMenu
          key={_menu.path}
          icon={_menu.icon}
          title={<span style={{ fontWeight: 500 }}>{_menu.title}</span>}>
          {_menu.menuItems.map((e) => (
            <Permission permissions={e.permissions}>
              <Menu.Item
                key={e.path}
                icon={e.icon}
                style={{
                  backgroundColor: location.pathname === e.path && '#1890FF',
                }}>
                <Link
                  to={e.path}
                  style={{
                    fontWeight: 500,
                    color: location.pathname === e.path && 'white',
                  }}>
                  {e.title}
                </Link>
              </Menu.Item>
            </Permission>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item icon={_menu.icon} key={_menu.path}>
          <Link to={_menu.path} style={{ fontWeight: 500 }}>
            {_menu.title}
          </Link>
        </Menu.Item>
      )}
    </Permission>
  )

  useEffect(() => {
    if (localStorage.getItem('openKey'))
      setOpenKeys([localStorage.getItem('openKey')])
  }, [])

  const getNewsData = async () => {
    try {
      const res = await getAllNew()
      if (res.status === 200) {
        // setDataServer(res.data.data);
        var actions = newsAPI(res.data.data)
        dispatch(actions)
      }
    } catch (error) {}
  }
  useEffect(() => {
    //Saller và supplier thấy đc mục balance
    //Admin ko thấy đc
    if (
      (localStorage.getItem('role') &&
        JSON.parse(localStorage.getItem('role')) === 'Supplier') ||
      JSON.parse(localStorage.getItem('role')) === 'Seller'
    ) {
      MENUS.push({
        path: ROUTES.BALANCE,
        title: 'Balance',
        permissions: [],
        icon: <DollarOutlined />,
      })
    }

    //Saller và supplier sẽ ko thấy đc mục menu transaction
    //Admin sẽ thấy đc
    if (
      localStorage.getItem('role') &&
      JSON.parse(localStorage.getItem('role')) === 'Admin'
    )
      MENUS.push({
        path: 'balance',
        title: 'Transaction',
        permissions: [],
        icon: <DollarOutlined />,
        menuItems: [
          {
            path: ROUTES.SELLER_TRANSACTION,
            title: 'Seller transaction',
            permissions: [],
          },
          {
            path: ROUTES.SUPPLIER_TRANSACTION,
            title: 'Supplier transaction',
            permissions: [],
          },
        ],
      })
  }, [])

  useEffect(() => {
    var data = JSON.parse(localStorage.getItem('balance'))
    var username = JSON.parse(localStorage.getItem('username'))
    setUsername(username)
    setBalance(data)
    getNewsData()
  }, [])
  return (
    <Layout className={styles['site-layout-background']}>
      <Sider
        className={styles['site-layout-background']}
        trigger={null}
        collapsible
        collapsed={collapsed}>
        <div
          style={{
            display: collapsed ? 'none' : '',
          }}>
          <div className={styles['logo_left_icon']}>
            <img
              className={styles['logo_left']}
              src={settingApp.link_logo || ''}
              alt=""
            />
          </div>
        </div>
        <div
          className={
            collapsed ? styles['level_left_active'] : styles['level_left']
          }>
          Your level
        </div>
        <div
          className={
            collapsed ? styles['gold_left_active'] : styles['gold_left']
          }>
          {dataUser.level && dataUser.level.name}
        </div>
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          className={styles['site-layout-background']}
          theme="dark"
          mode="inline"
          selectedKeys={routeMatch.path}>
          {MENUS.map(renderMenuItem)}
          <Menu.Item onClick={onClickLogout} key="9" icon={<LogoutOutlined />}>
            <Link to="/login">Logout</Link>
          </Menu.Item>
          <Menu.Item key="10">Version: {VERSION_APP}</Menu.Item>
        </Menu>
      </Sider>
      <Layout className={styles['site-layout']}>
        <div className={styles['header_custom']}>
          <div className={styles['header_custom_toggle']}>
            <MenuFoldOutlined
              style={{ fontSize: '1.25rem', margin: '0 0' }}
              onClick={toggle}
            />
          </div>
          <div className={styles['header_custom_right']}>
            <div className={styles['header_custom_right_question']}>
              <QuestionCircleOutlined style={{ fontSize: '1rem' }} />
            </div>
            <Popover content={contentBell} placement="bottom">
              <div className={styles['header_custom_right_question']}>
                <a>
                  <BellOutlined
                    style={{ fontSize: '1.25rem', margin: '0 1rem' }}
                  />
                  <span className={styles['cart__total']}>
                    {newsReducers.length}
                  </span>
                </a>
              </div>
            </Popover>
            <Popover content={content}>
              <div className={styles['header_custom_right_users']}>
                <div>
                  <img
                    className={styles['header_custom_right_users_img']}
                    src="https://ecomfullfillment.s3.ap-southeast-1.amazonaws.com/1625138766309_ecomfullfillment.png"
                    alt=""
                  />
                </div>
                <div className={styles['header_custom_right_users_child']}>
                  <div
                    className={
                      styles['header_custom_right_users_child_username']
                    }
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                    {username}
                  </div>
                  <div
                    className={
                      styles['header_custom_right_users_child_username_money']
                    }>
                    ${balance.available_amount}
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  )
}
