import styles from './orders-tracking-detail.module.scss'
import React, { useState, useEffect, useRef } from 'react'

import {
  Row,
  Col,
  Drawer,
  Table,
  Button,
  notification,
  Timeline,
  Modal,
  Space,
  Form,
  Input,
  Upload,
  Checkbox,
  Popover,
  Popconfirm,
  BackTop,
  Slider,
  Select,
  InputNumber,
  Spin,
} from 'antd'

import {
  FileZipOutlined,
  ExceptionOutlined,
  HomeOutlined,
  UserOutlined,
  EditOutlined,
  ShoppingCartOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExportOutlined,
  FormOutlined,
  CloseOutlined,
} from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import { ACTION, PERMISSIONS, ROUTES, STATUS_ORDER } from 'consts'
import { useHistory, useLocation } from 'react-router-dom'

//components
import Permission from 'components/permission'

//apis
import {
  updateOrders,
  updateStatusOrders,
  resendOrder,
  repairOrder,
  rebuildOrder,
} from 'apis/order'
import { filterOrderDetail } from 'apis/ordersTracking'
import { getAllProductType } from 'apis/product'
import { filterRoleUsers } from 'apis/users'
import { uploadFiles } from 'apis/upload'

const { Option } = Select
export default function OrdersTrackingDetail() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [formUpdate] = Form.useForm()
  const typingTimeoutRef = useRef(null)

  const [usersSupplier, setUsersSupplier] = useState([]) //dung de filter, o more filter
  const [params, setParams] = useState({})
  const [ordersDetail, setOrdersDetail] = useState()
  const [arrayFulfill, setArrayFulfill] = useState([])
  const [arrayUnsign, setArrayUnsign] = useState([])
  const [trackingOrders, setTrackingOrders] = useState([])
  const [allProductType, setAllProductType] = useState([])

  const columnsProducts = [
    {
      title: 'Actions',
      key: 'upload',
      width: 100,
    },
    {
      title: 'Information',
      key: 'information',
    },
    {
      title: 'Additional file',
      width: 275,
      key: 'additionalFile',
    },
    {
      title: 'Mockup',
      width: 275,
      key: 'mockup',
    },
  ]

  const ContentZoomImage = (data) => {
    const [valueBox, setValueBox] = useState(300)
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <img
          src={data}
          style={{ width: valueBox, height: valueBox, objectFit: 'contain' }}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        <Slider
          defaultValue={300}
          min={100}
          max={1000}
          onChange={(value) => setValueBox(value)}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    )
  }

  const _resetListOrder = async () => {
    try {
      const _params = params.slug.split('_')
      const res = await filterOrderDetail({
        id_order: _params[1],
        key_design: _params[0],
      })
      console.log(res)
      if (res.status === 200) {
        setOrdersDetail(res.data.data)
        setArrayFulfill(res.data.data.list_product_fulfill)
        setArrayUnsign(res.data.data.list_product_unsign)

        let trackings = []
        res.data.data.tracking_order.map((e) => {
          if (trackings.length === 0)
            trackings.push({ date: e.date, listTracking: [e] })
          else {
            const findTracking = trackings.findIndex((v) => v.date === e.date)
            if (findTracking !== -1)
              trackings[findTracking].listTracking.push(e)
            else trackings.push({ date: e.date, listTracking: [e] })
          }
        })
        setTrackingOrders(trackings)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _resendOrder = async () => {
    try {
      dispatch({
        type: ACTION.LOADING,
        data: true,
      })

      const body = { key: ordersDetail.key }

      const res = await resendOrder(body)
      console.log(res)

      if (res.status === 200) {
        await _getOrders(params)
        notification.success({ message: 'Resend success!' })
      } else notification.error({ message: 'Resend error, please try again' })

      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  const _getOrders = async (params) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const _params = params.slug.split('_')
      const res = await filterOrderDetail({
        id_order: _params[1],
        key_design: _params[0],
      })
      console.log(res)
      if (res.status === 200) {
        setOrdersDetail(res.data.data)
        setArrayFulfill(res.data.data.list_product_fulfill)
        setArrayUnsign(res.data.data.list_product_unsign)

        let trackings = []
        res.data.data.tracking_order &&
          res.data.data.tracking_order.map((e) => {
            if (trackings.length === 0)
              trackings.push({ date: e.date, listTracking: [e] })
            else {
              const findTracking = trackings.findIndex((v) => v.date === e.date)
              if (findTracking !== -1)
                trackings[findTracking].listTracking.push(e)
              else trackings.push({ date: e.date, listTracking: [e] })
            }
          })
        setTrackingOrders(trackings)
      } else {
        notification.error({ message: 'System error, please try again!' })

        history.push(ROUTES.ORDERS)
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      notification.error({ message: 'System error, please try again!' })
      dispatch({ type: ACTION.LOADING, data: false })
      history.push(ROUTES.ORDERS)
    }
  }

  const updateProductInOrderPendingDesign = async (object) => {
    try {
      dispatch({
        type: ACTION.LOADING,
        data: true,
      })
      console.log(JSON.stringify(object))
      const res = await updateOrders(object)
      console.log(res)
      if (res.status === 200)
        notification.success({ message: 'Update success!' })
      else notification.error({ message: 'Update failed, please try again!' })
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      if (res.status === 200) {
        _getOrders(params)
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  /*list modal update infomation table child in order pending design*/
  const ModalUpdateProductTypePendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={toggle}>
          {`Product type: ${
            productUnsign &&
            productUnsign.product_type &&
            productUnsign.product_type.title
          }`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Product type</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={
                productUnsign &&
                productUnsign.product_type &&
                productUnsign.product_type._id
              }
              onChange={async (value) => {
                const productType = allProductType.find((e) => e._id === value)
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (productType && indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].product_type =
                    productType
                  record.action = `edit product type by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (productType && indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].product_type =
                    productType
                  record.action = `edit product type by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {allProductType.map((e) => (
                <Option value={e._id}>{e.title}</Option>
              ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateCarrierPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={() => {
            //check product type empty
            if (!productUnsign.product_type) {
              notification.error({ message: 'Please update product type' })
              return
            }
            toggle()
          }}>
          {`Carrier: ${productUnsign.carrier}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Carriers</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={productUnsign.carrier}
              onChange={async (value) => {
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].carrier = value
                  record.action = `edit carriers by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].carrier =
                    value
                  record.action = `edit carriers by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {/* show list carrier từ product type của product unsign */}
              {productUnsign.product_type &&
                productUnsign.product_type.listcarrier.map((e) => (
                  <Option value={e.name}>{e.name}</Option>
                ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateSupplierPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={() => {
            //check product type empty
            if (!productUnsign.product_type) {
              notification.error({ message: 'Please update product type' })
              return
            }
            toggle()
          }}>
          {`Supplier: ${productUnsign.supplier}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          footer={null}>
          <Col>
            <h3>Suppliers</h3>
            <Select
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              style={{ width: '100%' }}
              defaultValue={productUnsign.supplier}
              onChange={async (value) => {
                const indexUpdate = record.list_product_unsign.findIndex(
                  (v) => v.id === productUnsign.id
                )
                if (indexUpdate !== -1) {
                  record.list_product_unsign[indexUpdate].supplier = value
                  record.action = `edit suppliers by item ${record.list_product_unsign[indexUpdate].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }

                const indexUpdateFulfill =
                  record.list_product_fulfill.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                if (indexUpdateFulfill !== -1) {
                  record.list_product_fulfill[indexUpdateFulfill].supplier =
                    value
                  record.action = `edit suppliers by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
                  await updateProductInOrderPendingDesign(record)
                  toggle()
                }
              }}>
              {/* show list supplier từ product type của product unsign */}
              {productUnsign.product_type &&
                productUnsign.product_type.supplyBy.map((e) => (
                  <Option value={e}>{e}</Option>
                ))}
            </Select>
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateCustomInfoPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [listCustomInfo, setListCustomInfo] = useState(
      productUnsign.custom_info
    )
    const [isUpdate, setIsUpdate] = useState(false)

    return (
      <>
        <Row onClick={toggle} style={{ cursor: 'pointer' }}>
          Custom info:
        </Row>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            maxHeight: 400,
            overflowY: 'auto',
          }}
          onClick={toggle}>
          {productUnsign.custom_info.map((e) => {
            const RowName = () => (
              <Row style={{ marginLeft: 80 }}>
                Name - {typeof e.name === 'string' ? e.name : ''}
              </Row>
            )
            const RowValue = () => (
              <Row style={{ marginLeft: 80 }}>Value - {e.value || ''}</Row>
            )
            return (
              <Col style={{ marginTop: 7 }}>
                <RowName />
                <RowValue />
              </Col>
            )
          })}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={async () => {
            if (isUpdate) await _getOrders(params)
            toggle()
          }}
          onOk={async () => {
            const indexUpdate = record.list_product_unsign.findIndex(
              (v) => v.id === productUnsign.id
            )
            if (indexUpdate !== -1) {
              record.list_product_unsign[indexUpdate].custom_info =
                listCustomInfo
              record.action = `edit custom info name by item ${record.list_product_unsign[indexUpdate].id}`
            }

            const indexUpdateFulfill = record.list_product_fulfill.findIndex(
              (v) => v.id === productUnsign.id
            )
            if (indexUpdateFulfill !== -1) {
              record.list_product_fulfill[indexUpdateFulfill].custom_info =
                listCustomInfo
              record.action = `edit custom info name by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
            }

            await updateProductInOrderPendingDesign(record)
            toggle()
          }}>
          {listCustomInfo.map((e, index) => {
            const InputName = () => (
              <Col>
                <h4 style={{ marginBottom: 0 }}>Name</h4>
                <Input
                  placeholder="Input name"
                  defaultValue={e.name || ''}
                  onBlur={(e) => {
                    const listCustomInfoNew = [...listCustomInfo]
                    listCustomInfoNew[index].name = e.target.value

                    setListCustomInfo([...listCustomInfoNew])
                    setIsUpdate(true)
                  }}
                />
              </Col>
            )

            const InputValue = () => (
              <Col>
                <h4 style={{ marginBottom: 0 }}>Value</h4>
                <Input
                  placeholder="Input value"
                  defaultValue={e.value || ''}
                  onBlur={(e) => {
                    const listCustomInfoNew = [...listCustomInfo]
                    listCustomInfoNew[index].value = e.target.value

                    setListCustomInfo([...listCustomInfoNew])
                    setIsUpdate(true)
                  }}
                />
              </Col>
            )
            return (
              <Row justify="space-between" style={{ marginTop: 10 }}>
                <InputName />
                <Space>
                  <InputValue />
                  <CloseOutlined
                    style={{
                      color: 'red',
                      cursor: 'pointer',
                      fontSize: 13,
                    }}
                    onClick={() => {
                      const listCustomInfoNew = [...listCustomInfo]
                      listCustomInfoNew.splice(index, 1)
                      setListCustomInfo([...listCustomInfoNew])
                    }}
                  />
                </Space>
              </Row>
            )
          })}

          <Row justify="end" style={{ marginTop: 20 }}>
            <Button
              type="dashed"
              onClick={() => {
                const listCustomInfoNew = [...listCustomInfo]
                listCustomInfoNew.push({ name: '', value: '' })
                setListCustomInfo([...listCustomInfoNew])
                setIsUpdate(true)
              }}>
              Add
            </Button>
          </Row>
        </Modal>
      </>
    )
  }

  const ModalUpdateCustomNotePendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.custom_note || '')

    const updateCustomNote = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].custom_note = value
        record.action = `edit custom note by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].custom_note = value
        record.action = `edit custom note by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={toggle}>
          {`Custom note: ${productUnsign.custom_note || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateCustomNote}>
          <Col>
            <h4>Custom note</h4>
            <Input
              autoFocus
              onPressEnter={updateCustomNote}
              style={{ width: '100%' }}
              placeholder="input custom note"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateQuantityPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.quantity || '')

    const updateQuantity = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].quantity = value
        record.action = `edit quantity by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].quantity = value
        record.action = `edit quantity by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={toggle}>
          {`Quantity: ${productUnsign.quantity || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateQuantity}>
          <Col>
            <h4>Quantity</h4>
            <InputNumber
              autoFocus
              onPressEnter={updateQuantity}
              style={{ width: '100%' }}
              placeholder="input quantity"
              onChange={(value) => setValue(value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateRenameForSupPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.rename_for_Sup || '')

    const updateRename = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].rename_for_Sup = value
        record.action = `edit rename for sup by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }

      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].rename_for_Sup = value
        record.action = `edit rename for sup by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
            pointerEvents:
              JSON.parse(localStorage.getItem('role')) === 'Designer' && 'none',
          }}
          onClick={toggle}>
          {`SKU Custom: ${productUnsign.rename_for_Sup || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateRename}>
          <Col>
            <h4>SKU Custom</h4>
            <Input
              autoFocus
              onPressEnter={updateRename}
              style={{ width: '100%' }}
              placeholder="input sku custom"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalUpdateSkuPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)
    const [value, setValue] = useState(productUnsign.sku || '')

    const updateSku = async () => {
      const indexUpdate = record.list_product_unsign.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdate !== -1) {
        record.list_product_unsign[indexUpdate].sku = value
        record.action = `edit sku by item ${record.list_product_unsign[indexUpdate].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
      const indexUpdateFulfill = record.list_product_fulfill.findIndex(
        (v) => v.id === productUnsign.id
      )
      if (indexUpdateFulfill !== -1) {
        record.list_product_fulfill[indexUpdateFulfill].sku = value
        record.action = `edit rename for sup by item ${record.list_product_fulfill[indexUpdateFulfill].id}`
        await updateProductInOrderPendingDesign(record)
        toggle()
      }
    }

    return (
      <>
        <div
          style={{
            marginBottom: '0.5rem',
            cursor: 'pointer',
            color: '#0A46E6',
          }}
          onClick={toggle}>
          {`SKU: ${productUnsign.sku || ''}`}
        </div>
        <Modal
          title="Update order information"
          visible={visible}
          onCancel={toggle}
          onOk={updateSku}>
          <Col>
            <h3>Sku</h3>
            <Input
              autoFocus
              onPressEnter={updateSku}
              defaultValue={productUnsign.custom_note || ''}
              style={{ width: '100%' }}
              placeholder="input sku"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
          </Col>
        </Modal>
      </>
    )
  }
  const ModalUpdateInformationPendingDesign = ({ productUnsign, record }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [product_type, setProduct_type] = useState(
      productUnsign.product_type || null
    )
    return (
      <>
        <Permission permissions={[PERMISSIONS.update_product_in_order]}>
          <Button
            style={{
              width: '7.5rem',
              height: '2.5rem',
              display:
                JSON.parse(localStorage.getItem('role')) === 'Designer' &&
                'none',
            }}
            type="primary"
            onClick={toggle}>
            Update
          </Button>
        </Permission>
        <Modal
          title="Update order information"
          centered
          visible={visible}
          onOk={async () => {
            record.action = 'edit infomation'
            await updateProductInOrderPendingDesign(record)
            toggle()
          }}
          onCancel={() => {
            _getOrders(params)
            toggle()
          }}>
          <Col>
            <Col style={{ marginBottom: 10 }}>
              <div>SKU</div>
              <Input
                defaultValue={productUnsign.sku || ''}
                style={{ width: '100%' }}
                placeholder="Input sku"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].sku = value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[indexUpdateFulfill].sku = value
                  }
                }}
              />
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>Product type</div>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                placeholder="Choose product type"
                style={{ width: 250 }}
                defaultValue={
                  productUnsign &&
                  productUnsign.product_type &&
                  productUnsign.product_type._id
                }
                onChange={(value) => {
                  const productType = allProductType.find(
                    (e) => e._id === value
                  )
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (productType && indexUpdate !== -1) {
                    setProduct_type({ ...productType })
                    record.list_product_unsign[indexUpdate].product_type =
                      productType
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (productType && indexUpdateFulfill !== -1) {
                    setProduct_type({ ...productType })
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].product_type = productType
                  }
                }}>
                {allProductType.map((e) => (
                  <Option value={e._id}>{e.title}</Option>
                ))}
              </Select>
            </Col>

            <Col
              style={{
                margin: '15px 0px',
                display: !productUnsign.custom_info.length && 'none',
              }}>
              Custom info
              {productUnsign.custom_info.map((e, index) => {
                const InputName = () => (
                  <Col>
                    <h4 style={{ marginBottom: 0 }}>Name</h4>
                    <Input
                      placeholder="Input name"
                      defaultValue={e.name || ''}
                      onBlur={async (e) => {
                        const value = e.target.value
                        const indexUpdate =
                          record.list_product_unsign.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdate !== -1) {
                          record.list_product_unsign[indexUpdate].custom_info[
                            index
                          ].name = value
                        }

                        const indexUpdateFulfill =
                          record.list_product_fulfill.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdateFulfill !== -1) {
                          record.list_product_fulfill[
                            indexUpdateFulfill
                          ].custom_info[index].name = value
                        }
                      }}
                    />
                  </Col>
                )

                const InputValue = () => (
                  <Col>
                    <h4 style={{ marginBottom: 0 }}>Value</h4>
                    <Input
                      placeholder="Input value"
                      defaultValue={e.value || ''}
                      onBlur={async (e) => {
                        const value = e.target.value
                        const indexUpdate =
                          record.list_product_unsign.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdate !== -1) {
                          record.list_product_unsign[indexUpdate].custom_info[
                            index
                          ].value = value
                        }

                        const indexUpdateFulfill =
                          record.list_product_fulfill.findIndex(
                            (v) => v.id === productUnsign.id
                          )
                        if (indexUpdateFulfill !== -1) {
                          record.list_product_fulfill[
                            indexUpdateFulfill
                          ].custom_info[index].value = value
                        }
                      }}
                    />
                  </Col>
                )
                return (
                  <Row
                    justify="space-between"
                    style={{ marginBottom: 10, padding: '0 10px' }}>
                    <InputName />
                    <InputValue />
                  </Row>
                )
              })}
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>Custom note</div>
              <Input
                defaultValue={productUnsign.custom_note || ''}
                style={{ width: '100%' }}
                placeholder="Input custom note"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].custom_note = value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].custom_note = value
                  }
                }}
              />
            </Col>

            <Col style={{ marginBottom: 10 }}>
              <div>SKU custom</div>
              <Input
                defaultValue={productUnsign.rename_for_Sup || ''}
                style={{ width: '100%' }}
                placeholder="Input sku custom"
                onChange={(e) => {
                  const value = e.target.value
                  const indexUpdate = record.list_product_unsign.findIndex(
                    (v) => v.id === productUnsign.id
                  )
                  if (indexUpdate !== -1) {
                    record.list_product_unsign[indexUpdate].rename_for_Sup =
                      value
                  }

                  const indexUpdateFulfill =
                    record.list_product_fulfill.findIndex(
                      (v) => v.id === productUnsign.id
                    )
                  if (indexUpdateFulfill !== -1) {
                    record.list_product_fulfill[
                      indexUpdateFulfill
                    ].rename_for_Sup = value
                  }
                }}
              />
            </Col>

            {product_type && (
              <>
                <Col style={{ marginBottom: 10 }}>
                  <div>Suppliers</div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Choose supplier"
                    defaultValue={productUnsign.supplier || undefined}
                    onChange={(value) => {
                      const indexUpdate = record.list_product_unsign.findIndex(
                        (v) => v.id === productUnsign.id
                      )
                      if (indexUpdate !== -1) {
                        record.list_product_unsign[indexUpdate].supplier = value
                      }

                      const indexUpdateFulfill =
                        record.list_product_fulfill.findIndex(
                          (v) => v.id === productUnsign.id
                        )
                      if (indexUpdateFulfill !== -1) {
                        record.list_product_fulfill[
                          indexUpdateFulfill
                        ].supplier = value
                      }
                    }}
                    style={{ width: '100%' }}>
                    {product_type.supplyBy.map((e) => (
                      <Option value={e}>{e}</Option>
                    ))}
                  </Select>
                </Col>

                <Col style={{ marginBottom: 10 }}>
                  <div>Carriers</div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Choose carriers"
                    defaultValue={productUnsign.carrier || undefined}
                    onChange={(value) => {
                      const indexUpdate = record.list_product_unsign.findIndex(
                        (v) => v.id === productUnsign.id
                      )
                      if (indexUpdate !== -1) {
                        record.list_product_unsign[indexUpdate].carrier = value
                      }

                      const indexUpdateFulfill =
                        record.list_product_fulfill.findIndex(
                          (v) => v.id === productUnsign.id
                        )
                      if (indexUpdateFulfill !== -1) {
                        record.list_product_fulfill[
                          indexUpdateFulfill
                        ].carrier = value
                      }
                    }}
                    style={{ width: '100%' }}>
                    {product_type.listcarrier.map((e) => (
                      <Option value={e.name}>{e.name}</Option>
                    ))}
                  </Select>
                </Col>
              </>
            )}
          </Col>
        </Modal>
      </>
    )
  }
  /*list modal update infomation table child in order pending design*/

  const uploadMockupOrderPendingDesign = async (record, recordMini, files) => {
    try {
      //tham số files có thể chứa link ảnh hoặc chứa các file cần uploadd
      let arrayLinkImg = [] //lọc các link ảnh
      let isCheckUpload = false //check user upload hay remove
      let filesUpload = [] //loc item là file để upload
      files.map((e) => {
        if (e.originFileObj) {
          filesUpload.push(e.originFileObj)
          isCheckUpload = true
        } else arrayLinkImg.push(e.url)
      })

      const indexUploadUnsign = record.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      const indexUploadFulfill = record.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )

      //case upload
      if (isCheckUpload) {
        dispatch({
          type: ACTION.LOADING,
          data: true,
        })

        let listUrl = await uploadFiles(filesUpload)

        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].mockup_thumb &&
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.length
          )
            record.list_product_unsign[indexUploadUnsign].mockup_thumb = [
              ...record.list_product_unsign[indexUploadUnsign].mockup_thumb,
              ...listUrl,
            ]

          record.list_product_unsign[indexUploadUnsign].mockup = [
            ...record.list_product_unsign[indexUploadUnsign].mockup,
            ...listUrl,
          ]

          record.action = `Upload mockup by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrder(record)
        }

        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb &&
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.length
          )
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb = [
              ...record.list_product_fulfill[indexUploadFulfill].mockup_thumb,
              ...listUrl,
            ]

          record.list_product_fulfill[indexUploadFulfill].mockup = [
            ...record.list_product_fulfill[indexUploadFulfill].mockup,
            ...listUrl,
          ]

          record.action = `upload mockup by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrder(record)
        }
      } else {
        //case remove
        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].mockup_thumb &&
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.length
          ) {
            let indexRemoveList = []
            record.list_product_unsign[indexUploadUnsign].mockup_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_unsign[
              indexUploadUnsign
            ].mockup.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_unsign[indexUploadUnsign].mockup = [
              ...newPrintFiles,
            ]

            record.list_product_unsign[indexUploadUnsign].mockup_thumb =
              arrayLinkImg
          } else
            record.list_product_unsign[indexUploadUnsign].mockup = arrayLinkImg

          record.action = `Delete mockup by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrder(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb &&
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.length
          ) {
            let indexRemoveList = []
            record.list_product_fulfill[indexUploadFulfill].mockup_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_fulfill[
              indexUploadFulfill
            ].mockup.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_fulfill[indexUploadFulfill].mockup = [
              ...newPrintFiles,
            ]

            record.list_product_fulfill[indexUploadFulfill].mockup_thumb =
              arrayLinkImg
          } else
            record.list_product_fulfill[indexUploadFulfill].mockup =
              arrayLinkImg

          record.action = `Delete mockup by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrder(record)
        }
      }
    } catch (error) {
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      console.log(error)
      notification.error({ message: 'Upload failed, please try again!' })
    }
  }

  const onClickCancel = async (recordMini, recordMain) => {
    try {
      if (recordMini.status === 'cancel') {
        notification.error({ message: 'Status current is cancel!' })
        return
      }

      const indexProductUnsign = recordMain.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductUnsign !== -1) {
        recordMain.list_product_unsign[indexProductUnsign].status = 'cancel'
        recordMain.action = `cancel item ${recordMain.list_product_unsign[indexProductUnsign].id}`
      }

      const indexProductFulfill = recordMain.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductFulfill !== -1) {
        recordMain.list_product_fulfill[indexProductFulfill].status = 'cancel'
        recordMain.action = `cancel item ${recordMain.list_product_fulfill[indexProductFulfill].id}`
      }

      notification.open({
        key: 'cancel',
        message: `Updating status cancel by order ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })
      const res = await updateOrders(recordMain)
      console.log(res)
      if (res.status === 200) {
        await _resetListOrder()
        notification.open({
          key: 'cancel',
          message: 'Updating status cancel success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration: 2,
        })
      } else
        notification.open({
          key: 'cancel',
          message: 'Updating status cancel failed, please try again',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          duration: 2,
        })
    } catch (error) {
      console.log(error)
      notification.open({
        key: 'cancel',
        message: 'Updating status cancel failed, please try again',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        duration: 2,
      })
    }
  }

  const onClickApprove = async (recordMini, recordMain) => {
    try {
      if (recordMini.status === 'approve') {
        notification.error({ message: 'Status current is approve!' })
        return
      }

      const indexProductUnsign = recordMain.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductUnsign !== -1) {
        recordMain.list_product_unsign[indexProductUnsign].status = 'approve'
        recordMain.action = `approve item ${recordMain.list_product_unsign[indexProductUnsign].id}`
      }

      const indexProductFulfill = recordMain.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )
      if (indexProductFulfill !== -1) {
        recordMain.list_product_fulfill[indexProductFulfill].status = 'approve'
        recordMain.action = `approve item ${recordMain.list_product_fulfill[indexProductFulfill].id}`
      }

      notification.open({
        key: 'approve',
        message: `Updating status approve by order ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })
      const res = await updateOrders(recordMain)
      if (res.status === 200) {
        await _resetListOrder()
        notification.open({
          key: 'approve',
          message: 'Updating status approve success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration: 2,
        })
      } else
        notification.open({
          key: 'approve',
          message: 'Updating status approve failed, please try again',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          duration: 2,
        })
    } catch (error) {
      console.log(error)
      notification.open({
        key: 'approve',
        message: 'Updating status approve failed, please try again',
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
        duration: 2,
      })
    }
  }

  const onClickRebuild = async (recordMini, recordMain) => {
    const keyNotifi = 'pending'
    try {
      if (recordMini.status == 'pending') {
        notification.error({ message: 'Status current is pending!' })
        return
      }

      notification.open({
        key: keyNotifi,
        message: `Updating status pending by order ${recordMain.name}`,
        icon: <Spin />,
        duration: 0,
      })

      const body = { key: recordMain.key, product_id: recordMini.id }

      const res = await rebuildOrder(body)
      console.log(res)
      if (res.status === 200) {
        await _resetListOrder()
        notification.open({
          key: keyNotifi,
          message: 'Updating status pending success',
          icon: <CheckCircleOutlined style={{ color: 'green' }} />,
          duration: 2,
        })
      } else
        notification.open({
          key: keyNotifi,
          message: 'Updating status pending failed, please try again',
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          duration: 2,
        })
    } catch (error) {
      console.log(error)
      notification.close(keyNotifi)
    }
  }

  const UploadMockupProductOrder = ({ record, recordMini }) => {
    const [listPrintFileRemove, setListPrintFileRemove] = useState([]) // list checkbox xoa anh hang loat
    const [
      classUploadPrintFileProductOrder,
      setClassUploadPrintFileProductOrder,
    ] = useState('')

    const [data, setData] = useState([])

    useEffect(() => {
      if (recordMini.mockup_thumb && recordMini.mockup_thumb.length) {
        setData([
          ...recordMini.mockup_thumb.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
        return
      }

      if (recordMini.mockup)
        setData([
          ...recordMini.mockup.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
    }, [])

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={data}
          onChange={({ fileList }) => {
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current)
            }
            typingTimeoutRef.current = setTimeout(async () => {
              uploadMockupOrderPendingDesign(record, recordMini, fileList)
            }, 350)
          }}
          className={classUploadPrintFileProductOrder}
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: (file) => (
              <div
                style={{
                  position: 'absolute',
                  left: '-1px',
                  right: '-1px',
                  top: '-1px',
                  bottom: '-1px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  defaultChecked={listPrintFileRemove.includes(file.url)}
                  onClick={(e) => {
                    const listPrintFileRemoveNew = [...listPrintFileRemove]
                    const indexLink = listPrintFileRemoveNew.findIndex(
                      (e) => e === file.url
                    )

                    if (indexLink !== -1)
                      listPrintFileRemoveNew.splice(indexLink, 1)
                    else listPrintFileRemoveNew.push(file.url)

                    if (listPrintFileRemoveNew.length)
                      setClassUploadPrintFileProductOrder(
                        'print-file-product-order'
                      )
                    else setClassUploadPrintFileProductOrder('')

                    setListPrintFileRemove([...listPrintFileRemoveNew])

                    e.stopPropagation()
                  }}></Checkbox>
                ,
              </div>
            ),
            showDownloadIcon: true,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}></div>
                </Popover>
              )
            },
          }}>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
        <Popconfirm
          title="Are you sure to delete list image?"
          onConfirm={() => {
            const listPrintFileRemoveNew = data.filter(
              (e) => !listPrintFileRemove.includes(e.url)
            )
            uploadMockupOrderPendingDesign(
              record,
              recordMini,
              listPrintFileRemoveNew
            )
          }}
          okText="Yes"
          cancelText="No">
          <Button
            type="dashed"
            danger
            style={{ display: !listPrintFileRemove.length && 'none' }}>
            Remove list image
          </Button>
        </Popconfirm>
      </>
    )
  }

  const updateProductInOrder = async (object) => {
    try {
      dispatch({
        type: ACTION.LOADING,
        data: true,
      })
      console.log(JSON.stringify(object))
      const res = await updateOrders(object)
      console.log(res)
      if (res.status === 200)
        notification.success({ message: 'Update success!' })
      else
        notification.error({
          message: res.data.message || 'Update failed, please try again!',
        })
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      if (res.status === 200) {
        await _getOrders(params)
      }
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  const uploadAdditionalFileOrderPendingDesign = async (
    record,
    recordMini,
    files
  ) => {
    try {
      //tham số files có thể chứa link ảnh hoặc chứa các file cần uploadd
      let arrayLinkImg = [] //lọc các link ảnh
      let isCheckUpload = false //check user upload hay remove
      let filesUpload = [] //loc item là file để upload
      files.map((e) => {
        if (e.originFileObj) {
          filesUpload.push(e.originFileObj)
          isCheckUpload = true
        } else arrayLinkImg.push(e.url)
      })

      const indexUploadUnsign = record.list_product_unsign.findIndex(
        (e) => e.id === recordMini.id
      )
      const indexUploadFulfill = record.list_product_fulfill.findIndex(
        (e) => e.id === recordMini.id
      )

      //case upload
      if (isCheckUpload) {
        dispatch({
          type: ACTION.LOADING,
          data: true,
        })

        let listUrl = await uploadFiles(filesUpload)

        dispatch({
          type: ACTION.LOADING,
          data: false,
        })

        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb &&
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb
              .length
          )
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb = [
              ...record.list_product_unsign[indexUploadUnsign].printFiles_thumb,
              ...listUrl,
            ]

          record.list_product_unsign[indexUploadUnsign].printFiles = [
            ...record.list_product_unsign[indexUploadUnsign].printFiles,
            ...listUrl,
          ]
          record.action = `upload print file by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrder(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb &&
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb
              .length
          )
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb = [
              ...record.list_product_fulfill[indexUploadFulfill]
                .printFiles_thumb,
              ...listUrl,
            ]

          record.list_product_fulfill[indexUploadFulfill].printFiles = [
            ...record.list_product_fulfill[indexUploadFulfill].printFiles,
            ...listUrl,
          ]
          record.action = `upload print file by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrder(record)
        }
      } else {
        //case remove
        if (indexUploadUnsign !== -1) {
          if (
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb &&
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb
              .length
          ) {
            let indexRemoveList = []
            record.list_product_unsign[indexUploadUnsign].printFiles_thumb.map(
              (url, index) => {
                if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
              }
            )

            const newPrintFiles = record.list_product_unsign[
              indexUploadUnsign
            ].printFiles.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_unsign[indexUploadUnsign].printFiles = [
              ...newPrintFiles,
            ]

            record.list_product_unsign[indexUploadUnsign].printFiles_thumb =
              arrayLinkImg
          } else
            record.list_product_unsign[indexUploadUnsign].printFiles =
              arrayLinkImg

          record.action = `delete img print file by item ${record.list_product_unsign[indexUploadUnsign].id}`
          updateProductInOrder(record)
        }
        if (indexUploadFulfill !== -1) {
          if (
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb &&
            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb
              .length
          ) {
            let indexRemoveList = []
            record.list_product_fulfill[
              indexUploadFulfill
            ].printFiles_thumb.map((url, index) => {
              if (!arrayLinkImg.includes(url)) indexRemoveList.push(index)
            })

            const newPrintFiles = record.list_product_fulfill[
              indexUploadFulfill
            ].printFiles.filter((e, index) => !indexRemoveList.includes(index))

            record.list_product_fulfill[indexUploadFulfill].printFiles = [
              ...newPrintFiles,
            ]

            record.list_product_fulfill[indexUploadFulfill].printFiles_thumb =
              arrayLinkImg
          } else
            record.list_product_fulfill[indexUploadFulfill].printFiles =
              arrayLinkImg

          record.action = `delete img print file by item ${record.list_product_fulfill[indexUploadFulfill].id}`
          updateProductInOrder(record)
        }
      }
    } catch (error) {
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
      console.log(error)
      notification.error({ message: 'Upload failed, please try again!' })
    }
  }

  const UploadPrintFileProductOrder = ({ record, recordMini }) => {
    const [listPrintFileRemove, setListPrintFileRemove] = useState([]) // list checkbox xoa anh hang loat
    const [
      classUploadPrintFileProductOrder,
      setClassUploadPrintFileProductOrder,
    ] = useState('')

    const [data, setData] = useState([])

    useEffect(() => {
      if (recordMini.printFiles_thumb && recordMini.printFiles_thumb.length) {
        setData([
          ...recordMini.printFiles_thumb.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
        return
      }

      if (recordMini.printFiles)
        setData([
          ...recordMini.printFiles.map((url, index) => {
            return {
              uid: index,
              name: 'image',
              status: 'done',
              url: url,
            }
          }),
        ])
    }, [])

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={data}
          onChange={({ fileList }) => {
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current)
            }
            typingTimeoutRef.current = setTimeout(async () => {
              uploadAdditionalFileOrderPendingDesign(
                record,
                recordMini,
                fileList
              )
            }, 350)
          }}
          className={classUploadPrintFileProductOrder}
          showUploadList={{
            showRemoveIcon: true,
            removeIcon: (file) => (
              <div
                style={{
                  position: 'absolute',
                  left: '-1px',
                  right: '-1px',
                  top: '-1px',
                  bottom: '-1px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  defaultChecked={listPrintFileRemove.includes(file.url)}
                  onClick={(e) => {
                    const listPrintFileRemoveNew = [...listPrintFileRemove]
                    const indexLink = listPrintFileRemoveNew.findIndex(
                      (e) => e === file.url
                    )

                    if (indexLink !== -1)
                      listPrintFileRemoveNew.splice(indexLink, 1)
                    else listPrintFileRemoveNew.push(file.url)

                    if (listPrintFileRemoveNew.length)
                      setClassUploadPrintFileProductOrder(
                        'print-file-product-order'
                      )
                    else setClassUploadPrintFileProductOrder('')

                    setListPrintFileRemove([...listPrintFileRemoveNew])

                    e.stopPropagation()
                  }}></Checkbox>
                ,
              </div>
            ),
            showDownloadIcon: true,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}>
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}></div>
                </Popover>
              )
            },
          }}>
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
        <Popconfirm
          title="Are you sure to delete list image?"
          onConfirm={() => {
            const listPrintFileRemoveNew = data.filter(
              (e) => !listPrintFileRemove.includes(e.url)
            )
            uploadAdditionalFileOrderPendingDesign(
              record,
              recordMini,
              listPrintFileRemoveNew
            )
          }}
          okText="Yes"
          cancelText="No">
          <Button
            type="dashed"
            danger
            style={{ display: !listPrintFileRemove.length && 'none' }}>
            Remove list image
          </Button>
        </Popconfirm>
      </>
    )
  }

  const updateInfo = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const data = formUpdate.getFieldsValue()
      const body = {
        ...ordersDetail,
        shipping: data,
      }
      body.action = `update info shipping by order ${params.slug}`

      const res = await updateOrders(body)
      if (res.status === 200) {
        await _getOrders(params)
        notification.success({ message: 'Update information shipping success' })
      } else
        notification.error({
          message: res.data.message || 'Update information shipping failed',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const UpdateInformationCustomerOrShipping = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: '#1890FF',
            cursor: 'pointer',
          }}
          onClick={() => {
            formUpdate.setFieldsValue({ ...ordersDetail.shipping })
            toggle()
          }}>
          <EditOutlined />
          <div style={{ marginLeft: 5 }}>Edit</div>
        </div>
        <Modal
          visible={visible}
          title={
            <div>
              <ShoppingCartOutlined
                style={{ color: '#00c732d9', marginRight: 4 }}
              />
              Edit shipping
            </div>
          }
          onCancel={toggle}
          onOk={async () => {
            await updateInfo()
          }}>
          <Form
            form={formUpdate}
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 20,
            }}>
            <Form.Item label="First name: " name="first_name">
              <Input placeholder="Enter first name" />
            </Form.Item>
            <Form.Item label="Last name: " name="last_name">
              <Input placeholder="Enter last name" />
            </Form.Item>
            <Form.Item label="Email: " name="email">
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item label="Company: " name="company">
              <Input placeholder="Enter company" />
            </Form.Item>
            <Form.Item label="Address 1: " name="address_1">
              <Input placeholder="Enter address 1" />
            </Form.Item>
            <Form.Item label="Address 2: " name="address_2">
              <Input placeholder="Enter address 2" />
            </Form.Item>
            <Form.Item label="City: " name="city">
              <Input placeholder="Enter city" />
            </Form.Item>
            <Form.Item label="Postcode: " name="postcode">
              <Input placeholder="Enter postcode" />
            </Form.Item>
            <Form.Item label="Province: " name="province_code">
              <Input placeholder="Enter province" />
            </Form.Item>
            <Form.Item label="Country: " name="country_code">
              <Input placeholder="Enter country" />
            </Form.Item>
            <Form.Item label="Phone: " name="phone">
              <Input placeholder="Enter phone" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }

  const DrawerInfo = () => {
    const [visibleDrawerInfo, setVisibleDrawerInfo] = useState(false)
    const toggleDrawerInfo = () => setVisibleDrawerInfo(!visibleDrawerInfo)

    return (
      <>
        <Button type="primary" onClick={toggleDrawerInfo}>
          Expand Infomation
        </Button>
        <Drawer
          width={500}
          onClose={toggleDrawerInfo}
          title="Infomation"
          visible={visibleDrawerInfo}>
          <div className={styles['tracking_orders_row_col_right']}>
            <div className={styles['tracking_orders_row_col_right_notes']}>
              <div
                className={styles['tracking_orders_row_col_right_notes_child']}>
                <div>
                  <ExceptionOutlined />
                </div>
                <div>Order Information</div>
              </div>
              <div
                className={styles['tracking_orders_row_col_right_notes_item']}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Order status:</div>
                    <div>{ordersDetail && ordersDetail.status}</div>
                  </Row>

                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Base cost:</div>
                    <div>{`$${ordersDetail && ordersDetail.total_base}`}</div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Order total:</div>
                    <div>{`$${ordersDetail && ordersDetail.total_cost}`}</div>
                  </Row>
                </Space>
              </div>
            </div>

            <div className={styles['tracking_orders_row_col_right_notes']}>
              <div
                className={styles['tracking_orders_row_col_right_notes_child']}>
                <div>
                  <FileZipOutlined />
                </div>
                <div>Seller Information</div>
              </div>
              <div
                className={styles['tracking_orders_row_col_right_notes_item']}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Seller name:</div>
                    <div>{ordersDetail && ordersDetail.name_seller}</div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Email:</div>
                    <div>{ordersDetail && ordersDetail.id_user}</div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Seller plan:</div>
                    <div></div>
                  </Row>
                </Space>
              </div>
            </div>

            <div className={styles['tracking_orders_row_col_right_notes']}>
              <div
                className={styles['tracking_orders_row_col_right_notes_child']}>
                <div>
                  <HomeOutlined />
                </div>
                <div>Store Information</div>
              </div>
              <div
                className={styles['tracking_orders_row_col_right_notes_item']}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Site:</div>
                    <div>{ordersDetail && ordersDetail.id_site}</div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Type:</div>
                    <div>{ordersDetail && ordersDetail.type}</div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Platform:</div>
                    <div>{ordersDetail && ordersDetail.type}</div>
                  </Row>
                </Space>
              </div>
            </div>

            <div className={styles['tracking_orders_row_col_right_notes']}>
              <div
                className={styles['tracking_orders_row_col_right_notes_child']}>
                <div>
                  <UserOutlined />
                </div>
                <div>Customer Information</div>
              </div>
              <div
                className={styles['tracking_orders_row_col_right_notes_item']}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Full name:</div>
                    <div>
                      {`${
                        ordersDetail && (ordersDetail.customer.first_name || '')
                      } ${
                        ordersDetail && (ordersDetail.customer.last_name || '')
                      }`}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Email:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.email || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Company:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.company || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Address 1:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.address_1 || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Address 2:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.address_2 || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>City:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.city || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Post code:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.postcode || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Province:</div>
                    <div>
                      {ordersDetail &&
                        (ordersDetail.customer.province_code || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Country:</div>
                    <div>
                      {ordersDetail &&
                        (ordersDetail.customer.country_code || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Phone:</div>
                    <div>
                      {ordersDetail && (ordersDetail.customer.phone || '')}
                    </div>
                  </Row>
                </Space>
              </div>
            </div>

            <div className={styles['tracking_orders_row_col_right_notes']}>
              <div
                className={
                  styles['tracking_orders_row_col_right_notes_parent']
                }>
                <div
                  className={
                    styles[
                      'tracking_orders_row_col_right_notes_child_fix_parent'
                    ]
                  }>
                  <div>
                    <FileZipOutlined />
                  </div>
                  <div>Shipping Information</div>
                </div>
                <Permission permissions={[PERMISSIONS.edit_shipping_info]}>
                  <UpdateInformationCustomerOrShipping />
                </Permission>
              </div>
              <div
                className={styles['tracking_orders_row_col_right_notes_item']}>
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Full name:</div>
                    <div>
                      {`${
                        ordersDetail && (ordersDetail.shipping.first_name || '')
                      } ${
                        ordersDetail && (ordersDetail.shipping.last_name || '')
                      }`}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Email:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.email || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Company:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.company || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Address 1:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.address_1 || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Address 2:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.address_2 || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>City:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.city || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Post code:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.postcode || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Province:</div>
                    <div>
                      {ordersDetail &&
                        (ordersDetail.shipping.province_code ||
                          ordersDetail.customer.province_code ||
                          '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Country:</div>
                    <div>
                      {ordersDetail &&
                        (ordersDetail.shipping.country_code || '')}
                    </div>
                  </Row>
                  <Row justify="space-between">
                    <div style={{ fontWeight: 600 }}>Phone:</div>
                    <div>
                      {ordersDetail && (ordersDetail.shipping.phone || '')}
                    </div>
                  </Row>
                </Space>
              </div>
            </div>
          </div>
        </Drawer>
      </>
    )
  }

  async function confirm(action = '', reasonActionRequired = '') {
    try {
      //bắt buộc phải có printfile mới cho chuyển trạng thái
      //doi voi cac quyen khac admin, fullfiller
      if (
        localStorage.getItem('role') &&
        !['Admin', 'Fullfiller'].includes(
          JSON.parse(localStorage.getItem('role'))
        )
      ) {
        //nếu chuyển từ pending design -> pending thì ko cần ràng buộc
        if (
          ordersDetail.status !== STATUS_ORDER.PENDING_DESIGN &&
          action !== STATUS_ORDER.PENDING
        ) {
          for (let j = 0; j < ordersDetail.list_product_unsign.length; ++j) {
            if (!ordersDetail.list_product_unsign[j].printFiles.length) {
              notification.error({
                message: `Please choose additional file in order ${ordersDetail.name}`,
              })
              return
            }
          }

          for (let j = 0; j < ordersDetail.list_product_fulfill.length; ++j) {
            if (!ordersDetail.list_product_fulfill[j].printFiles.length) {
              notification.error({
                message: `Please choose additional file in order ${ordersDetail.name}`,
              })
              return
            }
          }
        }
      }

      dispatch({
        type: ACTION.LOADING,
        data: true,
      })

      let resUpdate = null

      //Button More action
      //update status action-required
      if (action === STATUS_ORDER.ACTION_REQUIRED) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.ACTION_REQUIRED,
          reasonCancel: reasonActionRequired,
        }
        resUpdate = await updateStatusOrders(object)
      }
      if (action === STATUS_ORDER.ON_HOLD) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.ON_HOLD,
        }
        resUpdate = await updateStatusOrders(object)
      }
      if (action === STATUS_ORDER.CANCELLED_REFUNDED) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.CANCELLED_REFUNDED,
        }
        resUpdate = await updateStatusOrders(object)
      }
      if (action === STATUS_ORDER.IN_PRODUCTION) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.IN_PRODUCTION,
        }
        resUpdate = await updateStatusOrders(object)
      }
      if (action === STATUS_ORDER.NEED_PAY) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.NEED_PAY,
        }
        resUpdate = await updateStatusOrders(object)
      }
      if (action === STATUS_ORDER.PENDING) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.PENDING,
        }
        resUpdate = await updateStatusOrders(object)
      }

      //colling -> pending design
      if (ordersDetail.status === STATUS_ORDER.COLLING_OFF) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.PENDING_DESIGN,
        }
        resUpdate = await updateStatusOrders(object)
      }

      //pending design -> need pay
      if (ordersDetail.status === STATUS_ORDER.PENDING_DESIGN) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.NEED_PAY,
        }
        resUpdate = await updateStatusOrders(object)
      }

      //need pay -> pending
      if (ordersDetail.status === STATUS_ORDER.NEED_PAY) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.PENDING,
        }
        resUpdate = await updateStatusOrders(object)
      }

      //pending -> in production
      if (ordersDetail.status === STATUS_ORDER.PENDING) {
        const object = {
          id_order: ordersDetail.id_order,
          key: ordersDetail.key,
          key_design: ordersDetail.key_design,
          status: STATUS_ORDER.IN_PRODUCTION,
        }
        resUpdate = await updateStatusOrders(object)
      }

      console.log(resUpdate)
      if (resUpdate.status === 200) {
        await _getOrders(params)
        notification.success({ message: 'Update success!' })
      } else notification.error({ message: 'Update error, please try again' })

      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: ACTION.LOADING,
        data: false,
      })
    }
  }

  const UpdateActionRequired = () => {
    const [visible, setVisible] = useState(false)
    const [reason, setReason] = useState('')

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <ExportOutlined
            rotate={180}
            style={{ color: '#F60DC6', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Action required</div>
        </div>
        <Modal
          zIndex={1200}
          title="Update status action required"
          visible={visible}
          onCancel={toggle}
          onOk={() => confirm(STATUS_ORDER.ACTION_REQUIRED, reason)}>
          <Col>
            Reason
            <Input.TextArea
              value={reason}
              rows={4}
              onChange={(e) => setReason(e.target.value)}
              autoFocus
              onPressEnter={() => confirm(STATUS_ORDER.ACTION_REQUIRED, reason)}
            />
          </Col>
        </Modal>
      </>
    )
  }

  const ModalAssignProductType = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <FormOutlined
            style={{ color: '#ff5f02', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Assign Product Type</div>
        </div>
        <Modal
          zIndex={2999}
          footer={null}
          title="Assign product type"
          visible={visible}
          onCancel={toggle}>
          <Select
            dropdownStyle={{ zIndex: 3000 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Choose product type"
            showSearch
            style={{ width: '100%' }}
            onChange={async (value) => {
              const productType = allProductType.find((e) => e._id === value)
              if (productType) {
                const listProductAssignProductType = [
                  ...ordersDetail.list_product_unsign,
                  ...ordersDetail.list_product_fulfill,
                ]

                const listPromise = listProductAssignProductType.map(
                  async (e) => {
                    let resUpdate = null

                    const indexUpdateUnsign =
                      ordersDetail.list_product_unsign.findIndex(
                        (v) => v.id === e.id
                      )
                    const indexUpdateFulfill =
                      ordersDetail.list_product_fulfill.findIndex(
                        (v) => v.id === e.id
                      )

                    if (indexUpdateUnsign !== -1) {
                      ordersDetail.list_product_unsign[
                        indexUpdateUnsign
                      ].product_type = productType
                      ordersDetail.action = `assign product type by item ${ordersDetail.list_product_unsign[indexUpdateUnsign].id}`
                      resUpdate = await updateOrders(ordersDetail)
                    }

                    if (indexUpdateFulfill !== -1) {
                      ordersDetail.list_product_fulfill[
                        indexUpdateFulfill
                      ].product_type = productType
                      ordersDetail.action = `assign product type by item ${ordersDetail.list_product_fulfill[indexUpdateFulfill].id}`
                      resUpdate = await updateOrders(ordersDetail)
                    }

                    return resUpdate
                  }
                )
                dispatch({
                  type: ACTION.LOADING,
                  data: true,
                })
                const res = await Promise.all(listPromise)
                dispatch({
                  type: ACTION.LOADING,
                  data: false,
                })
                let isCheckUpdateSuccess = true
                for (let i = 0; i < res.length; ++i) {
                  if (res[i].status === 200) isCheckUpdateSuccess = true
                  else {
                    isCheckUpdateSuccess = false
                    break
                  }
                }

                if (isCheckUpdateSuccess) {
                  await _getOrders(params)
                  notification.success({
                    message: 'Assign product type success!',
                  })
                } else
                  notification.error({
                    message: 'Assign product type error, please try again',
                  })
              } else {
                notification.error({ message: 'Not find product type!' })
                toggle()
              }
            }}>
            {allProductType.map((e, index) => (
              <Option value={e._id} key={index}>
                {e.title}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const ModalAssignSupplier = () => {
    const [visible, setVisible] = useState(false)

    const toggle = () => setVisible(!visible)

    return (
      <>
        <div onClick={toggle}>
          <EditOutlined
            style={{ color: '#00c732d9', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Assign Supplier</div>
        </div>
        <Modal
          zIndex={2999}
          footer={null}
          title="Assign supplier"
          visible={visible}
          onCancel={toggle}>
          <Select
            dropdownStyle={{ zIndex: 3000 }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Choose supplier"
            showSearch
            style={{ width: '100%' }}
            onChange={async (value) => {
              const listProductAssignProductType = [
                ...ordersDetail.list_product_unsign,
                ...ordersDetail.list_product_fulfill,
              ]

              const listPromise = listProductAssignProductType.map(
                async (e) => {
                  let resUpdate = null

                  const indexUpdateUnsign =
                    ordersDetail.list_product_unsign.findIndex(
                      (v) => v.id === e.id
                    )
                  const indexUpdateFulfill =
                    ordersDetail.list_product_fulfill.findIndex(
                      (v) => v.id === e.id
                    )

                  if (indexUpdateUnsign !== -1) {
                    ordersDetail.list_product_unsign[
                      indexUpdateUnsign
                    ].supplier = value
                    ordersDetail.action = `assign supplier by item ${ordersDetail.list_product_unsign[indexUpdateUnsign].id}`
                    resUpdate = await updateOrders(ordersDetail)
                  }

                  if (indexUpdateFulfill !== -1) {
                    ordersDetail.list_product_fulfill[
                      indexUpdateFulfill
                    ].supplier = value
                    ordersDetail.action = `assign supplier by item ${ordersDetail.list_product_fulfill[indexUpdateFulfill].id}`
                    resUpdate = await updateOrders(ordersDetail)
                  }

                  return resUpdate
                }
              )
              dispatch({
                type: ACTION.LOADING,
                data: true,
              })
              const res = await Promise.all(listPromise)
              dispatch({
                type: ACTION.LOADING,
                data: false,
              })
              let isCheckUpdateSuccess = true
              for (let i = 0; i < res.length; ++i) {
                if (res[i].status === 200) isCheckUpdateSuccess = true
                else {
                  isCheckUpdateSuccess = false
                  break
                }
              }

              if (isCheckUpdateSuccess) {
                await _getOrders(params)
                notification.success({ message: 'Assign supplier success!' })
              } else
                notification.error({
                  message: 'Assign supplier error, please try again',
                })
            }}>
            {usersSupplier.map((e, index) => (
              <Option value={e.username} key={index}>
                {e.username}
              </Option>
            ))}
          </Select>
        </Modal>
      </>
    )
  }

  const contentMoreAction = (
    <div className={styles['assign_popover']}>
      <ModalAssignProductType />
      <ModalAssignSupplier />
      <Popconfirm
        title="Are you sure resend orders!"
        onConfirm={_resendOrder}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            style={{ color: '#00FF00', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Resend</div>
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure update status need pay this order!"
        onConfirm={() => confirm(STATUS_ORDER.NEED_PAY)}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            rotate={180}
            style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Need Pay</div>
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure update status pending design this order!"
        onConfirm={() => confirm(STATUS_ORDER.PENDING_DESIGN)}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            rotate={180}
            style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Pending Design</div>
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure update status pending this order!"
        onConfirm={() => confirm(STATUS_ORDER.PENDING)}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            rotate={180}
            style={{ color: '#FFCC01', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Pending</div>
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure update status in production this order!"
        onConfirm={() => confirm(STATUS_ORDER.IN_PRODUCTION)}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            rotate={180}
            style={{ color: '#0033FF', fontSize: '1rem', marginRight: 8 }}
          />
          <div>In Production</div>
        </div>
      </Popconfirm>
      <Popconfirm
        title="Are you sure update status on hold this order!"
        onConfirm={() => confirm(STATUS_ORDER.ON_HOLD)}
        okText="Yes"
        cancelText="No">
        <div>
          <ExportOutlined
            rotate={180}
            style={{ color: '#F60DC6', fontSize: '1rem', marginRight: 8 }}
          />
          <div>On hold</div>
        </div>
      </Popconfirm>
      <UpdateActionRequired />
      <Popconfirm
        title="Are you sure update status cancelled/refuned this order!"
        onConfirm={() => confirm(STATUS_ORDER.CANCELLED_REFUNDED)}
        okText="Yes"
        cancelText="No">
        <div>
          <CloseCircleOutlined
            style={{ color: 'red', fontSize: '1rem', marginRight: 8 }}
          />
          <div>Refunded/cancel</div>
        </div>
      </Popconfirm>
    </div>
  )

  const _repairOrder = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const body = {
        id_order: ordersDetail.id_order,
        id_site: ordersDetail.id_site,
      }
      console.log(JSON.stringify(body))
      const res = await repairOrder(body)
      console.log(res)
      if (res.status === 200) {
        await _getOrders({ slug: params.slug })
        notification.success({ message: 'Repair order success' })
      } else
        notification.error({
          message: res.data.message || 'Repair order failed',
        })
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false })
      console.log(error)
    }
  }

  const _getAllProductType = async () => {
    try {
      const res = await getAllProductType()
      if (res.status === 200) {
        setAllProductType(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersBySupplier = async () => {
    try {
      const res = await filterRoleUsers('Supplier')

      if (res.status === 200) {
        setUsersSupplier(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const order = new URLSearchParams(location.search).get('order')

    if (order) {
      setParams({ slug: order })
      _getOrders({ slug: order })
      _getAllProductType()
      getUsersBySupplier()
    } else {
      history.push(ROUTES.ORDERS)
      return
    }
  }, [])

  return (
    <>
      <div className={styles['tracking_orders']}>
        <BackTop style={{ right: '20px', bottom: '20px' }} />
        <Row className={styles['tracking_orders_title']}>
          <Col
            style={{ width: '100%', paddingTop: '1rem' }}
            xs={24}
            sm={11}
            md={11}
            lg={11}
            xl={11}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => history.push(ROUTES.ORDERS)}>
              <div
                style={{ color: 'black', fontWeight: '600', fontSize: '1rem' }}>
                <ArrowLeftOutlined
                  style={{
                    color: 'black',
                    fontWeight: '600',
                    fontSize: '1rem',
                    marginRight: '0.5rem',
                  }}
                />
              </div>
              <div
                style={{
                  color: 'black',
                  fontWeight: '600',
                  fontSize: '1rem',
                }}>{`Order Detail: ${
                ordersDetail ? ordersDetail.id_ecommerce || '' : ''
              }`}</div>
            </div>
          </Col>
          <Col
            style={{ width: '100%', paddingTop: '1rem' }}
            xs={24}
            sm={11}
            md={11}
            lg={11}
            xl={11}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
              }}>
              <DrawerInfo />
            </div>
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            width: '100%',
            backgroundColor: 'white',
            marginBottom: '1rem',
            paddingTop: 15,
            paddingBottom: 15,
          }}
          align="middle">
          <Space size="middle">
            <Permission permissions={[PERMISSIONS.update_status_order]}>
              <Popover placement="bottom" content={contentMoreAction}>
                <Col className={styles['more_action_col']}>More Action</Col>
              </Popover>
            </Permission>
            <Permission permissions={[PERMISSIONS.repair_order]}>
              <Popconfirm
                onConfirm={_repairOrder}
                okText="Ok"
                cancelText="No"
                title="Do you want to Repair Order ?">
                <Button type="primary" size="large">
                  Repair Order
                </Button>
              </Popconfirm>
            </Permission>
          </Space>
          <div style={{ paddingRight: 15, fontSize: 19, fontWeight: 600 }}>
            Status: {ordersDetail && ordersDetail.status}
          </div>
        </Row>
        <Row className={styles['tracking_orders_row']}>
          <Col
            className={styles['tracking_orders_row_col']}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}>
            <div className={styles['orders_detail_main_parent_content']}>
              <div
                className={styles['orders_detail_main_parent_content_fulfill']}>
                <div
                  style={{
                    color: 'black',
                    fontWeight: '600',
                    fontSize: '1rem',
                    borderBottom: '1px solid rgb(236, 230, 230)',
                    paddingBottom: '0.5rem',
                    marginBottom: '1rem',
                  }}>
                  Product fulfill
                </div>

                <Space
                  size="large"
                  style={{ display: !arrayFulfill.length && 'none' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Un check
                  </div>
                </Space>
                <div
                  className={
                    styles['orders_detail_main_parent_content_fulfill_table']
                  }>
                  <Table
                    style={{ width: '100%' }}
                    pagination={false}
                    rowClassName={(productUnsign) => {
                      if (productUnsign.status === 'approve')
                        return 'product-unsign-approve'
                      if (productUnsign.status === 'pending')
                        return 'product-unsign-pending'
                      if (productUnsign.status === 'uncheck')
                        return 'product-unsign-uncheck'
                      if (productUnsign.status === 'cancel')
                        return 'product-unsign-cancel'
                    }}
                    columns={columnsProducts.map((values, index) => {
                      if (values.key === 'additionalFile') {
                        return {
                          ...values,
                          render: (text, recordMini) => (
                            <UploadPrintFileProductOrder
                              record={ordersDetail}
                              recordMini={recordMini}
                            />
                          ),
                        }
                      }
                      if (values.key === 'mockup') {
                        return {
                          ...values,
                          render: (text, recordMini) => (
                            <UploadMockupProductOrder
                              record={ordersDetail}
                              recordMini={recordMini}
                            />
                          ),
                        }
                      }
                      if (values.key === 'information') {
                        return {
                          ...values,
                          render: (text, recordMini) => {
                            return (
                              <Col>
                                <a
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}
                                  target="_blank"
                                  href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                  recordMini && recordMini.tracking_number
                                    ? recordMini.tracking_number
                                    : ''
                                }`}</a>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Status: ${
                                  recordMini && recordMini.status
                                    ? recordMini.status
                                    : ''
                                }.`}</div>
                                <ModalUpdateSkuPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Product name: ${
                                  recordMini && recordMini.name
                                    ? recordMini.name
                                    : ''
                                }.`}</div>

                                <div
                                  style={{
                                    marginBottom: '0.5rem',
                                  }}>
                                  Product Variantion:{' '}
                                  {recordMini.variants
                                    .map((e, index) => {
                                      if (
                                        index ===
                                        recordMini.variants.length - 1
                                      )
                                        return `${e.name || e.option}: ${
                                          e.value
                                        }`
                                      return `${e.name || e.option}: ${
                                        e.value
                                      }|`
                                    })
                                    .join('')}
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Order item id: ${
                                  recordMini && recordMini.id
                                    ? recordMini.id
                                    : ''
                                }.`}</div>
                                <ModalUpdateQuantityPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateProductTypePendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>
                                  Order created:{' '}
                                  {(recordMini.dateCreated &&
                                    recordMini.dateCreated) ||
                                    (recordMini.date_created &&
                                      recordMini.date_created)}
                                </div>
                                <ModalUpdateCustomInfoPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateSupplierPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateCarrierPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateCustomNotePendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateRenameForSupPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                              </Col>
                            )
                          },
                        }
                      }

                      if (values.key === 'upload') {
                        return {
                          ...values,
                          render: (text, recordMini) => {
                            return (
                              <Space wrap>
                                <Permission
                                  permissions={[
                                    PERMISSIONS.approve_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    onClick={() =>
                                      onClickApprove(recordMini, ordersDetail)
                                    }>
                                    Approve
                                  </Button>
                                </Permission>

                                <Permission
                                  permissions={[
                                    PERMISSIONS.rebuild_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    onClick={() =>
                                      onClickRebuild(recordMini, ordersDetail)
                                    }>
                                    Rebuild
                                  </Button>
                                </Permission>
                                <Permission
                                  permissions={[
                                    PERMISSIONS.cancel_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    danger
                                    onClick={() =>
                                      onClickCancel(recordMini, ordersDetail)
                                    }>
                                    Cancel
                                  </Button>
                                </Permission>
                                <ModalUpdateInformationPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                              </Space>
                            )
                          },
                        }
                      }
                      return { ...values }
                    })}
                    dataSource={arrayFulfill}
                    size="small"
                  />
                </div>
              </div>
              <div
                className={styles['orders_detail_main_parent_content_fulfill']}>
                <div
                  style={{
                    color: 'black',
                    fontWeight: '600',
                    fontSize: '1rem',
                    borderBottom: '1px solid rgb(236, 230, 230)',
                    paddingBottom: '0.5rem',
                    marginBottom: '1rem',
                  }}>
                  Product unsign
                </div>

                <Space
                  size="large"
                  style={{ display: !arrayUnsign.length && 'none' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(23, 209, 23)',
                      }}></div>
                    Approve
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'orange',
                      }}></div>
                    Pending
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: 'rgb(133, 197, 250)',
                      }}></div>
                    Un check
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        backgroundColor: '#FFAFAF',
                      }}></div>
                    Cancel
                  </div>
                </Space>
                <div
                  className={
                    styles['orders_detail_main_parent_content_fulfill_table']
                  }>
                  <Table
                    style={{ width: '100%' }}
                    pagination={false}
                    rowClassName={(productUnsign) => {
                      if (productUnsign.status === 'approve')
                        return 'product-unsign-approve'
                      if (productUnsign.status === 'pending')
                        return 'product-unsign-pending'
                      if (productUnsign.status === 'uncheck')
                        return 'product-unsign-uncheck'
                      if (productUnsign.status === 'cancel')
                        return 'product-unsign-cancel'
                    }}
                    columns={columnsProducts.map((values, index) => {
                      if (values.key === 'additionalFile') {
                        return {
                          ...values,
                          render: (text, recordMini) => (
                            <UploadPrintFileProductOrder
                              record={ordersDetail}
                              recordMini={recordMini}
                            />
                          ),
                        }
                      }
                      if (values.key === 'mockup') {
                        return {
                          ...values,
                          render: (text, recordMini) => (
                            <UploadMockupProductOrder
                              record={ordersDetail}
                              recordMini={recordMini}
                            />
                          ),
                        }
                      }
                      if (values.key === 'information') {
                        return {
                          ...values,
                          render: (text, recordMini) => {
                            return (
                              <Col>
                                <a
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}
                                  target="_blank"
                                  href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}>{`Tracking number: ${
                                  recordMini && recordMini.tracking_number
                                    ? recordMini.tracking_number
                                    : ''
                                }`}</a>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Status: ${
                                  recordMini && recordMini.status
                                    ? recordMini.status
                                    : ''
                                }.`}</div>
                                <ModalUpdateSkuPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Product name: ${
                                  recordMini && recordMini.name
                                    ? recordMini.name
                                    : ''
                                }.`}</div>

                                <div
                                  style={{
                                    marginBottom: '0.5rem',
                                  }}>
                                  Product Variantion:{' '}
                                  {recordMini.variants
                                    .map((e, index) => {
                                      if (
                                        index ===
                                        recordMini.variants.length - 1
                                      )
                                        return `${e.name || e.option}: ${
                                          e.value
                                        }`
                                      return `${e.name || e.option}: ${
                                        e.value
                                      }|`
                                    })
                                    .join('')}
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>{`Order item id: ${
                                  recordMini && recordMini.id
                                    ? recordMini.id
                                    : ''
                                }.`}</div>
                                <ModalUpdateQuantityPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateProductTypePendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginBottom: '0.5rem',
                                  }}>
                                  Order created:{' '}
                                  {(recordMini.dateCreated &&
                                    recordMini.dateCreated) ||
                                    (recordMini.date_created &&
                                      recordMini.date_created)}
                                </div>
                                <ModalUpdateCustomInfoPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateSupplierPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateCarrierPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateCustomNotePendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                                <ModalUpdateRenameForSupPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                              </Col>
                            )
                          },
                        }
                      }

                      if (values.key === 'upload') {
                        return {
                          ...values,
                          render: (text, recordMini) => {
                            return (
                              <Space wrap>
                                <Permission
                                  permissions={[
                                    PERMISSIONS.approve_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    onClick={() =>
                                      onClickApprove(recordMini, ordersDetail)
                                    }>
                                    Approve
                                  </Button>
                                </Permission>

                                <Permission
                                  permissions={[
                                    PERMISSIONS.rebuild_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    onClick={() =>
                                      onClickRebuild(recordMini, ordersDetail)
                                    }>
                                    Rebuild
                                  </Button>
                                </Permission>
                                <Permission
                                  permissions={[
                                    PERMISSIONS.cancel_product_in_order,
                                  ]}>
                                  <Button
                                    style={{
                                      width: '7.5rem',
                                      height: '2.5rem',
                                    }}
                                    type="primary"
                                    danger
                                    onClick={() =>
                                      onClickCancel(recordMini, ordersDetail)
                                    }>
                                    Cancel
                                  </Button>
                                </Permission>
                                <ModalUpdateInformationPendingDesign
                                  productUnsign={recordMini}
                                  record={ordersDetail}
                                />
                              </Space>
                            )
                          },
                        }
                      }
                      return { ...values }
                    })}
                    dataSource={arrayUnsign}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className={styles['order_logs']}>
          <div
            style={{
              color: 'black',
              fontWeight: '600',
              fontSize: '1rem',
              borderBottom: '1px solid rgb(236, 230, 230)',
              paddingBottom: '1rem',
              marginBottom: '1rem',
            }}>
            Order logs
          </div>
          {trackingOrders.map((e) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                marginBottom: 20,
              }}>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}>
                <Col
                  style={{ width: '100%' }}
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}>
                  <h3 style={{ marginLeft: 28 }}>{e.date}</h3>
                  <Timeline>
                    {e.listTracking.map((values) => {
                      return (
                        <Timeline.Item>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}>
                            <div style={{ width: '100%' }}>{values.name}</div>
                            <div
                              style={{ width: '20.5rem', marginLeft: '1rem' }}>
                              <div>{values.time}</div>
                              <div style={{ fontWeight: 700 }}>
                                {values.user}
                              </div>
                            </div>
                          </div>
                        </Timeline.Item>
                      )
                    })}
                  </Timeline>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
