import React, { useState, useEffect, useRef } from 'react'
import styles from './products.module.scss'

//components
import ModalPrintFilesProduct from 'components/products/ModalPrintFilesProduct'

//components antd
import {
  Col,
  Row,
  Table,
  Button,
  Select,
  Input,
  Badge,
  notification,
  Space,
  Popconfirm,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'

import { PERMISSIONS } from 'consts'
import Permission from 'components/permission'

//icons antd
import {
  PlusOutlined,
  ShoppingCartOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons'

//apis
import { getAllProduct, deleteProduct } from 'apis/product'
import { getSites } from 'apis/sites'

export default function Products() {
  const history = useHistory()

  const [listProductType, setListProductType] = useState([])
  const [dataProduct, setDataProduct] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [paramsFilter, setParamsFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [countProducts, setCountProducts] = useState(0)

  const [valueSearch, setValueSearch] = useState('')
  const [valueSite, setValueSite] = useState('default')
  const [valueProductType, setValueProductType] = useState('default')

  const { Option } = Select

  function handleChangeSites(value) {
    setPage(1)
    setValueSite(value)
    if (value === 'default') {
      delete paramsFilter.idSite
      _getAllProduct({ page: 1, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    } else {
      _getAllProduct({ page: 1, pageSize, idSite: value, ...paramsFilter })
      setParamsFilter({ ...paramsFilter, idSite: value })
    }
  }

  function handleChangeTypes(value) {
    setPage(1)
    setValueProductType(value)
    if (value === 'default') {
      delete paramsFilter.producttype
      _getAllProduct({ page: 1, pageSize, ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    } else {
      _getAllProduct({ page: 1, pageSize, producttype: value, ...paramsFilter })
      setParamsFilter({ ...paramsFilter, producttype: value })
    }
  }

  const _deleteProduct = async (product) => {
    try {
      setLoading(true)
      const body = {
        idSite: product.idSite,
        id: product.id,
      }
      const res = await deleteProduct(body)
      console.log(res)
      if (res.status === 200) {
        await _getAllProduct({ page, pageSize, ...paramsFilter })
        notification.success({ message: 'Delete product success!' })
      } else {
        notification.error({
          message: res.data.message || 'Delete product failed!',
        })
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value
      setPage(1)
      if (value === '') {
        delete paramsFilter.search
        _getAllProduct({ page: 1, pageSize, ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      } else {
        _getAllProduct({ page: 1, pageSize, search: value, ...paramsFilter })
        setParamsFilter({ ...paramsFilter, search: value })
      }
    }, 300)
  }

  const [sites, setSites] = useState([])

  const _getAllProduct = async (params) => {
    try {
      setLoading(true)
      console.log(params)
      const res = await getAllProduct(params)
      console.log(res)
      if (res.status === 200) {
        setDataProduct(res.data.data)
        setCountProducts(res.data.count)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getSitesData = async () => {
    try {
      const res = await getSites()
      if (res.status === 200) {
        setSites(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllProductType = async () => {
    try {
      const res = await getAllProduct()
      console.log(res)
      if (res.status === 200) {
        let listTitleProductType = []
        res.data.data.map((e) => {
          if (e.product_type) listTitleProductType.push(e.product_type.title)
        })
        setListProductType([...new Set(listTitleProductType)])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _getAllProduct({ page, pageSize })
    getSitesData()
    getAllProductType()
  }, [])

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text, record) => {
        if (record.permalink)
          return (
            <a href={record.permalink} target="_blank">
              {text}
            </a>
          )
        else return text
      },
    },
    {
      title: 'Product type',
      dataIndex: 'product_type',
      render: (text) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Badge status="default" text={text && text.title} />
          <span
            style={{
              marginBottom: 0,
              fontStyle: 'italic',
              fontSize: '0.68rem',
              marginLeft: '15px',
            }}>
            SKU: {text && text.sku}
          </span>
        </div>
      ),
    },
    {
      title: 'Site',
      dataIndex: 'idSite',
      render: (text, record) => {
        const site = sites.find((e) => e.site_name == text)
        return (
          <a href={site && site.url} target="_blank">
            {text}
          </a>
        )
      },
    },
    {
      title: 'Create date',
      dataIndex: 'dateCreated',
      render: (text) => text && text,
    },
    {
      title: 'Designs',
      dataIndex: 'printFiles',
      render: (text, record) =>
        text && (
          <ModalPrintFilesProduct
            product={record}
            printFiles={text}
            reload={() => _getAllProduct({ page, pageSize, ...paramsFilter })}
          />
        ),
    },
    {
      title: 'Option',
      render: (text, record) => (
        <Space size="middle">
          <Permission permissions={[PERMISSIONS.update_product]}>
            <EditOutlined
              style={{ color: '#40A9FF', fontSize: '1rem' }}
              onClick={() => {
                if (
                  localStorage.getItem('username') &&
                  JSON.parse(localStorage.getItem('username')) == record.idUser
                ) {
                  history.push({
                    pathname: '/add-product',
                    search: '?_id=' + record._id,
                  })
                } else
                  notification.error({
                    message: 'You not permission update this product',
                  })
              }}
            />
          </Permission>
          <Permission permissions={[PERMISSIONS.delete_product]}>
            <Popconfirm
              title="Are you sure delete this product?"
              onConfirm={() => _deleteProduct(record)}
              okText="Yes"
              cancelText="No">
              <DeleteOutlined style={{ color: 'orange', fontSize: '1rem' }} />
            </Popconfirm>
          </Permission>
        </Space>
      ),
    },
  ]

  return (
    <div className={styles['orders_manager']}>
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <div>
              <div className={styles['orders_manager_header_row_col_parent']}>
                <div
                  className={
                    styles['orders_manager_header_row_col_parent_icon']
                  }>
                  <ShoppingCartOutlined />
                </div>
                <div>PRODUCTS MANAGER</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_header_row_col']}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}>
            <Permission permissions={[PERMISSIONS.create_product]}>
              <div className={styles['orders_manager_header_row_col_right']}>
                <Link to="/add-product">
                  <Button type="primary" icon={<PlusOutlined />} size="large">
                    Add new
                  </Button>
                </Link>
              </div>
            </Permission>
          </Col>
        </Row>
      </div>
      <div className={styles['orders_manager_content']}>
        <Row className={styles['orders_manager_content_row']}>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}>
            <div>
              <Input.Search
                onChange={onSearch}
                value={valueSearch}
                className={styles['orders_manager_content_row_col_search']}
                placeholder="Search by title"
                allowClear
              />
            </div>
          </Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}>
            <div>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                className={styles['import_orders_content_select_child']}
                defaultValue="default"
                onChange={handleChangeSites}
                value={valueSite}>
                <Option value="default">All sites</Option>
                {sites.map((values, index) => {
                  return <Option value={values.site_name}>{values.name}</Option>
                })}
              </Select>
            </div>
          </Col>
          <Col
            className={styles['orders_manager_content_row_col']}
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}>
            <div>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                className={styles['import_orders_content_select_child']}
                defaultValue="default"
                onChange={handleChangeTypes}
                value={valueProductType}>
                <Option value="default">All product types</Option>
                {listProductType.map((values, index) => {
                  return <Option value={values}>{values}</Option>
                })}
              </Select>
            </div>
          </Col>
          <Row justify="end" style={{ width: '100%' }}>
            <Button
              type="link"
              onClick={() => {
                setParamsFilter({})
                setPage(1)
                setValueSearch('')
                setValueSite('default')
                setValueProductType('default')
                _getAllProduct({ page: 1, pageSize })
              }}
              style={{
                visibility: !Object.keys(paramsFilter).length && 'hidden',
              }}>
              Clear all filter
            </Button>
          </Row>
        </Row>
      </div>
      <div className={styles['orders_manager_tabs_table']}>
        <Table
          className={styles['orders_manager_tabs_table_child']}
          columns={columns}
          dataSource={dataProduct}
          loading={loading}
          size="small"
          pagination={{
            current: page,
            defaultPageSize: 20,
            pageSizeOptions: [20, 30, 50, 100],
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setPage(page)
              setPageSize(pageSize)
              _getAllProduct({ page, pageSize, ...paramsFilter })
            },
            total: countProducts,
          }}
        />
      </div>
    </div>
  )
}
