import React from 'react'
import styles from './exportcsv.module.scss'
import { ToTopOutlined } from '@ant-design/icons'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export default function ExportTrackingInOrders({
  fileName,
  name,
  getOrdersByExportOrder,
}) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData, fileName, name) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div
      style={{ minWidth: 130 }}
      onClick={async () => {
        const data = await getOrdersByExportOrder()
        let dataExport = []
        data.map((e) => {
          e.list_product_fulfill.map((v) => {
            dataExport.push({
              'Site ID': e.key_design || '',
              'Order ID': e.id_ecommerce || '',
              'Order Item ID': v.id || '',
              'Customer email': e.shipping.email || e.customer.email || '',
              'Customer phone': e.shipping.phone || e.customer.phone || '',
              SKU: v.sku || '',
              'Tracking Carrier': v.carrier || '',
              'Tracking Number': v.tracking_number || '',
            })
          })

          e.list_product_unsign.map((v) => {
            dataExport.push({
              'Site ID': e.key_design || '',
              'Order ID': e.id_ecommerce || '',
              'Order Item ID': v.id || '',
              'Customer email': e.shipping.email || e.customer.email || '',
              'Customer phone': e.shipping.phone || e.customer.phone || '',
              SKU: v.sku || '',
              'Tracking Carrier': v.carrier || '',
              'Tracking Number': v.tracking_number || '',
            })
          })
        })

        exportToCSV(dataExport, fileName)
      }}
      className={styles['import_orders_title_right']}>
      <div className={styles['import_orders_title_left_icon']}>
        <ToTopOutlined
          style={{ fontSize: '1.25rem', color: '#2A53CD', marginTop: '0.5rem' }}
        />
      </div>
      <div style={{ color: '#2A53CD', width: 'max-content' }}>{name}</div>
    </div>
  )
}
