import * as types from "../../../constants/index";
// nhận data từ server
const initialState = {};
var dataEditSite = (state = initialState, action) => {
  switch (action.type) {
    case types.DATA_EDIT_SITE: {
      //   
      state = action.data;

      return state;
      //   state = action.data;
      //   
      //   return state;
    }
    default: return state;
  }
};
export default dataEditSite;
