import { ACTION, SEND_USERS } from "consts";

var initialState = [];

const sendUsers = (state = initialState, action) => {
  switch (action.type) {
    case SEND_USERS: {

      state = action.data;
      localStorage.setItem('sendUsers', JSON.stringify(action.data));
      return [...state];
    }

    default:
      return state;
  }
};

export default sendUsers;
