import React from 'react'
import styles from './exportcsv.module.scss'
import { ToTopOutlined } from '@ant-design/icons'

import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

const ExportCSV = ({ fileName, name, getOrdersByExportOrder }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = (csvData, fileName, name) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div
      style={{ minWidth: 130 }}
      onClick={async () => {
        const data = await getOrdersByExportOrder()

        let dataExport = []
        data.map((e) => {
          e.list_product_unsign.map((v) => {
            dataExport.push({
              'Tracking Number': v.tracking_number || '',
              'Site ID': e.key_design || '',
              'Order ID': e.id_ecommerce || '',
              'Order item Id': v.id || '',
              'Site name': e.id_site || '',
              'Product name': (v.product_type && v.product_type.title) || '',
              Status: v.status || '',
              'Shipping Name': `${
                e.shipping.first_name || e.customer.first_name
              } ${e.shipping.last_name || e.customer.last_name}`,
              'Shipping address1':
                e.shipping.address_1 || e.customer.address_1 || '',
              'Shipping address2':
                e.shipping.address_2 || e.customer.address_2 || '',
              'Shipping city': e.shipping.city || e.customer.city || '',
              'Shipping email': e.shipping.email || e.customer.email || '',
              'Shipping phone': e.shipping.phone || e.customer.phone || '',
              'Shipping Province code':
                e.shipping.province_code || e.customer.province_code || '',
              'Shipping country code':
                e.shipping.country_code || e.customer.country_code || '',
              Postcode: e.shipping.postcode || e.customer.postcode || '',
              Phone: e.shipping.phone || e.customer.postcode || '',
              'Custom info':
                v.custom_info && v.custom_info.length
                  ? v.custom_info
                      .map((e, index) => {
                        if (index === v.custom_info.length - 1)
                          return `${e.name}: ${e.value}`
                        return `${e.name}: ${e.value}|`
                      })
                      .join('')
                  : '',
              'Customer note': v.custom_note || e.note || '',
              'Product name': v.name || '',
              Supplier: v.supplier || '',
              'Item Sku': v.sku || '',
              'SKU Custom': v.rename_for_Sup || '',
              'Item Quantity': v.quantity || '',
              'Sale cost': e.total_price || '',
              'Print File': v.printFiles ? v.printFiles.join(', ') : '',
              'Mockup File': v.mockup ? v.mockup.join(', ') : '',
              'Create Date': e.time_created,
              'Order Items: Product Variation':
                v.variants && v.variants.length
                  ? v.variants
                      .map((e, index) => {
                        if (index === v.variants.length - 1)
                          return `${e.name || e.option}: ${e.value}`
                        return `${e.name || e.option}: ${e.value}|`
                      })
                      .join('')
                  : '',
              ...Object.fromEntries(
                v.variants.map((e) => [
                  (e.name && e.name.toLowerCase()) ||
                    (e.option && e.option.toLowerCase()),
                  e.value,
                ])
              ),
            })
          })

          e.list_product_fulfill.map((v) => {
            dataExport.push({
              'Tracking Number': v.tracking_number || '',
              'Site ID': e.key_design || '',
              'Order ID': e.id_ecommerce || '',
              'Order item Id': v.id || '',
              'Site name': e.id_site || '',
              'Product name': (v.product_type && v.product_type.title) || '',
              Status: v.status || '',
              'Shipping Name': `${
                e.shipping.first_name || e.customer.first_name
              } ${e.shipping.last_name || e.customer.last_name}`,
              'Shipping address1':
                e.shipping.address_1 || e.customer.address_1 || '',
              'Shipping address2':
                e.shipping.address_2 || e.customer.address_2 || '',
              'Shipping city': e.shipping.city || e.customer.city || '',
              'Shipping email': e.shipping.email || e.customer.email || '',
              'Shipping phone': e.shipping.phone || e.customer.phone || '',
              'Shipping Province code':
                e.shipping.province_code || e.customer.province_code || '',
              'Shipping country code':
                e.shipping.country || e.customer.country || '',
              Postcode: e.shipping.postcode || e.customer.postcode || '',
              Phone: e.shipping.phone || e.customer.postcode || '',
              'Custom info':
                v.custom_info && v.custom_info.length
                  ? v.custom_info
                      .map((e, index) => {
                        if (index === v.custom_info.length - 1)
                          return `${e.name}: ${e.value}`
                        return `${e.name}: ${e.value}|`
                      })
                      .join('')
                  : '',
              'Customer note': v.custom_note || e.note || '',
              'Product name': v.name || '',
              Supplier: v.supplier || '',
              'Item Sku': v.sku || '',
              'SKU Custom': v.rename_for_Sup || '',
              'Item Quantity': v.quantity || '',
              'Sale cost': e.total_price || '',
              'Print File': v.printFiles ? v.printFiles.join(', ') : '',
              'Mockup File': v.mockup ? v.mockup.join(', ') : '',
              'Create Date': e.time_created,
              'Order Items: Product Variation':
                v.variants && v.variants.length
                  ? v.variants
                      .map((e, index) => {
                        if (index === v.variants.length - 1)
                          return `${e.name || e.option}: ${e.value}`
                        return `${e.name || e.option}: ${e.value}|`
                      })
                      .join('')
                  : '',
              ...Object.fromEntries(
                v.variants.map((e) => [
                  (e.name && e.name.toLowerCase()) ||
                    (e.option && e.option.toLowerCase()),
                  e.value,
                ])
              ),
            })
          })
        })
        exportToCSV(dataExport, fileName)
      }}
      className={styles['import_orders_title_right']}>
      <div className={styles['import_orders_title_left_icon']}>
        <ToTopOutlined
          style={{ fontSize: '1.25rem', color: '#2A53CD', marginTop: '0.5rem' }}
        />
      </div>
      <div style={{ color: '#2A53CD' }}>{name}</div>
    </div>
  )
}

export default ExportCSV
