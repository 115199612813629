import React, { useEffect, useState } from 'react'
import styles from './login.module.scss'

import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import logo from 'assets/img/logo.png'
import { ACTION, ROUTES, VERSION_APP } from 'consts'
import { decodeJWT } from 'utils'
import { useDispatch, useSelector } from 'react-redux'

//antd
import { Form, Input, Button, Checkbox, notification, Row } from 'antd'

//icons antd
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons'

//apis
import { login, register, getRecoveryCode } from 'apis/auth'

export default function Login(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const [form] = Form.useForm()

  const settingApp = useSelector((state) => state.setting)
  const [status, setStatus] = useState(1)

  const params = queryString.parse(location.search)

  const onClickStatus = (data) => setStatus(data)

  const onFinish = async (values) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      var objectLogin = {
        username: values.emailLogin,
        password: values.passwordLogin,
      }
      const res = await login(objectLogin)
      console.log(res)
      if (res.status === 200) {
        if (res.data.mess) notification.error({ message: res.data.mess })
        else {
          // decode thông tin user
          const payload = decodeJWT(res.data.accessToken)
          //Nếu tài khoản user đã xác thực rồi thì
          //vào giao diện chính
          if (payload && payload.is_active) {
            dispatch({ type: ACTION.LOGIN, data: res.data })
            history.push(ROUTES.DASHBOARD)
            notification.success({ message: 'Login success' })
          }
          //Nếu user tồn tại nhưng chưa được xác
          //thực thì sẽ qua giao diện
          //xác thực với thông tin user được truyền kèm
          else {
            await getRecoveryCode(payload && payload.username) //send otp vao email
            history.push({
              pathname: ROUTES.MAIL,
              state: payload && {
                ...payload,
                passwordLogin: values.passwordLogin,
              },
            })
            notification.warning({ message: 'Please authentication otp!' })
          }
        }
      } else {
        notification.error({
          message: 'Error',
          description:
            res.data.message ||
            'Login failed, wrong username or password, please try again!',
        })
      }
      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  const onFinishRegister = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true })
      const registers = form.getFieldValue()
      const body = {
        ...registers,
        phone: '',
        birthday: '',
        address: '',
        role: 'Seller', //Mặc định khi tạo là role seller
        displayName: '',
        permissions: [],
      }
      console.log(body)
      const res = await register(body)
      console.log(res)
      //Khi đăng kí thành công thì cho qua giao diện xác thực otp
      //đồng thời truyền kèm thêm data đc trả về (data của user vừa được đăng kí)
      //khi request api register
      if (res.status === 200) {
        history.push({
          pathname: ROUTES.MAIL,
          state: { ...res.data, passwordLogin: body.password },
        })
        notification.success({ message: 'Register success' })
      } else {
        if (res.data.message)
          notification.error({
            message: 'Error',
            description: res.data.message,
          })
        else
          notification.error({
            message: 'Error',
            description: 'Register failed, please try again!',
          })
      }

      dispatch({ type: ACTION.LOADING, data: false })
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false })
    }
  }

  useEffect(() => {
    if (params && Object.keys(params).length) {
      onFinish({ emailLogin: params.username, passwordLogin: params.password })
    }
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [status])

  return (
    <div className={styles['login']}>
      <div className={styles['login_img_parent']}>
        <img
          className={styles['login_img']}
          src={settingApp.link_logo || ''}
          alt=""
        />
      </div>
      <div className={styles['login_choose_parent']}>
        <div className={styles['login_choose']}>
          <div
            onClick={() => onClickStatus(1)}
            className={
              status === 1
                ? styles['login_choose_status_active']
                : styles['login_choose_status']
            }>
            Login
          </div>
          <div
            className={
              status === 2
                ? styles['login_choose_status_active']
                : styles['login_choose_status']
            }
            onClick={() => onClickStatus(2)}>
            Register
          </div>
        </div>
      </div>
      {status === 1 ? (
        <Form
          className={styles['login_bottom']}
          form={form}
          onFinish={onFinish}>
          <Form.Item
            className={styles['login_bottom_email']}
            name="emailLogin"
            rules={[
              { required: true, message: 'Please input your username!' },
            ]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            className={styles['login_bottom_password']}
            name="passwordLogin"
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <div className={styles['login_bottom_left']}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox className={styles['login_bottom_left_checkbox']}>
                Remember me
              </Checkbox>
            </Form.Item>
            <Link to={ROUTES.FORGOT_PASSWORD}>
              <div className={styles['login_bottom_left_forgot_password']}>
                Forgot password?
              </div>
            </Link>
          </div>
          <div className={styles['login_bottom_left_button_parent']}>
            <Form.Item>
              <Button
                className={styles['login_bottom_left_button']}
                type="primary"
                htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : (
        <Form
          className={styles['login_bottom']}
          form={form}
          onFinish={onFinishRegister}>
          <Form.Item
            className={styles['login_bottom_email']}
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
            style={{ marginBottom: 24 }}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            className={styles['login_bottom_password']}
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
            ]}>
            <Input.Password
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            className={styles['login_bottom_email']}
            name="mail"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Please input or correct format email!',
              },
            ]}>
            <Input
              size="large"
              prefix={<GoogleOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            className={styles['login_bottom_email']}
            name="first_name"
            rules={[
              { required: true, message: 'Please input your first name!' },
            ]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First name"
            />
          </Form.Item>
          <Form.Item
            className={styles['login_bottom_email']}
            name="last_name"
            rules={[
              { required: true, message: 'Please input your last name!' },
            ]}>
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Last name"
            />
          </Form.Item>
          <div
            style={{ zIndex: '999' }}
            className={styles['login_bottom_left_button_parent']}>
            <Form.Item>
              <Button
                className={styles['login_bottom_left_button']}
                type="primary"
                htmlType="submit">
                Create Account
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
      {status === 1 ? (
        <div className={styles['copyright']}>
          <div>
            <div>Copyright © 2021 Vie. All rights reserved.</div>
            <Row justify="center">Version: {VERSION_APP}</Row>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
