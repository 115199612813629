import * as types from "../../../constants/index";
// nhận data từ server
const initialState = false;


var activeAccount = (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVE_ACCOUNT: {

      var activeAccount = {
        activeAccount: action.data.status
      }
      localStorage.setItem('activeAccount', JSON.stringify(activeAccount));




      //   var {status} = action.data;
      //   
      //   state = status;

      //   
      //   return state;
    }
    // case types.TEST: {
    //   
    //   state = action.data;
    //   return state;
    // }
    default: return state;
  }
};
export default activeAccount;
