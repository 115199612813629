import styles from './roles.module.scss'
import { Col, Row, Table, Button } from 'antd'
import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { ROUTES } from 'consts'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    width: 150,
  },
  {
    title: 'Site',
    dataIndex: 'site',
    width: 150,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    width: 150,
  },
]

const data = []
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    name: `username ${i}`,
    phoneNumber: `038494349${i}`,
    site: `Website bán hàng Viesoftware`,
    role: `Seller ${i}`,
  })
}
export default function Roles() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loading, setLoading] = useState(false)
  const start = () => {
    setLoading(true)
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 1000)
  }

  const onSelectChange = (selectedRowKeys) => {

    setSelectedRowKeys(selectedRowKeys)
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }
  const hasSelected = selectedRowKeys.length > 0
  return (
    <div className={styles['users_manager']}>
      <div className={styles['users_manager_title']}>
        <Row className={styles['users_manager_title_row']}>
          <Col
            className={styles['users_manager_title_row_col']}
            xs={24}
            sm={11}
            md={11}
            lg={11}
            xl={11}
          >
            <div className={styles['users_manager_title_row_col_title']}>
              <div>LIST OF USERS</div>
            </div>
          </Col>
          <Col
            className={styles['users_manager_title_row_col']}
            xs={24}
            sm={11}
            md={11}
            lg={11}
            xl={11}
          >
            <div className={styles['users_manager_title_row_col_button']}>
              <Link to={ROUTES.USERS}>
                <Button type="primary">Decentralized management</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      {/* <div className={styles["users_manager_note"]}>
          <div className={styles["users_manager_note_top"]}>
            Role management and decentralization
          </div>
          <div>
            Support for adding, decentralizing and managing, deleting roles
          </div>
        </div> */}
      <div className={styles['users_manager_table']}>
        <Table
          className={styles['users_manager_table_child']}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 240 }}
        />
      </div>
    </div>
  )
}
