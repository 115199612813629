import styles from "./content.module.scss";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { CKEditor } from "ckeditor4-react";
import ReactHtmlParser, {

} from "html-react-parser";

import {

  FileTextOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Popover,

  Row,

  Col,

  Tabs,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
const content = (
  <div className={styles["sort"]}>
    <div>Sort by connect date 1</div>
    <div>Sort by connect date 2</div>
  </div>
);
const columns = [
  {
    title: "Title",
    dataIndex: "titleNew",
    width: 150,
  },
  {
    title: "Type",
    dataIndex: "type",
    width: 150,
  },
  {
    title: "Content",
    dataIndex: "content",
    width: 150,
  },
  {
    title: (
      <Popover content={content}>
        <div>Connect date</div>
      </Popover>
    ),
    dataIndex: "connectDate",
    width: 150,
  },
  {
    title: "Description",
    dataIndex: "descriptionNew",
    width: 150,
  },
];
const { TabPane } = Tabs;

function callback(key) {

}
export default function SettingContent() {
  const newsWatchedData = useSelector((state) => {
    return state.newsWatched;
  });

  const sendUsers = useSelector((state) => {
    return state.sendUsers;
  });

  const dispatch = useDispatch();
  const [status, setStatus] = useState(1);
  const [statusRight, setStatusRight] = useState(1);
  const onClickStatusLeft = (data) => {
    setStatus(data);
  };
  const onClickStatusRight = (data) => {
    setStatusRight(data);
  };
  const [dataEditor6Hours, setDataEditor6Hours] = useState("");
  const [dataEditor24Hours, setDataEditor24Hours] = useState("");
  const handleChangeEditor6Hours = (value) => {
    // setDataEditor6Hours(ReactHtmlParser(editor.getData()));
    setDataEditor6Hours(value);
  };
  const handleChangeEditor24Hours = (e, editor) => {
    // setDataEditor24Hours(ReactHtmlParser(editor.getData()));
    setDataEditor24Hours(editor.getData());
  };
  const showTab = () => {
    var result;
    if (status === 1) {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.1 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.1 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    } else if (status === 2) {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.2 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.2 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    } else {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >

          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.3 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.3 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    }
    return result;
  };
  const showTabRight = () => {
    var result;
    if (statusRight === 1) {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.1 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.1 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    } else if (statusRight === 2) {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >
          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.2 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.2 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    } else {
      result = (
        <Col
          style={{
            width: "100%",
            borderLeft: "2.5px solid rgb(231, 218, 218)",
            paddingLeft: "0.5rem",
          }}
          xs={24}
          sm={24}
          md={24}
          lg={20}
          xl={20}
        >

          <div
            style={{
              width: "100%",
              marginLeft: "1rem",
              paddingRight: '1rem',
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 6.3 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "1rem", width: '100%' }}>
              <div style={{ marginBottom: "1rem" }}>After 24.3 hours</div>
              <div

                style={{ width: "100%", maxWidth: "100%", overflow: "auto", border: '1px solid grey' }}
              >
                {" "}
                <CKEditor

                  data="<p>Hi [first_name],</p><p>Your order [order_name] ([line_item_sku]) has been approved into Viesoftware system for<strong> 6 hours. </strong>However, design files have not been uploaded completely. Kindly complete it so that your order can be processed as soon as possible.</p><p>Kind regards,</p><p>Viesoftware Team.</p>"
                  onChange={(e) => {
                    handleChangeEditor6Hours(e.editor.getData());
                  }}
                />
              </div>
            </div>
          </div>

        </Col>
      );
    }
    return result;
  };
  return (
    <div className={styles["content_setting"]}>
      <div className={styles["content_setting_title"]}>
        <div>
          <FileTextOutlined />
        </div>
        <div>Your content</div>
      </div>
      <div
        style={{ width: "100%" }}
        className={styles["content_setting_parent"]}
      >
        <Tabs
          style={{ width: "100%" }}
          size="large"
          defaultActiveKey="1"
          onChange={callback}
        >
          <TabPane
            tab={
              <span>
                <UserOutlined />
                Orders status content
              </span>
            }
            key="1"
          >
            <Row className={styles["tab1"]}>
              <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                <div className={styles["tab1_left"]}>
                  <div
                    className={
                      status === 1
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusLeft(1)}
                  >
                    Pending design
                  </div>
                  <div
                    className={
                      status === 2
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusLeft(2)}
                  >
                    Action required
                  </div>
                  <div
                    className={
                      status === 3
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusLeft(3)}
                  >
                    Need pay
                  </div>
                </div>
              </Col>
              {showTab()}
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <BellOutlined />
                Seller next level content
              </span>
            }
            key="2"
          >
            <Row className={styles["tab1"]}>
              <Col xs={24} sm={24} md={24} lg={3} xl={3}>
                <div className={styles["tab1_left"]}>
                  <div
                    className={
                      statusRight === 1
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusRight(1)}
                  >
                    Diamond
                  </div>
                  <div
                    className={
                      statusRight === 2
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusRight(2)}
                  >
                    Gold
                  </div>
                  <div
                    className={
                      statusRight === 3
                        ? styles["tab1_left_menu_active"]
                        : styles["tab1_left_menu"]
                    }
                    onClick={() => onClickStatusRight(3)}
                  >
                    Sliver
                  </div>
                </div>
              </Col>
              {showTabRight()}
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
