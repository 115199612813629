import { combineReducers } from 'redux'
import login from './login/login'
import register from './register/register'
import activeAccount from './activeAccount/activeAccount'
import site from './site/site'
import passwordForget from './passwordForget/passwordForget'
import newPassword from './newPassword/newPassword'
import dataEditSite from './dataEditSite/dataEditSite'
import products from './products/products'
import dataVariant from './dataVariant/dataVariant'
import dataPrintFile from './dataPrintFile/dataPrintFile'
import newsWatched from './newsWatched/newsWatched'
import sendUsers from './sendUsers/sendUsers'
import orders from './orders/orders'
import news from './news/news'
import newsIsSeen from './newsIsSeen/newsIsSeen'
import setting from './setting'
const rootReducers = combineReducers({
  login: login,
  passwordForget: passwordForget,
  register: register,
  activeAccount: activeAccount,
  newPassword: newPassword,
  site: site,
  dataEditSite: dataEditSite,
  products: products,
  dataVariant,
  dataPrintFile,
  newsWatched,
  sendUsers,
  orders,
  news,
  newsIsSeen,
  setting,
})
export default rootReducers
