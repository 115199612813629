import { get, post } from './httpClient'

// export const getOrdersTracking = () => get('/order')
export const getOrdersTracking = (params) =>
  get('/order/gettrackingorder', params)
export const updateOrdersTracking = (object) =>
  post('/order/updatetrackingorder', object)
export const filterOrderTracking = (object) =>
  get('/order/gettrackingorder', object)
export const filterOrderTrackingByCarrier = (object) =>
  get('/order/gettrackingorder', object)
export const filterOrderDetail = (params) =>
  get('/order/getorderdetail', { ...params })
