import React, { useEffect, useState, useRef } from 'react'

import { getLogs } from 'apis/logs'
import { Table, Select, Row, Space, Col, Input, DatePicker, Button } from 'antd'
import moment from 'moment'

export default function LogsApp() {
  const dateFormat = 'YYYY/MM/DD'

  const [allLog, setAllLog] = useState([])
  const [countLogs, setCountLogs] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({})
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1) //pagination, current page
  const [pageSize, setPageSize] = useState(20) //pagination, current page size

  const [valueSearch, setValueSearch] = useState('')
  const [valueType, setValueType] = useState()
  const [valueDate, setValueDate] = useState(null)

  const typingTimeoutRef = useRef(null)

  const columns = [
    {
      title: 'Action',
      dataIndex: 'action',
    },
    {
      title: 'Content',
      dataIndex: 'content',
    },
    {
      title: 'Time',
      dataIndex: 'time',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
  ]

  const TYPES = ['order', 'product', 'site', 'supplier', 'seller']

  const _getLogs = async (params) => {
    try {
      setLoading(true)
      const res = await getLogs(params)
      console.log(res)
      if (res.status === 200) {
        setCountLogs(res.data.count)
        setAllLog(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const value = e.target.value
      setPage(1)

      if (value) {
        setParamsFilter({ ...paramsFilter, search: value })
        _getLogs({ ...paramsFilter, search: value, page: 1, pageSize })
      } else {
        delete paramsFilter.search
        setParamsFilter({ ...paramsFilter })
        _getLogs({ ...paramsFilter, page: 1, pageSize })
      }
    }, 350)
  }

  useEffect(() => {
    _getLogs({ page, pageSize })
  }, [])

  return (
    <div style={{ margin: 16, backgroundColor: 'white', padding: 15 }}>
      <h2>Logs App</h2>
      <Row style={{ margin: '20px 0' }}>
        <Space size="large">
          <Col>
            <div>Search by content</div>
            <Input.Search
              placeholder="Search..."
              style={{ width: 350 }}
              onChange={onSearch}
              value={valueSearch}
              allowClear
            />
          </Col>
          <Col>
            <div>Filter by type</div>
            <Select
              onChange={(value) => {
                setPage(1)
                setValueType(value)
                if (value) {
                  setParamsFilter({ ...paramsFilter, type: value })
                  _getLogs({ ...paramsFilter, type: value, page: 1, pageSize })
                } else {
                  delete paramsFilter.type
                  setParamsFilter({ ...paramsFilter })
                  _getLogs({ ...paramsFilter, page: 1, pageSize })
                }
              }}
              value={valueType}
              placeholder="Filter by type"
              allowClear
              style={{ width: 250 }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {TYPES.map((e) => (
                <Select.Option value={e}>{e}</Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <div>Filter by Date</div>
            <DatePicker
              format={dateFormat}
              placeholder="Filter by date"
              value={valueDate ? moment(valueDate, dateFormat) : valueDate}
              onChange={(value, dateString) => {
                setPage(1)
                
                if (dateString) {
                  setValueDate(dateString)
                  setParamsFilter({ ...paramsFilter, date: dateString })
                  _getLogs({
                    ...paramsFilter,
                    date: dateString,
                    page: 1,
                    pageSize,
                  })
                } else {
                  setValueDate(null)
                  delete paramsFilter.date
                  setParamsFilter({ ...paramsFilter })
                  _getLogs({ ...paramsFilter, page: 1, pageSize })
                }
              }}
              style={{ width: 250 }}
            />
          </Col>
          <Col>
            <Button
              type="link"
              style={{
                marginTop: 20,
                display: !Object.keys(paramsFilter).length && 'none',
              }}
              onClick={() => {
                setValueSearch('')
                setValueDate(null)
                setValueType()
                setParamsFilter({})
                setPage(1)

                _getLogs({
                  page: 1,
                  pageSize,
                })
              }}
            >
              Clear all filter
            </Button>
          </Col>
        </Space>
      </Row>
      <Table
        columns={columns}
        dataSource={allLog}
        loading={loading}
        pagination={{
          current: page,
          defaultPageSize: 20,
          pageSizeOptions: [10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setPage(page)
            setPageSize(pageSize)
            _getLogs({ page, pageSize, ...paramsFilter })
          },
          total: countLogs,
        }}
      />
    </div>
  )
}
