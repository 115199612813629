import {
  Col,
  InputNumber,
  Row,
  Popover,
  DatePicker,
  Modal,
  Drawer,
  Table,
  notification,
  Form,
  Button,
  Select,
  Input,
} from "antd";

import moment from "moment";

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTION, PERMISSIONS } from "consts";
import Permission from "components/permission";
import {
  getSeller,
  getAllSellerSelect,
  selectSeller,
  searchTransactionDate,
  searchTransaction,
  createTransaction,
  searchTransactionAction,
  updateStatusTransaction,
} from "apis/transaction";
import styles from "./seller-transaction.module.scss";
import {
  InfoCircleOutlined,
  EditOutlined,
  WalletOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { getSites } from "apis/sites";
import { updateStatusOrders } from "apis/order";

export default function SellerTransaction() {
  const dispatch = useDispatch();
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkStrictly, setCheckStrictly] = useState(false)
  const [actions, setActions] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [visible, setVisile] = useState(false);
  const [status, setStatus] = useState(false);
  const [seller, setSeller] = useState([]);
  const [sellerSelect, setSellerSelect] = useState([]);
  const [balance, setBalance] = useState({});
  const [visibleDeposit, setVisileDeposit] = useState(false);
  const setModal1Visible1 = (modal1Visible) => {
    setModal1Visible(modal1Visible);
  };

  // const showDrawer = () => {
  //   setVisibleDrawer(true)

  // };

  // const onClose = () => {
  //   setVisibleDrawer(false)
  // };

  const setModal1Visible2 = (modal2Visible) => {
    setModal2Visible(modal2Visible);
  };
  const setModal2VisibleModal = (modal2Visible) => {
    setModal2Visible(modal2Visible);
  };
  const { Option } = Select;
  function handleChange(value) {

  }
  function handleChangeProductTypeDrawer(value) {

  }
  function handleChangeSitesDrawer(value) {

  }
  function handleChangeStatusProductDrawer(value) {

  }
  const searchTransactionSitesData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });

      const res = await searchTransaction({ site: value });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function handleChangeSites(value) {

    if (value === 'default') {
      getSellerData()
    }
    else {
      searchTransactionSitesData(value);
    }

  }
  const searchTransactionActionData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });

      const res = await searchTransactionAction({ action: value });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function handleChangeActions(value) {

    if (value === "default") {
      getSellerData();
    } else {
      searchTransactionActionData(value);
    }
  }
  const searchSellerStatusData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });

      const res = await searchTransaction({ status: value });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function handleChangeStatus(value) {

    if (value === 'default') {
      getSellerData()
    }
    else {
      searchSellerStatusData(value);
    }
  }
  const showDrawerDeposit = () => {
    setVisileDeposit(true);
  };
  const onCloseDeposit = () => {
    setVisileDeposit(false);
  };
  useEffect(() => {
    var data = JSON.parse(localStorage.getItem("balance"));
    setBalance(data);
  }, []);
  const { Search } = Input;
  const searchTransactionData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });

      const res = await searchTransaction({ search: value });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const typingTimeoutRef = useRef(null);
  const onSearch = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      const value = e.target.value;
      searchTransactionData(value);
    }, 300);
  };
  const { RangePicker } = DatePicker;
  const searchTransactionDateData = async (data1, data2) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await searchTransactionDate({
        startDate: data1,
        endDate: data2,
      });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
      // openNotification();
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  function onChange(dates, dateStrings) {
    if (dateStrings[0] && dateStrings[0].length > 0 && dateStrings[1] && dateStrings[1].length > 0) {
      while (dateStrings[0].indexOf("-") !== -1) {
        dateStrings[0] = dateStrings[0].replace("-", "/");
      }
      while (dateStrings[1].indexOf("-") !== -1) {
        dateStrings[1] = dateStrings[1].replace("-", "/");
      }


      searchTransactionDateData(dateStrings[0], dateStrings[1]);
    }
    else {
      getSellerData()
    }

  }
  function onChangeDrawer(dates, dateStrings) {


  }
  const showDrawer = () => {
    setVisile(true);
  };
  const onClose = () => {
    setVisile(false);
  };
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const selectSellerData = async (value) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await selectSeller({ idSeller: value });

      if (res.status === 200) setSeller(res.data);
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  function handleChangeSeller(value) {
    if (value === "default") {
      getSellerData();
    } else {
      selectSellerData(value);
    }
  }
  const [selectedRowKeysMain, setSelectedRowKeysMain] = useState([])
  const onSelectChange = (selectedRowKeys, checkStrictly) => {


    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeysMain(checkStrictly)
  };
  const rowSelection = {
    selectedRowKeys, checkStrictly,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const contentAvailable = (
    <div className={styles["popover"]}>
      <div>
        Available amount = Approved Deposit - Paid amount: The money amount that
        is available for charging orders
      </div>
    </div>
  );
  const contentUpcoming = (
    <div className={styles["popover"]}>
      <div>
        Upcoming amount = Sum of your non-charge orders – Available amount: Here
        the money you need to make deposit so that all non-charged orders can be
        paid and processed.
      </div>
    </div>
  );
  const contentPending = (
    <div className={styles["popover"]}>
      <div>
        Pending deposit = All Deposit that Admin not Approved nor Rejected yet
      </div>
    </div>
  );
  const contentPaid = (
    <div className={styles["popover"]}>
      <div>Paid amount = Your total money that is charged on your orders.</div>
    </div>
  );
  const openNotification = () => {
    notification.success({
      message: "Success",
      description: "Successful transaction creation!!!",
    });
  };
  const openNotificationUpdateStatus = () => {
    notification.success({
      message: "Success",
      description: "Status update successful!!!",
    });
  };
  const openNotificationUpdateStatusError = () => {
    notification.error({
      message: "Error",
      description: "Can only be changed from pending to approved!!!",
    });
  };
  const createTransactionData = async (body) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await createTransaction(body);

      if (res.status === 200) {
        await getSellerData();
        openNotification();
        onCloseDeposit();
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const onFinishDeposit = (values) => {

    var role = JSON.parse(localStorage.getItem("role"));
    var username = JSON.parse(localStorage.getItem("username"));
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDay();
    var object = {
      idTransaction: values.transactionID,
      amount: values.amountMoney,
      discount: 0,
      createdBy: username,
      createdDate: `${year}/${month}/${day}`,
      idSite: "",
      note: values.note,
      status: role === "Admin" ? "approve" : "pending",
      idSeller: username,
      idSupplier: values.sendTo,
    };
    if (actions < 0) {
      object.action = "SubtractCustom";
    } else if (actions === 0) {
      object.action = "Deposit";
    } else {
      object.action = "AddCustom";
    }


    createTransactionData(object);
  };

  const onFinishFailedDeposit = (errorInfo) => {

  };
  function onChangeFormatter(value) {

    setActions(value);
  }
  const [sellerTemp, setSellerTemp] = useState([]);
  const [sellerTempStatus, setSellerTempStatus] = useState([]);
  const getSellerData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getSeller();

      if (res.status === 200) {
        setSeller(res.data);
        setSellerTemp(res.data)
        setSellerTempStatus(res.data)
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  useEffect(() => {
    getSellerData();
  }, []);
  const getAllSellerSelectData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getAllSellerSelect();

      if (res.status === 200) setSellerSelect(res.data.data);
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  useEffect(() => {
    getAllSellerSelectData();
  }, []);
  const Setting = (props) => (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      }}
      site={props.site}
    >
      <Permission permissions={[PERMISSIONS.update_balance]}>
        {props.site.status === "pending" ? (
          <div
            onClick={() => onClickTableEdit(props)}
            style={{ marginRight: "0.5rem", cursor: "pointer" }}
          >
            <EditOutlined style={{ color: "#36A0FB", fontSize: "1.25rem" }} />
          </div>
        ) : (
          <div style={{ marginRight: "0.5rem", cursor: "pointer" }}>
            <EditOutlined style={{ color: "grey", fontSize: "1.25rem" }} />
          </div>
        )}
      </Permission>
    </div>
  );
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   width: 150,
    // },
    {
      title: "Seller",
      dataIndex: "idSeller",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 150,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      width: 150,
    },
    {
      title: "Transaction ID",
      dataIndex: "idTransaction",
      width: 150,
    },
    {
      title: "Site",
      dataIndex: "idSite",
      width: 150,
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      width: 150,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 150,
      render: (text) => text && moment(text).format('YYYY-MM-DD')
    },
    // {
    //   title: "Setting",
    //   dataIndex: "setting",
    //   width: 150,
    //   render: (text, record) => <Setting site={record} />,
    // },
  ];
  const onClickTableEdit = (props) => {

    setModal2Visible(true);
    const updateStatus = form.getFieldValue();
    updateStatus.status = props.site.status;
    updateStatus.id = props.site._id;
    updateStatus.seller = props.site.idSeller;
    updateStatus.amount = props.site.amount;
    updateStatus.discount = props.site.discount;
    updateStatus.transactionID = props.site.idTransaction;
    updateStatus.site = props.site.idSite;
    updateStatus.createdBy = props.site.createdBy;
    updateStatus.createdDate = props.site.createdDate;
  };
  const onFinishUpdateTransaction = (values) => {

    if (values.status === "pending") {
      openNotificationUpdateStatusError();
      // setModal2VisibleModal(false);
    } else {
      var object = {
        idTransaction: values.transactionID,
        status: values.status,
      };

      updateStatusTransactionData(object);
    }
  };
  const updateStatusTransactionData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await updateStatusTransaction(object);

      if (res.status === 200) {
        await getSellerData();
        openNotificationUpdateStatus();
        setSelectedRowKeys([]);
        // setModal2VisibleModal(false);
      }
      dispatch({ type: ACTION.LOADING, data: false });
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const onFinishFailedUpdateTransaction = (errorInfoy) => {

  };
  const [sites, setSites] = useState([]);
  const getSitesData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getSites();

      if (res.status === 200) setSites(res.data.data);
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  useEffect(() => {
    getSitesData();
  }, []);
  var arrayActions = [];
  sellerTemp.forEach((values, index) => {
    arrayActions.push(values.action);
  })
  const unique = [... new Set(arrayActions)];
  var arrayStatus = [];
  sellerTempStatus.forEach((values, index) => {
    arrayStatus.push(values.status);
  })
  const uniqueStatus = [... new Set(arrayStatus)];
  const updateStatusOrdersData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      // 
      const res = await updateStatusOrders(object);

      if (res.status === 200) {
        await getSellerData();
        openNotificationUpdateStatus();
        setSelectedRowKeys([]);
      }
      dispatch({ type: ACTION.LOADING, data: false });
      // history.push(ROUTES.NEWS);
    } catch (error) {

      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const onClickAccept = () => {
    var temp = 0;
    var idTransaction;
    selectedRowKeysMain.forEach((values, index) => {
      if (values.status === 'pending') {
        temp++;
        var object = {
          idTransaction: values.idTransaction,
          status: 'approve',
        };

        updateStatusTransactionData(object);
      }
      else {
      }
    })
    if (temp > 0) {

    }
    else {
      setSelectedRowKeys([])
      openNotificationUpdateStatusError();
    }
  }
  const onClickDecline = () => {
    setSelectedRowKeys([])
  }
  return (
    <div className={styles["orders_manager"]}>
      <div className={styles["orders_manager_header"]}>
        <Row className={styles["orders_manager_header_row"]}>
          <Col
            className={styles["orders_manager_header_row_col"]}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div
              className={styles["orders_manager_header_row_col_parent_select"]}
            >
              <div className={styles["orders_manager_header_row_col_parent"]}>
                <div
                  className={
                    styles["orders_manager_header_row_col_parent_icon"]
                  }
                >
                  <WalletOutlined />
                </div>
                <div>BALANCE OVERVIEW</div>
              </div>
              <div>
                <Select
                  defaultValue="default"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onChange={handleChangeSeller}
                >
                  <Option value="default">All seller</Option>
                  {sellerSelect.map((values, index) => {
                    return (
                      <Option value={values.username}>{values.username}</Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </Col>
          <Col
            className={styles["orders_manager_header_row_col"]}
            xs={24}
            sm={24}
            md={11}
            lg={11}
            xl={11}
          >
            <div
              className={styles["orders_manager_header_row_col_parent_fix"]}
              onClick={showDrawerDeposit}
            >
              <div className={styles["orders_manager_header_row_col_download"]}>
                <div style={{ marginRight: "0.5rem" }}>
                  <DownloadOutlined style={{ fontSize: "1rem" }} />
                </div>
                <div>Export</div>
              </div>
              <div className={styles["orders_manager_header_row_col_right"]}>
                <Permission permission={[PERMISSIONS.create_balance]}>
                  <div>
                    <Button type="primary" size="large">
                      Custom transaction
                    </Button>
                  </div>
                </Permission>
              </div>
            </div>
            <Drawer
              title="Custom transaction"
              width={400}
              onClose={onCloseDeposit}
              visible={visibleDeposit}
              bodyStyle={{ paddingBottom: 80 }}
              footer={null}
            >
              <Form
                layout="vertical"
                hideRequiredMark
                style={{ width: "100%" }}
                onFinish={onFinishDeposit}
                onFinishFailed={onFinishFailedDeposit}
              >
                <Row gutter={16}>
                  {/* <Col className={styles["title_parent"]} span={24}>
                    <div className={styles["title"]}>
                      Please make a payment according to this guide then fill
                      the information below:
                    </div>
                  </Col> */}
                  <Col style={{ width: "100%" }} span={24}>
                    <Form.Item
                      style={{ width: "100%", color: "black" }}
                      name="sendTo"
                      label="Send to"
                      rules={[
                        { required: true, message: "Please select an send to" },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        placeholder="Select seller"
                      >
                        {sellerSelect.map((values, index) => {
                          return (
                            <Option value={values.username}>
                              {values.username}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ width: "100%" }} span={12}>
                    <Form.Item
                      style={{ width: "100%", color: "black" }}
                      name="amount"
                      label="Amount"
                      rules={[
                        { required: true, message: "Please select an amount" },
                      ]}
                    >
                      <Select style={{ width: "100%" }} placeholder="Actions">
                        {actions < 0 ? (
                          <Option value="SubtractCustom">SubtractCustom</Option>
                        ) : (
                          ""
                        )}
                        {actions === 0 ? (
                          <Option value="Deposit">Deposit</Option>
                        ) : (
                          ""
                        )}
                        {actions > 0 ? (
                          <Option value="AddCustom">AddCustom</Option>
                        ) : (
                          ""
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col style={{ paddingTop: "1.75rem" }} span={12}>
                    <Form.Item
                      style={{ width: "100%" }}
                      // label="Amount"
                      name="amountMoney"
                      rules={[
                        {
                          required: true,
                          message: "Please input your amount!",
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        defaultValue={0}
                        // className={styles["amount_drawer"]}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={onChangeFormatter}
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Transaction ID"
                      name="transactionID"
                      rules={[
                        {
                          required: true,
                          message: "Please input your transaction ID!",
                        },
                      ]}
                    >
                      <Input placeholder="Input transaction ID" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Note"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: "Please input your note!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Input notes"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col className={styles["deposit_button"]} span={24}>
                    <Form.Item>
                      <Button
                        style={{ width: "100%", height: "2.5rem" }}
                        // icon={<PlusOutlined />}
                        type="primary"
                        htmlType="submit"
                      >
                        Send
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>
          </Col>
        </Row>
        <Row className={styles["orders_manager_header_bottom"]}>
          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>AVAILABLE AMOUNT</div>
                <Popover placement="top" content={contentAvailable}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_bottom"]
                }
              >
                <div>{`$${balance.available_amount}`}</div>
              </div>
            </div>
          </Col>
          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>UPCOMING AMOUNT</div>
                <Popover placement="top" content={contentUpcoming}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles[
                  "orders_manager_header_bottom_col_parent_bottom_upcoming"
                  ]
                }
              >
                <div>{`$${balance.upcoming_amount}`}</div>
              </div>
            </div>
          </Col>

          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>PENDING DEPOSIT</div>
                <Popover placement="top" content={contentPending}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>{" "}
                </Popover>
              </div>

              <div
                className={
                  styles[
                  "orders_manager_header_bottom_col_parent_bottom_pending"
                  ]
                }
              >
                <div>{`$${balance.pending_amount}`}</div>
              </div>
            </div>
          </Col>

          <Col
            className={styles["orders_manager_header_bottom_col"]}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
          >
            <div className={styles["orders_manager_header_bottom_col_parent"]}>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_child"]
                }
              >
                <div>PAID AMOUNT</div>
                <Popover placement="top" content={contentPaid}>
                  <div
                    className={
                      styles[
                      "orders_manager_header_bottom_col_parent_child_icon"
                      ]
                    }
                  >
                    <InfoCircleOutlined />
                  </div>
                </Popover>
              </div>
              <div
                className={
                  styles["orders_manager_header_bottom_col_parent_bottom_paid"]
                }
              >
                <div>{`$${balance.paid_amount}`}</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles["balance_manager"]}>
        <div className={styles["orders_manager_content"]}>
          <Row className={styles["orders_manager_content_row"]}>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              <div>
                <Input onChange={onSearch} className={styles["orders_manager_content_row_col_search"]}
                  placeholder="Search transaction ID" allowClear />

              </div>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              <div>
                <RangePicker
                  className={styles["orders_manager_content_row_col_date"]}
                  ranges={{
                    Today: [moment(), moment()],
                    "This Month": [
                      moment().startOf("month"),
                      moment().endOf("month"),
                    ],
                  }}
                  onChange={onChange}
                />
              </div>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              <div>
                <Select
                  className={styles["import_orders_content_select_child"]}
                  defaultValue="default"
                  onChange={handleChangeSites}
                >
                  <Option value="default">All sites</Option>
                  {sites.map((values, index) => {
                    return (
                      <Option value={values.site_name}>
                        {values.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              <div>
                {" "}
                <Select
                  className={styles["import_orders_content_select_child"]}
                  defaultValue="default"
                  onChange={handleChangeActions}
                >
                  <Option value="default">All actions</Option>
                  {
                    unique.map((values, index) => {
                      return (
                        <Option value={values}>{values}</Option>
                      )
                    })
                  }

                </Select>
              </div>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              <div>
                {" "}
                <Select
                  className={styles["import_orders_content_select_child"]}
                  defaultValue="default"
                  onChange={handleChangeStatus}
                >
                  <Option value="default">All status</Option>
                  {
                    uniqueStatus.map((values, index) => {
                      return (
                        <Option value={values}>{values}</Option>
                      )
                    })
                  }


                </Select>
              </div>
              <Drawer
                title="More filters"
                width={250}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Button onClick={onClose} style={{ marginRight: 8 }}>
                      Clear all filter
                    </Button>
                    <Button onClick={onClose} type="primary">
                      Done
                    </Button>
                  </div>
                }
              >
                <Form layout="vertical" hideRequiredMark>
                  <Row gutter={16}></Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="statusProduct"
                        label="Product status"
                        rules={[
                          {
                            required: true,
                            message: "Please select product status!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeStatusProductDrawer}
                        >
                          <Option value="default">Choose product status</Option>
                          <Option value="statusProduct1">
                            Product status 1
                          </Option>
                          <Option value="statusProduct2">
                            Product status 2
                          </Option>
                          <Option value="statusProduct3">
                            Product status 3
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="site"
                        label="Site"
                        rules={[
                          {
                            required: true,
                            message: "Please select site!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeSitesDrawer}
                        >
                          <Option value="default">Choose site</Option>
                          <Option value="site1">Site 1</Option>
                          <Option value="site2">Site 2</Option>
                          <Option value="site3">Site 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="typeProduct"
                        label="Product type"
                        rules={[
                          {
                            required: true,
                            message: "Please select product type!",
                          },
                        ]}
                      >
                        <Select
                          defaultValue="default"
                          onChange={handleChangeProductTypeDrawer}
                        >
                          <Option value="default">Choose product tpye</Option>
                          <Option value="typeProduct1">Product type 1</Option>
                          <Option value="typeProduct2">Product type 2</Option>
                          <Option value="typeProduct3">Product type 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="dateTime"
                        label="DateTime"
                        rules={[
                          {
                            required: true,
                            message: "Please choose the dateTime",
                          },
                        ]}
                      >
                        <RangePicker
                          style={{ width: "100%" }}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          ranges={{
                            Today: [moment(), moment()],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                          }}
                          onChange={onChangeDrawer}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </Col>
            <Col
              className={styles["orders_manager_content_row_col"]}
              xs={24}
              sm={11}
              md={11}
              lg={7}
              xl={7}
            >
              {/* <div
                className={styles["orders_manager_content_row_col_filter_fix"]}
              >
                <div>
                  <FilterOutlined
                    style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                  />
                </div>
                <div>More filter</div>
              </div> */}
            </Col>
          </Row>
        </div>
        <div className={styles["orders_manager_tabs_table"]}>
          <Table
            className={styles["orders_manager_tabs_table_child"]}
            rowSelection={rowSelection}
            // onRow={onRow}
            rowKey="_id"

            columns={columns}
            dataSource={seller}
            pagination={{ pageSize: 50 }}
            scroll={{ y: 240 }}
          />
          <Modal
            title="Update Transaction"
            centered
            footer={null}
            width={600}
            visible={modal2Visible}
            onOk={() => setModal2VisibleModal(false)}
            onCancel={() => setModal2VisibleModal(false)}
          >
            <Form
              form={form}
              onFinish={onFinishUpdateTransaction}
              onFinishFailed={onFinishFailedUpdateTransaction}
            >
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Status
                  </div>
                }
                name="status"
                rules={[{ required: true, message: "Please input status!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    ID
                  </div>
                }
                name="id"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Seller
                  </div>
                }
                name="seller"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Amount
                  </div>
                }
                name="amount"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Discount
                  </div>
                }
                name="discount"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Transaction ID
                  </div>
                }
                name="transactionID"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Site
                  </div>
                }
                name="site"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Created by
                  </div>
                }
                name="createdBy"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={
                  <div
                    style={{
                      width: "100rem",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Created Date
                  </div>
                }
                name="createdDate"
              >
                <Input disabled />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Form.Item onClick={() => setModal2VisibleModal(false)}>
                  <Button style={{ marginRight: "1rem" }} type="primary" danger>
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>{" "}
        {
          selectedRowKeys && selectedRowKeys.length > 0 ? (<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '1rem', width: '100%' }}>
            <div onClick={onClickDecline} style={{ marginRight: '1rem' }}><Button style={{ width: '7.5rem', height: '2.5rem' }}>Decline</Button></div>
            <div onClick={onClickAccept}><Button type="primary" style={{ width: '7.5rem', height: '2.5rem' }}>Accept</Button></div>
          </div>) : (<div></div>)
        }
      </div>
    </div>
  );
}
