const orderColumns = [
  {
    title: 'Order Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Site',
    dataIndex: 'siteUrl',
    key: 'siteUrl',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
    key: 'customer',
  },
  {
    title: 'Shipping',
    dataIndex: 'shipping',
    key: 'shipping',
  },
  {
    title: 'Base cost',
    dataIndex: 'total_base',
    key: 'total_base',
  },
  {
    title: 'Order total',
    dataIndex: 'total_cost',
    key: 'total_cost',
  },
  {
    title: 'Seller',
    dataIndex: 'name_seller',
  },
  {
    title: 'Order Logs',
    dataIndex: 'tracking_order',
    key: 'tracking_order',
  },
  {
    title: 'Create date',
    dataIndex: 'time_created',
    key: 'time_created',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
]

export default orderColumns
