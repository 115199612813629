import React, { useEffect, useState } from 'react'
// import { socket } from 'socket'
import { socket } from './socket/index'
import Views from './views'
import Loading from 'components/loading/Loading'
import { ACTION } from 'consts'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
import { ordersAPI } from 'actions/orders/orders'
import { newsAPI } from 'actions/news/news'
import { getOrders } from 'apis/order'
import { getAllNew } from 'apis/news'
import { getAppSetting } from 'apis/app-setting'
import { clearBrowserCache } from 'utils'

export default function App() {
  const dispatch = useDispatch()
  const dataUser = useSelector((state) => state.login.dataUser)

  //config notification
  notification.config({ duration: 2 })

  const getAllOrders = async () => {
    try {
      const res = await getOrders()
      if (res.status === 200) dispatch(ordersAPI(res.data))
    } catch (error) {}
  }

  const getAllNews = async () => {
    try {
      const res = await getAllNew()
      if (res.status === 200) dispatch(newsAPI(res.data.data))
    } catch (error) {}
  }

  const _getSettingApp = async () => {
    try {
      const res = await getAppSetting()
      if (res.status === 200) {
        if (res.data.data) {
          document.querySelector("link[rel*='icon']").href =
            res.data.data.link_logo || ''
          dispatch({ type: 'GET_SETTING_APP', data: res.data.data })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    clearBrowserCache()
    _getSettingApp()
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      dispatch({
        type: ACTION.LOGIN,
        data: {
          accessToken: localStorage.getItem('accessToken'),
          refreshToken: localStorage.getItem('refreshToken'),
        },
      })
    }
  }, [])

  useEffect(() => {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      socket.on(
        JSON.parse(localStorage.getItem('username')) + '_order',
        (data) => {
          //khi có order mới rót về thì gọi lại api get all order và lưu vào reducer
          getAllOrders()
        }
      )

      socket.on(
        JSON.parse(localStorage.getItem('username')) + '_new',
        (data) => {
          //khi có order mới rót về thì gọi lại api get all news và lưu vào reducer
          getAllNews()
        }
      )
    }
  }, [dataUser])

  return (
    <>
      <Loading />
      <Views />
    </>
  )
}
