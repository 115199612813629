import React, { useEffect, useState, useRef } from 'react'
import styles from './tracking-log.module.scss'
import moment from 'moment'

import {
  Row,
  Col,
  Table,
  Select,
  DatePicker,
  Space,
  Button,
  BackTop,
  Input,
} from 'antd'

import { ClockCircleOutlined, PaperClipOutlined } from '@ant-design/icons'

//apis
import { getTrackingLogs } from 'apis/logs'

//export
import ExportResultTrackingFailed from 'components/ExportCSV/ExportResultTrackingFailed'
import ExportResultTracking from 'components/ExportCSV/ExportResultTracking'

export default function HistoryImportTracking() {
  const [trackingLogs, setTrackingLogs] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ page: 1, pageSize: 20 })
  const [countTrackingLogs, setCountTrackingLogs] = useState(0)
  const [users, setUsers] = useState([])
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date
  const [valueSearch, setValueSearch] = useState('')
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)

  const _getAllUserImportTracking = async () => {
    try {
      const res = await getTrackingLogs()
      if (res.status === 200) {
        const listUser = res.data.data.map((e) => e.username)
        setUsers([...new Set(listUser)])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getTrackingLogs = async (params) => {
    try {
      setLoading(true)
      console.log(params)
      const res = await getTrackingLogs(params)
      console.log(res)
      if (res.status === 200) {
        setCountTrackingLogs(res.data.count)
        setTrackingLogs(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    const value = e.target.value
    setValueSearch(value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(() => {
      if (value) paramsFilter.search = value
      else delete paramsFilter.search

      paramsFilter.page = 1
      _getTrackingLogs({ ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
    }, 750)
  }

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
    },
    {
      title: 'File Name',
      dataIndex: 'filename',
    },
    {
      title: 'Items failed',
      dataIndex: 'faile',
    },
    {
      title: 'Items success',
      dataIndex: 'success',
    },
    {
      title: 'Total items',
      dataIndex: 'totalRow',
    },
    {
      title: 'Counting',
      render: (text, record) => (record.countProcess ? record.countProcess : 0),
    },
    {
      title: 'Create At',
      render: (text, record) =>
        record.time_created
          ? moment(record.time_created).format('DD/MM/YYYY HH:mm:ss')
          : '',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) =>
        text && (
          <div
            style={{
              fontWeight: 500,
              color:
                (text === 'faile' && 'red') ||
                (['pending', 'running'].includes(text) && 'orange') ||
                (['success', 'done'].includes(text) && 'green'),
            }}>
            {text}
          </div>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'link',
      render: (text, record) => (
        <Space size="small">
          <a href={text || ''} target="_blank">
            <Button icon={<PaperClipOutlined />} type="link">
              Download File
            </Button>
          </a>
          {record.result && (
            <ExportResultTracking
              fileName="Download result"
              name="Download Result"
              dataExport={record.result}
            />
          )}
          {record.status === 'faile' && record.data_upload && (
            <ExportResultTrackingFailed
              fileName="Download result failed"
              name="Download Result Failed"
              dataExport={record.data_upload}
            />
          )}
        </Space>
      ),
    },
  ]

  useEffect(() => {
    _getAllUserImportTracking()
    _getTrackingLogs(paramsFilter)
  }, [])

  return (
    <div className={styles['orders_manager']}>
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <div className={styles['orders_manager_header']}>
        <Row className={styles['orders_manager_header_row']}>
          <div className={styles['orders_manager_header_row_col_parent']}>
            <div
              className={styles['orders_manager_header_row_col_parent_icon']}>
              <ClockCircleOutlined />
            </div>
            <div>TRACKING HISTORY</div>
          </div>
        </Row>
      </div>
      <Row style={{ width: '100%', marginTop: 20 }}>
        <Space size="middle">
          <Input.Search
            allowClear
            placeholder="Input file name"
            style={{ width: 300 }}
            onChange={onSearch}
            value={valueSearch}
          />
          <Select
            allowClear
            onChange={(value) => {
              if (value) paramsFilter.username = value
              else delete paramsFilter.username

              paramsFilter.page = 1
              _getTrackingLogs({ ...paramsFilter })
              setParamsFilter({ ...paramsFilter })
            }}
            value={paramsFilter.username}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            style={{ width: 250 }}
            placeholder="Choose username">
            {users.map((username, index) => (
              <Select.Option value={username} key={index}>
                {username}
              </Select.Option>
            ))}
          </Select>
          <Select
            open={isOpenSelect}
            onBlur={() => {
              if (isOpenSelect) toggleOpenSelect()
            }}
            onClick={() => {
              if (!isOpenSelect) toggleOpenSelect()
            }}
            style={{ width: 380 }}
            placeholder="Choose time"
            allowClear
            value={valueTime}
            onChange={async (value) => {
              setValueTime(value)

              //khi search hoac filter thi reset page ve 1
              paramsFilter.page = 1

              //xoa params search date hien tai
              const p = Object.keys(valueDateTimeSearch)
              if (p.length) delete paramsFilter[p[0]]

              setValueDateSearch(null)
              delete paramsFilter.startDate
              delete paramsFilter.endDate

              if (isOpenSelect) toggleOpenSelect()

              if (value) {
                const searchDate = Object.fromEntries([[value, true]]) // them params search date moi

                _getTrackingLogs({ ...paramsFilter, ...searchDate })
                setParamsFilter({ ...paramsFilter, ...searchDate })
                setValueDateTimeSearch({ ...searchDate })
              } else {
                _getTrackingLogs({ ...paramsFilter })
                setParamsFilter({ ...paramsFilter })
                setValueDateTimeSearch({})
              }
            }}
            dropdownRender={(menu) => (
              <div>
                <DatePicker.RangePicker
                  onFocus={() => {
                    if (!isOpenSelect) toggleOpenSelect()
                  }}
                  onBlur={() => {
                    if (isOpenSelect) toggleOpenSelect()
                  }}
                  value={valueDateSearch}
                  onChange={async (dates, dateStrings) => {
                    //khi search hoac filter thi reset page ve 1
                    paramsFilter.page = 1

                    if (isOpenSelect) toggleOpenSelect()

                    //nếu search date thì xoá các params date
                    delete paramsFilter.to_day
                    delete paramsFilter.yesterday
                    delete paramsFilter.this_week
                    delete paramsFilter.last_week
                    delete paramsFilter.last_month
                    delete paramsFilter.this_month
                    delete paramsFilter.this_year
                    delete paramsFilter.last_year

                    //Kiểm tra xem date có được chọn ko
                    //Nếu ko thì thoát khỏi hàm, tránh cash app
                    //và get danh sách order
                    if (!dateStrings[0] && !dateStrings[1]) {
                      delete paramsFilter.startDate
                      delete paramsFilter.endDate
                      _getTrackingLogs({ ...paramsFilter })
                      setParamsFilter({ ...paramsFilter })
                      setValueDateSearch(null)
                      setValueTime()
                    } else {
                      const dateFirst = dateStrings[0]
                      const dateLast = dateStrings[1]
                      setValueDateSearch(dates)
                      setValueTime(`${dateFirst} -> ${dateLast}`)

                      dateFirst.replace(/-/g, '/')
                      dateLast.replace(/-/g, '/')

                      paramsFilter.startDate = dateFirst
                      paramsFilter.endDate = dateLast

                      _getTrackingLogs(paramsFilter)
                      setParamsFilter({ ...paramsFilter })
                    }
                  }}
                  style={{ width: '100%' }}
                />
                {menu}
              </div>
            )}>
            <Select.Option value="to_day">Today</Select.Option>
            <Select.Option value="yesterday">Yesterday</Select.Option>
            <Select.Option value="this_week">This week</Select.Option>
            <Select.Option value="last_week">Last week</Select.Option>
            <Select.Option value="last_month">Last month</Select.Option>
            <Select.Option value="this_month">This month</Select.Option>
            <Select.Option value="this_year">This year</Select.Option>
            <Select.Option value="last_year">Last year</Select.Option>
          </Select>
          <Button
            style={{
              display: Object.keys(paramsFilter).length > 2 ? '' : 'none',
            }}
            onClick={() => {
              setValueSearch('')
              setValueTime()
              setValueDateSearch(null)
              setParamsFilter({ page: 1, pageSize: 20 })
              _getTrackingLogs({ page: 1, pageSize: 20 })
            }}>
            Clear all filter
          </Button>
        </Space>
      </Row>
      <Table
        style={{ width: '100%', marginTop: 25 }}
        size="small"
        loading={loading}
        columns={columns}
        dataSource={trackingLogs}
        pagination={{
          position: ['bottomLeft'],
          current: paramsFilter.page,
          defaultPageSize: 20,
          pageSizeOptions: [20, 30, 50, 100],
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            paramsFilter.page = page
            paramsFilter.pageSize = pageSize
            _getTrackingLogs(paramsFilter)
            setParamsFilter({ ...paramsFilter })
          },
          total: countTrackingLogs,
        }}
      />
    </div>
  )
}
