import { ORDERS_API, ORDERS_SOCKET } from 'consts'

var initialState = []

const orders = (state = initialState, action) => {
  switch (action.type) {
    case ORDERS_SOCKET: {
      state.unshift(action.data);


      return [...state];
    }
    case ORDERS_API: {

      state = action.data;

      // var result = state.reverse();
      return [...state];
    }
    default:
      return state
  }
}

export default orders
