import React, { useEffect } from 'react'

import { Row, Col, notification } from 'antd'

import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { ROUTES } from 'consts'

import { addSite, updateSite } from 'apis/sites'

export default function AuthApp() {
  const location = useLocation()
  const history = useHistory()

  const _verifyApp = async () => {
    const params = queryString.parse(location.search)

    if (Object.keys(params).length) {
      //nếu có site_name là update etsy
      // ngược lại là add site etsy
      if (localStorage.getItem('site_name')) {
        const code_verifier = localStorage.getItem('code_verifier')
        const site_name = localStorage.getItem('site_name')
        const keyEtsy = localStorage.getItem('key_etsy')

        const body = {
          site_name: site_name,
          key: keyEtsy,
          code_verifier: code_verifier,
          code: params.code,
        }
        const res = await updateSite(body)
        console.log(res)
        if (res.status === 200) {
          notification.success({
            message: 'Authorization success',
          })
        } else
          notification.error({
            message:
              res.data.mess ||
              'The authorization code was not found or was already used',
          })
      } else {
        const code_verifier = localStorage.getItem('code_verifier')
        const nameSite = localStorage.getItem('name_site_etsy')
        const keyEtsy = localStorage.getItem('key_etsy')
        const urlEtsy = localStorage.getItem('url_etsy')
        const collingoffEtsy = localStorage.getItem('collingoff_etsy')

        const body = {
          key: keyEtsy,
          name: nameSite || 'Etsy',
          platform: 'Etsy',
          site_name: nameSite || 'Etsy',
          status: true,
          code: params.code,
          code_verifier: code_verifier,
          time_collingoff: collingoffEtsy,
          url: urlEtsy,
          user_admin: localStorage.getItem('username')
            ? JSON.parse(localStorage.getItem('username'))
            : '',
        }
        console.log(body)
        const res = await addSite(body)
        console.log(res)
        if (res.status === 200) {
          notification.success({
            message: 'Authorization success',
          })
        } else
          notification.error({
            message:
              res.data.mess ||
              'The authorization code was not found or was already used',
          })

        localStorage.removeItem('code_verifier')
        localStorage.removeItem('name_site_etsy')
        localStorage.removeItem('key_etsy')
        localStorage.removeItem('url_etsy')
        localStorage.removeItem('collingoff_etsy')
      }
    }

    history.push(ROUTES.SITES)
  }

  useEffect(() => {
    _verifyApp()
  }, [])

  return (
    <div>
      <Row justify="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            margin: 'auto',
            background: 'rgb(255, 255, 255)',
            display: 'block',
            shapeRendering: 'auto',
          }}
          width="150px"
          height="150px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid">
          <circle cx="30" cy="50" fill="#e90c59" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"></animate>
          </circle>
          <circle cx="70" cy="50" fill="#46dff0" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="0s"></animate>
          </circle>
          <circle cx="30" cy="50" fill="#e90c59" r="20">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;0.5;1"
              values="30;70;30"
              begin="-0.5s"></animate>
            <animate
              attributeName="fill-opacity"
              values="0;0;1;1"
              calcMode="discrete"
              keyTimes="0;0.499;0.5;1"
              dur="1s"
              repeatCount="indefinite"></animate>
          </circle>
        </svg>
      </Row>
      <Row justify="center">
        <Col>
          <div style={{ textAlign: 'center' }}>
            Completing connection, this shouldn't take much longer.
          </div>
          <div style={{ textAlign: 'center' }}>
            You will be automatically redirected once complete.
          </div>
        </Col>
      </Row>
    </div>
  )
}
