import React, { useState, useEffect, useRef } from 'react'

import orderColumns from '../orders/orderColumns'
import { ROUTES, STATUS_ORDER } from 'consts'

//antd
import {
  BackTop,
  Row,
  Col,
  Input,
  Select,
  Form,
  Space,
  Button,
  Drawer,
  Checkbox,
  Tag,
  DatePicker,
  Spin,
  Table,
  Popover,
  Modal,
  Timeline,
  Upload,
  Slider,
} from 'antd'

//icons antd
import { FilterOutlined } from '@ant-design/icons'

//apis
import { getOrdersArchive, getAllSite } from 'apis/order'
import { getAllProductType } from 'apis/product'
import { getCarrier } from 'apis/carrier'
import { filterRoleUsers } from 'apis/users'

const { Option } = Select
const { RangePicker } = DatePicker
export default function OrderArchive() {
  const [formFilter] = Form.useForm()

  const [expandedRows, setExpandedRows] = useState([])
  const [sites, setSites] = useState([])
  const [countOrders, setCountOrders] = useState(0) //hiện thị số lượng orders theo status
  const [ordersByStatus, setOrdersByStatus] = useState([]) // data table
  const [paramsFilter, setParamsFilter] = useState({}) //params search by site, date, order name
  const [page, setPage] = useState(1) //pagination, current page
  const [pageSize, setPageSize] = useState(20) //pagination, current page size
  const [valueSearch, setValueSearch] = useState('') //dùng để hiện thị value search trong input search
  const [valueDateSearch, setValueDateSearch] = useState(null) //dùng để hiện thị date trong filter by date
  const [valueDateTimeSearch, setValueDateTimeSearch] = useState({})
  const [optionsSearch, setOptionsSearch] = useState('search')
  const [usersSeller, setUsersSeller] = useState([]) //dung de filter, o more filter
  const [usersSupplier, setUsersSupplier] = useState([]) //dung de filter, o more filter
  const [valueTime, setValueTime] = useState() //dùng để hiện thị value trong filter by time
  const [tagsFilterRender, setTagsFilterRender] = useState({})
  const [loadingTable, setLoadingTable] = useState(false) //loading table antd
  const [carriers, setCarriers] = useState([])
  const [allProductType, setAllProductType] = useState([])
  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const toggleOpenSelect = () => setIsOpenSelect(!isOpenSelect)
  const [isSpin, setIsSpin] = useState(false)

  const columnsPendingDesignItemMain = [
    {
      title: 'Information',
      dataIndex: 'information',
      key: 'information',
      with: 200,
    },
    {
      title: 'Additional file',
      dataIndex: 'printFiles',
      key: 'additionalFile',
    },
    {
      title: 'Mockup',
      dataIndex: 'mockup',
      key: 'mockup',
    },
  ]

  async function onChange(dates, dateStrings) {
    //khi search hoac filter thi reset page ve 1
    setPage(1)

    if (isOpenSelect) toggleOpenSelect()

    //Kiểm tra xem date có được chọn ko
    //Nếu ko thì thoát khỏi hàm, tránh cash app
    //và get danh sách order
    if (!dateStrings[0] && !dateStrings[1]) {
      delete paramsFilter.startDate
      delete paramsFilter.endDate
      getOrdersByStatus(1, pageSize, { ...paramsFilter })
      setParamsFilter({ ...paramsFilter })
      setValueDateSearch(null)
      setValueTime()
    } else {
      const dateFirst = dateStrings[0]
      const dateLast = dateStrings[1]
      setValueDateSearch(dates)
      setValueTime(`${dateFirst} -> ${dateLast}`)

      dateFirst.replace(/-/g, '/')
      dateLast.replace(/-/g, '/')
      getOrdersByStatus(1, pageSize, {
        ...paramsFilter,
        startDate: dateFirst,
        endDate: dateLast,
      })
      setParamsFilter({
        ...paramsFilter,
        startDate: dateFirst,
        endDate: dateLast,
      })
    }
  }

  const typingTimeoutRef = useRef(null)
  const onSearch = (e) => {
    setValueSearch(e.target.value)
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    typingTimeoutRef.current = setTimeout(async () => {
      const values = e.target.value
      //khi search hoac filter thi reset page ve 1
      setPage(1)

      if (values) {
        const paramsOptionsSearch = Object.fromEntries([
          [optionsSearch, values],
        ]) //convert array[[key, value]] to object

        getOrdersByStatus(1, pageSize, {
          ...paramsFilter,
          ...paramsOptionsSearch,
        })
        setParamsFilter({ ...paramsFilter, ...paramsOptionsSearch })
      } else {
        delete paramsFilter[optionsSearch]
        getOrdersByStatus(1, pageSize, { ...paramsFilter })
        setParamsFilter({ ...paramsFilter })
      }
    }, 750)
  }

  const ContentZoomImage = (data) => {
    const [valueBox, setValueBox] = useState(100)
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <img
          src={data}
          style={{ width: valueBox, height: valueBox, objectFit: 'contain' }}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
        <Slider
          defaultValue={100}
          min={100}
          max={1000}
          onChange={(value) => setValueBox(value)}
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    )
  }

  const UploadPrintFileProductOrder = ({ recordMini }) => {
    const data = recordMini.printFiles.map((url, index) => {
      return {
        uid: index,
        name: 'image',
        status: 'done',
        url: url,
      }
    })

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={data}
          showUploadList={{
            showRemoveIcon: false,
            showDownloadIcon: true,
            showPreviewIcon: false,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}
                  ></div>
                </Popover>
              )
            },
          }}
        ></Upload>
      </>
    )
  }

  const UploadMockupProductOrder = ({ recordMini }) => {
    const data = recordMini.mockup.map((url, index) => {
      return {
        uid: index,
        name: 'image',
        status: 'done',
        url: url,
      }
    })

    return (
      <>
        <Upload
          listType="picture-card"
          multiple
          fileList={data}
          showUploadList={{
            showRemoveIcon: false,
            showDownloadIcon: true,
            showPreviewIcon: false,
            downloadIcon: (file) => {
              return (
                <Popover
                  style={{ top: 300 }}
                  placement="top"
                  content={ContentZoomImage(file.url)}
                >
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      position: 'absolute',
                      left: '-38px',
                      top: '-38px',
                    }}
                  ></div>
                </Popover>
              )
            },
          }}
        ></Upload>
      </>
    )
  }

  const ModalTrackingOrders = ({ listTracking }) => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    return (
      <>
        <a onClick={toggle}>{listTracking.length} Logs</a>
        <Modal
          style={{ minWidth: 600, maxWidth: '90vw' }}
          onCancel={toggle}
          visible={visible}
          title="Order logs"
          footer={null}
        >
          <Timeline style={{ width: '100%' }}>
            {listTracking.map((e) => (
              <Timeline.Item style={{ width: '100%' }}>
                <Row justify="space-between" style={{ width: '100%' }}>
                  <div>{e.name} </div>
                  <Col>
                    <div>{e.time}</div>
                    <div style={{ fontWeight: 700 }}>{e.user}</div>
                  </Col>
                </Row>
              </Timeline.Item>
            ))}
          </Timeline>
        </Modal>
      </>
    )
  }

  const DrawerMoreFilter = () => {
    const [visible, setVisible] = useState(false)
    const [customily, setCustomily] = useState(
      tagsFilterRender.customily || false
    )

    const toggle = () => setVisible(!visible)

    return (
      <>
        <Space style={{ marginTop: 20 }}>
          <Button
            type="dashed"
            onClick={async () => {
              //khi search hoac filter thi reset page ve 1
              setPage(1)
              await getOrdersByStatus(1, pageSize)
              setParamsFilter({})
              setValueSearch('')
              setValueDateSearch(null)
              formFilter.resetFields()
              setTagsFilterRender({})
              setValueTime()
              setCustomily(false)
              toggle()
            }}
            style={{ display: !Object.keys(paramsFilter).length && 'none' }}
          >
            Clear all filter
          </Button>
          <Button type="primary" icon={<FilterOutlined />} onClick={toggle}>
            More Filter
          </Button>
        </Space>
        <Drawer
          title="More fitlers"
          onClose={toggle}
          visible={visible}
          width={430}
          footer={
            <Row justify="end">
              <Space>
                <Button
                  type="primary"
                  onClick={async () => {
                    //khi search hoac filter thi reset page ve 1
                    setPage(1)
                    let data = formFilter.getFieldsValue()
                    if (customily) data.customily = true
                    else data.customily = false
                    Object.keys(data).filter(
                      (e) =>
                        !data[e] && delete data[e] && delete paramsFilter[e]
                    )
                    setTagsFilterRender({ ...data })

                    await getOrdersByStatus(1, pageSize, {
                      ...paramsFilter,
                      ...data,
                    })
                    setParamsFilter({ ...paramsFilter, ...data })
                    toggle()
                  }}
                >
                  Done
                </Button>
              </Space>
            </Row>
          }
        >
          <Form layout="vertical" form={formFilter}>
            <Row>
              <Space
                direction="vertical"
                size="large"
                style={{ width: '100%' }}
              >
                <Col>
                  <div>Filter by status product</div>
                  <Form.Item name="product_status">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose status"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <div style={{ padding: '5px 12px' }}>
                            <Checkbox
                              checked={customily}
                              onChange={(e) => setCustomily(e.target.checked)}
                            >
                              Customily
                            </Checkbox>
                          </div>
                        </>
                      )}
                    >
                      <Option value="approve">Approve</Option>
                      <Option value="uncheck">Uncheck</Option>
                      <Option value="pending">Pending</Option>
                      <Option value="no_custom">Nocustom</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by carrier</div>
                  <Form.Item name="carrier">
                    <Select
                      notFoundContent={isSpin ? <Spin size="small" /> : null}
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose carrier"
                    >
                      {carriers.map((e, index) => (
                        <Option value={e.name} key={index}>
                          {e.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by seller</div>
                  <Form.Item name="seller">
                    <Select
                      notFoundContent={isSpin ? <Spin size="small" /> : null}
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose seller"
                    >
                      {usersSeller.map((e, index) => (
                        <Option value={e.username} key={index}>
                          {e.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by supplier</div>
                  <Form.Item name="supplier">
                    <Select
                      notFoundContent={isSpin ? <Spin size="small" /> : null}
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose supplier"
                    >
                      {usersSupplier.map((e, index) => (
                        <Option value={e.username} key={index}>
                          {e.username}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <div>Filter by product type</div>
                  <Form.Item name="product_type">
                    <Select
                      notFoundContent={isSpin ? <Spin size="small" /> : null}
                      style={{ width: '100%' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      placeholder="Choose product type"
                    >
                      {allProductType.map((e, index) => (
                        <Option value={e.sku} key={index}>
                          {e.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Space>
            </Row>
          </Form>
        </Drawer>
      </>
    )
  }

  const TagsFilter = () => {
    return (
      <Row align="center" style={{ marginTop: 25 }}>
        {Object.keys(tagsFilterRender).map(
          (e) =>
            tagsFilterRender[e] && (
              <Tag
                style={{ height: 'max-content', borderStyle: 'dashed' }}
                closable
                onClose={async () => {
                  delete paramsFilter[e]
                  delete tagsFilterRender[e]

                  await getOrdersByStatus(1, pageSize, { ...paramsFilter })
                  setParamsFilter({ ...paramsFilter })
                  setTagsFilterRender({ ...tagsFilterRender })
                  formFilter.setFieldsValue(
                    Object.fromEntries([[e, undefined]])
                  )
                }}
              >
                {e}: {tagsFilterRender[e]}
              </Tag>
            )
        )}
      </Row>
    )
  }

  const getOrdersByStatus = async (page = 1, pageSize = 20, filter) => {
    try {
      setLoadingTable(true)
      let params

      if (filter)
        params = {
          page,
          pageSize,
          ...filter,
        }
      else
        params = {
          page,
          pageSize,
        }
      console.log(params)
      const res = await getOrdersArchive(params)
      console.log(res)
      if (res.status === 200) {
        setOrdersByStatus(res.data.data)
        setCountOrders(res.data.count)
      }
      setLoadingTable(false)
    } catch (error) {
      setLoadingTable(false)
      console.log(error)
    }
  }

  const _getCarriers = async () => {
    try {
      const res = await getCarrier()

      if (res.status === 200) {
        setCarriers(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const _getAllProductType = async () => {
    try {
      const res = await getAllProductType()
      if (res.status === 200) {
        setAllProductType(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getAllSiteData = async () => {
    try {
      const res = await getAllSite()
      if (res.status === 200) setSites(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersBySeller = async () => {
    try {
      const res = await filterRoleUsers('Seller')

      if (res.status === 200) {
        setUsersSeller(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getUsersBySupplier = async () => {
    try {
      const res = await filterRoleUsers('Supplier')

      if (res.status === 200) {
        setUsersSupplier(res.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadData = async () => {
    try {
      setIsSpin(true)
      await _getCarriers()
      await _getAllProductType()
      await getAllSiteData()
      await getAllSiteData()
      await getUsersBySeller()
      await getUsersBySupplier()
      setIsSpin(false)
    } catch (error) {
      setIsSpin(false)
    }
  }

  useEffect(() => {
    loadData()
    getOrdersByStatus()
  }, [])

  return (
    <div
      style={{
        backgroundColor: 'white',
        margin: '1rem',
        padding: '1rem',
        height: '100%',
      }}
    >
      <BackTop style={{ right: '20px', bottom: '20px' }} />
      <Row style={{ marginTop: 20, marginBottom: 30, width: '100%' }}>
        <Row justify="space-between" style={{ width: '100%' }}>
          <Col>
            <div>Search orders by options</div>
            <Input
              style={{ width: 450 }}
              value={valueSearch}
              onChange={onSearch}
              placeholder="Search by"
              allowClear
              addonBefore={
                <Select
                  placeholder="options"
                  value={optionsSearch}
                  style={{ width: 180 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    delete paramsFilter[optionsSearch]
                    setParamsFilter({
                      ...paramsFilter,
                      ...Object.fromEntries([[value, valueSearch]]),
                    })
                    setOptionsSearch(value)
                  }}
                >
                  <Option value="search">name order</Option>
                  <Option value="name_customer">name customer</Option>
                  <Option value="address_customer">address customer</Option>
                  <Option value="email_customer">email customer</Option>
                  <Option value="phone_customer">phone customer</Option>
                  <Option value="country_customer">country customer</Option>
                  <Option value="product_name">name product</Option>
                  <Option value="sku">sku product</Option>
                  <Option value="rename_for_Sup">SKU custom</Option>
                </Select>
              }
            />
          </Col>
          {/* <Col>
            <div>Filter by date</div>
            <RangePicker
              value={valueDateSearch}  
              onChange={onChange}
              style={{width: 250}}
            />
          </Col> */}
          <Col>
            <div>Filter by time</div>
            <Select
              open={isOpenSelect}
              onBlur={() => {
                if (isOpenSelect) toggleOpenSelect()
              }}
              onClick={() => {
                if (!isOpenSelect) toggleOpenSelect()
              }}
              style={{ width: 380 }}
              placeholder="Choose time"
              allowClear
              value={valueTime}
              onChange={async (value) => {
                setValueTime(value)

                //khi search hoac filter thi reset page ve 1
                setPage(1)

                //xoa params search date hien tai
                const p = Object.keys(valueDateTimeSearch)
                if (p.length) delete paramsFilter[p[0]]

                setValueDateSearch(null)
                delete paramsFilter.startDate
                delete paramsFilter.endDate

                if (isOpenSelect) toggleOpenSelect()

                if (value) {
                  const searchDate = Object.fromEntries([[value, true]]) // them params search date moi
                  getOrdersByStatus(1, pageSize, {
                    ...paramsFilter,
                    ...searchDate,
                  })
                  setParamsFilter({ ...paramsFilter, ...searchDate })
                  setValueDateTimeSearch({ ...searchDate })
                } else {
                  getOrdersByStatus(1, pageSize, { ...paramsFilter })
                  setParamsFilter({ ...paramsFilter })
                  setValueDateTimeSearch({})
                }
              }}
              dropdownRender={(menu) => (
                <div>
                  <RangePicker
                    onFocus={() => {
                      if (!isOpenSelect) toggleOpenSelect()
                    }}
                    onBlur={() => {
                      if (isOpenSelect) toggleOpenSelect()
                    }}
                    value={valueDateSearch}
                    onChange={onChange}
                    style={{ width: '100%' }}
                  />
                  {menu}
                </div>
              )}
            >
              <Option value="to_day">Today</Option>
              <Option value="yesterday">Yesterday</Option>
              <Option value="this_week">This week</Option>
              <Option value="last_week">Last week</Option>
              <Option value="last_month">Last month</Option>
              <Option value="this_month">This month</Option>
              <Option value="this_year">This year</Option>
              <Option value="last_year">Last year</Option>
            </Select>
          </Col>
          <Col>
            <div>Filter by site</div>
            <Select
              notFoundContent={isSpin ? <Spin size="small" /> : null}
              style={{ width: 280 }}
              placeholder="Choose site"
              value={paramsFilter ? paramsFilter.site : ''}
              showSearch
              filterOption={(input, option) =>
                option.children.indexOf(input) >= 0
              }
              onChange={async (value) => {
                //khi search hoac filter thi reset page ve 1
                setPage(1)
                if (value) {
                  getOrdersByStatus(1, pageSize, {
                    ...paramsFilter,
                    site: value,
                  })
                  setParamsFilter({ ...paramsFilter, site: value })
                } else {
                  delete paramsFilter.site
                  getOrdersByStatus(1, pageSize, { ...paramsFilter })
                  setParamsFilter({ ...paramsFilter })
                }
              }}
              allowClear
            >
              {sites.map((values) => {
                return <Option value={values.site_name}>{values.name}</Option>
              })}
            </Select>
          </Col>
        </Row>
        <Row justify="end" style={{ width: '100%' }}>
          <TagsFilter />
          <DrawerMoreFilter />
        </Row>
        <div style={{ width: '100%', marginTop: 20 }}>
          <Row
            justify="space-between"
            style={{ width: '100%', marginBottom: 16 }}
          >
            <Space>
              <Button
                type="dashed"
                onClick={() => {
                  if (expandedRows.length === pageSize) setExpandedRows([])
                  else setExpandedRows(ordersByStatus.map((e) => e.id_order))
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    if (!e.target.checked) setExpandedRows([])
                    else setExpandedRows(ordersByStatus.map((e) => e.id_order))
                  }}
                  checked={expandedRows.length === pageSize ? true : false}
                >
                  Expand All
                </Checkbox>
              </Button>
              <div style={{ fontSize: 15, fontWeight: 500 }}>
                Total order: {countOrders}
              </div>
            </Space>
            <Space size="large">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    backgroundColor: 'rgb(23, 209, 23)',
                  }}
                ></div>
                Approve
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    backgroundColor: 'orange',
                  }}
                ></div>
                Pending
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    backgroundColor: 'rgb(133, 197, 250)',
                  }}
                ></div>
                Uncheck
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginRight: 5,
                    backgroundColor: '#FFAFAF',
                  }}
                ></div>
                Cancel
              </div>
            </Space>
          </Row>

          <Table
            style={{
              width: '100%',
              border: '1px solid rgb(214, 200, 200)',
              backgroundColor: 'white',
            }}
            loading={loadingTable}
            rowKey="id_order"
            columns={orderColumns.map((e) => {
              if (e.key === 'name') {
                return {
                  ...e,
                  render: (text, record) => (
                    <a
                      href={
                        ROUTES.ORDERS_TRACKING_DETAIL +
                        '?order=' +
                        `${record.key_design}_${record.id_order}`
                      }
                    >
                      {text}
                    </a>
                  ),
                  sorter: (a, b) =>
                    a.name.replace(/#/g, '').toLowerCase().length -
                    b.name.replace(/#/g, '').toLowerCase().length,
                }
              }
              if (e.key === 'siteUrl') {
                return {
                  ...e,
                  render: (text, record) => (
                    <a
                      href={
                        localStorage.getItem('role') &&
                        JSON.parse(localStorage.getItem('role')) !==
                          'Supplier' &&
                        record.siteUrl
                      }
                      target="_blank"
                    >
                      {(localStorage.getItem('role') &&
                        JSON.parse(localStorage.getItem('role')) === 'Admin' &&
                        `${record.key_design}: ${record.id_site}`) ||
                        (localStorage.getItem('role') &&
                          JSON.parse(localStorage.getItem('role')) ===
                            'Supplier' &&
                          `${record.key_design}`) ||
                        (localStorage.getItem('role') &&
                          !['Admin', 'Supplier'].includes(
                            JSON.parse(localStorage.getItem('role'))
                          ) &&
                          `${record.id_site}`)}
                    </a>
                  ),
                  sorter: (a, b) => a.siteUrl.length - b.siteUrl.length,
                }
              }
              if (e.key === 'customer') {
                return {
                  ...e,
                  render: (text, record) => (
                    <Popover
                      placement="top"
                      content={
                        record.customer ? (
                          `${record.customer.first_name} ${record.customer.last_name}, ${record.customer.address_1}, ${record.customer.address_2}, ${record.customer.city}, ${record.customer.phone}, ${record.customer.email}.`
                        ) : (
                          <div> No information </div>
                        )
                      }
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                          color: '#2A53CD',
                        }}
                      >
                        {record.customer &&
                          record.customer.first_name +
                            ' ' +
                            record.customer.last_name}
                      </div>
                    </Popover>
                  ),
                  sorter: (a, b) =>
                    a.customer.first_name.length - b.customer.first_name.length,
                }
              }
              if (e.key === 'shipping') {
                return {
                  ...e,
                  render: (text, record) => (
                    <Popover
                      placement="top"
                      content={
                        record.shipping ? (
                          `${record.shipping.first_name} ${record.shipping.last_name}, ${record.shipping.address_1}, ${record.shipping.address_2}, ${record.shipping.city}, ${record.shipping.phone}, ${record.shipping.email}.`
                        ) : (
                          <div> No information </div>
                        )
                      }
                    >
                      <div
                        style={{
                          cursor: 'pointer',
                          color: '#2A53CD',
                        }}
                      >
                        {record.shipping &&
                          record.shipping.first_name +
                            ' ' +
                            record.shipping.last_name}
                      </div>
                    </Popover>
                  ),
                  sorter: (a, b) =>
                    a.shipping.first_name.length - b.shipping.first_name.length,
                }
              }
              if (e.key === 'time_created') {
                return {
                  ...e,
                  render: (text, record) => text || record.date_created,
                  defaultSortOrder: 'descend',
                  sorter: (a, b) =>
                    new Date(a.time_created || a.date_created).getTime() -
                    new Date(b.time_created || b.date_created).getTime(),
                }
              }
              if (e.key === 'tracking_order') {
                return {
                  ...e,
                  render: (text, record) =>
                    text && <ModalTrackingOrders listTracking={text} />,
                  sorter: (a, b) =>
                    a.tracking_order.length - b.tracking_order.length,
                }
              }
              if (e.key === 'quantity') {
                return {
                  ...e,
                  sorter: (a, b) => a.quantity - b.quantity,
                }
              }
              if (e.key === 'total_base') {
                return {
                  ...e,
                  sorter: (a, b) => a.total_base - b.total_base,
                }
              }
              if (e.key === 'total_price') {
                return {
                  ...e,
                  sorter: (a, b) => a.total_price - b.total_price,
                }
              }
              return e
            })}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div style={{ margin: '13px 0px' }}>
                    <Table
                      pagination={false}
                      rowClassName={(productUnsign) => {
                        if (productUnsign.status === 'approve')
                          return 'product-unsign-approve'
                        if (productUnsign.status === 'pending')
                          return 'product-unsign-pending'
                        if (productUnsign.status === 'uncheck')
                          return 'product-unsign-uncheck'
                        if (productUnsign.status === 'cancel')
                          return 'product-unsign-cancel'
                      }}
                      columns={columnsPendingDesignItemMain.map(
                        (values, index) => {
                          if (values.key === 'additionalFile') {
                            return {
                              ...values,
                              render: (text, recordMini) => (
                                <UploadPrintFileProductOrder
                                  recordMini={recordMini}
                                />
                              ),
                            }
                          }
                          if (values.key === 'mockup') {
                            return {
                              ...values,
                              render: (text, recordMini) => (
                                <UploadMockupProductOrder
                                  recordMini={recordMini}
                                />
                              ),
                            }
                          }
                          if (values.key === 'information') {
                            return {
                              ...values,
                              render: (text, recordMini) => {
                                return (
                                  <Col style={{ width: 400 }}>
                                    <a
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                      target="_blank"
                                      href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}
                                    >{`Tracking number: ${
                                      recordMini && recordMini.tracking_number
                                        ? recordMini.tracking_number
                                        : ''
                                    }`}</a>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Status: ${
                                      recordMini && recordMini.status
                                        ? recordMini.status
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                      }}
                                    >
                                      {`SKU: ${recordMini.sku || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Product name: ${
                                      recordMini && recordMini.name
                                        ? recordMini.name
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Order item id: ${
                                      recordMini && recordMini.id
                                        ? recordMini.id
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Quantity: ${recordMini.quantity || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Product type: ${
                                        recordMini &&
                                        recordMini.product_type &&
                                        recordMini.product_type.title
                                      }`}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >
                                      Order created:{' '}
                                      {(recordMini.dateCreated &&
                                        recordMini.dateCreated) ||
                                        (recordMini.date_created &&
                                          recordMini.date_created)}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                      }}
                                    >
                                      <Row>Custom info:</Row>
                                      {recordMini.custom_info.map((e) => {
                                        const RowName = () => (
                                          <Row style={{ marginLeft: 80 }}>
                                            Name - {e.name || ''}
                                          </Row>
                                        )
                                        const RowValue = () => (
                                          <Row style={{ marginLeft: 80 }}>
                                            Value - {e.value || ''}
                                          </Row>
                                        )
                                        return (
                                          <Col style={{ marginTop: 7 }}>
                                            <RowName />
                                            <RowValue />
                                          </Col>
                                        )
                                      })}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Supplier: ${recordMini.supplier || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Carrier: ${recordMini.carrier || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Custom note: ${
                                        recordMini.custom_note || ''
                                      }`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`SKU Custom: ${
                                        recordMini.rename_for_Sup || ''
                                      }`}
                                    </div>
                                  </Col>
                                )
                              },
                            }
                          }

                          return { ...values }
                        }
                      )}
                      dataSource={record.list_product_unsign}
                      size="small"
                      style={{
                        display: !record.list_product_unsign.length && 'none',
                      }}
                    />
                    <br />
                    <Table
                      pagination={false}
                      rowClassName={(productUnsign) => {
                        if (productUnsign.status === 'approve')
                          return 'product-unsign-approve'
                        if (productUnsign.status === 'pending')
                          return 'product-unsign-pending'
                        if (productUnsign.status === 'uncheck')
                          return 'product-unsign-uncheck'
                        if (productUnsign.status === 'cancel')
                          return 'product-unsign-cancel'
                      }}
                      columns={columnsPendingDesignItemMain.map(
                        (values, index) => {
                          if (values.key === 'additionalFile') {
                            return {
                              ...values,
                              render: (text, recordMini) => (
                                <UploadPrintFileProductOrder
                                  recordMini={recordMini}
                                />
                              ),
                            }
                          }
                          if (values.key === 'mockup') {
                            return {
                              ...values,
                              render: (text, recordMini) => (
                                <UploadMockupProductOrder
                                  recordMini={recordMini}
                                />
                              ),
                            }
                          }
                          if (values.key === 'information') {
                            return {
                              ...values,
                              render: (text, recordMini) => {
                                return (
                                  <Col>
                                    <a
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                      target="_blank"
                                      href={`https://www.trackingmore.com/${recordMini.carrier}-tracking.html?number=${recordMini.tracking_number}`}
                                    >{`Tracking number: ${
                                      recordMini && recordMini.tracking_number
                                        ? recordMini.tracking_number
                                        : ''
                                    }`}</a>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Status: ${
                                      recordMini && recordMini.status
                                        ? recordMini.status
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                      }}
                                    >
                                      {`SKU: ${recordMini.sku || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Product name: ${
                                      recordMini && recordMini.name
                                        ? recordMini.name
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >{`Order item id: ${
                                      recordMini && recordMini.id
                                        ? recordMini.id
                                        : ''
                                    }.`}</div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Quantity: ${recordMini.quantity || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Product type: ${
                                        recordMini &&
                                        recordMini.product_type &&
                                        recordMini.product_type.title
                                      }`}
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginBottom: '0.5rem',
                                      }}
                                    >
                                      Order created:{' '}
                                      {(recordMini.dateCreated &&
                                        recordMini.dateCreated) ||
                                        (recordMini.date_created &&
                                          recordMini.date_created)}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                      }}
                                    >
                                      <Row>Custom info:</Row>
                                      {recordMini.custom_info.map((e) => {
                                        const RowName = () => (
                                          <Row style={{ marginLeft: 80 }}>
                                            Name - {e.name || ''}
                                          </Row>
                                        )
                                        const RowValue = () => (
                                          <Row style={{ marginLeft: 80 }}>
                                            Value - {e.value || ''}
                                          </Row>
                                        )
                                        return (
                                          <Col style={{ marginTop: 7 }}>
                                            <RowName />
                                            <RowValue />
                                          </Col>
                                        )
                                      })}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Supplier: ${recordMini.supplier || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Carrier: ${recordMini.carrier || ''}`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`Custom note: ${
                                        recordMini.custom_note || ''
                                      }`}
                                    </div>
                                    <div
                                      style={{
                                        marginBottom: '0.5rem',
                                        cursor: 'pointer',
                                        color: '#0A46E6',
                                        pointerEvents:
                                          JSON.parse(
                                            localStorage.getItem('role')
                                          ) === 'Designer' && 'none',
                                      }}
                                    >
                                      {`SKU Custom: ${
                                        recordMini.rename_for_Sup || ''
                                      }`}
                                    </div>
                                  </Col>
                                )
                              },
                            }
                          }

                          return { ...values }
                        }
                      )}
                      dataSource={record.list_product_fulfill}
                      size="small"
                      style={{
                        display: !record.list_product_fulfill.length && 'none',
                      }}
                    />
                  </div>
                )
              },

              expandedRowKeys: expandedRows,
              onExpandedRowsChange: (expandedRows) =>
                setExpandedRows(expandedRows),
            }}
            dataSource={ordersByStatus.length ? ordersByStatus : []}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              defaultPageSize: 20,
              pageSizeOptions: [20, 30, 50, 100],
              showQuickJumper: true,
              onChange: (page, pageSize) => {
                setExpandedRows([])
                setPage(page)
                setPageSize(pageSize)
                getOrdersByStatus(page, pageSize, paramsFilter)
              },
              total: countOrders || 0,
            }}
            size="small"
          />
        </div>
      </Row>
    </div>
  )
}
