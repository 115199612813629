import React, { useState, useEffect, useRef } from "react";
import styles from "./add.module.scss";
import { CKEditor } from "ckeditor4-react";
import { ACTION, ROUTES } from "consts";
import parse from 'html-react-parser'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Checkbox,
  Switch,
  InputNumber,
  Select,
  notification,
  Upload,
  Drawer,
  Popconfirm,
  Collapse,
  Tooltip,
  Modal,
  Affix,
  Tabs,
  Table,
  Progress
} from "antd";

import {
  MinusCircleOutlined,
  PlusOutlined,
  InboxOutlined,
  DownCircleOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  SaveOutlined,
  UndoOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  FileImageOutlined,
  HighlightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import { useDispatch } from "react-redux";
import {
  Link,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";

//apis
import {
  getAllProductType,
  createProduct,
  searchProduct,
  updateProduct
} from "apis/product";
import { createProductType, getCountry, updateProductType, searchProductType } from "apis/productType";
import { filterRoleUsers } from "apis/users";
import { getSites } from "apis/sites";
import { getCarrier } from "apis/carrier";
import { getCategories } from 'apis/category'
import { uploadFiles } from "apis/upload";

const { Option } = Select;
export default function AddProduct() {
  let history = useHistory();
  let location = useLocation()
  let { slug } = useParams()
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formProduct] = Form.useForm();
  let initUuid = uuidv4() //random uuid
  const typeQuery = new URLSearchParams(location.search).get('type')

  const [listUser, setListUser] = useState([])
  const [initDataEditor, setInitDataEditor] = useState(null)
  const [selectRowKeyProduct, setSelectRowKeyProduct] = useState([]) //list variants key dc tich 
  const [selectRowProduct, setSelectRowProduct] = useState([]) //list variants product dc tich
  const [dataEditor, setDataEditor] = useState("");
  const [sites, setSites] = useState([]);
  const [carrier, setCarrier] = useState([]);
  const [productTypeGroup, setProductTypeGroup] = useState([]);
  const [productType, setProductType] = useState([]);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [visibleProgress, setVisibleProgress] = useState(false)
  const [percentProgress, setPercentProgress] = useState(0)
  const [valueSearchProductType, setValueSearchProductType] = useState('')

  //Option, array values product type
  //func create new product type
  const [variantsPreview, setVariantsPreview] = useState([{
    option: "",
    values: [],
  }]);
  const [sku, setSku] = useState(""); //sku của product type
  const [suppliersProductType, setSuppliersProductType] = useState([]) //supplier của product type
  const [carrierProductType, setCarrierProductType] = useState([])
  const [printFilesPreview, setPrintFilesPreview] = useState([{
    image_file: '',
    name_file: '',
    width: '', //size
    height: '', //size
    note: ''
  }])
  const [mockupProductType, setMockupProductType] = useState([])
  const [mockupUpdateProductType, setMockupUpdateProductType] = useState() //list mockup ban đầu của product type 
  const [templateUploadProductType, setTemplateUploadProductType] = useState([])
  const [templateUploadUpdateProductType, setTemplateUploadUpdateProductType] = useState([]) //list template upload ban đầu của product type 
  const [listCheckboxProductType, setListCheckboxProductType] = useState([]) //list item variant dc chon
  const [listCheckboxVariantProductType, setListCheckboxVariantProductType] = useState([]) //list item checkbox dc render

  const addVariantProductTypeNew = () => {
    if (variantsPreview.length != 2) {
      let variantsInputAdd = variantsPreview;
      variantsInputAdd.push({
        option: "",
        values: [],
      });
      setVariantsPreview([...variantsInputAdd])
    }
  };

  const addPrintFileProductTypeNew = () => {
    if (printFilesPreview.length != 10) {
      let printFileAdd = printFilesPreview
      printFileAdd.push({
        image_file: '',
        name_file: '',
        width: '', //size
        height: '', //size
        note: ''
      })
      setPrintFilesPreview([...printFileAdd])
    }
  }

  const ModalEditStatusVariants = () => {
    const [visible, setVisible] = useState(false)
    const [variantsPreviewNew, setVariantsPreviewNew] = useState([...variantsPreview])
    const [isUpdate, setIsUpdate] = useState(false) //check user co edit ko ?

    const toggle = () => setVisible(!visible)

    return (
      <div style={{ display: location.state && 'none' }}>
        <Button icon={<InfoCircleOutlined />} onClick={toggle}>
          Edit status
        </Button>
        <Modal
          title="Edit status"
          visible={visible}
          onCancel={toggle}
          onOk={() => {
            //nếu user ko edit thì mặc định update status là false
            if (!isUpdate) {
              variantsPreviewNew.map((e, indexVariant) => {
                e.values.map((v, indexValues) => {
                  if (listCheckboxProductType.includes(v.title))
                    variantsPreviewNew[indexVariant].values[indexValues].is_active = false // mặc định edit status là false
                })
              })
            }
            setVariantsPreview([...variantsPreviewNew])
          }}
        >
          <Row>
            Status: <Switch style={{ marginLeft: 8 }} onChange={(value) => {
              setIsUpdate(true)
              variantsPreviewNew.map((e, indexVariant) => {
                e.values.map((v, indexValues) => {
                  if (listCheckboxProductType.includes(v.title))
                    variantsPreviewNew[indexVariant].values[indexValues].is_active = value
                })
              })
            }} />
          </Row>
        </Modal>
      </div>
    )
  }

  const ModalEditProductCost = () => {
    const [visible, setVisible] = useState(false)
    const [variantsPreviewNew, setVariantsPreviewNew] = useState(variantsPreview)
    const [isUpdate, setIsUpdate] = useState(false) //check user co edit ko ?

    const editProductCost = () => {
      //nếu user ko edit thì mặc định edit 3 field là 0
      if (!isUpdate) {
        variantsPreviewNew.map((e, indexVariant) => {
          e.values.map((v, indexValues) => {
            if (listCheckboxProductType.includes(v.title)) {
              variantsPreviewNew[indexVariant].values[indexValues].price = 0
              variantsPreviewNew[indexVariant].values[indexValues].regular_price = 0
              variantsPreviewNew[indexVariant].values[indexValues].sale_price = 0
            }
          })
        })
      }
      setVariantsPreview([...variantsPreviewNew])
    }

    const toggle = () => setVisible(!visible)

    return (
      <div>
        <Button
          icon={<DollarOutlined />}
          onClick={toggle}
        >
          Edit product cost
        </Button>
        <Modal
          title="Edit product cost"
          visible={visible}
          onCancel={toggle}
          onOk={editProductCost}
        >
          <Space>
            Base Cost:
            <InputNumber
              onPressEnter={editProductCost}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              defaultValue={0}
              onChange={(value) => {
                setIsUpdate(true)
                variantsPreviewNew.map((e, indexVariant) => {
                  e.values.map((v, indexValues) => {
                    if (listCheckboxProductType.includes(v.title))
                      variantsPreviewNew[indexVariant].values[indexValues].price = value
                  })
                })
              }}
            />
          </Space>
          <br />
          <br />
          <Space>
            Regular Price:
            <InputNumber
              onPressEnter={editProductCost}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              defaultValue={0}
              onChange={(value) => {
                setIsUpdate(true)
                variantsPreviewNew.map((e, indexVariant) => {
                  e.values.map((v, indexValues) => {
                    if (listCheckboxProductType.includes(v.title))
                      variantsPreviewNew[indexVariant].values[indexValues].regular_price = value
                  })
                })
              }}
            />
          </Space>
          <br />
          <br />
          <Space>
            Sale Price:
            <InputNumber
              onPressEnter={editProductCost}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              defaultValue={0}
              onChange={(value) => {
                setIsUpdate(true)
                variantsPreviewNew.map((e, indexVariant) => {
                  e.values.map((v, indexValues) => {
                    if (listCheckboxProductType.includes(v.title))
                      variantsPreviewNew[indexVariant].values[indexValues].sale_price = value
                  })
                })
              }}
            />
          </Space>
        </Modal>
      </div>
    )
  }

  const ModalEditSupplierCost = () => {
    const [visible, setVisible] = useState(false)
    const [variantsPreviewNew, setVariantsPreviewNew] = useState(variantsPreview)
    const [isUpdate, setIsUpdate] = useState(false) //check user co edit ko ?
    const [suppliersProductTypeNew, setSuppliersProductTypeNew] = useState(suppliersProductType)

    const editCostSupplier = () => {
      // nếu user ko edit thì mặc định edit cost supplier là 0
      if (!isUpdate) {
        variantsPreviewNew.map((e, indexVariant) => {
          e.values.map((v, indexValues) => {
            v.cost_supplier.map((h, indexSupplier) => {
              if (listCheckboxProductType.includes(v.title))
                variantsPreviewNew[indexVariant].values[indexValues].cost_supplier[indexSupplier].cost = 0
            })
          })
        })
      } else {
        variantsPreviewNew.map((e, indexVariant) => {
          e.values.map((v, indexValues) => {
            if (listCheckboxProductType.includes(v.title)) {

              variantsPreviewNew[indexVariant].values[indexValues].cost_supplier = [...suppliersProductTypeNew]
            }
          })
        })
      }
      setVariantsPreview([...variantsPreviewNew])
    }

    const toggle = () => setVisible(!visible)
    return (
      <div style={{ display: !suppliersProductType.length && 'none' }}>
        <Button icon={<DollarOutlined />} onClick={toggle}>
          Edit supplier cost
        </Button>
        <Modal
          title="Edit supplier cost"
          visible={visible}
          onCancel={toggle}
          onOk={editCostSupplier}
          width={380}
        >
          {suppliersProductTypeNew.map((f, index) => {
            return (
              <Space style={{ marginBottom: 15 }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  {f.name}
                  <InputNumber
                    onPressEnter={editCostSupplier}
                    min={0}
                    style={{ width: 250 }}
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    defaultValue={0}
                    onChange={(value) => {
                      setIsUpdate(true)
                      suppliersProductTypeNew[index] = {
                        ...suppliersProductTypeNew[index],
                        cost: value
                      }
                    }}
                  />
                </div>
              </Space>
            )
          })}
        </Modal>
      </div>
    )
  }

  //chuc nang update product type
  //khoi tao gia tri cho product type
  const initProductType = () => {
    if (location.state) {
      setVariantsPreview([...location.state.variants])
      setSku(location.state.sku)
      setPrintFilesPreview([...location.state.printFiles])
      setMockupProductType([...location.state.mockup])
      setMockupUpdateProductType([...location.state.mockup])
      setTemplateUploadProductType([...location.state.template_upload])
      setTemplateUploadUpdateProductType([...location.state.template_upload])
      setDataEditor(location.state.description)
      setCarrierProductType([...location.state.listcarrier])
      setSuppliersProductType([...location.state.supplyBy.map(e => {
        return {
          name: e,
          cost: ''
        }
      })])

      form.setFieldsValue({
        ...location.state,
        dragger: location.state.mockup.map(e => {
          const nameFile = e.split('/')
          return { thumbUrl: e, name: nameFile[nameFile.length - 1] || 'image' }
        }),
        template_upload: location.state.template_upload.map(e => {
          const nameFile = e.split('/')
          return { thumbUrl: e, name: nameFile[nameFile.length - 1] || 'file' }
        }),
        suppliers: location.state.supplyBy,
        country: location.state.shippingCountry,
        carrier: location.state.listcarrier.map(e => e.idCarrier)
      })

      let listCheckboxVariants = []
      location.state.variants.map(e => {
        e.values.map(f => {
          listCheckboxVariants.push(f.title)
        })
      })
      setListCheckboxVariantProductType([...listCheckboxVariants])
    }
  }

  useEffect(() => {
    if (typeQuery === "productType") {
      setModal2VisibleModal(true);
    }
    if (carrier.length) initProductType()
  }, [carrier])

  const getAllSites = async () => {
    try {
      const res = await getSites({
        idUser:
          localStorage.getItem("username") &&
          JSON.parse(localStorage.getItem("username")),
      });
      if (res.status === 200) setSites(res.data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const typingTimeoutRef = useRef(null);
  const onSearchProductType = (e) => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current)
    }
    const values = e.target.value
    
    typingTimeoutRef.current = setTimeout(async () => {
      setValueSearchProductType(values)
      try {
        dispatch({ type: ACTION.LOADING, data: true });
        const res = await searchProductType({ search: values });
        console.log(res)
        if (res.status === 200) setProductType(res.data.result);
        dispatch({ type: ACTION.LOADING, data: false });
      } catch (error) {
        console.log(error)
        dispatch({ type: ACTION.LOADING, data: false });
      }
      setAddProduct([]) //reset list chon product type
    }, 750);
  };

  const setModal2VisibleModal = (modal2Visible) => {
    setModal2Visible(modal2Visible);
  };

  const setModal1VisibleModal = (modal1Visible) => {
    setModal1Visible(modal1Visible);
    // this.setState({ modal1Visible });
  };

  const normFileImages = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setMockupProductType([...e.fileList])
    return e && e.fileList.map((v, index) => {
      if (v.status != 'done') e.fileList[index].status = 'done'
      return v
    })
  };

  const normFileImagesTemplateUpload = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setTemplateUploadProductType([...e.fileList])
    return e && e.fileList.map((v, index) => {
      if (v.status != 'done') e.fileList[index].status = 'done'
      return v
    })
  };

  const [country, setCountry] = useState([]);
  const getCountryData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getCountry();
      console.log(res)
      if (res.status === 200) {
        setCountry(res.data.data);
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const createProductTypeData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      console.log(JSON.stringify(object))
      const res = await createProductType(object);
      console.log(res)
      if (res.status === 200) {
        notification.success({ message: "Create product type success" })
        setModal2VisibleModal(false)
        history.goBack()
      } else {
        if (res.data.message)
          notification.error({ message: res.data.message })
        else
          notification.error({ message: "Create product type failed!" })
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const updateProductTypeData = async (object) => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      delete object.dateCreated
      object.id = location.state && location.state.id
      object._id = location.state && location.state._id

      console.log(JSON.stringify(object))
      const res = await updateProductType(object);
      console.log(res)
      if (res.status === 200) {
        notification.success({ message: "Update product type success" })
        setModal2VisibleModal(false)
        history.goBack()
      } else {
        if (res.data.message)
          notification.error({ message: res.data.message })
        else
          notification.error({ message: "Create product type failed!" })
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const onFinishAddProductType = async () => {
    //validated cac field bat buoc
    let validated = true
    try {
      await form.validateFields()
      validated = true
    } catch (error) {
      validated = false
    }

    if (!validated) {
      notification.error({ message: "Please complete all required fields!" })
      return
    }

    if (!dataEditor) {
      notification.error({ message: "Please description!" })
      return
    }

    if (!mockupProductType.length) {
      notification.error({ message: "Please input mockup!" })
      return
    }

    for (let i = 0; i < variantsPreview.length; ++i) {
      if (!variantsPreview[i].values.length) {
        notification.error({ message: "Please input value variant!" })
        return
      }

      if (!variantsPreview[i].option) {
        notification.error({ message: "Please input attribute variant!" })
        return
      }
    }

    for (let i = 0; i < printFilesPreview.length; ++i) {
      if (!printFilesPreview[i].name_file) {
        notification.error({ message: "Please input name print file!" })
        return
      }
      if (!printFilesPreview[i].height || !printFilesPreview[i].width) {
        notification.error({ message: "Please input height or width size print file!" })
        return
      }
    }

    for (let i = 0; i < variantsPreview.length; ++i) {
      for (let j = 0; j < variantsPreview[i].values.length; ++j) {
        if (!variantsPreview[i].values[j].sku) {
          notification.error({ message: "Please input sku variant" })
          return
        }

        if (!variantsPreview[i].values[j].price ||
          !variantsPreview[i].values[j].regular_price ||
          !variantsPreview[i].values[j].sale_price) {
          notification.error({ message: "Please input base cost, regular price or sale price!" })
          return
        }

        if (variantsPreview[i].values[j].regular_price < variantsPreview[i].values[j].sale_price) {
          notification.error({ message: "Regular price must always be greater than sale price!" })
          return
        }

        let isCheckInputCostSupplier = false
        variantsPreview[i].values[j].cost_supplier.map(e => {
          if (e.cost) isCheckInputCostSupplier = true
          else isCheckInputCostSupplier = false
        })
        if (!isCheckInputCostSupplier) {
          notification.error({ message: "Please input cost supplier!" })
          return
        }
      }
    }

    try {
      dispatch({ type: ACTION.LOADING, data: true });
      var dataForm = form.getFieldsValue()

      /* upload, update mockup product type */
      var dataMockup = new FormData();
      var mockupProductTypeNew = null //mockup create product type
      var isSelectMockup = false //check user co chon anh mockup khong?
      let filesMockup = [] 
      mockupProductType.forEach((values1, index1) => {
        if (values1.originFileObj) {
          dataMockup.append("files", values1.originFileObj);
          isSelectMockup = true
          filesMockup.push(values1.originFileObj)
        }
      });

      if (isSelectMockup) {
        mockupProductTypeNew = await uploadFiles(filesMockup)
      }

      var resultMockUp = null

      //Trường hợp update product type
      if (location.state) {
        if (!isSelectMockup) {
          if (mockupProductType.length == 0) resultMockUp = []
          if (mockupProductType.length == mockupUpdateProductType.length)
            resultMockUp = mockupUpdateProductType
          if (mockupProductType.length < mockupUpdateProductType.length)
            resultMockUp = mockupProductType.map(e => {
              if (e && e.thumbUrl) return e.thumbUrl
            })
        }

        if (isSelectMockup) {
          let s = mockupProductType.filter(e => !e.originFileObj)
          resultMockUp = [...mockupProductTypeNew, ...s.map(v => v.thumbUrl)]
        }
      }

      //Trường hợp create product type
      if (!location.state) {
        if (mockupProductTypeNew) {
          resultMockUp = mockupProductTypeNew
        } else resultMockUp = []
      }
      /* upload, update mockup product type */

      /* upload, update template upload product type */
      var templateUploadProductNew = null //template upload create product type
      var isSelectTemplateUpload = false //check user co chon anh template upload khong?
      let filesTemplate = []
      templateUploadProductType.forEach((values1) => {
        if (values1.originFileObj) {
          isSelectTemplateUpload = true
          filesTemplate.push(values1.originFileObj)
        }
      });

      if (isSelectTemplateUpload) {
        templateUploadProductNew = await uploadFiles(filesTemplate)
      }

      var resultTemplateUpload = null

      //Trường hợp update product type
      if (location.state) {
        if (!isSelectTemplateUpload) {
          if (templateUploadProductType.length == 0) resultTemplateUpload = []
          if (templateUploadProductType.length == templateUploadUpdateProductType.length)
            resultTemplateUpload = templateUploadUpdateProductType
          if (templateUploadProductType.length < templateUploadUpdateProductType.length)
            resultTemplateUpload = templateUploadProductType.map(e => {
              if (e && e.thumbUrl) return e.thumbUrl
            })
        }

        if (isSelectTemplateUpload) {
          let s = templateUploadProductType.filter(e => !e.originFileObj)
          resultTemplateUpload = [...templateUploadProductNew, ...s.map(v => v.thumbUrl)]
        }
      }

      //Trường hợp create product type
      if (!location.state) {
        if (templateUploadProductNew) {
          resultTemplateUpload = templateUploadProductNew
        } else resultTemplateUpload = []
      }
      /* upload, update template upload product type */


      var isSelectImgPrintFile = false //check user có chọn img print file ko ?
      let filesPrintFile = []
      printFilesPreview.forEach((values1, index1) => {
        if (values1.fileUpload) {
          isSelectImgPrintFile = true
          filesPrintFile.push(values1.fileUpload)
        }
      });

      if (isSelectImgPrintFile) {
        const resultsPrintFiles = await uploadFiles(filesPrintFile)
        resultsPrintFiles.map(e => {
          for (let i = 0; i < resultsPrintFiles[0].length; ++i)
            if (printFilesPreview[i].fileUpload) {
              printFilesPreview[i].image_file = e
              delete printFilesPreview[i].fileUpload
              break
            }
        })
      }

      var username = JSON.parse(localStorage.getItem("username"));

      var object = {
        title: dataForm.title,
        sku: dataForm.sku,
        // priority: dataForm.priority || 0,
        location: dataForm.location || '',
        dateCreated: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        category: dataForm.category,
        description: dataEditor,
        shippingCountry: dataForm.country || ['all'],
        mockup: resultMockUp,
        template_upload: resultTemplateUpload,
        nameProductType: dataForm.title,
        idUser: username,
        idSite: "",
        printFiles: printFilesPreview.map(e => {
          return {
            ...e,
            size: e.width + 'x' + e.height,
          }
        }),
        variants: variantsPreview,
        listcarrier: carrierProductType.map(e => {
          return {
            name: e.name,
            idCarrier: e.code || e.idCarrier,
            cost: e.cost || 0
          }
        }), //lọc và lấy 3 thuộc tính cần thiết
        supplyBy: dataForm.suppliers,
      };

      dispatch({ type: ACTION.LOADING, data: false });
      console.log(JSON.stringify(object))
      if (location.state) updateProductTypeData(object)
      else createProductTypeData(object);
    } catch (error) {
      dispatch({ type: ACTION.LOADING, data: false });
      console.log(error)
    }
  };

  const getAllProductTypeData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getAllProductType();
      console.log(res)
      if (res.status === 200) {
        setProductType(res.data.result);
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };
  const getAllProductTypeGroupData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getCategories();
      console.log(res)
      if (res.status === 200) {
        setProductTypeGroup(res.data);
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const ModalEditImage = () => {
    const [visible, setVisible] = useState(false)
    const [img, setImg] = useState('')
    const [fileUpload, setFileUpload] = useState()
    const toggle = () => setVisible(!visible)

    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    const upload = () => {
      let listVariantUpload = []
      //preview ảnh trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        variantsProductNew[keyActiveTab][key].uploadVariant = img
        listVariantUpload.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu file ảnh
      if (fileUpload) {
        let addProductNew = [...addProduct]

        addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
          e.values.map((f, indexValues) => {
            listVariantUpload.map(l => {
              if (f.title == l.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].uploadVariant = fileUpload
            })
          })
        })

        setAddProduct([...addProductNew])
      }

      toggle()
    }
    return (
      <>
        <Button icon={<FileImageOutlined />} onClick={toggle} style={{display: recordProduct && 'none'}}>
          Edit Image
        </Button>
        <Modal
          title="Edit image"
          visible={visible}
          onCancel={toggle}
          onOk={upload}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            data={(file) => {
              setFileUpload(file)
              getBase64(file, imgUrl => setImg(imgUrl))
            }}
          >
            {img ? <img src={img} alt="" style={{ width: '100%' }} /> : <div>
              <PlusOutlined />
            </div>}
          </Upload>
        </Modal>
      </>
    )
  }

  const UploadImageProduct = ({ variant }) => {
    const [urlImg, setUrlImg] = useState(variant.uploadVariant)

    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    return (
      <Upload
        disabled={recordProduct && true}
        name="avatar"
        listType="picture-card"
        className="upload-print-file"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        data={(file) => {
          //luu file
          let addProductNew = [...addProduct]
          addProductNew[keyActiveTab].variants.map((f, indexVariant) => {
            f.values.map((v, indexValues) => {
              if (v.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].uploadVariant = file
            })
          })
          setAddProduct([...addProductNew])

          getBase64(file, img => {
            setUrlImg(img)

            //preview anh tren table
            let variantsProductNew = [...variantsProduct]
            const indexAdd = variantsProductNew[keyActiveTab].findIndex(ob => ob.key == variant.key)
            if (indexAdd != -1) {
              variantsProductNew[keyActiveTab][indexAdd].uploadVariant = img
              setVariantsProduct([...variantsProductNew])
            }
          })
        }}
      >
        {urlImg ? <img src={urlImg} alt="" style={{ width: '100%' }} /> : <div>
          <PlusOutlined />
        </div>}
      </Upload>
    )
  }

  const ModalEditRegularPrice = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [value, setValue] = useState(0)

    const editRegularPrice = () => {
      let listVariantEditRegularPrice = []
      //preview regular price trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        variantsProductNew[keyActiveTab][key].regular_price = value
        listVariantEditRegularPrice.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu regular price
      let addProductNew = [...addProduct]

      addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
        e.values.map((f, indexValues) => {
          listVariantEditRegularPrice.map(l => {
            if (f.title == l.title)
              addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].regular_price = value
          })
        })
      })
      setAddProduct([...addProductNew])

      toggle()
    }

    return (
      <>
        <Button icon={<DollarOutlined />} onClick={toggle}>
          Edit Regular Price
        </Button>
        <Modal
          title="Edit Regular Price"
          visible={visible}
          onCancel={toggle}
          onOk={editRegularPrice}
        >
          <Space>
            Regular Price:
            <InputNumber
              autoFocus
              onPressEnter={editRegularPrice}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </Space>
        </Modal>
      </>
    )
  }

  const InputRegularPriceProduct = ({ value, variant }) => {
    const [valueRegular, setValueRegular] = useState(value)
    return (
      <InputNumber
        min={0}
        style={{ width: '100%' }}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        defaultValue={value}
        onBlur={() => {
          // preview regular price trên table
          let variantsProductNew = [...variantsProduct]
          variantsProductNew[keyActiveTab][variant.key].regular_price = valueRegular
          setVariantsProduct([...variantsProductNew])

          //lưu regular price
          let addProductNew = [...addProduct]

          addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
            e.values.map((f, indexValues) => {
              if (f.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].regular_price = valueRegular
            })
          })
          setAddProduct([...addProductNew])
        }}
        onChange={(value) => setValueRegular(value)}
      />
    )
  }

  const ModalEditSalePrice = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [value, setValue] = useState(0)

    const editSalePrice = () => {
      let listVariantEditSalePrice = []
      //preview sale price trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        variantsProductNew[keyActiveTab][key].sale_price = value
        listVariantEditSalePrice.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu sale price
      let addProductNew = [...addProduct]

      addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
        e.values.map((f, indexValues) => {
          listVariantEditSalePrice.map(l => {
            if (f.title == l.title)
              addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].sale_price = value
          })
        })
      })
      setAddProduct([...addProductNew])

      toggle()
    }

    return (
      <>
        <Button icon={<DollarOutlined />} onClick={toggle}>
          Edit Sale Price
        </Button>
        <Modal
          title="Edit Sale Price"
          visible={visible}
          onCancel={toggle}
          onOk={editSalePrice}
        >
          <Space>
            Sale Price:
            <InputNumber
              autoFocus
              onPressEnter={editSalePrice}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </Space>
        </Modal>
      </>
    )
  }

  const InputSalePriceProduct = ({ value, variant }) => {
    const [valueSalePrice, setValueSalePrice] = useState(value)
    return (
      <InputNumber
        min={0}
        style={{ width: '100%' }}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        defaultValue={value}
        onBlur={() => {
          //preview regular price trên table
          let variantsProductNew = [...variantsProduct]
          variantsProductNew[keyActiveTab][variant.key].sale_price = valueSalePrice
          setVariantsProduct([...variantsProductNew])

          //lưu regular price
          let addProductNew = [...addProduct]

          addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
            e.values.map((f, indexValues) => {
              if (f.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].sale_price = valueSalePrice
            })
          })
          setAddProduct([...addProductNew])
        }}
        onChange={(value) => setValueSalePrice(value)}
      />
    )
  }

  const ModalEditStatus = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [checked, setChecked] = useState(false)

    const editStatus = () => {
      let listVariantEditStatus = []
      //preview sale price trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        variantsProductNew[keyActiveTab][key].is_active = checked
        listVariantEditStatus.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu sale price
      let addProductNew = [...addProduct]

      addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
        e.values.map((f, indexValues) => {
          listVariantEditStatus.map(l => {
            if (f.title == l.title)
              addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].is_active = checked
          })
        })
      })
      setAddProduct([...addProductNew])

      toggle()
    }

    return (
      <>
        <Button icon={<MinusCircleOutlined />} onClick={toggle} style={{display: recordProduct && 'none'}}>
          Edit Status
        </Button>
        <Modal
          title="Edit Status"
          visible={visible}
          onCancel={toggle}
          onOk={editStatus}
        >
          <Switch onChange={(checked) => setChecked(checked)} />
        </Modal>
      </>
    )
  }

  const StatusProduct = ({ value, variant }) => {
    return (
      <Switch 
        disabled={recordProduct && true}
        defaultChecked={value} 
        onChange={(checked) => {
          // preview status trên table
          let variantsProductNew = [...variantsProduct]
          variantsProductNew[keyActiveTab][variant.key].is_active = checked
          setVariantsProduct([...variantsProductNew])

          // lưu status
          let addProductNew = [...addProduct]

          addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
            e.values.map((f, indexValues) => {
              if (f.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].is_active = checked
            })
          })
          setAddProduct([...addProductNew])
        }}
      />
    )
  }

  const ModalEditBaseCost = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [value, setValue] = useState(0)

    const editBaseCost = () => {
      let listVariantEditBaseCost = []
      //preview base cost (price) trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        variantsProductNew[keyActiveTab][key].price = value
        listVariantEditBaseCost.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu base cost (price)
      let addProductNew = [...addProduct]

      addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
        e.values.map((f, indexValues) => {
          listVariantEditBaseCost.map(l => {
            if (f.title == l.title)
              addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].price = value
          })
        })
      })
      setAddProduct([...addProductNew])

      toggle()
    }

    return (
      <>
        <Button icon={<DollarOutlined />} onClick={toggle} style={{display: recordProduct && 'none'}}>
          Edit Base Cost
        </Button>
        <Modal
          title="Edit Base Cost"
          visible={visible}
          onCancel={toggle}
          onOk={editBaseCost}
        >
          <Space>
            Base Cost:
            <InputNumber
              autoFocus
              onPressEnter={editBaseCost}
              min={0}
              style={{ width: '100%' }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </Space>
        </Modal>
      </>
    )
  }

  const InputBaseCostProduct = ({ value, variant }) => {
    const [valueBaseCost, setValueBaseCost] = useState(value)
    return (
      <InputNumber
        disabled={recordProduct && true}
        min={0}
        style={{ width: '100%' }}
        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        defaultValue={value}
        onBlur={() => {
          //preview base cost(price) trên table
          let variantsProductNew = [...variantsProduct]
          variantsProductNew[keyActiveTab][variant.key].price = valueBaseCost
          setVariantsProduct([...variantsProductNew])

          //lưu base cost(price)
          let addProductNew = [...addProduct]

          addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
            e.values.map((f, indexValues) => {
              if (f.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].price = valueBaseCost
            })
          })
          setAddProduct([...addProductNew])
        }}
        onChange={(value) => setValueBaseCost(value)}
      />
    )
  }

  const ModalEditProductSku = () => {
    const [visible, setVisible] = useState(false)
    const toggle = () => setVisible(!visible)

    const [value, setValue] = useState('')

    const editProductSku = () => {
      let listVariantEditProductSku = []
      //preview sku trên table
      let variantsProductNew = [...variantsProduct]
      selectRowKeyProduct.map(key => {
        let skuSplit = variantsProductNew[keyActiveTab][key].sku.split("-")

        variantsProductNew[keyActiveTab][key].sku = skuSplit[0] + "-" + skuSplit[1] + "-" + value
        listVariantEditProductSku.push(variantsProductNew[keyActiveTab][key])
      })
      setVariantsProduct([...variantsProductNew])

      //lưu sku
      let addProductNew = [...addProduct]

      addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
        e.values.map((f, indexValues) => {
          listVariantEditProductSku.map(l => {
            if (f.title == l.title) {
              let skuSplit = addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].sku.split("-")
              addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].sku = skuSplit[0] + "-" + skuSplit[1] + "-" + value
            }
          })
        })
      })
      setAddProduct([...addProductNew])

      toggle()
    }

    return (
      <>
        <Button icon={<HighlightOutlined />} onClick={toggle}>
          Edit Product Sku
        </Button>
        <Modal
          title="Edit Product Sku"
          visible={visible}
          onCancel={toggle}
          onOk={editProductSku}
        >
          <Space>
            Product Sku:
            <Input
              autoFocus
              onPressEnter={editProductSku}
              style={{ width: '100%' }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Space>
        </Modal>
      </>
    )
  }

  const InputSkuProduct = ({ value, variant }) => {
    let uuidLength6Default = ''
    let skuAddonDefault = ''

    let sku = value.split("-")
    skuAddonDefault = sku[0] + '-' + sku[1]

    if (!sku[2]) {
      //truong hop tao product
      //khoi tao uuid co length la 6
      uuidLength6Default = initUuid.slice(0, 6)

      //preview sku trên table
      variantsProduct.map((e, index) => {
        e.map((v, indexV) => {
          let skuVariant = v.sku.split("-")
          const skuAddon = skuVariant[0] + '-' + skuVariant[1]

          const uuidLength6 = initUuid.slice(0, 6)
          variantsProduct[index][indexV].sku = skuAddon + '-' + uuidLength6
        })
      })

      //lưu sku      
      addProduct.map((k, indexProduct) => {
        k.variants.map((e, indexVariant) => {
          e.values.map((f, indexValues) => {
            let skuProduct = f.sku.split("-")
            const skuAddon = skuProduct[0] + '-' + skuProduct[1]

            const uuidLength6 = initUuid.slice(0, 6)
            addProduct[indexProduct].variants[indexVariant].values[indexValues].sku = skuAddon + '-' + uuidLength6
          })
        })
      })
    }

    //truong hop update product
    //cat chuoi uuid co length la 6
    if (sku[2]) {
      sku.map((e, index) => {
        if (index >= 2) {
          if (sku.length - 1 === index) uuidLength6Default += sku[index]
          else uuidLength6Default += sku[index] + "-"
        }
      })
    }

    const [valueSku, setValueSku] = useState(uuidLength6Default)

    return (
      <Input
        addonBefore={skuAddonDefault + '-'}
        defaultValue={uuidLength6Default}
        onBlur={() => {
          //preview sku trên table
          let variantsProductNew = [...variantsProduct]
          variantsProductNew[keyActiveTab][variant.key].sku = skuAddonDefault + '-' + valueSku

          setVariantsProduct([...variantsProductNew])

          //lưu sku
          let addProductNew = [...addProduct]

          addProductNew[keyActiveTab].variants.map((e, indexVariant) => {
            e.values.map((f, indexValues) => {
              if (f.title == variant.title)
                addProductNew[keyActiveTab].variants[indexVariant].values[indexValues].sku = skuAddonDefault + '-' + valueSku
            })
          })
          setAddProduct([...addProductNew])
        }}
        onChange={(e) => setValueSku(e.target.value)}
      />
    )
  }

  const columnsVariantProduct = [
    {
      title: "Image",
      key: "uploadVariant",
      render: (text, record) => <UploadImageProduct variant={record} />
    },
    {
      title: "Option",
      key: "option",
      dataIndex: 'title',
      editable: true,
    },
    {
      title: "Base Cost",
      key: "baseCost",
      dataIndex: 'price',
      render: (text, record) => <InputBaseCostProduct value={text} variant={record} />
    },
    {
      title: "Regular Price",
      key: "regularPrice",
      dataIndex: 'regular_price',
      render: (text, record) => <InputRegularPriceProduct value={text} variant={record} />
    },
    {
      title: "Sale Price",
      key: "salePrice",
      dataIndex: 'sale_price',
      render: (text, record) => <InputSalePriceProduct value={text} variant={record} />
    },
    {
      title: "Product Sku",
      key: "productSku",
      dataIndex: 'sku',
      width: 250,
      render: (text, record) => <InputSkuProduct value={text} variant={record} />
    },
    {
      title: "Status",
      key: "status",
      dataIndex: 'is_active',
      render: (text, record) => <StatusProduct value={text} variant={record} />
    },
  ]

  const UploadPrintFileProduct = ({ printFile, indexPrintFile }) => {
    const [urlImg, setUrlImg] = useState(printFile.image_file)
    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    }

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="upload-print-file"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        data={(file) => {
          getBase64(file, img => {
            setUrlImg(img)
            printFilesProduct[indexPrintFile].image_file = img
          })

          printFilesProduct[indexPrintFile].fileUpload = file
        }}
      >
        {urlImg ? <img src={urlImg} alt="" style={{ width: '100%' }} /> : <div>
          <PlusOutlined />
        </div>}
      </Upload>
    )
  }

  const StatusPrintFileProduct = ({ value, indexPrintFile }) => {
    return <Switch
      defaultChecked={value}
      onChange={(checked) => printFilesProduct[indexPrintFile].personalization = checked}
    />
  }

  const columnsPrintFilesProduct = [
    {
      title: "Print File",
      key: "uploadPrintFile",
      render: (text, record, index) => <UploadPrintFileProduct printFile={record} indexPrintFile={index} />
    },
    {
      title: "Name",
      key: "name_file",
      dataIndex: 'name_file',
    },
    {
      title: "Size",
      key: "size",
      dataIndex: 'size',
      render: (text, record) => (record.width || '') + ' x ' + (record.height || '')
    },
    {
      title: "Note",
      key: "note",
      dataIndex: 'note',
    },
    {
      title: <Tooltip
        title="You will upload a personalized print file when orders are placed. Enable personalization will place all orders for this product on hold until you upload a new print file with your customer's personalization. Please visit our tutorial for instructions and FREE templates."
      >
        Personalization
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Tooltip>,
      key: "status",
      dataIndex: 'personalization',
      width: 150,
      render: (text, record, index) => <StatusPrintFileProduct value={text} indexPrintFile={index} />
    },
  ]

  //func create product
  const [keyActiveTab, setKeyActiveTab] = useState(0) //key của tab đang expand
  const [recordProduct, setRecordProduct] = useState(null) //object product dung de update
  const [addProduct, setAddProduct] = useState([]); //list product type
  const [variantsProduct, setVariantsProduct] = useState([]) //data source table create product variants
  const [printFilesProduct, setPrintFilesProduct] = useState([]) //data source table create product print files
  const [descriptionProduct, setDescriptionProduct] = useState("");
  const [platform, setPlatform] = useState('')
  const [keyActiveTabsProductType, setKeyActiveTabsProductType] = useState(0)

  const RenderProductType = () => {
    return <Tabs 
        style={{width: '100%'}} 
        defaultActiveKey={keyActiveTabsProductType} 
        onTabClick={(key) => setKeyActiveTabsProductType(key) }
      >
      {productTypeGroup.map((p, index) => {
        //kiểm tra mỗi category có trong list product type ko?
        let isNotProductTypeByGroup = productType.find(e => e.category == p.name && e.is_active)
        if (!isNotProductTypeByGroup) return null

        return (
          <Tabs.TabPane tab={p.name} key={index}>
            <div className={styles["product_add_content_content"]}>
              <Row className={styles["product_add_content_content_row"]}>
                {productType.map((values5) => {
                  if (p.name == values5.category && values5.is_active) {
                    //xu li lay gia tri min price trong variants
                    let minBaseCost = 0
                    values5.variants.map(e => {
                      const values = e.values.map(v => v.price).sort((a, b) => a - b)
                      if (minBaseCost < values[0]) minBaseCost = values[0]
                    })
                    return (
                      <Col
                        className={styles["product_add_content_content_row_col"]}
                        onClick={() => {
                          //check user co chon product type cung loai
                          const p = addProduct.find(e => e.category !== values5.category)
                          if (p) {
                            notification.error({message: "Please choose product type the same category", duration: 3})
                            return
                          }
          
                          let addProductNew = [...addProduct]
                          let variantsProductNew = [...variantsProduct]
          
                          const isCheckAddProduct = addProductNew.findIndex((e) => e._id == values5._id)
          
                          //nếu đã đc chọn rồi thì remove
                          if (isCheckAddProduct != -1) {
                            addProductNew.splice(isCheckAddProduct, 1)
                            variantsProductNew.splice(isCheckAddProduct, 1)
                          }
                          else {
                            addProductNew.push(values5) //nếu chưa đc chọn thì add
                            
                            let dataSource = []
                            let key = 0
                            values5.variants.map(e => e.values.map(q => {
                              //gộp các values của variant thành 1 mảng
                              dataSource.push({ ...q, uploadVariant: '', key: key++ })
                            }))
                            variantsProductNew.push([...dataSource])
          
                            let descriptionProductType = []
                            addProductNew.map(e => {
                              if (e.description) {
                                const desRegex = e.description.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '')
                                const test1 = desRegex.replace(/' '/g, '')
                                let isHave = false
                                descriptionProductType.map(d => {
                                  const test2 = d.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '').replace(/' '/g, '')
                                  if (test1 === test2) isHave = true
                                })

                                if (!isHave) descriptionProductType.push(e.description)
                              }
                            })
                            console.log(descriptionProductType)
                            setDescriptionProduct(parse(descriptionProductType.toString())) //lay description cua cac product type dc chon
                            setInitDataEditor({ initData: parse(descriptionProductType.toString()) })
          
                            //categories sẽ lấy product type đầu tiên đc chọn
                            formProduct.setFieldsValue({
                              categories: addProductNew[0].category,
                              description: parse(descriptionProductType.toString())
                            });
                          }
          
                          // lấy tất cả print files của các product type đc chon
                          // thêm thuộc tính status cho printfile
                          let printFiles = []
                          addProductNew.map(e => {
                            e.printFiles.map(v => {
                              printFiles.push({ ...v, personalization: true })
                            })
                          })
                          setPrintFilesProduct([...printFiles])
          
                          setAddProduct([...addProductNew])
                          setVariantsProduct([...variantsProductNew])
                        }}
                        xs={24}
                        sm={11}
                        md={7}
                        lg={5}
                        xl={5}
                      >
                        <div className={styles["product_add_content_content_row_col_item"]}>
                          <div
                            className={
                              styles[
                              "product_add_content_content_row_col_item_image_parent"
                              ]
                            }
                          >
                            <img
                              className={
                                styles[
                                "product_add_content_content_row_col_item_image_child"
                                ]
                              }
                              src={values5.mockup[0]}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div
                            className={
                              styles["product_add_content_content_row_col_item_bottom"]
                            }
                          >
                            <div
                              className={
                                styles[
                                "product_add_content_content_row_col_item_bottom_title"
                                ]
                              }
                            >
                              {values5.nameProductType}
                            </div>
                            <div
                              className={
                                styles[
                                "product_add_content_content_row_col_item_bottom_title_price"
                                ]
                              }
                            >
                              {`Location: ${values5.location || ''}`}
                            </div>
                            <div
                              className={
                                styles[
                                "product_add_content_content_row_col_item_bottom_title_price"
                                ]
                              }
                            >
                              {`Base cost: ${minBaseCost}$`}
                            </div>
                          </div>
          
                          {addProduct.map(e => {
                            if (e._id == values5._id)
                              return (
                                <div
                                  className={
                                    styles["product_add_content_content_row_col_item_click"]
                                  }
                                >
                                  <DownCircleOutlined
                                    style={{ fontSize: "2.5rem", color: "#41AD49" }}
                                  />
                                </div>
                              )
                          })}
                        </div>
                      </Col>
                    );
                  }
                })}
              </Row>
            </div>
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  };

  const RenderProductTypeBySearch = () => {
    return (
      <Row>
        {productType.map((values5) => {
          if (values5.is_active) {
            //xu li lay gia tri min price trong variants
            let minBaseCost = 0
            values5.variants.map(e => {
              const values = e.values.map(v => v.price).sort((a, b) => a - b)
              if (minBaseCost < values[0]) minBaseCost = values[0]
            })
            return (
              <Col
                className={styles["product_add_content_content_row_col"]}
                onClick={() => {
                  //check user co chon product type cung loai
                  const p = addProduct.find(e => e.category !== values5.category)
                  if (p) {
                    notification.error({message: "Please choose product type the same category", duration: 3})
                    return
                  }

                  let addProductNew = [...addProduct]
                  let variantsProductNew = [...variantsProduct]

                  const isCheckAddProduct = addProductNew.findIndex((e) => e._id == values5._id)

                  //nếu đã đc chọn rồi thì remove
                  if (isCheckAddProduct != -1) {
                    addProductNew.splice(isCheckAddProduct, 1)
                    variantsProductNew.splice(isCheckAddProduct, 1)
                  }
                  else {
                    addProductNew.push(values5) //nếu chưa đc chọn thì add
                    
                    let dataSource = []
                    let key = 0
                    values5.variants.map(e => e.values.map(q => {
                      //gộp các values của variant thành 1 mảng
                      dataSource.push({ ...q, uploadVariant: '', key: key++ })
                    }))
                    variantsProductNew.push([...dataSource])

                    let descriptionProductType = []
                    addProductNew.map(e => {
                      if (e.description) {
                        const desRegex = e.description.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '')
                        const test1 = desRegex.replace(/' '/g, '')
                        let isHave = false
                        descriptionProductType.map(d => {
                          const test2 = d.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '').replace(/' '/g, '')
                          if (test1 === test2) isHave = true
                        })

                        if (!isHave) descriptionProductType.push(e.description)
                      }
                    })
                    console.log(descriptionProductType)
                    setDescriptionProduct(parse(descriptionProductType.toString())) //lay description cua cac product type dc chon
                    setInitDataEditor({ initData: parse(descriptionProductType.toString()) })

                    //categories sẽ lấy product type đầu tiên đc chọn
                    formProduct.setFieldsValue({
                      categories: addProductNew[0].category,
                      description: parse(descriptionProductType.toString())
                    });
                  }

                  // lấy tất cả print files của các product type đc chon
                  // thêm thuộc tính status cho printfile
                  let printFiles = []
                  addProductNew.map(e => {
                    e.printFiles.map(v => {
                      printFiles.push({ ...v, personalization: true })
                    })
                  })
                  setPrintFilesProduct([...printFiles])

                  setAddProduct([...addProductNew])
                  setVariantsProduct([...variantsProductNew])
                }}
                xs={24}
                sm={11}
                md={7}
                lg={5}
                xl={5}
              >
                <div className={styles["product_add_content_content_row_col_item"]}>
                  <div
                    className={
                      styles[
                      "product_add_content_content_row_col_item_image_parent"
                      ]
                    }
                  >
                    <img
                      className={
                        styles[
                        "product_add_content_content_row_col_item_image_child"
                        ]
                      }
                      src={values5.mockup[0]}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                  <div
                    className={
                      styles["product_add_content_content_row_col_item_bottom"]
                    }
                  >
                    <div
                      className={
                        styles[
                        "product_add_content_content_row_col_item_bottom_title"
                        ]
                      }
                    >
                      {values5.nameProductType}
                    </div>
                    <div
                      className={
                        styles[
                        "product_add_content_content_row_col_item_bottom_title_price"
                        ]
                      }
                    >
                      {`Location: ${values5.location || ''}`}
                    </div>
                    <div
                      className={
                        styles[
                        "product_add_content_content_row_col_item_bottom_title_price"
                        ]
                      }
                    >
                      {`Base cost: ${minBaseCost}$`}
                    </div>
                  </div>

                  {addProduct.map(e => {
                    if (e._id == values5._id)
                      return (
                        <div
                          className={
                            styles["product_add_content_content_row_col_item_click"]
                          }
                        >
                          <DownCircleOutlined
                            style={{ fontSize: "2.5rem", color: "#41AD49" }}
                          />
                        </div>
                      )
                  })}
                </div>
              </Col>
            );
          }
        })}
      </Row>
    )
  }

  const uploadMockupImagesProduct = async () => {
    let mockupProductDefault = []
    let isCheckUploadMockup = false //check user co chon anh mockup
    let filesMockup = []
    addProduct.map(e => {
      e.mockup.map(file => {
        if (typeof file != 'string') {
          isCheckUploadMockup = true
          filesMockup.push(file)
        }
        else mockupProductDefault.push(file)
      })
    })

    if (isCheckUploadMockup) {
      const results = await uploadFiles(filesMockup)
      return [...results, ...mockupProductDefault]
    }

    return [...mockupProductDefault]
  };

  const uploadTemplateUploadProduct = async () => {
    let templateUploadProductDefault = []
    let isCheckTemplateUpload = false //check user co chon template upload ko?
    let filesTemplate = []
    addProduct.map(e => {
      e.template_upload.map(file => {
        if (typeof file != 'string') {
          isCheckTemplateUpload = true
          filesTemplate.push(file)
        }
        else templateUploadProductDefault.push(file)
      })
    })

    if (isCheckTemplateUpload) {
      const results = await uploadFiles(filesTemplate)
      return [...results, ...templateUploadProductDefault]
    }

    return [...templateUploadProductDefault]
  };

  // Nếu nhấn button Create New thì status là true
  // Nếu nhấn button Save Draft thì status là false
  const _createProduct = async (status = true) => {
    // validated cac field bat buoc
    let isValidated = false
    try {
      await formProduct.validateFields()
      isValidated = true
    } catch (error) {
      isValidated = false
    }

    if (!isValidated) {
      notification.error({ message: "Please complete all required fields!" })
      return
    }

    if (!descriptionProduct) {
      notification.error({ message: "Please input description!" })
      return
    }

    for (let i = 0; i < addProduct.length; ++i) {
      if (!addProduct[i].mockup.length) {
        notification.error({ message: `Please input mockup ${addProduct[i].title}` })
        return
      }
    }

    //chỉ validated cho variant của product type đầu tiên
    for (let i = 0; i < addProduct[0].variants.length; ++i) {
      for (let j = 0; j < addProduct[0].variants[i].values.length; ++j) {
        if (!addProduct[0].variants[i].values[j].price ||
          !addProduct[0].variants[i].values[j].regular_price ||
          !addProduct[0].variants[i].values[j].sale_price
        ) {
          notification.error({ message: "Please input base cost, regular price or sale price!" })
          return
        }
        if (!addProduct[0].variants[i].values[j].regular_price < !addProduct[0].variants[i].values[j].sale_price) {
          notification.error({ message: "Regular price must always be greater than sale price" })
          return
        }
        if (!addProduct[0].variants[i].values[j].sku) {
          notification.error({ message: "Please input variant SKU!" })
          return
        }
      }
    }

    try {
      setVisibleProgress(true)
      let percent = 0
      var timeoutProgress = setInterval(() => {
        percent += Math.floor(Math.random() * 15) //random 0-10
        if (percent > 100) setPercentProgress(100)
        else setPercentProgress(percent)
      }, 800)

      /*Upload image variant product */
      let filterListImageUpload = [] // lọc ra các values của variant ko đc chọn img 
      let arrayFileEqual = [] // array các file upload
      let resultImagesUpload = [] //gộp các values của variant có file upload trùng nhau

      addProduct.map(e => {
        e.variants.map(v => {
          v.values.map(f => {
            if (f.uploadVariant) {
              if (!arrayFileEqual.includes(JSON.stringify(f.uploadVariant)))
                arrayFileEqual.push(JSON.stringify(f.uploadVariant))
              filterListImageUpload.push(f)
            }
          })
        })
      })

      arrayFileEqual.map(e => {
        resultImagesUpload.push(
          filterListImageUpload.filter(v => JSON.stringify(v.uploadVariant) == e)
        )
      })

      //check user co upload image variant ko
      // ngược lại thì khởi tạo uploadVariant chuỗi rỗng
      if (resultImagesUpload.length) {
        const resUpload = await uploadFiles(resultImagesUpload)
        resultImagesUpload.map((results, indexImage) => {
          results.map(o => {
            addProduct.map((e, indexProduct) => {
              e.variants.map((v, indexVariant) => {
                v.values.map((f, indexValues) => {
                  if (JSON.stringify(o.uploadVariant) === JSON.stringify(f.uploadVariant))
                    addProduct[indexProduct].variants[indexVariant].values[indexValues].uploadVariant = resUpload[indexImage]
                  else addProduct[indexProduct].variants[indexVariant].values[indexValues].uploadVariant = ""
                })
              })
            })
          })
        })
      } else addProduct.map((e, indexProduct) => {
        e.variants.map((v, indexVariant) => {
          v.values.map((f, indexValues) => {
            addProduct[indexProduct].variants[indexVariant].values[indexValues].uploadVariant = ""
          })
        })
      })
      /*Upload image variant product */

      /*Upload image print files product */
      let isEmptyFileUploadPrintFiles = false //check user co upload image print file ko
      let filesPrintFile = []
      printFilesProduct.map(e => {
        if (e.fileUpload) {
          isEmptyFileUploadPrintFiles = true
          filesPrintFile.push(e.fileUpload)
        }
      })

      if (isEmptyFileUploadPrintFiles) {
        const results = await uploadFiles(filesPrintFile)
        results.map(img => {
          for (let i = 0; i < printFilesProduct.length; ++i) {
            if (printFilesProduct[i].fileUpload) {
              printFilesProduct[i].image_file = img
              delete printFilesProduct[i].fileUpload
              break
            }
          }
        })
      }
      /*Upload image print files product */

      var variantCreateProduct = [...addProduct[0].variants]
      var variantsMulti = []
      //nếu chọn trên 1 product type thì sẽ phát sinh thêm 1 variant
      if (addProduct.length > 1) {
        let variantsIncurred = []

        addProduct.map(e => {
          variantsIncurred.push({
            ...e.variants[0].values[0],
            title: e.title
          })
        })

        variantCreateProduct.push({
          option: "product",
          values: variantsIncurred
        })

        addProduct.map(e => {
          if (e.variants.find(v => v.option != 'product')) {
            variantsMulti.push(e.variants)
          }
        })
      }


      //check nếu user ko chỉnh sửa description thì lấy các description của các product type
      let descriptionCreateProduct = ""
      if (typeof descriptionProduct != 'string') {
        addProduct.map(e => {
          descriptionCreateProduct += e.description
        })
      }

      const data = formProduct.getFieldsValue()
      const images = await uploadMockupImagesProduct();
      const templateUpload = await uploadTemplateUploadProduct()

      const body = {
        dateCreated: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        variants: variantCreateProduct,
        variants_multi: variantsMulti,
        printFiles: printFilesProduct,
        platform: platform,
        idSite: data.idSite,
        tags: data.tags || [],
        mockup: images,
        template_upload: templateUpload,
        title: data.title,
        category: data.categories,
        description: descriptionCreateProduct || descriptionProduct,
        status: status,
        product_type_id: addProduct[0].id, //lay id product type dau tien
        personalization_instructions: data.personalization_instructions || '',
        idUser:
          localStorage.getItem("username") &&
          JSON.parse(localStorage.getItem("username")),
      }

      //create product
      if (!recordProduct) {
        console.log(JSON.stringify(body))
        const res = await createProduct(body);
        console.log(res)
        if (res.status === 200) {
          if (!res.data.success) 
            notification.error({ message: res.data.mess });
          else {
            setModal1VisibleModal(false);
            history.push(ROUTES.PRODUCTS)
            notification.success({ message: "Create product success!" });
          }
        }
        if (res.status === 401) notification.error({ message: "Unable to authentication account, please connect Site again" });
        if (res.status === 502) notification.error({ message: "System not enough permission create product" });

      } else { //update product
        delete body.dateCreated
        delete body.platform
        delete body.product_type_id
        delete body.category
        delete body.tags
        body.id = recordProduct.id
        body._id = recordProduct._id
        body.is_active = recordProduct.is_active
        body.variants = recordProduct.variants
        body.variants_multi = recordProduct.variants_multi

        console.log(JSON.stringify(body))

        const res = await updateProduct(body);
        console.log(res)
        if (res.status === 200) {
          if (!res.data.success) 
            notification.error({ message: res.data.mess });
          else {
            setModal1VisibleModal(false);
            history.goBack()
            notification.success({ message: "Update product success!" });
          }
        } 
        if (res.status === 401) notification.error({ message: "Unable to authentication account, please connect Site again" });
        if (res.status === 502) notification.error({ message: "System not enough permission update product" });
      }

      setVisibleProgress(false)
      clearInterval(timeoutProgress)
      setPercentProgress(0)
    } catch (error) {
      setVisibleProgress(false)
      clearInterval(timeoutProgress)
      setPercentProgress(0)

      console.log(error)
      notification.error({ message: "System error, please try again!" });
    }
  };

  const getCarrierData = async () => {
    try {
      dispatch({ type: ACTION.LOADING, data: true });
      const res = await getCarrier();
      if (res.status === 200) {
        setCarrier(res.data.data);
      }
      dispatch({ type: ACTION.LOADING, data: false });
    } catch (error) {
      console.log(error)
      dispatch({ type: ACTION.LOADING, data: false });
    }
  };

  const _filterRoleUsers = async (role = 'Supplier') => {
    try {
      const res = await filterRoleUsers({role, status: 1});

      if (res.status === 200) {
        setListUser(res.data.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    _filterRoleUsers()
    getCarrierData();
    getAllProductTypeData();
    getAllProductTypeGroupData();
    getAllSites();
    getCountryData();
  }, []);

  //chức năng update product
  useEffect(() => {
    //lấy product đc chọn từ UI product để update product
    const idProduct = new URLSearchParams(location.search).get("_id")
    if (idProduct) {
      setModal1VisibleModal(true)
      searchProduct({ _id: idProduct }).then(res => {
        if (res.data.success) {
          const product = res.data.item

          //init product de dien san cac gia tri
          formProduct.setFieldsValue({
            title: product.title,
            idSite: product.idSite,
            categories: product.category,
            description: product.description,
            tags: product.tags,
            personalization_instructions: product.personalization_instructions,
          })

          setDescriptionProduct(parse(product.description))
          setInitDataEditor({ initData: parse(product.description) })

          setPrintFilesProduct([...product.printFiles])

          let listVariants = []
          let v = []
          let key = 0
          product.variants.map(e => e.values.map(f => v.push({...f, key: key++})))
          listVariants.push(v)
          setVariantsProduct([...listVariants])

          let listProduct = []
          listProduct.push(product)
          setAddProduct([...listProduct])

          setRecordProduct(product)
        } else {
          notification.error("System error, please try again!")
          history.goBack()
        }
      })
    }
  }, [])

  const onCloseAddProductType = () => {
    Modal.confirm({
      title: 'Do you want to exit?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (typeQuery === "productType") {
          history.push(ROUTES.PRODUCT_TYPES);
          setModal2VisibleModal(false);
        } else {
          setModal2VisibleModal(false);
        }
      },
    });
  };

  return (
    <div className={styles["product_add"]}>
      <div className={styles['loading-progress']} style={{display: !visibleProgress && 'none'}}>
        <div className={styles['overlay-progress']}></div>
        <Progress type="circle" percent={percentProgress}/>
      </div>
      <Link style={{ width: "100%" }} to={ROUTES.PRODUCTS}>
        <div className={styles["product_add_title"]}>
          <div>
            <ArrowLeftOutlined />
          </div>
          <div>Select product type</div>
        </div>
      </Link>
      <Form style={{ width: "100%" }}>
        <div className={styles["product_add_content"]}>
          <div className={styles["product_add_content_parent"]}>
            <Row className={styles["product_add_content_parent_row"]}>

              <Drawer
                title={location.state ? "Edit product type" : "Add new product types"}
                width="100%"
                footer={
                  <Row justify='end'>
                    <Button type="primary" onClick={onFinishAddProductType} icon={<SaveOutlined />}>
                      {location.state ? 'Save' : 'Add'}
                    </Button>
                    <a href={window.location.href}>
                      <Button
                        style={{ display: !location.state && 'none', marginLeft: "1rem" }}
                        icon={<UndoOutlined />}
                      >
                        Reset
                      </Button>
                    </a>
                    <Button
                      style={{ marginLeft: "1rem" }}
                      onClick={onCloseAddProductType}
                    >
                      Cancel
                    </Button>
                  </Row>
                }
                visible={modal2Visible}
                onClose={onCloseAddProductType}
              >
                <Form
                  className={styles["modal"]}
                  form={form}
                  layout="vertical"
                >
                  <Row className={styles["modal_detail_row"]}>
                    <Col
                      style={{width: 'calc(100% - 330px)'}}
                    >
                      <div
                        className={styles["modal_detail_row_col_parent_bottom"]}
                      >
                        <div
                          className={
                            styles["modal_detail_row_col_parent_title"]
                          }
                        >
                          Detail
                        </div>
                        <div style={{ padding: "1rem" }}>
                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row_fix"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                name="title"
                                label="Title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your title!",
                                  },
                                ]}
                              >
                                <Input
                                  className={
                                    styles[
                                    "modal_detail_row_col_parent_row_col_input"
                                    ]
                                  }
                                  placeholder="Enter title"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row_fix"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                name="location"
                                label="Location"
                              >
                                <Input
                                  className={
                                    styles[
                                    "modal_detail_row_col_parent_row_col_input"
                                    ]
                                  }
                                  placeholder="Enter location"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row_fix"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                label="SKU"
                                name="sku"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your sku!",
                                  },
                                ]}
                              >
                                <Input
                                  className={
                                    styles[
                                    "modal_detail_row_col_parent_row_col_input"
                                    ]
                                  }
                                  placeholder="Enter sku"
                                  onChange={(e) => setSku(e.target.value)}
                                  disabled={location.state && localStorage.getItem('role') && ['Admin', 'Fullfiller'].includes(localStorage.getItem('role')) && true}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                label="Description"
                                name="descriptionPDT"
                                help={!dataEditor && "Please enter description"}
                                validateStatus="error"
                              >
                                <CKEditor
                                  initData={location.state && parse(location.state.description)}
                                  onChange={(e) => {
                                    setDataEditor(e.editor.getData())
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div
                        className={styles["modal_detail_row_col_parent_bottom"]}
                      >
                        <div
                          className={
                            styles["modal_detail_row_col_parent_title"]
                          }
                        >
                          Mockup Images
                        </div>
                        <div
                          className={
                            styles[
                            "modal_detail_row_col_parent_bottom_upload_images"
                            ]
                          }
                        >
                          <Form.Item
                            className={
                              styles[
                              "modal_detail_row_col_parent_bottom_images"
                              ]
                            }
                            name="dragger"
                            valuePropName="fileList"
                            getValueFromEvent={normFileImages}
                            noStyle
                          >
                            <Upload.Dragger
                              multiple
                              name="files"
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              listType="picture"
                              showUploadList={{
                                showRemoveIcon: true,
                                removeIcon: <Popconfirm
                                  title="Are you sure to delete this image?"
                                  okText="Yes"
                                  cancelText="No"
                                  onCancel={(e) => e.stopPropagation()}
                                >
                                  <div 
                                    style={{
                                      position: 'absolute',
                                      left: '-1px',
                                      right: '-1px',
                                      top: '-1px',
                                      bottom: '-1px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <DeleteOutlined />
                                  </div>
                                </Popconfirm>
                              }}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a .PNG, .JPG, .TIFF, .EPS image.
                              </p>
                            </Upload.Dragger>
                          </Form.Item>
                        </div>
                      </div>

                      <div className={styles["variants_new_change"]}>
                        <Row
                          style={{
                            display: "flex",
                            borderBottom: " 1px solid #f0f0f0",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Col
                            style={{ width: "100%" }}
                            xs={24}
                            sm={24}
                            md={11}
                            lg={11}
                            xl={11}
                          >
                            <div
                              className={styles["variants_new_change_title"]}
                            >
                              Variants
                            </div>
                          </Col>
                          <Col
                            className={styles["add_variants_new"]}
                            style={{ width: "100%" }}
                            xs={24}
                            sm={24}
                            md={11}
                            lg={11}
                            xl={11}
                          >
                            <Tooltip title="Maximum 2 Variants added">
                              <Button
                                type='link'
                                onClick={addVariantProductTypeNew}
                                icon={<PlusOutlined />}
                              >
                                Add Variant
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                        <div style={{ padding: "0rem 1rem 1rem 1rem" }}>
                          <Col style={{ width: "100%" }}>
                            {variantsPreview.map((e, index) => {
                              const RenderInput = () => <Input
                                placeholder='Input size'
                                defaultValue={e.option}
                                onChange={(e) => {
                                  variantsPreview[
                                    index
                                  ].option = e.target.value;
                                }}
                              />
                              return (
                                <>
                                  <Row
                                    style={{ width: "100%" }}
                                    wrap={false}
                                    align="middle"
                                  >
                                    <Col
                                      style={{
                                        width: "50%",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <span style={{ marginBottom: 0 }}>
                                        Attribute
                                      </span>
                                      <RenderInput />
                                    </Col>
                                    <Col style={{ width: "50%" }}>
                                      <span style={{ marginBottom: 0 }}>
                                        Value
                                      </span>
                                      <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="input the value"
                                        value={e.values.map(v => v.title)}
                                        onDeselect={(v) => { //remove tag
                                          let items = [...variantsPreview]
                                          const indexRemove = e.values.findIndex(f => f.title == v)
                                          if (indexRemove != -1) {
                                            items[index].values.splice(indexRemove, 1)
                                            setVariantsPreview([...items]);
                                          }

                                          let listCheckboxVariantProductTypeNew = [...listCheckboxVariantProductType]
                                          const indexRemoveCheckbox = listCheckboxVariantProductType.findIndex(f => f == v)
                                          if (indexRemoveCheckbox != -1) {
                                            listCheckboxVariantProductTypeNew.splice(indexRemoveCheckbox, 1)
                                            setListCheckboxVariantProductType([...listCheckboxVariantProductTypeNew])
                                          }
                                        }}
                                        onSelect={(e) => { //add tag
                                          let items = [...variantsPreview]
                                          let listCheckboxVariantProductTypeNew = [...listCheckboxVariantProductType]

                                          //check value add này đã tồn tại chưa
                                          for (let i = 0; i < items.length; ++i) {
                                            for (let j = 0; j < items[i].values.length; ++j) {
                                              if (items[i].values[j].title == e) {
                                                notification.error({ message: "Value is exists!" })
                                                return
                                              }
                                            }
                                          }

                                          //trường hợp nhập nhiều variant bởi dấu phẩy
                                          //ví dụ: color, size, quantity
                                          const splitValue = e.split(',')
                                          splitValue.map(v => {
                                            if (v) 
                                              items[index].values.push({
                                                title: v.trim(),
                                                sku: "",
                                                cost_supplier: [...suppliersProductType],
                                                price: "",
                                                regular_price: "",
                                                sale_price: "",
                                                is_active: true,
                                              })

                                            listCheckboxVariantProductTypeNew.push(v.trim())
                                          })
                                          
                                          setVariantsPreview([...items]);
                                          setListCheckboxVariantProductType([...listCheckboxVariantProductTypeNew])
                                        }}
                                        optionLabelProp="label"
                                      ></Select>
                                    </Col>
                                    <Popconfirm
                                      title="Are you sure to delete?"
                                      onConfirm={() => {
                                        //remove checkbox variant
                                        let listCheckboxVariantProductTypeNew = [...listCheckboxVariantProductType]
                                        variantsPreview[index].values.map(x => {
                                          const indexRemoveCheckbox = listCheckboxVariantProductType.findIndex(h => h == x.title)
                                          if (indexRemoveCheckbox != -1)
                                            listCheckboxVariantProductTypeNew.splice(indexRemoveCheckbox, 1)
                                        })
                                        setListCheckboxVariantProductType([...listCheckboxVariantProductTypeNew])

                                        //remove item input variant
                                        let removeVariantsInput = [
                                          ...variantsPreview,
                                        ];
                                        removeVariantsInput.splice(index, 1);
                                        setVariantsPreview([...removeVariantsInput]);

                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <CloseOutlined
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                          fontSize: 18,
                                          marginTop: 22,
                                          marginLeft: 8,
                                          visibility: variantsPreview.length == 1 && 'hidden'
                                        }}
                                      />
                                    </Popconfirm>
                                  </Row>
                                  <br />
                                </>
                              )
                            }
                            )}
                          </Col>
                        </div>
                        <Col
                          style={{ width: "100%", display: variantsPreview.length == 2 && 'none' }}
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                        >
                          <div
                            style={{
                              width: "100%",
                              padding: "0 16px 16px 16px",
                            }}
                          >
                            <Button
                              type="dashed"
                              block
                              onClick={addVariantProductTypeNew}
                              icon={<PlusOutlined />}
                            >
                              Add variant
                            </Button>
                          </div>
                        </Col>
                      </div>

                      <div className={styles["preview"]}>
                        <div
                          style={{
                            color: "black",
                            padding: "1rem",
                            fontSize: "1rem",
                            fontWeight: "600",
                            borderBottom: "1px solid #f0f0f0",
                            paddingBottom: "1rem",
                            width: "100%",
                          }}
                        >
                          Preview
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <Collapse ghost defaultActiveKey={['1']} expandIconPosition='right' style={{ width: '100%' }}>
                            <Collapse.Panel key="1">
                              <Row style={{ marginBottom: 20, display: !listCheckboxProductType.length && 'none' }}>
                                <Space>
                                  <ModalEditSupplierCost />
                                  <ModalEditProductCost />
                                  <ModalEditStatusVariants />
                                </Space>
                              </Row>
                              <Row style={{ fontWeight: 700, padding: '20px 0', backgroundColor: '#FAFAFA' }}>
                                <Col
                                  lg={3}
                                  xl={3}
                                >
                                  <Checkbox
                                    indeterminate={listCheckboxProductType.length && listCheckboxVariantProductType.length != listCheckboxProductType.length ? true : false}
                                    checked={listCheckboxProductType.length && listCheckboxVariantProductType.length == listCheckboxProductType.length ? true : false}
                                    onChange={(e) => {
                                      //rỗng hoặc có check
                                      if (e.target.checked) {
                                        setListCheckboxProductType([...listCheckboxVariantProductType])
                                      } else {
                                        setListCheckboxProductType([])
                                      }
                                    }}
                                  >
                                    {
                                      listCheckboxProductType.length ? listCheckboxProductType.length + ' select' : ''
                                    }
                                  </Checkbox>
                                </Col>
                                <Col
                                  lg={3}
                                  xl={3}
                                >
                                  Variants
                                </Col>
                                <Col
                                  lg={8}
                                  xl={8}
                                >
                                  SKU
                                </Col>
                                <Col
                                  lg={4}
                                  xl={4}
                                >
                                  Supplier Costs
                                </Col>
                                <Col
                                  lg={4}
                                  xl={4}
                                >
                                  Seller
                                </Col>
                                <Col
                                  lg={2}
                                  xl={2}
                                >
                                  Status
                                </Col>
                              </Row>
                              {variantsPreview.map((e, indexVariant) => {
                                return (
                                  e.values.map((v, indexValues) => {

                                    const InputSku = () => {
                                      if (!v.sku)
                                        variantsPreview[indexVariant].values[indexValues].sku = sku + '-' + v.title

                                      var splitSku = variantsPreview[indexVariant].values[indexValues].sku.split('-')
                                      return (
                                        <Input
                                          defaultValue={splitSku[1]}
                                          addonBefore={sku && `${sku}-`}
                                          onChange={(e) => {
                                            variantsPreview[indexVariant].values[indexValues].sku = sku + '-' + e.target.value
                                          }}
                                        />
                                      )
                                    }

                                    const CheckboxEdit = () =>
                                      <Checkbox
                                        value={v.title}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            let listCheckboxProductTypeNew = [...listCheckboxProductType]
                                            listCheckboxProductTypeNew.push(v.title)
                                            setListCheckboxProductType([...listCheckboxProductTypeNew])
                                          }
                                          else {
                                            const indexRemove = listCheckboxProductType.findIndex(j => j === v.title)
                                            if (indexRemove !== -1) {
                                              let listCheckboxProductTypeNew = [...listCheckboxProductType]
                                              listCheckboxProductTypeNew.splice(indexRemove, 1)
                                              setListCheckboxProductType([...listCheckboxProductTypeNew])
                                            }
                                          }
                                        }}
                                        defaultChecked={listCheckboxProductType.includes(v.title)}>
                                      </Checkbox>

                                    const InputBaseCost = () =>
                                      <InputNumber
                                        min={0}
                                        style={{ width: '100%' }}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        defaultValue={v.price || 0}
                                        onChange={(value) => {
                                          variantsPreview[indexVariant].values[indexValues].price = value
                                        }}
                                      />

                                    const InputRegularPrice = () =>
                                      <InputNumber
                                        min={0}
                                        style={{ width: '100%' }}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        defaultValue={v.regular_price || 0}
                                        onChange={(value) => {
                                          variantsPreview[indexVariant].values[indexValues].regular_price = value
                                        }}
                                      />

                                    const InputSalePrice = () =>
                                      <InputNumber
                                        min={0}
                                        style={{ width: '100%' }}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        defaultValue={v.sale_price || 0}
                                        onChange={(value) => {
                                          variantsPreview[indexVariant].values[indexValues].sale_price = value
                                        }}
                                      />

                                    const SwitchStatus = () =>
                                      <Switch
                                        disabled={location.state && 'none'}
                                        defaultChecked={v.is_active}
                                        onChange={(value) => {
                                          variantsPreview[indexVariant].values[indexValues].is_active = value
                                        }}
                                      />

                                    const CostSupplier = () =>
                                      v.cost_supplier.map((f, indexSupplier) => {
                                        const InputSupplierCosts = () =>
                                          <InputNumber
                                            min={0}
                                            style={{ width: '100%' }}
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            defaultValue={f.cost || 0}
                                            onChange={(value) => {
                                              let CS = variantsPreview[indexVariant].values[indexValues].cost_supplier
                                              CS[indexSupplier] = {
                                                name: f.name,
                                                cost: value
                                              }
                                              variantsPreview[indexVariant].values[indexValues].cost_supplier = [...CS]
                                            }}
                                          />
                                        return (
                                          <div className="supplier_cost">
                                            <div>{f.name}</div>
                                            <div>
                                              <InputSupplierCosts />
                                            </div>
                                          </div>
                                        )
                                      })

                                    return (
                                      <div
                                        style={{
                                          width: "100%",
                                          margin: "1.25rem 0",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          borderBottom: '1px solid #f0f0f0',
                                          paddingBottom: 18
                                        }}
                                      >
                                        <Row
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Col
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={3}
                                            xl={3}
                                            style={{ paddingRight: '8px' }}
                                          >
                                            <CheckboxEdit />
                                          </Col>
                                          <Col
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={3}
                                            xl={3}
                                            style={{ paddingRight: '8px' }}
                                          >
                                            <div>{v.title}</div>
                                          </Col>
                                          <Col
                                            xs={23}
                                            sm={23}
                                            md={11}
                                            lg={8}
                                            xl={8}
                                            style={{ paddingRight: '16px' }}
                                          >
                                            <InputSku />
                                          </Col>
                                          <Col
                                            xs={23}
                                            sm={23}
                                            md={11}
                                            lg={4}
                                            xl={4}
                                            style={{ paddingRight: '8px' }}
                                          >
                                            <CostSupplier />
                                          </Col>
                                          <Col
                                            xs={23}
                                            sm={23}
                                            md={11}
                                            lg={4}
                                            xl={4}
                                            style={{ padding: '0 8px' }}
                                          >
                                            <div className="supplier_cost">
                                              <div className="supplier_cost">
                                                <div>Base Cost</div>
                                                <div>
                                                  <InputBaseCost />
                                                </div>
                                              </div>
                                              <div className="supplier_cost">
                                                <div>Regular Price</div>
                                                <div>
                                                  <InputRegularPrice />
                                                </div>
                                              </div>
                                              <div className="supplier_cost">
                                                <div>Sale Price</div>
                                                <div>
                                                  <InputSalePrice />
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col
                                            xs={1}
                                            sm={1}
                                            md={1}
                                            lg={2}
                                            xl={2}
                                            style={{ padding: '0 8px' }}
                                          >
                                            <SwitchStatus />
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  })
                                );
                              })}
                            </Collapse.Panel>
                          </Collapse>
                        </div>
                      </div>
                      
                      <div
                        className={styles["modal_detail_row_col_parent_bottom"]}
                      >
                        <div
                          className={
                            styles["modal_detail_row_col_parent_title"]
                          }
                        >
                          Template Upload
                        </div>
                        <div
                          className={
                            styles[
                            "modal_detail_row_col_parent_bottom_upload_images"
                            ]
                          }
                        >
                          <Form.Item
                            className={
                              styles[
                              "modal_detail_row_col_parent_bottom_images"
                              ]
                            }
                            name="template_upload"
                            valuePropName="fileList"
                            getValueFromEvent={normFileImagesTemplateUpload}
                            noStyle
                          >
                            <Upload.Dragger
                              multiple
                              name="files"
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              listType="picture"
                              showUploadList={{
                                showRemoveIcon: true,
                                removeIcon: <Popconfirm
                                  title="Are you sure to delete this file?"
                                  okText="Yes"
                                  cancelText="No"
                                  onCancel={(e) => e.stopPropagation()}
                                >
                                  <div 
                                    style={{
                                      position: 'absolute',
                                      left: '-1px',
                                      right: '-1px',
                                      top: '-1px',
                                      bottom: '-1px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <DeleteOutlined />
                                  </div>
                                </Popconfirm>
                              }}
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                Support for a .PNG, .JPG, .TIFF, .EPS image.
                              </p>
                            </Upload.Dragger>
                          </Form.Item>
                        </div>
                      </div>

                      <div className={styles["variants_fix"]}>
                        <div className={styles["variants_fix_title"]}>
                          <Row justify='space-between' style={{ width: '100%' }}>
                            Print Files
                            <Button type="link" icon={<PlusOutlined />} onClick={addPrintFileProductTypeNew}>
                              Add Print File
                            </Button>
                          </Row>
                        </div>
                        <div style={{ padding: "0 1rem", width: "100%" }}>
                          <Row
                            className={styles["variants_fix_body_row"]}
                          >
                            <Col
                              xs={24}
                              sm={24}
                              md={11}
                              lg={3}
                              xl={3}
                              className={
                                styles["variants_fix_body_row_col"]
                              }
                            >
                              <div> Print file</div>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={11}
                              lg={6}
                              xl={6}
                              className={
                                styles["variants_fix_body_row_col"]
                              }
                            >
                              <div>Name</div>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={11}
                              lg={6}
                              xl={6}
                              className={
                                styles["variants_fix_body_row_col"]
                              }
                            >
                              <div>Size</div>
                            </Col>
                            <Col
                              xs={24}
                              sm={24}
                              md={11}
                              lg={6}
                              xl={6}
                              className={
                                styles["variants_fix_body_row_col"]
                              }
                            >
                              <div>Note</div>
                            </Col>
                          </Row>
                        </div>
                        <Collapse expandIconPosition='right' ghost defaultActiveKey={['1']} style={{ width: "100%" }}>
                          <Collapse.Panel key="1">
                            {printFilesPreview.map((e, index) => {
                              const InputFileName = () =>
                                <Input
                                  defaultValue={e.name_file}
                                  placeholder="Enter print name"
                                  onChange={(e) => {
                                    printFilesPreview[index].name_file = e.target.value
                                  }}
                                />

                              const InputSize = () =>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <Input
                                    defaultValue={e.width}
                                    placeholder="Width"
                                    onChange={(e) => {
                                      printFilesPreview[index].width = e.target.value
                                    }}
                                    style={{ marginRight: 6 }}
                                  />
                                  x
                                  <Input
                                    defaultValue={e.height}
                                    placeholder="Height"
                                    onChange={(e) => {
                                      printFilesPreview[index].height = e.target.value
                                    }}
                                    style={{ marginLeft: 6 }}
                                  />
                                </div>


                              const InputNote = () => <Input defaultValue={e.note} onChange={(e) => {
                                printFilesPreview[index].note = e.target.value
                              }} />

                              const UploadImage = () =>
                                <Upload
                                  name="avatar"
                                  className="upload-print-file"
                                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                  listType="picture-card"
                                  showUploadList={false}
                                  data={async (file) => {
                                    if (file) {
                                      let printFilesNew = printFilesPreview
                                      const getBase64 = (file) => {
                                        return new Promise((resolve, reject) => {
                                          const reader = new FileReader();
                                          reader.readAsDataURL(file);
                                          reader.onload = () =>
                                            resolve(reader.result);
                                          reader.onerror = (error) =>
                                            reject(error);
                                        });
                                      };

                                      const base64 = await getBase64(file);
                                      printFilesNew[index].image_file = base64
                                      printFilesNew[index].fileUpload = file
                                      setPrintFilesPreview([...printFilesNew])
                                    }
                                  }}
                                >
                                  {e.image_file ? (
                                    <img
                                      src={e.image_file}
                                      alt=""
                                      style={{ width: '100%' }}
                                    />
                                  ) : (
                                    <div>
                                      <PlusOutlined />
                                      <div style={{ marginTop: 4 }}>Upload</div>
                                    </div>
                                  )}
                                </Upload>

                              return <div
                                className={styles["variants_fix_body"]}
                              >
                                <Row
                                  className={
                                    styles["variants_fix_body_row_fix"]
                                  }
                                  style={{ borderBottom: '1px solid #f0f0f0' }}
                                >
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={3}
                                    xl={3}
                                    className={
                                      styles["variants_fix_body_row_col"]
                                    }
                                  >
                                    <UploadImage />
                                  </Col>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={7}
                                    xl={7}
                                    className={
                                      styles["variants_fix_body_row_col"]
                                    }
                                    style={{ padding: '0 8px' }}
                                  >
                                    <InputFileName />
                                  </Col>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={7}
                                    xl={7}
                                    className={
                                      styles["variants_fix_body_row_col"]
                                    }
                                    style={{ padding: '0 8px' }}
                                  >
                                    <InputSize />
                                  </Col>
                                  <Col
                                    xs={24}
                                    sm={24}
                                    md={11}
                                    lg={6}
                                    xl={6}
                                    className={
                                      styles["variants_fix_body_row_col"]
                                    }
                                    style={{ padding: '0 8px' }}
                                  >
                                    <InputNote />
                                  </Col>
                                  <Col
                                    lg={1}
                                    xl={1}
                                  >
                                    <Popconfirm
                                      title="Are you sure to delete?"
                                      onConfirm={() => {
                                        let printFilesNew = printFilesPreview
                                        printFilesNew.splice(index, 1)
                                        setPrintFilesPreview([...printFilesNew])
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <MinusCircleOutlined
                                        style={{
                                          fontSize: "1rem",
                                          color: "red",
                                          visibility: printFilesPreview.length == 1 && 'hidden'
                                        }}
                                      />
                                    </Popconfirm>
                                  </Col>
                                </Row>
                              </div>
                            }
                            )}
                            <Form.Item
                              style={{
                                width: "100%",
                                padding: "1.5rem 1rem",
                                display: printFilesPreview.length == 10 && 'none'
                              }}
                            >
                              <Button
                                type="dashed"
                                onClick={addPrintFileProductTypeNew}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add Print File
                              </Button>
                            </Form.Item>
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    </Col>

                    <Col
                      style={{width: 300}}
                    >
                      <div className={styles["modal_detail_row_col_right"]}>
                        <div
                          className={
                            styles["modal_detail_row_col_right_parent"]
                          }
                        >
                          <div
                            style={{ fontSize: "1rem" }}
                            className={
                              styles["modal_detail_row_col_right_parent_title"]
                            }
                          >
                            Suppliers
                          </div>
                          <Form.Item
                            style={{ padding: "1rem", width: "100%" }}
                            className={
                              styles["modal_detail_row_col_right_parent_bottom"]
                            }
                            name="suppliers"
                            label="Suppliers"
                            rules={[
                              {
                                required: true,
                                message: "Please select your suppliers!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              placeholder="Please select your suppliers"
                              onChange={(value) => {

                                let costSupplier = value.map(e => {
                                  return {
                                    name: e,
                                    cost: ''
                                  }
                                })

                                setSuppliersProductType([...costSupplier])

                                let variantsPreviewNew = [...variantsPreview]
                                variantsPreview.map((v, indexVariant) => {
                                  v.values.map((f, indexValues) => {
                                    variantsPreviewNew[indexVariant].values[indexValues].cost_supplier = costSupplier
                                  })
                                })

                                setVariantsPreview([...variantsPreviewNew])
                              }}
                            >
                              {listUser.map((user) => {
                                return <Option value={user.username}>{user.username}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                        </div>

                        <div
                          className={
                            styles["modal_detail_row_col_right_parent"]
                          }
                          style={{ border: '1px solid #f0f0f0', marginBottom: 24 }}
                        >
                          <div
                            style={{ fontSize: "1rem", border: 'none', borderBottom: '1px solid #f0f0f0' }}
                            className={
                              styles["modal_detail_row_col_right_parent_title"]
                            }
                          >
                            Carrier
                          </div>
                          <Form.Item
                            style={{
                              padding: "1rem 1rem 1rem 1rem",
                              width: "100%",
                            }}
                            name="carrier"
                            label="Carrier"
                            rules={[{
                              required: true,
                              message: "Please select carrier"
                            }]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Please select carrier"
                              mode="multiple"
                              onSelect={(value) => {
                                let carrierProductTypeNew = [...carrierProductType]
                                const getCarrier = carrier.find(e => e.code === value)
                                if (getCarrier) {
                                  carrierProductTypeNew.push(getCarrier)
                                  setCarrierProductType([...carrierProductTypeNew])
                                }
                              }}
                              onDeselect={(value) => {
                                let carrierProductTypeNew = [...carrierProductType]
                                const indexRemove = carrierProductType.findIndex(e => e.idCarrier === value)
                                if (indexRemove !== -1) {
                                  carrierProductTypeNew.splice(indexRemove, 1)
                                  setCarrierProductType([...carrierProductTypeNew])
                                }
                              }}
                            >
                              {carrier.map((values, index) => {
                                return <Option value={values.code} key={index}>{values.name}</Option>;
                              })}
                            </Select>
                          </Form.Item>
                          <div style={{ padding: '0px 16px 16px 16px' }}>
                            {carrierProductType.map((e, index) => {
                              const Cost = () => <InputNumber
                                defaultValue={e.cost || 0}
                                style={{ width: 120 }}
                                min={0}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => {
                                  carrierProductType[index] = {
                                    ...carrierProductType[index],
                                    cost: value
                                  }
                                }}
                              />
                              return <Row align='middle' justify='space-between' style={{ marginBottom: 10 }}>
                                <span style={{ fontSize: 12, marginBottom: 0, fontWeight: 500 }}>
                                  {e.name}
                                </span>
                                <Cost />
                              </Row>
                            }
                            )}
                          </div>
                        </div>

                        <div
                          className={
                            styles["modal_detail_row_col_right_parent"]
                          }
                        >
                          <div
                            style={{ fontSize: "1rem" }}
                            className={
                              styles["modal_detail_row_col_right_parent_title"]
                            }
                          >
                            Category
                          </div>
                          <Form.Item
                            style={{ padding: "1rem", width: "100%" }}
                            className={
                              styles["modal_detail_row_col_right_parent_bottom"]
                            }
                            name="category"
                            label="Category"
                            rules={[
                              {
                                required: true,
                                message: "Please select your category!",
                                // type: "array",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              placeholder="Please select category"
                            >
                              {productTypeGroup.map((values, index) => {
                                return (
                                  <Option value={values.name}>
                                    {values.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>

                        <div
                          className={
                            styles["modal_detail_row_col_right_parent"]
                          }
                        >
                          <div
                            style={{ fontSize: "1rem" }}
                            className={
                              styles["modal_detail_row_col_right_parent_title"]
                            }
                          >
                            Shipping country
                          </div>
                          <div
                            style={{
                              display: "flex",
                              // flexDirection:'column',
                              padding: "1rem 1rem 0 1rem",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                            className={
                              styles["modal_detail_row_col_right_parent_bottom"]
                            }
                          >
                            <Form.Item
                              style={{ width: "50%", marginTop: "0.5rem" }}
                              name="includeExclude"
                            >
                              <Select
                                placeholder="Select"
                                defaultValue="include"
                              >
                                <Option value="exclude">Exclude</Option>
                                <Option value="include">Include</Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              style={{ width: "50%", marginTop: "0.5rem" }}
                              name="country"
                            >
                              <Select
                                mode="multiple"
                                defaultValue="all"
                                placeholder="Please select country"
                              >
                                <Option value='all'>All</Option>
                                {country.map((values, index) => {
                                  return (
                                    <Option value={values.country_code}>
                                      {values.country_name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </Row>
          </div>

          <div className={styles["product_add_content_select"]}>
            <Row className={styles["product_add_content_select_row"]}>
              <Col
                className={styles["product_add_content_select_row_col"]}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div
                  className={styles["product_add_content_select_row_col_title"]}
                ></div>
              </Col>
              <Col
                className={styles["product_add_content_select_row_col"]}
                xs={24}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                {addProduct.length ? (
                  <div
                    className={
                      styles["product_add_content_select_row_col_button"]
                    }
                  >
                    <Affix offsetTop={15}>
                      <Button style={{ width: "8.75rem" }} type="primary" onClick={() => setModal1VisibleModal(true)}>
                        Continue
                      </Button>
                    </Affix>
                  </div>
                ) : ''}

                <Drawer
                  title={recordProduct ? "Edit product" : "Add new product"}
                  width="100%"
                  footer={<Row justify='end'>
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => _createProduct()}
                        style={{ display: recordProduct && 'none' }}
                      >
                        Create New
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => _createProduct(false)}
                        style={{ display: recordProduct && 'none' }}
                      >
                        Save Draft
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => _createProduct()}
                        style={{ display: !recordProduct && 'none' }}

                      >
                        Save
                      </Button>
                      <a href={window.location.href}>
                        <Button
                          icon={<UndoOutlined />}
                          style={{ display: !recordProduct && 'none' }}
                        >
                          Reset
                        </Button>
                      </a>
                      <Button onClick={() => {
                        Modal.confirm({
                          title: 'Do you want to exit?',
                          icon: <ExclamationCircleOutlined />,
                          onOk() {
                            setModal1VisibleModal(false)
                            history.push(ROUTES.PRODUCTS)  
                          },
                        });
                      }}>Cancel</Button>
                    </Space>
                  </Row>}
                  visible={modal1Visible}
                  onClose={() => {
                    Modal.confirm({
                      title: 'Do you want to exit?',
                      icon: <ExclamationCircleOutlined />,
                      onOk() {
                        setModal1VisibleModal(false)
                        history.push(ROUTES.PRODUCTS)  
                      },
                    });
                  }}
                >
                  <Form
                    form={formProduct}
                    className={styles["modal_detail"]}
                    layout="vertical"
                  >
                    <Row className={styles["modal_detail_row"]}>
                      <Col
                        className={styles["modal_detail_row_col"]}
                        xs={24}
                        sm={24}
                        md={18}
                        lg={18}
                        xl={18}
                      >
                        <div className={styles["modal_detail_row_col_parent"]}>
                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input your title!",
                                  },
                                ]}
                              >
                                <Input
                                  className={
                                    styles[
                                    "modal_detail_row_col_parent_row_col_input"
                                    ]
                                  }
                                  placeholder="Enter title"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row
                            className={
                              styles["modal_detail_row_col_parent_row"]
                            }
                          >
                            <Col
                              className={
                                styles["modal_detail_row_col_parent_row_col"]
                              }
                              xs={24}
                              sm={24}
                              md={24}
                              lg={24}
                              xl={24}
                            >
                              <Form.Item
                                label="Description"
                                name="description"
                                help={!descriptionProduct && "Please enter description"}
                                validateStatus="error"
                              >
                                {initDataEditor && <CKEditor
                                  {...initDataEditor}
                                  onChange={(e) => setDescriptionProduct(e.editor.getData())}
                                />}
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>

                        <div
                          className={
                            styles["modal_detail_row_col_title_parent"]
                          }
                        >
                          <Tabs
                            defaultActiveKey="0"
                            style={{ width: '100%' }} animated
                            onChange={(key) => {
                              setKeyActiveTab(key)
                              setSelectRowKeyProduct([]) //reset lại các các checkbox khi chuyển qua tab mới
                            }}
                          >
                            {addProduct.map((d, index) => {

                              return <Tabs.TabPane tab={d.title} key={index}>
                                <div
                                  className={
                                    styles["modal_detail_row_col_parent_bottom"]
                                  }
                                >
                                  <div
                                    className={
                                      styles["modal_detail_row_col_parent_title"]
                                    }
                                  >
                                    Mockup Images
                                  </div>
                                  <div
                                    className={
                                      styles[
                                      "modal_detail_row_col_parent_bottom_upload_images"
                                      ]
                                    }
                                  >
                                    <Upload.Dragger
                                      name="files"
                                      listType="picture"
                                      multiple
                                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                      defaultFileList={d.mockup.map((e, index) => {
                                        let nameFile = ['image']
                                        if (typeof e === 'string')
                                          nameFile = e.split('/')
                                        return {
                                          uid: index,
                                          name: nameFile[nameFile.length - 1] || 'image',
                                          status: 'done',
                                          url: e,
                                          thumbUrl: e,
                                        }
                                      })}
                                      data={(file) => {
                                        addProduct[index].mockup.push(file)
                                      }}
                                      onRemove={(file) => {
                                        let indexRemoveFile =
                                          addProduct[index].mockup.findIndex(e => JSON.stringify(file.originFileObj) == JSON.stringify(e) || JSON.stringify(file.thumbUrl) == JSON.stringify(e))
                                        if (indexRemoveFile != -1)
                                          addProduct[index].mockup.splice(indexRemoveFile, 1)
                                      }}
                                      onChange={(info) => {
                                        if (info.file.status != 'done') info.file.status = 'done'
                                      }}
                                      showUploadList={{
                                        showRemoveIcon: true,
                                        removeIcon: <Popconfirm
                                          title="Are you sure to delete this image?"
                                          okText="Yes"
                                          cancelText="No"
                                          onCancel={(e) => e.stopPropagation()}
                                        >
                                          <div 
                                            style={{
                                              position: 'absolute',
                                              left: '-1px',
                                              right: '-1px',
                                              top: '-1px',
                                              bottom: '-1px',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <DeleteOutlined />
                                          </div>
                                        </Popconfirm>
                                      }}
                                    >
                                      <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p>
                                      <p className="ant-upload-text">
                                        Click or drag file to this area to upload
                                      </p>
                                      <p className="ant-upload-hint">
                                        Support for a .PNG, .JPG, .TIFF, .EPS image.
                                      </p>
                                    </Upload.Dragger>
                                  </div>
                                </div>

                                <div
                                  className={
                                    styles["modal_detail_row_col_parent_bottom"]
                                  }
                                >
                                  <div
                                    className={
                                      styles["modal_detail_row_col_parent_title"]
                                    }
                                  >
                                    Variants
                                  </div>
                                  <div
                                    className={
                                      styles[
                                      "modal_detail_row_col_parent_bottom_upload_images"
                                      ]
                                    }
                                  >
                                    <Collapse
                                      defaultActiveKey="0"
                                      expandIconPosition="right"
                                      ghost
                                    >
                                      <Collapse.Panel key="0">
                                        {selectRowKeyProduct.length ? (
                                          <Space style={{ marginBottom: 15 }} wrap>
                                            <ModalEditImage />
                                            <ModalEditBaseCost />
                                            <ModalEditRegularPrice />
                                            <ModalEditSalePrice />
                                            <ModalEditProductSku />
                                            <ModalEditStatus />
                                          </Space>
                                        ) : ''}
                                        <Table
                                          size="small"
                                          columns={columnsVariantProduct}
                                          rowSelection={{
                                            selectedRowKeys: selectRowKeyProduct,
                                            onChange: (selectedRowKeys, selectedRows) => {
                                              setSelectRowProduct(selectedRows)
                                              setSelectRowKeyProduct(selectedRowKeys)
                                          }}}
                                          dataSource={variantsProduct[index]}
                                          pagination={false}
                                          rowKey="key"
                                        />
                                      </Collapse.Panel>
                                    </Collapse>
                                  </div>
                                </div>
                                <div
                                  className={styles["modal_detail_row_col_parent_bottom"]}
                                >
                                  <div
                                    className={
                                      styles["modal_detail_row_col_parent_title"]
                                    }
                                  >
                                    Template Upload
                                  </div>
                                  <div
                                    className={
                                      styles[
                                      "modal_detail_row_col_parent_bottom_upload_images"
                                      ]
                                    }
                                  >
                                    <Upload.Dragger
                                      multiple
                                      name="files"
                                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                      listType="picture"
                                      showUploadList={{ showRemoveIcon: false }}
                                      defaultFileList={d.template_upload.map((e, index) => {
                                        let nameFile = ['file']
                                        if (typeof e === 'string')
                                          nameFile = e.split('/')

                                        return {
                                          uid: index,
                                          name: nameFile[nameFile.length - 1] || 'file',
                                          status: 'done',
                                          url: e,
                                          thumbUrl: e,
                                        }
                                      })}
                                      data={(file) => {
                                        addProduct[index].template_upload.push(file)
                                      }}
                                      onRemove={(file) => {

                                        let indexRemoveFile =
                                          addProduct[index].template_upload.findIndex(e => JSON.stringify(file.originFileObj) == JSON.stringify(e) || JSON.stringify(file.thumbUrl) == JSON.stringify(e))
                                        if (indexRemoveFile != -1)
                                          addProduct[index].template_upload.splice(indexRemoveFile, 1)
                                      }}
                                      onChange={(info) => {
                                        if (info.file.status != 'done') info.file.status = 'done'
                                      }}
                                      style={{display: 'none'}} //ẩn component upload 
                                    >
                                      <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                      </p>
                                      <p className="ant-upload-text">
                                        Click or drag file to this area to upload
                                      </p>
                                      <p className="ant-upload-hint">
                                        Support for a .PNG, .JPG, .TIFF, .EPS image.
                                      </p>
                                    </Upload.Dragger>
                                  </div>
                                </div>
                              </Tabs.TabPane>
                            })}
                          </Tabs>
                        </div>

                        <div
                        className={styles["modal_detail_row_col_parent_bottom"]}
                      >
                        <div
                          className={
                            styles["modal_detail_row_col_parent_title"]
                          }
                          style={{
                            display: 'flex',
                            flexDirection:'column'
                          }}
                        >
                          <h3 style={{fontSize: '1.15rem',marginBottom: 3, fontWeight: 600}}>Personalization ( Etsy Only )</h3>
                          <p style={{color: 'gray', fontSize: 15, fontWeight: 400, marginBottom: 0}}>Collect personalized information for this listing</p>
                        </div>
                        <div
                          className={
                            styles[
                            "modal_detail_row_col_parent_bottom_upload_images"
                            ]
                          }
                        >
                          <h3 style={{fontSize: '1rem',marginBottom: 3, fontWeight: 600}}>Instructions for buyers</h3>
                          <p style={{color: 'gray', fontSize: 14, fontWeight: 400}}>Enter the personalization instructions you want buyers to see</p>
                          <Form.Item name="personalization_instructions">
                            <Input.TextArea rows={5} placeholder="Example: Enter the name you want on the necklace. Max 12 characters, no spaces, no special characters. Thank you!"/>
                          </Form.Item>
                        </div>
                      </div>

                        <div
                          className={
                            styles["modal_detail_row_col_parent_bottom"]
                          }
                        >
                          <div
                            className={
                              styles["modal_detail_row_col_parent_title"]
                            }
                          >
                            Print Files
                          </div>
                          <div className={styles["modal_detail_row_col_parent_bottom_upload_images"]}>
                            <Collapse
                              defaultActiveKey="0"
                              expandIconPosition="right"
                              ghost
                            >
                              <Collapse.Panel key="0">
                                <Table
                                  size="small"
                                  columns={columnsPrintFilesProduct}
                                  dataSource={printFilesProduct}
                                  pagination={false}
                                />
                              </Collapse.Panel>
                            </Collapse>
                          </div>
                        </div>
                      </Col>

                      <Col
                        className={
                          styles["modal_detail_row_col_right_parent_two"]
                        }
                        xs={24}
                        sm={24}
                        md={5}
                        lg={5}
                        xl={5}
                      >
                        <div className={styles["modal_detail_row_col_right"]}>
                          <div
                            className={
                              styles["modal_detail_row_col_right_parent"]
                            }
                          >
                            <div
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_title"
                                ]
                              }
                            >
                              Site
                            </div>
                            <Form.Item
                              style={{ padding: "1rem", width: "100%" }}
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_bottom"
                                ]
                              }
                              label="Site"
                              name="idSite"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select your site!",
                                },
                              ]}
                            >
                              <Select
                                disabled={recordProduct && true}
                                allowClear
                                placeholder="Select your site"
                                onChange={(value) => {
                                  const indexSite = sites.findIndex(e => e.site_name === value)
                                  if (indexSite != -1) setPlatform(sites[indexSite].platform)
                                }}
                              >
                                {sites.map((e) => (
                                  <Option value={e.site_name}>{e.site_name}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className={
                              styles["modal_detail_row_col_right_parent"]
                            }
                          >
                            <div
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_title"
                                ]
                              }
                            >
                              Categories
                            </div>
                            <Form.Item
                              style={{ padding: "1rem", width: "100%" }}
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_bottom"
                                ]
                              }
                              name="categories"
                            >
                              <Select
                                disabled={true}
                              // onChange={(value) => setCategory(value)}
                              >
                                {productTypeGroup.map((e) => (
                                  <Select.Option value={e.name}>
                                    {e.name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            className={
                              styles["modal_detail_row_col_right_parent"]
                            }
                          >
                            <div
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_title"
                                ]
                              }
                            >
                              Tags
                            </div>
                            <Form.Item
                              style={{ padding: "1rem", width: "100%" }}
                              className={
                                styles[
                                "modal_detail_row_col_right_parent_bottom"
                                ]
                              }
                              name="tags"
                            >
                              <Select
                                mode="tags"
                                placeholder="Please input tags"
                                // onChange={(value) => setTags(value)}
                                style={{ width: "100%" }}
                              ></Select>
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Drawer>
              </Col>
            </Row>
          </div>
          <Row style={{marginBottom: 20, width: '100%'}}>
            <Input.Search 
              placeholder="Input search product type" 
              onChange={onSearchProductType}
              allowClear
              style={{width: 300}}
            />
          </Row>
          {valueSearchProductType ? <RenderProductTypeBySearch /> : <RenderProductType />}
        </div>
      </Form>
    </div>
  );
}
